import {databases, storage, teams} from "@/config/server-api";

const delay = ms => new Promise(res => setTimeout(res, ms));

import {
    budgetPlanCategoryCollection,
    budgetPlanCollection,
    budgetPlanStandardCategoryCollection,
    contactPersonCollection,
    customerCollection,
    customerStatusCollection,
    customFieldCollection,
    customFieldValueCollection,
    eventCollection, historyEntryCollection,
    invoiceCollection, invoicePositionCollection, invoiceSettingCollection,
    locationCollection, myProductsCollection,
    notificationCollection,
    paymenStatusCollection, plannerAccountCollection, profilePicBucket,
    singleBudgetCollection, stilCollection,
    taskCollection, templateBudgetPlanCategoryCollection, templateBudgetPlanEntryCollection,
    templateCollection, templateTimePlanEntriesCollection,
    timePlanCollection,
    timePlanEntryCollection,
    vendorCategoryCollection,
    vendorCollection, vendorServicesCollection,
    vendorStatusCollection,
    weddingCollection, weddingFileBucket, weddingFilesCollection, weddingLabelCollection,
    weddingStatusCollection,
    weddingVendorsCollection,
} from "@/config/awSettings"
import {Query} from "appwrite";

export default {
    namespaced: true,
    state: {
        count: {},
        deleted: 0,
        progressed: 0,
        errors: 0,
    },
    getters: {
        count: (state) => state.count,
        deleted: (state) => state.deleted,
        progressed: (state) => state.progressed,
        errors: (state) => state.errors,
    },
    actions: {

        /**
         * Erstellt einen neuen Lead
         *
         * @param payload
         * @returns {Promise<unknown>}
         */
        getUserData({commit}, payload) {
            return new Promise((resolve, reject) => {
                let count = {}
                databases.listDocuments(paymenStatusCollection, [], 0).then(function (documentList) {
                    if (payload.uid === '6365163c0426092c3802') {
                        reject('Nicht möglich.')
                    } //6365163c0426092c3802
                    for (let doc of documentList.documents) {
                        if (doc.status !== 'canceled') {
                            reject('Bitte zuerst Abo kündigen.')
                        }
                    }
                    databases.listDocuments(customerCollection, [], 0).then(function (documentList) {
                        count.customers = documentList.total
                    }).catch((error) => {
                        reject(error)
                    }).finally(() => {
                        databases.listDocuments(eventCollection, [], 0).then(function (documentList) {
                            count.events = documentList.total
                        }).catch((error) => {
                            reject(error)
                        }).finally(() => {
                            databases.listDocuments(taskCollection, [], 0).then(function (documentList) {
                                count.tasks = documentList.total
                            }).catch((error) => {
                                reject(error)
                            }).finally(() => {
                                databases.listDocuments(weddingCollection, [], 0).then(function (documentList) {
                                    count.weddings = documentList.total
                                }).catch((error) => {
                                    reject(error)
                                }).finally(() => {
                                    databases.listDocuments(vendorCollection, [], 0).then(function (documentList) {
                                        count.vendor = documentList.total
                                    }).catch((error) => {
                                        reject(error)
                                    }).finally(() => {
                                        databases.listDocuments(contactPersonCollection, [], 0).then(function (documentList) {
                                            count.contactPerson = documentList.total
                                        }).catch((error) => {
                                            reject(error)
                                        }).finally(() => {
                                            databases.listDocuments(budgetPlanCollection, [], 0).then(function (documentList) {
                                                count.budgetPlan = documentList.total
                                            }).catch((error) => {
                                                reject(error)
                                            }).finally(() => {
                                                databases.listDocuments(timePlanCollection, [], 0).then(function (documentList) {
                                                    count.timePlan = documentList.total
                                                }).catch((error) => {
                                                    reject(error)
                                                }).finally(() => {
                                                    databases.listDocuments(locationCollection, [], 0).then(function (documentList) {
                                                        count.location = documentList.total
                                                    }).catch((error) => {
                                                        reject(error)
                                                    }).finally(() => {
                                                        databases.listDocuments(templateCollection, [], 0).then(function (documentList) {
                                                            count.template = documentList.total
                                                        }).catch((error) => {
                                                            reject(error)
                                                        }).finally(() => {
                                                            databases.listDocuments(invoiceCollection, [], 0).then(function (documentList) {
                                                                count.invoice = documentList.total
                                                            }).catch((error) => {
                                                                reject(error)
                                                            }).finally(() => {
                                                                commit('setCount', count)
                                                                resolve()
                                                            })
                                                        })
                                                    })
                                                })
                                            })
                                        })
                                    })
                                })
                            })
                        })
                    })
                }).catch((error) => {
                    reject(error)
                })
            })
        },
        // eslint-disable-next-line no-empty-pattern
        deleteAccount({dispatch, commit}, payload) {
            return new Promise((resolve,reject) => {
                commit('setProgress', {
                    actDeleted: 0,
                    actErrors: 0,
                    progressed: 0
                })
                dispatch('deleteAllEntriesFromEntityPlannerId', {
                    collection: customerCollection,
                    uid: payload.uid
                }).finally(() => {
                    dispatch('deleteAllEntriesFromEntityPlannerId', {
                        collection: eventCollection,
                        uid: payload.uid
                    }).finally(() => {
                        dispatch('deleteAllEntriesFromEntityPlannerId', {
                            collection: taskCollection,
                            uid: payload.uid
                        }).finally(() => {
                            dispatch('deleteAllEntriesFromEntityPlannerId', {
                                collection: weddingCollection,
                                uid: payload.uid
                            }).finally(() => {
                                dispatch('deleteAllEntriesFromEntity', {
                                    collection: weddingVendorsCollection,
                                    uid: payload.uid
                                }).finally(() => {
                                    dispatch('deleteAllEntriesFromEntityPlannerId', {
                                        collection: vendorCollection,
                                        uid: payload.uid
                                    }).finally(() => {
                                        dispatch('deleteAllEntriesFromEntity', {
                                            collection: contactPersonCollection,
                                            uid: payload.uid
                                        }).finally(() => {
                                            dispatch('deleteAllEntriesFromEntity', {
                                                collection: paymenStatusCollection,
                                                uid: payload.uid
                                            }).finally(() => {
                                                dispatch('deleteAllEntriesFromEntity', {
                                                    collection: budgetPlanCollection,
                                                    uid: payload.uid
                                                }).finally(() => {
                                                    dispatch('deleteAllEntriesFromEntity', {
                                                        collection: singleBudgetCollection,
                                                        uid: payload.uid
                                                    }).finally(() => {
                                                        dispatch('deleteAllEntriesFromEntity', {
                                                            collection: budgetPlanCategoryCollection,
                                                            uid: payload.uid
                                                        }).finally(() => {
                                                            dispatch('deleteAllEntriesFromEntity', {
                                                                collection: budgetPlanStandardCategoryCollection,
                                                                uid: payload.uid
                                                            }).finally(() => {
                                                                dispatch('deleteAllEntriesFromEntity', {
                                                                    collection: timePlanCollection,
                                                                    uid: payload.uid
                                                                }).finally(() => {
                                                                    dispatch('deleteAllEntriesFromEntity', {
                                                                        collection: timePlanEntryCollection,
                                                                        uid: payload.uid
                                                                    }).finally(() => {
                                                                        dispatch('deleteAllEntriesFromEntityPlannerId', {
                                                                            collection: vendorCategoryCollection,
                                                                            uid: payload.uid
                                                                        }).finally(() => {
                                                                            dispatch('deleteAllEntriesFromEntityPlannerId', {
                                                                                collection: vendorStatusCollection,
                                                                                uid: payload.uid
                                                                            }).finally(() => {
                                                                                dispatch('deleteAllEntriesFromEntityPlannerId', {
                                                                                    collection: weddingStatusCollection,
                                                                                    uid: payload.uid
                                                                                }).finally(() => {
                                                                                    dispatch('deleteAllEntriesFromEntityPlannerId', {
                                                                                        collection: customerStatusCollection,
                                                                                        uid: payload.uid
                                                                                    }).finally(() => {
                                                                                        dispatch('deleteAllEntriesFromEntityPlannerId', {
                                                                                            collection: customFieldCollection,
                                                                                            uid: payload.uid
                                                                                        }).finally(() => {
                                                                                            dispatch('deleteAllEntriesFromEntity', {
                                                                                                collection: customFieldValueCollection,
                                                                                                uid: payload.uid
                                                                                            }).finally(() => {
                                                                                                dispatch('deleteAllEntriesFromEntityPlannerId', {
                                                                                                    collection: notificationCollection,
                                                                                                    uid: payload.uid
                                                                                                }).finally(() => {
                                                                                                    dispatch('deleteAllEntriesFromEntity', {
                                                                                                        collection: historyEntryCollection,
                                                                                                        uid: payload.uid
                                                                                                    }).finally(() => {
                                                                                                        dispatch('deleteAllEntriesFromEntityPlannerId', {
                                                                                                            collection: myProductsCollection,
                                                                                                            uid: payload.uid
                                                                                                        }).finally(() => {
                                                                                                            dispatch('deleteAllEntriesFromEntityPlannerId', {
                                                                                                                collection: locationCollection,
                                                                                                                uid: payload.uid
                                                                                                            }).finally(() => {
                                                                                                                dispatch('deleteAllEntriesFromEntityPlannerId', {
                                                                                                                    collection: stilCollection,
                                                                                                                    uid: payload.uid
                                                                                                                }).finally(() => {
                                                                                                                    dispatch('deleteAllEntriesFromEntity', {
                                                                                                                        collection: vendorServicesCollection,
                                                                                                                        uid: payload.uid
                                                                                                                    }).finally(() => {
                                                                                                                        dispatch('deleteAllEntriesFromEntity', {
                                                                                                                            collection: templateCollection,
                                                                                                                            uid: payload.uid
                                                                                                                        }).finally(() => {
                                                                                                                            dispatch('deleteAllEntriesFromEntity', {
                                                                                                                                collection: templateBudgetPlanCategoryCollection,
                                                                                                                                uid: payload.uid
                                                                                                                            }).finally(() => {
                                                                                                                                dispatch('deleteAllEntriesFromEntity', {
                                                                                                                                    collection: templateBudgetPlanEntryCollection,
                                                                                                                                    uid: payload.uid
                                                                                                                                }).finally(() => {
                                                                                                                                    dispatch('deleteAllEntriesFromEntity', {
                                                                                                                                        collection: templateTimePlanEntriesCollection,
                                                                                                                                        uid: payload.uid
                                                                                                                                    }).finally(() => {
                                                                                                                                        dispatch('deleteAllEntriesFromEntityPlannerId', {
                                                                                                                                            collection: weddingLabelCollection,
                                                                                                                                            uid: payload.uid
                                                                                                                                        }).finally(() => {
                                                                                                                                            dispatch('deleteAllEntriesFromEntity', {
                                                                                                                                                collection: invoiceSettingCollection,
                                                                                                                                                uid: payload.uid
                                                                                                                                            }).finally(() => {
                                                                                                                                                dispatch('deleteAllEntriesFromEntityPlannerId', {
                                                                                                                                                    collection: invoiceCollection,
                                                                                                                                                    uid: payload.uid
                                                                                                                                                }).finally(() => {
                                                                                                                                                    dispatch('deleteAllEntriesFromEntity', {
                                                                                                                                                        collection: invoicePositionCollection,
                                                                                                                                                        uid: payload.uid
                                                                                                                                                    }).finally(() => {
                                                                                                                                                        dispatch('deleteAllEntriesFromEntity', {
                                                                                                                                                            collection: weddingFilesCollection,
                                                                                                                                                            uid: payload.uid
                                                                                                                                                        }).finally(() => {
                                                                                                                                                            dispatch('deleteAllEntriesFromBucket', {
                                                                                                                                                                bucket: profilePicBucket,
                                                                                                                                                                uid: payload.uid
                                                                                                                                                            }).finally(() => {
                                                                                                                                                                dispatch('deleteAllEntriesFromBucket', {
                                                                                                                                                                    bucket: weddingFileBucket,
                                                                                                                                                                    uid: payload.uid
                                                                                                                                                                }).finally(() => {
                                                                                                                                                                    teams.delete(payload.uid).finally(() => {
                                                                                                                                                                        databases.deleteDocument(plannerAccountCollection, payload.uid).then(()=>{
                                                                                                                                                                            resolve()
                                                                                                                                                                        }).catch(()=>{
                                                                                                                                                                            reject('Fehler beim Löschen des Accounts. Bitte wende dich an den Support.')
                                                                                                                                                                        })
                                                                                                                                                                    })
                                                                                                                                                                })
                                                                                                                                                            })
                                                                                                                                                        })
                                                                                                                                                    })
                                                                                                                                                })
                                                                                                                                            })
                                                                                                                                        })
                                                                                                                                    })
                                                                                                                                })
                                                                                                                            })
                                                                                                                        })
                                                                                                                    })
                                                                                                                })
                                                                                                            })
                                                                                                        })
                                                                                                    })
                                                                                                })
                                                                                            })
                                                                                        })
                                                                                    })
                                                                                })
                                                                            })
                                                                        })
                                                                    })
                                                                })
                                                            })
                                                        })
                                                    })
                                                })
                                            })
                                        })
                                    })
                                })
                            })
                        })
                    })
                })
            })

        },

        // eslint-disable-next-line no-empty-pattern
        deleteAllEntriesFromEntityPlannerId({commit}, payload) {
            return new Promise((resolve) => {
                let actDeleted = 0
                let actErrors = 0
                let progressed = 0
                databases.listDocuments(payload.collection, [Query.equal("plannerId",
                    payload.uid
                )], 0).then(async function (documentListX) {
                    console.log('Lösche ' + documentListX.total + 'Einträge aus der Collection: ' + payload.collection)
                    if (documentListX.total === 0) {
                        commit('setProgress', {
                            actDeleted,
                            actErrors,
                            progressed
                        })
                        resolve()
                    }
                    let pages = Math.ceil(documentListX.total / 25)
                    for (let i = 0; i < pages; i++) {
                        databases.listDocuments(payload.collection, [Query.equal("plannerId",
                            payload.uid
                        )], 25, i * 25).then(async (documentList) => {
                            for (let doc of documentList.documents) {
                                databases.deleteDocument(payload.collection, doc.$id).then(() => {
                                }).catch((err) => {
                                    console.log(err)
                                    actErrors++
                                }).finally(() => {
                                    actDeleted++
                                    progressed++
                                    if (progressed === documentListX.total) {
                                        commit('setProgress', {
                                            actDeleted,
                                            actErrors,
                                            progressed
                                        })
                                        resolve()
                                    }
                                })
                                await delay(100)
                            }
                            await delay(100)
                        })
                        await delay(100)
                    }
                })
            })
        },
        // eslint-disable-next-line no-empty-pattern
        deleteAllEntriesFromBucket({commit}, payload) {
            return new Promise((resolve) => {
                let actDeleted = 0
                let actErrors = 0
                let progressed = 0
                storage.listFiles(payload.bucket, '', 0).then(async function (documentListX) {
                    console.log('Lösche ' + documentListX.total + 'Einträge aus Bucket: ' + payload.bucket)
                    if (documentListX.total === 0) {
                        commit('setProgress', {
                            actDeleted,
                            actErrors,
                            progressed
                        })
                        resolve()
                    }
                    let pages = Math.ceil(documentListX.total / 25)
                    for (let i = 0; i < pages; i++) {
                        storage.listFiles(payload.bucket, '', 25, i * 25).then(async (documentList) => {
                            for (let doc of documentList.files) {
                                storage.deleteFile(payload.bucket, doc.$id).then(() => {
                                }).catch((err) => {
                                    console.log(err)
                                    actErrors++
                                }).finally(() => {
                                    actDeleted++
                                    progressed++
                                    if (progressed === documentListX.total) {
                                        commit('setProgress', {
                                            actDeleted,
                                            actErrors,
                                            progressed
                                        })
                                        resolve()
                                    }
                                })
                                await delay(100)
                            }
                            await delay(100)
                        })
                        await delay(100)
                    }
                })
            })
        },
        // eslint-disable-next-line no-empty-pattern
        deleteAllEntriesFromEntity({commit}, payload) {
            return new Promise((resolve) => {
                let actDeleted = 0
                let actErrors = 0
                let progressed = 0
                databases.listDocuments(payload.collection, [], 0).then(async function (documentListX) {
                    console.log('Lösche ' + documentListX.total + 'Einträge aus der Collection: ' + payload.collection)
                    if (documentListX.total === 0) {
                        commit('setProgress', {
                            actDeleted,
                            actErrors,
                            progressed
                        })
                        resolve()
                    }
                    let pages = Math.ceil(documentListX.total / 25)
                    for (let i = 0; i < pages; i++) {
                        databases.listDocuments(payload.collection, [], 25, i * 25).then(async (documentList) => {
                            for (let doc of documentList.documents) {
                                databases.deleteDocument(payload.collection, doc.$id).then(() => {
                                }).catch((err) => {
                                    console.log(err)
                                    console.log('ID: '+doc.$id)

                                    actErrors++
                                }).finally(() => {
                                    actDeleted++
                                    progressed++
                                    if (progressed === documentListX.total) {
                                        commit('setProgress', {
                                            actDeleted,
                                            actErrors,
                                            progressed
                                        })
                                        resolve()
                                    }
                                })
                                await delay(100)
                            }
                            await delay(100)
                        })
                        await delay(100)
                    }
                })
            })
        }
    },
    mutations: {
        setCount(state, payload) {
            state.count = payload
        },
        setProgress(state, payload) {
            state.deleted += payload.actDeleted
            state.progressed += payload.progressed
            state.errors += payload.actErrors
        },
    }
}

