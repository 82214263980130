<template>
  <div class="container weddings pb-16 fadeInFast">
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <v-row>
      <v-col cols="12" md="8">
        <HeadlineDetailView
            :icon="'mdi-ring'"
            :headline="getStringDate(wedding.wedDate) +' | ' + wedding.customerName"
            :entity="'Hochzeit'"
        ></HeadlineDetailView>
      </v-col>
      <v-col cols="12" md="4" class="text-end">
        <a class="v-btn" @click="unArchiveWedding(wedding.id)" v-if="wedding.isArchived">
          <v-btn class=" mt-2 ms-3 " width="170">
            <v-icon class="pe-2 ">
              mdi mdi-star
            </v-icon>
            Aus Archiv
          </v-btn>
        </a>
        <a class="v-btn" @click="archiveWedding(wedding.id)" v-else>
          <v-btn class=" mt-2 ms-3 " width="170">
            <v-icon class="pe-2 ">
              mdi mdi-archive-outline
            </v-icon>
            Archivieren
          </v-btn>
        </a>
      </v-col>
    </v-row>


    <div class=" " style="position:relative">
      <v-stepper class="mt-3 mb-1  rounded-xl" flat >
        <v-stepper-header>
          <template v-for="n in weddingStatus">
            <v-stepper-step
                @click="setWeddingStatus(n.name)"
                :key="n.id"
                :step="n.position"
                complete
                editable
                edit-icon="mdi-check"
                v-if="n.position < getPositionStatus(wedding.status)"
            >
              {{ n.name }}
            </v-stepper-step>
            <v-stepper-step
                :key="n.id"
                :step="n.position"
                complete-icon="mdi-arrow-down"
                complete

                v-else-if="n.position === getPositionStatus(wedding.status)"
            >
              {{ n.name }}
            </v-stepper-step>
            <v-stepper-step
                @click="setWeddingStatus(n.name)"
                :key="n.id"
                :step="n.position"
                v-else
                editable
            >
              {{ n.name }}
            </v-stepper-step>
            <v-divider
                v-if="n !== weddingStatus"
                :key="n.id"
            ></v-divider>
          </template>
        </v-stepper-header>
      </v-stepper>
      <p class="pa-0 ma-0 text-end">
        <a @click="tabs=1"><small class=" pa-0 ma-0"><v-icon>mdi-image</v-icon>Titelbild ändern</small></a>
      </p>
      <v-img v-if="getTitlePic()" :src="getTitlePic().href" height="150px"   width="100%"  class="rounded-xl">
      </v-img>

    </div>


    <v-card flat class="detailCard pa-4 rounded-xl mt-6">
      <Tab
          :tabs="['Allgemein','Dateien','Dienstleister','Rechnungen','Sonstiges']"
          :register="tabs"
          @changed="tabs=$event"
      >
      </Tab>


      <!--
      <v-stepper class= "mb-5" outlined >
        <v-stepper-header>
          <v-stepper-step
              editable
              :step="status.index"
              v-for="status of weddingStatus"
              :key="status"
          >
            {{ status.text }}
          </v-stepper-step>

        </v-stepper-header>
      </v-stepper>
-->

      <div v-if="tabs === 0">
        <v-row class="pa-4 ">
          <v-col cols="12" md="6" lg="5">
            <KeyValueBlock
                :title="''"
                :key-values="[
              {
                title: 'Status',
                value: wedding.status,
                editBtn:{
                  icon:'mdi-check-circle',
                  entity: 'wedding',
                  id: wedding.id,
                  row: 'status',
                  type: 'autoComplete',
                  autoComplete:{
                    value: wedding.status,
                    items: weddingStatusForSelection
                  }
                }
              },
              {
                title: 'Kunde', value: wedding.customerName,
                btn: {
                  icon:'mdi-account',
                  entity: 'customer',
                  id: wedding.customerId
                },
                editBtn:
                {
                  icon:'mdi-check-circle',
                  entity: 'wedding',
                  id: wedding.id,
                  row: 'customerId',
                  type: 'autoComplete',
                  autoComplete:{
                    value: wedding.customerId,
                    items: customersForSelection
                  }
                }
              },
                {
                title: 'Location', value: wedding.locationName,
                btn: {
                  icon:'mdi mdi-map-marker-radius-outline',
                  entity: 'location',
                  id: wedding.locationId
                },
                editBtn:
                {
                  icon:'mdi-check-circle',
                  entity: 'wedding',
                  id: wedding.id,
                  row: 'location',
                  type: 'autoComplete',
                  autoComplete:{
                    value: wedding.locationId,
                    items: locationsForSelection
                  }
                }
              },
                  {title: 'Weitere Locations', value: getLocationNames(wedding.locations),
               editBtn:{
                  icon:'mdi-check-circle',
                  entity: 'wedding',
                  id: wedding.id,
                  row: 'locations',
                  type: 'multipleAutoComplete',
                   autoComplete:{
                    value: wedding.locations,
                    items: locationsForSelection,
                    color: 'red'
                  }
              }},
              {
                title: 'Hochzeit am',
                value: getStringDate(wedding.wedDate),
                 editBtn:{
                  icon:'mdi-check-circle',
                  entity: 'wedding',
                  id: wedding.id,
                  row: 'wedDate',
                  type: 'date',
                  oldValue: getDatePicker(wedding.wedDate)
                }
              },
              {title: 'Label', value: wedding.labels,
               editBtn:{
                  icon:'mdi-check-circle',
                  entity: 'wedding',
                  id: wedding.id,
                  row: 'labels',
                  type: 'multipleAutoComplete',
                   autoComplete:{
                    value: wedding.labels,
                    items: weddingLabelNames.concat(wedding.labels),
                    color: 'red'
                  }
              }},
              {
                title: 'Art',
                value: wedding.type,
                isEdit: false,
                editBtn:{
                  icon:'mdi-check-circle',
                  entity: 'wedding',
                  id: wedding.id,
                  row: 'type',
                  type: 'autoComplete',
                  autoComplete:{
                    value: wedding.type,
                    items: this.myProducts
                }}
              },

              {
                title: 'Notizen',
                value: wedding.notes,
                isEdit: false,
                editBtn:{
                  icon:'mdi-check-circle',
                  entity: 'wedding',
                  id: wedding.id,
                  row: 'notes',
                  type: 'textarea'
                }
              },
              ]"
                @editEntry="editEntry"
                @openEntry="openEntry"
            ></KeyValueBlock>
            <CustomFieldBlock :entity="'wedding'" :user="user" :entryId="wedding.id"/>
            <div class="  pt-8  ">
              <h3 class="pb-2" v-if="budgetPlans.length>0">Budget
                <v-icon @click="dialogBudgetplan = true">mdi-plus</v-icon>
              </h3>
              <v-list class="rounded-xl pb-5" v-if="budgetPlans.length>0">
                <div v-for="(budgetPlan) in budgetPlans" :key="budgetPlan.id">
                  <v-list-item @click="openBudgetPlan(budgetPlan.id)"
                               style="background-color: #F3F4F3; "
                               class="rounded-xl mb-2">
                    <v-container>
                      <v-row>
                        <v-col cols="5">
                          <h4>{{ budgetPlan.name }}</h4>
                        </v-col>
                        <v-col>
                          Budget
                          <h3>{{ replaceDot(budgetPlan.planned.toFixed(2)) }}€</h3>
                        </v-col>
                        <v-col md="3">
                          <a>Details </a>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-list-item>
                </div>
                <Spinner v-if="isCreatingBudget">

                </Spinner>
              </v-list>
              <v-card v-else @click="dialogBudgetplan = true" class="pb-3 rounded-xl pt-3" flat
                      style="background-color: #F3F4F3">
                <v-row>
                  <v-col cols="8">
                    <v-card-title>
                      <h5>Budgetplan erstellen</h5>
                      <!--<h6 class="grey--text ms-1">Bald verfügbar</h6>-->
                    </v-card-title>
                    <v-card-text>
                      Verwalte und veranschauliche das Budget Deiner Kunden.<br><br>
                      <span class="primary--text text-subtitle-1">ERSTELLEN</span>
                    </v-card-text>
                  </v-col>
                  <v-col cols="4" class="align-self-center">
                    <v-img
                        :src="require('@/assets/images/drawSVGs/money.svg')"
                        width="100px">
                    </v-img>
                  </v-col>
                </v-row>
              </v-card>
            </div>

            <div class="pt-8">
              <h3 class="pb-2" v-if="timePlans.length>0">Tagesabläufe
                <v-icon @click="dialogTimePlan = true">mdi-plus</v-icon>
              </h3>
              <v-list v-if="timePlans.length>0" class="rounded-xl ">
                <div v-for="timePlan in timePlans" :key="timePlan.id" class="">
                  <v-list-item @click="openTimePlan(timePlan.id)" style="background-color: #F3F4F3; "
                               class="rounded-xl mb-2">
                    <v-container>
                      <v-row>
                        <v-col cols="5">
                          <h4>{{ timePlan.name }}</h4>
                        </v-col>
                        <v-col>
                          <h3 v-if="timePlan.date">{{ timePlan.date }}</h3>
                        </v-col>
                        <v-col md="3">
                          <a>Details </a>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-list-item>

                </div>
                <Spinner v-if="isCreatingTimePlan">

                </Spinner>
              </v-list>
              <v-card v-else @click="dialogTimePlan = true" class="pb-3 rounded-xl pt-3" flat
                      style="background-color: #F3F4F3">
                <v-row>
                  <v-col cols="8">
                    <v-card-title>
                      <h5>Tagesablauf erstellen</h5>
                      <!--<h6 class="grey--text ms-1">Bald verfügbar</h6>-->
                    </v-card-title>
                    <v-card-text>
                      Plane den zeitliche Ablauf eines Tages. <br> <br>
                      <span class="primary--text text-subtitle-1">ERSTELLEN</span>
                    </v-card-text>
                  </v-col>
                  <v-col cols="4" class="align-self-center">
                    <v-img
                        :src="require('@/assets/images/drawSVGs/list.svg')"
                        width="100px">
                    </v-img>
                  </v-col>
                </v-row>
              </v-card>
            </div>

            <div class="pt-8">
              <h3 class="pb-2" v-if="seatPlans.length>0">
                Sitzpläne und Gästelisten
                <v-icon @click="createSeatPlan">mdi-plus</v-icon>
              </h3>


              <v-list v-if="seatPlans.length>0" class="rounded-xl ">
                <div v-for="seatPlan in seatPlans" :key="seatPlan.id" class="">
                  <v-list-item @click="openSeatPlan(seatPlan.id)" style="background-color: #F3F4F3; "
                               class="rounded-xl mb-2">
                    <v-container>
                      <v-row>
                        <v-col cols="9">
                          <h4>{{ seatPlan.name }}</h4>
                        </v-col>

                        <v-col md="3">
                          <a>Details </a>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-list-item>

                </div>
                <Spinner v-if="isCreatingSeatPlan">

                </Spinner>
              </v-list>
              <v-card v-else @click="createSeatPlan" class="pb-3 rounded-xl pt-3" flat
                      style="background-color: #F3F4F3">
                <v-row>
                  <v-col cols="8">
                    <v-card-title>
                      <h5>Sitzplan erstellen</h5>
                      <v-tooltip bottom color="primary" max-width="200px">
                        <template v-slot:activator="{ on, attrs }">
                                                    <span v-bind="attrs" v-on="on">
                                                        <b><h6 class="primary--text ms-1">NEUE FUNKTION!</h6></b>
                                                    </span>
                        </template>
                        Wir haben die Sitzpläne bereits zum Testen für dich freigeschaltet und freuen uns auf dein
                        Feedback!<br>
                      </v-tooltip>
                    </v-card-title>
                    <v-card-text>
                      Plane den Sitzplan und die Gästeliste der Hochzeit. <br> <br>
                      <span class="primary--text text-subtitle-1">ERSTELLEN</span>
                    </v-card-text>
                  </v-col>
                  <v-col cols="4" class="align-self-center">
                    <v-img
                        :src="require('@/assets/images/drawSVGs/specialEvent.svg')"
                        width="100px">
                    </v-img>
                  </v-col>
                </v-row>
              </v-card>
            </div>

          </v-col>

          <v-col cols="12" md="6" lg="7">
            <div>
              <h2 class="text-h5 pb-2">Offene Aufgaben
                <v-btn
                    @click="openDialogCreateTask()"
                    class="ms-3 mt-1 primary"
                >
                  Erstellen
                </v-btn>
              </h2>
              <div>
                <VirtualScrollTasks
                    :height="200"
                    :items="tasksForWedding"
                    :noResultsText="'Du hast aktuell keine offenen Aufgaben für diese Hochzeit.'"
                    @openEntry="openTask($event)"
                ></VirtualScrollTasks>
                <CreateTask
                    :dialog="dialogCreateTask"
                    :weddingSelection="wedding.id"
                    @hideDialog="hideDialogCreateTask"
                ></CreateTask>
              </div>
            </div>

            <div class="pt-6">
              <h2 class="text-h5 pb-2">
                Historie
              </h2>
              <v-list-item class="ps-6 hover pa-2" @click="dialogAddNote =true">
                <v-col cols="1" class="text-center">
                  <v-icon>mdi-plus</v-icon>
                </v-col>
                <v-col>
                  Notiz erstellen
                </v-col>
              </v-list-item>
              <div>
                <VirtualScrollHistory
                    :items="weddingHistory"
                    :noResultsText="'Du hast aktuell keine offenen Aufgaben für diese Hochzeit.'"
                    @openEntry="openTask($event)"
                    @deletedEntry="deletedEntry"
                ></VirtualScrollHistory>
                <CreateTask
                    :dialog="dialogCreateTask"
                    :weddingSelection="wedding.id"
                    @hideDialog="hideDialogCreateTask"
                ></CreateTask>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>

      <div v-if="tabs === 1">
        <v-row class="pt-6 pb-6">
          <v-col>
            <UploadFile
                :weddingId="wedding.id"
            ></UploadFile>
          </v-col>
        </v-row>
        <VirtualScrollFiles
            :items="files"
            :weddingId="wedding.id"
        ></VirtualScrollFiles>
      </div>

      <div v-if="tabs === 2">
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="4"
                   v-for="vendorLink of vendors"
                   :key="vendorLink.id">
              <v-card>
                <v-card-title>
                  <a>
                    <h5 @click="goTo('/dienstleister/dienstleister/'+vendorLink.vendorId)">
                      <v-icon color="primary" class="pe-2">mdi mdi-handshake</v-icon>
                      {{ vendorLink.name }}
                    </h5>
                  </a>
                </v-card-title>
                <v-card-text>
                  {{ vendorLink.role }}
                  <br>
                  Status: <b>{{ vendorLink.status }}</b>
                </v-card-text>
                <v-card-actions class="">
                  <v-spacer></v-spacer>
                  <v-icon @click="deleteVendorLink(vendorLink.id)">mdi-delete</v-icon>
                  <v-icon @click="openVendorLink(vendorLink)">mdi-pencil</v-icon>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-btn class="primary v-btn mt-3" @click="startLinkVendor()">Dienstleister verknüpfen</v-btn>
          </v-row>
        </v-container>
      </div>
      <div v-if="tabs === 3">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-btn @click="dialogInvoice =true" class="primary">
                Neue Rechnung
              </v-btn>
              <VirtualScrollInvoice
                  :items="invoices"
                  :noResultsText="'Du hast aktuell keine offenen Rechnungen für diese Hochzeit.'"
                  @deletedEntry="deletedEntry"
                  @hideDialog="dialogInvoice =false"
              >
              </VirtualScrollInvoice>

            </v-col>
          </v-row>
        </v-container>

      </div>
      <div v-if="tabs === 4">
        <v-container>
          <v-row>
            <v-col cols="12" lg="6">
              <KeyValueBlock
                  :key-values="[
                  {title: 'Erstellt am', value: getStringDate(wedding.created)},
              ]">
              </KeyValueBlock>
            </v-col>
          </v-row>
        </v-container>

      </div>


    </v-card>

    <Vorlagen
        :dialog="dialogBudgetplan"
        :title="'Budgetplan erstellen'"
        :type="'Budgetplan'"
        :entries="budgetPlanTemplates"
        @hideDialog="dialogBudgetplan=false"
        @withTemplate="createBudgetPlanTemplate"
        @withoutTemplate="createBudgetPlanNoTemplate"
        @deletedTemplate="getBudgetPlanTemplates"
    ></Vorlagen>
    <Vorlagen
        :dialog="dialogTimePlan"
        :title="'Tagesablauf erstellen'"
        :type="'Tagesablauf'"
        :entries="timePlanTemplates"
        @hideDialog="dialogTimePlan=false"
        @withTemplate="createTimePlanTemplate"
        @withoutTemplate="createTimePlanNoTemplate"
        @deletedTemplate="getTimePlanTemplates"
    ></Vorlagen>

    <Dialog
        :dialog="dialogLinkVendor"
        :title="'Dienstleister verknüpfen'"
        :values="vendorLinkFields"
        :successButton="'Anfügen'"
        :icon="'mdi mdi-handshake'"
        @hideDialog="hideDialogLinkVendor"
        @emitNewData="linkVendor"
        @createEntry="createEntry($event)"
    ></Dialog>
    <Dialog
        :dialog="dialogLinkVendor"
        :title="'Dienstleister verknüpfen'"
        :values="vendorLinkFields"
        :successButton="'Anfügen'"
        :icon="'mdi mdi-handshake'"
        @hideDialog="hideDialogLinkVendor"
        @emitNewData="linkVendor"
        @createEntry="createEntry($event)"
    ></Dialog>
    <Dialog
        :dialog="dialogEditVendor"
        :title="'Dienstleisterverknüpfung bearbeiten'"
        :values="vendorEditLinkFields"
        :successButton="'Bestätigen'"
        :icon="'mdi mdi-handshake'"
        @hideDialog="hideDialogEditLinkVendor"
        @emitNewData="editVendor"
        @createEntry="editVendorLink($event)"
    ></Dialog>
    <Dialog
        :dialog="dialogAddVendor"
        :title="'Neuen Dienstleister anlegen'"
        :values="vendorCreateFields"
        :successButton="'Erstellen'"
        :icon="'mdi-account-plus'"
        :headLine="{text: 'Haupt-Ansprechpartner', position: 1}"
        @hideDialog="hideDialogAddVendor"
        @emitNewData="addVendor"
    ></Dialog>
    <CreateHistoryEntry
        :dialog="dialogAddNote"
        :weddingSelection="wedding.id"
        @hideDialog="dialogAddNote = false"
        @createdEntry="sortHistory"
    ></CreateHistoryEntry>
    <CreateInvoice
        :dialog="dialogInvoice"
        :customerSelection="wedding.customerId"
        @hideDialog="dialogInvoice = false"
    ></CreateInvoice>
    <CreateTask
    ></CreateTask>
  </div>

</template>
<script>

import {mapGetters} from "vuex"
import {error, success, dotToKomma, msgObj, date2String, getDateForPicker} from "@/helper/helper";

const Message = () => import("@/components/generalUI/Message");
const Dialog = () => import("@/components/dialog/FormDialog");
const Vorlagen = () => import("@/components/entities/wedding/Vorlagen");
const CreateTask = () => import("@/components/dialog/createDialogs/CreateTask");
const KeyValueBlock = () => import("@/components/detailView/KeyValueBlock");
const HeadlineDetailView = () => import("@/components/detailView/HeadlineDetailView");
const UploadFile = () => import("@/components/entities/wedding/UploadWeddingFile");
const VirtualScrollTasks = () => import("@/components/entities/Task/VirtualScrollTasks");
const VirtualScrollInvoice = () => import("@/components/entities/wedding/VirtualScrollInvoice");

const VirtualScrollFiles = () => import("@/components/entities/wedding/VirtualScrollWeddingFiles");
const VirtualScrollHistory = () => import("@/components/entities/wedding/History");
const CreateHistoryEntry = () => import("@/components/dialog/createDialogs/CreateHistoryEntry");
const CustomFieldBlock = () => import("@/components/detailView/CustomFieldBlock");
const Spinner = () => import("@/components/generalUI/Spinner");
const Tab = () => import("@/components/generalUI/Tab");
const CreateInvoice = () => import("@/components/dialog/createDialogs/CreateInvoice");

export default {
  name: 'weddingDetail',
  components: {
    Dialog,
    Message,
    HeadlineDetailView,
    KeyValueBlock,
    UploadFile,
    VirtualScrollFiles,
    VirtualScrollTasks,
    VirtualScrollHistory,
    CreateTask,
    CustomFieldBlock,
    CreateHistoryEntry,
    Vorlagen,
    Spinner,
    VirtualScrollInvoice,
    Tab,
    CreateInvoice
  },
  mounted() {
    this.$store.dispatch('auth/checkLogin', {}).then((res) => {
      this.$store.dispatch('wedding/setVendorsEmpty').then(() => {
        this.$store.dispatch('wedding/getWedding', {
          uid: res,
          weddingId: this.$route.params.id
        }).then(() => {
          this.$store.dispatch('locations/getLocationsForSelection', {
            uid: res,
          }).catch((err) => {
            this.message = error(err)
          })
          this.$store.dispatch('task/getTasksForWedding', {
            uid: res,
            weddingId: this.$route.params.id
          }).catch((err) => {
            this.message = error(err)
          })
          this.$store.dispatch('file/getFiles', {
            uid: res,
            weddingId: this.$route.params.id
          }).catch((err) => {
            this.message = error(err)
          })
          this.$store.dispatch('vendor/getVendorsForSelection', {
            uid: res,
          }).catch((err) => {
            this.message = error(err)
          })
          this.$store.dispatch('customer/getCustomersForSelection', {
            uid: res,
          }).catch((err) => {
            this.message = error(err)
          })
          this.$store.dispatch('settings/getStatusForSelection', {
            uid: res,
          }).catch((err) => {
            this.message = error(err)
          })
          this.$store.dispatch('wedding/getHistoryForWedding', {
            uid: res,
            weddingId: this.$route.params.id
          }).then(() => {
            this.sortHistory()
          }).catch((err) => {
            this.message = error(err)
          })
          this.$store.dispatch('settings/getMyProductsForSelection', {
            uid: res
          }).catch((err) => {
            this.message = error(err)
          })
          this.$store.dispatch('settings/getStatus', {
            uid: res,
          }).catch((err) => {
            this.message = error(err)
          })
          this.$store.dispatch('settings/getWeddingLabels', {
            uid: res,
          }).catch((err) => {
            this.message = error(err)
          })
          this.$store.dispatch('invoice/getInvoicesForCustomer', {
            customerId: this.wedding.customerId,
          }).catch((err) => {
            this.message = error(err)
          })
          this.$store.dispatch('timePlan/getTimePlanTemplates', {
            uid: res,
          }).catch((err) => {
            this.message = error(err)
          })
          this.$store.dispatch('budgetPlan/getBudgetPlanTemplates', {
            uid: res,
          }).catch((err) => {
            this.message = error(err)
          })
        }).catch((err) => {
          this.message = error(err)
        })
      }).catch((err) => {
        this.message = {
          show: true,
          text: err,
          color: "error",
        }
        setTimeout(() => this.message.show = false, 5000);
      })
      this.$store.dispatch('budgetPlan/getBudgetPlansForWedding', {
        weddingId: this.$route.params.id
      }).catch((err) => {
        this.message = error(err)
      })
      this.getTemplates()

      this.$store.dispatch('timePlan/getTimePlansForWedding', {
        weddingId: this.$route.params.id
      }).catch((err) => {
        this.message = error(err)
      })

      this.$store.dispatch('seatPlan/getSeatPlansForWedding', {
        weddingId: this.$route.params.id
      }).catch((err) => {
        this.message = error(err)
      })
    })
  },
  computed: {
    ...mapGetters('wedding', {
      wedding: 'wedding',
      vendors: 'vendorLinks',
      weddingHistory: 'weddingHistory'
    }),
    ...mapGetters('settings', {
      vendorStatus: 'vendorStatus',
      weddingStatus: 'weddingStatus',
      vendorStatusForSelection: 'vendorStatusForSelection',
      weddingStatusForSelection: 'weddingStatusForSelection',
      myProducts: 'myProductsForSelection',
      weddingLabels: 'weddingLabels',
      weddingLabelNames: 'weddingLabelNames',
    }),
    ...mapGetters('auth', {
      user: 'user',
    }),
    ...mapGetters('locations', {
      locationsForSelection: 'locationsForSelection',
    }),
    ...mapGetters('vendor', {
      vendorsForSelection: 'vendorsForSelection',
    }),
    ...mapGetters('customer', {
      customersForSelection: 'customersForSelection',
    }),
    ...mapGetters('file', {
      files: 'files',
    }),
    ...mapGetters('task', {
      tasksForWedding: 'tasksForWedding',
    }),
    ...mapGetters('invoice', {
      invoices: 'invoices',
    }),
    ...mapGetters('budgetPlan', {
      budgetPlans: 'budgetPlans',
      budgetPlanTemplates: 'budgetPlanTemplates',
      budgetPlanIsInCreation: 'isInCreation'
    }),
    ...mapGetters('timePlan', {
      timePlans: 'timePlans',
      timePlanTemplates: 'timePlanTemplates',
      timePlanIsInCreation: 'isInCreation'
    }),
    ...mapGetters('seatPlan', {
      seatPlans: 'seatPlans',

    }),
  },
  data() {
    return {
      dialogCreateTask: false,
      dialogLinkVendor: false,
      dialogAddVendor: false,
      dialogAddStatus: false,
      dialogAddNote: false,
      dialogInvoice: false,
      dialogEditVendor: false,
      dialogSeatPlan: false,
      message: msgObj(),
      tabs: 0,
      vendorLinkFields: this.getVendorLinkFields(),
      vendorCreateFields: this.getVendorCreateFields(),
      vendorEditLinkFields: [],
      dialogBudgetplan: false,
      dialogTimePlan: false,
      editId: "",
      isCreatingBudget: false,
      isCreatingTimePlan: false,
      isCreatingSeatPlan: false,
      seatPlanTemplates: []
    }
  },
  methods: {
    addBudgetplanner(templateId) {
      this.isCreatingBudget = true
      this.$store.dispatch('budgetPlan/createBudgetPlan', {
        weddingId: this.$route.params.id,
        uid: this.user.id,
        name: 'Budgetplan von ' + this.wedding.customerName,
        templateId
      }).then(() => {
        this.$store.dispatch('budgetPlan/getBudgetPlansForWedding', {
          weddingId: this.$route.params.id
        }).catch((err) => {
          this.message = error(err)
        }).finally(() => {
          this.isCreatingBudget = false
        })
        this.tabs = 0
      }).finally(() => {
        this.isCreatingBudget = false

      })
      this.dialogBudgetplan = false
    },
    addTimePlan(templateId) {
      this.$store.dispatch('timePlan/createTimePlan', {
        weddingId: this.$route.params.id,
        uid: this.user.id,
        name: 'Tagesablauf von ' + this.wedding.customerName,
        date: new Date(this.wedding.wedDate).getTime(),
        templateId
      }).then(() => {
        this.$store.dispatch('timePlan/getTimePlansForWedding', {
          weddingId: this.$route.params.id
        }).catch((err) => {
          this.message = error(err)
        })
        this.tabs = 0
      })
      this.dialogTimePlan = false
    },
    archiveWedding(id) {
      this.$store.dispatch('customer/editField', {
        entity: "wedding",
        uid: this.user.id,
        id: id,
        newValue: true,
        row: 'isArchived'
      }).then(() => {
        this.$router.push('/weddings')
      })
    },
    createBudgetPlanTemplate(templateId) {
      this.addBudgetplanner(templateId)
    },
    createTimePlanTemplate(templateId) {
      this.addTimePlan(templateId)
    },
    createBudgetPlanNoTemplate() {
      this.addBudgetplanner(null)
    },
    createTimePlanNoTemplate() {
      this.addTimePlan(null)
    },
    createSeatPlan() {
      this.$store.dispatch('seatPlan/createSeatPlan', {
        weddingId: this.$route.params.id,
        uid: this.user.id,
        name: 'Sitzplan von ' + this.wedding.customerName
      }).then(() => {
        this.$store.dispatch('seatPlan/getSeatPlansForWedding', {
          weddingId: this.$route.params.id
        }).catch((err) => {
          this.message = error(err)
        })
        this.tabs = 0
      })
      this.dialogTimePlan = false
    },
    deletedEntry() {
      this.message = success("Eintrag erfolgreich gelöscht.")
      this.$store.dispatch('wedding/getWedding', {
        uid: this.user.id,
        weddingId: this.$route.params.id
      }).then(() => {
        this.$store.dispatch('settings/getMyProducts', {
          uid: this.user.id,
        }).catch((err) => {
          this.message = error(err)
        })
        this.$store.dispatch('wedding/getHistoryForWedding', {
          uid: this.user.id,
          weddingId: this.$route.params.id
        }).then(() => {
          this.sortHistory()
        }).catch((err) => {
          this.message = error(err)
        })
      }).catch((err) => {
        this.message = error(err)
      })
    },
    getTemplates() {
      this.$store.dispatch('budgetPlan/getBudgetPlanTemplates', {
        uid: this.user.id
      }).catch((err) => {
        this.message = error(err)
      })
      this.$store.dispatch('timePlan/getTimePlanTemplates', {
        uid: this.user.id
      }).catch((err) => {
        this.message = error(err)
      })
    },
    getBudgetPlanTemplates() {
      this.$store.dispatch('budgetPlan/getBudgetPlanTemplates', {
        uid: this.user.id
      }).catch((err) => {
        this.message = error(err)
      })
    },
    getTimePlanTemplates() {
      this.$store.dispatch('timePlan/getTimePlanTemplates', {
        uid: this.user.id
      }).catch((err) => {
        this.message = error(err)
      })
    },
    sortHistory() {
      this.$store.dispatch('sort/sort', {
        objectArray: this.weddingHistory,
        sortField: 'date',
        sortStyle: 'DESC'
      }).catch((err) => {
        this.message = error(err)
      })
    },
    getStringDate(date) {
      return date2String(date)
    },
    getDatePicker(time) {
      if (time) {
        return getDateForPicker(time)
      } else {
        return ''
      }
    },
    replaceDot(float) {
      return dotToKomma(float)
    },

    unArchiveWedding(id) {
      this.$store.dispatch('customer/editField', {
        entity: "wedding",
        uid: this.user.id,
        id: id,
        newValue: false,
        row: 'isArchived'
      }).then(() => {
        this.$router.push('/weddings')
      })
    },
    setWeddingStatus(name) {
      this.$store.dispatch('customer/editField', {
        entity: "wedding",
        uid: this.user.id,
        id: this.wedding.id,
        newValue: name,
        row: 'status'
      }).then(() => {
        this.$store.dispatch('wedding/getWedding', {
          weddingId: this.wedding.id,
          uid: this.user.id
        }).catch((err) => {
          this.message = error(err)
        })
      }).catch((err) => {
        this.message = error(err)
      })
    },
    editEntry(payload) {
      switch (payload.row) {
        case "customerId":
          this.$store.dispatch('customer/getCustomer', {
            uid: this.user.id,
            customerId: payload.newValue,
          }).then(() => {
            this.$store.dispatch('customer/editAutoCompleteCustomer', {
              uid: this.user.id,
              id: payload.id,
              newValue: payload.newValue,
            })
          }).catch((err) => {
            this.message = error(err)
          })
          break
      }
      this.$store.dispatch('customer/editField', {
        entity: "wedding",
        uid: this.user.id,
        id: payload.id,
        newValue: payload.newValue,
        row: payload.row
      }).then(() => {
        this.$store.dispatch('wedding/getWedding', {weddingId: payload.id, uid: this.user.id})
            .catch((err) => {
              this.message = error(err)
            })
      }).catch((err) => {
        this.message = error(err)
      })
    },
    goTo(link) {
      this.$router.push(link)
    },
    startLinkVendor() {
      this.vendorLinkFields = this.getVendorLinkFields()
      this.dialogLinkVendor = true
    },
    hideDialogLinkVendor() {
      this.dialogLinkVendor = false
    },
    hideDialogEditLinkVendor() {
      this.dialogEditVendor = false
    },
    hideDialogAddVendor() {
      this.dialogAddVendor = false
    },
    hideDialogCreateTask() {
      this.dialogCreateTask = false
    },
    openTask(id) {
      this.$router.push('/planner/tasks/task/' + id)
    },
    openSeatPlan(id) {
      this.$router.push('/weddings/wedding/' + this.wedding.id + '/sitzplan/' + id)
    },
    openDialogCreateTask() {
      this.dialogCreateTask = true
    },
    getVendorLinkFields() {
      return [
        {
          title: 'Dienstleister',
          value: null,
          id: 0,
          type: 'autoComplete',
          items: this.vendorsForSelection,
          icon: 'mdi-domain',
          entity: 'vendor'
        },
        {
          title: 'Rolle',
          value: '',
          id: 1,
          type: 'string'
        },
        {
          title: 'Status',
          value: null,
          id: 2,
          type: 'autoComplete',
          items: this.vendorStatusForSelection,
          entity: 'status'
        },
      ]
    },
    addVendor(newValues) {
      let newVendor = this.getVendorFromInput(newValues)
      this.$store.dispatch('vendor/createVendor', {
        newVendor,
        uid: this.user.id
      }).then(() => {
        this.vendorCreateFields = this.getVendorCreateFields()
        this.hideDialogAddVendor()
        this.message = success("Dienstleister wurde erfolgreich angelegt.")
      }).catch((err) => {
        this.message = error(err)
      })
    },
    getVendorFromInput(newValues) {
      let vendor = {
        firstname: '',
        lastname: '',
        name: '',
        email: '',
        role: '',
        isMain: false,
      }
      for (let value of newValues) {
        switch (value.title) {
          case 'Vorname':
            vendor.firstname = value.value
            break;
          case 'Nachname':
            vendor.lastname = value.value
            break;
          case 'Unternehmensname (optional)':
            vendor.name = value.value
            break;
          case 'Telefonnummer':
            vendor.tel = value.value
            break;
          case 'E-Mail Adresse':
            vendor.email = value.value
            break;
          case 'Rolle /  Tätigkeit':
            vendor.role = value.value
            break;
        }
      }
      return vendor
    },
    getLinkVendorFromInput(newValues) {
      let vendor = {
        vendorId: '',
        status: '',
        role: ''
      }
      for (let value of newValues) {
        switch (value.title) {
          case 'Dienstleister':
            vendor.vendorId = value.value
            break;
          case 'Status':
            vendor.status = value.value
            break;
          case 'Rolle':
            vendor.role = value.value
            break;
        }
      }
      return vendor
    },
    openEntry(payload) {
      switch (payload.entity) {
        case "customer":
          this.$router.push('/kunden/kunde/' + payload.id)
          break
        case "location":
          this.$router.push('/locations/location/' + payload.id)
          break
      }
    },
    linkVendor(newValues) {
      let linkedVendor = this.getLinkVendorFromInput(newValues)
      this.$store.dispatch('wedding/linkVendor', {
        weddingId: this.$route.params.id,
        linkedVendor,
        uid: this.user.id
      }).then(() => {
        this.vendorCreateFields = this.getVendorLinkFields()
        this.hideDialogLinkVendor()
        this.message = success("Dienstleister wurde erfolgreich verknüpft.")
      }).catch((err) => {
        this.message = error(err)
      })
    },
    editVendor(newValues) {
      let linkedVendor = this.getLinkVendorFromInput(newValues)
      this.$store.dispatch('wedding/editLinkVendor', {
        weddingId: this.$route.params.id,
        linkedVendor,
        id: this.editId,
        uid: this.user.id
      }).then(() => {
        this.vendorCreateFields = this.getVendorLinkFields()
        this.hideDialogEditLinkVendor()
        this.message = success("Dienstleister wurde erfolgreich verknüpft.")
        location.reload()
      }).catch((err) => {
        this.message = error(err)
      })
    },

    createEntry(payload) {
      switch (payload.entity) {
        case 'vendor':
          this.dialogAddVendor = true
          break
      }
    },

    deleteVendorLink(linkId) {
      this.$store.dispatch('wedding/deleteVendorLink', {
        uid: this.user.id,
        weddingId: this.$route.params.id,
        vendorLinkId: linkId
      })
    },
    editVendorLink(payload) {
      this.vendorEditLinkFields = this.getVendorEditLinkFields(payload)
      console.log(this.vendorEditLinkFields);
      console.log(payload);
      this.dialogEditVendor = true
      console.log(this.dialogEditVendor)
    },
    getLocationNames(listOfIDs) {
      let list = []
      for (let locationID of listOfIDs)
      {
        for(let selection of this.locationsForSelection)
        {
          if(selection.value === locationID){
            list.push(selection.text)
          }
        }
      }
      return list
    },
    openVendorLink(payload) {
      this.vendorEditLinkFields = this.getVendorEditLinkFields(payload)
      console.log(this.vendorEditLinkFields);
      console.log(payload);
      this.dialogEditVendor = true
      this.editId = payload.id
      console.log(this.dialogEditVendor)
    },
    getPositionStatus(name) {
      for (let status of this.weddingStatus) {
        if (status.name === name) {
          return status.position
        }
      }
    },
    openBudgetPlan(payload) {
      this.$router.push("/weddings/wedding/" + this.$route.params.id + "/budgetPlan/" + payload)
    },
    openTimePlan(payload) {
      this.$router.push("/weddings/wedding/" + this.$route.params.id + "/timePlan/" + payload)
    },
    getTitlePic(){
      for(let file of this.files){
        if(file.isTitlePic){
          return file
        }
      }
    },
    getVendorCreateFields() {
      return [
        {
          title: 'Unternehmensname (optional)',
          value: '',
          id: 0,
          type: 'string'
        },
        {
          title: 'Vorname',
          value: '',
          id: 1,
          type: 'string'
        },
        {
          title: 'Nachname',
          value: '',
          id: 2,
          type: 'string'
        },
        {
          title: 'Rolle /  Tätigkeit',
          value: '',
          id: 3,
          type: 'string'
        },
        {
          title: 'Telefonnummer',
          value: '',
          id: 4,
          type: 'string'
        },
        {
          title: 'E-Mail Adresse',
          value: '',
          id: 5,
          type: 'string'
        },
      ]
    },
    getVendorEditLinkFields(payload) {
      return [
        {
          title: 'Dienstleister',
          value: payload.vendorId,
          id: 0,
          type: 'autoComplete',
          items: this.vendorsForSelection,
          icon: 'mdi-domain',
          entity: 'vendor'
        },
        {
          title: 'Rolle',
          value: payload.role,
          id: 1,
          type: 'string'
        },
        {
          title: 'Status',
          value: payload.status,
          id: 2,
          type: 'autoComplete',
          items: this.vendorStatusForSelection,
          entity: 'status'
        },
      ]
    }
  }
}
</script>
