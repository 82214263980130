<template>
  <div class="pa-3">
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>

        <HeadlineTableView
            :icon="update.icon"
            :headline="update.title"
            :button-icon="''"
            :button-text="''"
        ></HeadlineTableView>

    <v-card flat class="rounded-xl pa-2 pa-sm-8" >
        <p>Hier sind die neuesten Funktionen und Updates zu Wedding-Flow vom {{date2String( update.created) }}:</p>
        <p class="subtitle-1">{{update.text}}</p>
        <v-list>
            <div v-for="feature of update.features" :key="feature">
                <v-list-item>
                    {{feature}}
                </v-list-item>
                <v-divider></v-divider>
            </div>

        </v-list>
        <p class="pt-8">
            Wir wünschen dir viel Spaß beim Ausprobieren der neuen Funktionen!
        </p>
    </v-card>


  </div>
</template>

<script>

import {mapGetters} from "vuex";
import {date2String, error, msgObj} from "@/helper/helper";
const Message = () => import("@/components/generalUI/Message");
const HeadlineTableView = () => import("@/components/tableView/HeadlineTableView");

export default {
  name: "Notification",
  components: {
    Message,
    HeadlineTableView
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user',
    }),
    ...mapGetters('notification', {
        update: 'update',
    }),
  },
  mounted() {
    window.scrollTo(0, 0)
    this.$store.dispatch('notification/getUpdate', {
      uid: this.user.id,
      notificationId: this.$route.params.id
    }).catch((err) => {
      this.message = error(err)
    })
  },
  data() {
    return {
      message: msgObj(),
    }
  },
  methods: {date2String}
}
</script>

<style lang="sass" scoped>
</style>
<style scoped>
</style>
