<template>
  <v-container>
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <v-form class="pe-md-16">
      <v-row>
        <v-col cols="3">
          Firma
        </v-col>
        <v-col cols="9">
          <v-text-field v-model="newInvoiceSettings.company">

          </v-text-field>
        </v-col>
        <v-col cols="3">
          Name
        </v-col>
        <v-col cols="9">
          <v-text-field v-model="newInvoiceSettings.name">

          </v-text-field>
        </v-col>
        <v-col cols="3">
          Straße und Haus<wbr>nummer
        </v-col>
        <v-col cols="9">
          <v-text-field v-model="newInvoiceSettings.street">

          </v-text-field>
        </v-col>
        <v-col cols="3">
          PLZ, Ort
        </v-col>
        <v-col cols="9">
          <v-text-field v-model="newInvoiceSettings.city">

          </v-text-field>
        </v-col>
        <v-col cols="3">
          Ust-ID
        </v-col>
        <v-col cols="9">
          <v-text-field v-model="newInvoiceSettings.ustId">

          </v-text-field>
        </v-col>
        <v-col cols="3">
          Steuer<wbr>nummer
        </v-col>
        <v-col cols="9">
          <v-text-field v-model="newInvoiceSettings.steuernummer">

          </v-text-field>
        </v-col>
        <v-col cols="3">
          Kontoinhaber
        </v-col>
        <v-col cols="9">
          <v-text-field v-model="newInvoiceSettings.Kontoinhaber">

          </v-text-field>
        </v-col>
        <v-col cols="3" >
          IBAN
        </v-col>
        <v-col cols="9">
          <v-text-field v-model="newInvoiceSettings.IBAN">

          </v-text-field>
        </v-col>
        <v-col cols="3">
          BIC
        </v-col>
        <v-col cols="9">
          <v-text-field v-model="newInvoiceSettings.BIC">

          </v-text-field>
        </v-col>
        <v-col cols="3">
          Email
        </v-col>
        <v-col cols="9">
          <v-text-field v-model="newInvoiceSettings.email">

          </v-text-field>
        </v-col>
        <v-col cols="3">
          Telefon
        </v-col>
        <v-col cols="9">
          <v-text-field v-model="newInvoiceSettings.phone">

          </v-text-field>
        </v-col>
        <v-col cols="3" >
          Nächste Rechnungs<wbr>nummer
        </v-col>
        <v-col cols="9">
          <v-text-field v-model="newInvoiceSettings.nextInvoice" type="number">

          </v-text-field>
        </v-col>
        <v-col cols="3">
          Nächste Angebots<wbr>nummer
        </v-col>
        <v-col cols="9">
          <v-text-field v-model="newInvoiceSettings.nextQuote"  type="number">

          </v-text-field>
        </v-col>
        <v-col cols="3">
          Standard Steuersatz (%)
        </v-col>
        <v-col cols="9">
          <v-text-field v-model="newInvoiceSettings.steuersatz"  type="number">

          </v-text-field>
        </v-col>
        <v-col cols="3">
          Absatz zur Klein<wbr>unternehmer<wbr>regelung
        </v-col>
        <v-col cols="9">
          <v-checkbox v-model="newInvoiceSettings.kleinunternehmerB">

          </v-checkbox>
        </v-col>
        <v-col cols="3" v-if="newInvoiceSettings.kleinunternehmerB">
          Absatz zur Klein<wbr>unternehmer<wbr>regelung
        </v-col>
        <v-col cols="9" v-if="newInvoiceSettings.kleinunternehmerB">
          <v-text-field  v-model="newInvoiceSettings.kleinunternehmer">

          </v-text-field>
        </v-col>
        <v-col cols="3">
          Kopfzeile
        </v-col>
        <v-col cols="9">
          <v-text-field v-model="newInvoiceSettings.header">

          </v-text-field>
        </v-col>
        <v-col cols="3">
          Fußzeile
        </v-col>
        <v-col cols="9">
          <v-text-field v-model="newInvoiceSettings.footer">

          </v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <v-btn class="primary" @click="createOrUpdateInvoiceSettings()">Speichern</v-btn>
  </v-container>
</template>
<script>

import {mapGetters} from "vuex";
import {error, msgObj,success} from "@/helper/helper";
const Message = () => import("@/components/generalUI/Message");

export default {
  name: "FieldSettings",
  components:{
    Message
  },
  computed: {
    ...mapGetters('settings', {
      invoiceSettings: 'invoiceSettings',
    }),
  },
  data() {
    return {
      message: msgObj(),
      allowedCustomFields: 3,
      newInvoiceSettings: {

      }
    }
  },
  props: {
    user: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  mounted() {
    this.$store.dispatch('settings/getInvoiceSettings', {
      uid: this.user.id,
    }).catch((err) => {
      this.message = error(err)
    }).then(()=>{
      this.newInvoiceSettings = this.invoiceSettings
    })
  },
  methods: {
    createOrUpdateInvoiceSettings(){
      this.$store.dispatch('settings/createOrUpdateInvoiceSettings', {
        uid: this.user.id,
        newInvoiceSettings: this.newInvoiceSettings
      }).catch((err) => {
        this.message = error(err)
      }).then(()=>{
        this.message = success('Erfolgreich gespeicchert.')

      })
    }
  }
}
</script>
