import {databases, account} from "../../config/server-api";
import {getError} from "@/config/errors";
import {
    leadCollection,
    bugReportCollection,
} from "@/config/awSettings"

export default {
    namespaced: true,
    state: {},
    getters: {},
    actions: {
        /**
         * Erstellt einen neuen Lead
         *
         * @param payload
         * @returns {Promise<unknown>}
         */
        // eslint-disable-next-line no-empty-pattern
        createMessage({}, payload) {
            return new Promise((resolve, reject) => {
                const promiseSession = account.createAnonymousSession()
                promiseSession.then(function () {
                    const promiseCreateDoc = databases.createDocument(leadCollection, "unique()", payload)
                    promiseCreateDoc.then(function () {
                        console.log('successMessage')
                        resolve()
                    }, function (error) {
                        console.log(error)
                        reject(error)
                    })
                }, function (error) {
                    console.log(error)
                    reject(getError(error,"E0101"))
                })

            })
        },
        // eslint-disable-next-line no-empty-pattern
        createBugReport({}, payload) {
            return new Promise((resolve, reject) => {
                if( !payload.bugReport.bug){
                    payload.bugReport.bug = false
                }
                if(!payload.bugReport.optimizing){
                    payload.bugReport.optimizing = false
                }
                const promiseCreateDoc = databases.createDocument(bugReportCollection, "unique()", payload.bugReport)
                promiseCreateDoc.then(function () {
                    resolve()
                }, function (error) {
                    console.log(error)
                    reject(getError(error,"E0101"))
                })
            })
        },
    },
    mutations: {}
}
