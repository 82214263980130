<template>
    <v-container fluid class="pa-0 ma-0 background"
                 :style="'background-image: url('+require('@/assets/images/titlePicture.jpg')+')'">
        <Message
                :message="message"
                @closeAlert="message.show = false"
        ></Message>
        <v-row style="width: 100vw;" class="pl-10 d-flex justify-center">
            <v-col cols="12" class="d-flex justify-center align-center" style="height: 100vh">
                <v-card class="pa-9 transparent-card rounded-xl" max-width="500px" min-width="40vw">
                    <div v-if="loginEnabled">
                        <a href="https://wedding-flow.de"><h1 class="logo mb-0 pb-0">Weddingflow</h1></a>
                    <p class="text-uppercase">Willkommen zurück!</p>

                    <small class="">Noch keinen Account? <a @click="$router.push('/registrieren')">Jetzt registrieren</a>.</small>
                    <div v-if="loaded && loginEnabled">
                        <v-text-field :rules="[rules.required]"
                                      label="E-Mail"
                                      outlined
                                      dense
                                      v-model="email"
                                      class="pt-3"/>
                        <v-text-field @keyup.enter="login" :rules="[rules.required]" label="Passwort" type="password"
                                      outlined
                                      dense
                                      v-model="password"></v-text-field>
                    </div>

                    <Spinner class="pb-5" v-else></Spinner>

                    <small class="pb-5">Du hast Probleme bei der Anmeldung?<br>
                        <a href="/#/forgotPassword">Passwort zurücksetzen</a><br>
                        <a href="/#/support">Problem melden</a>
                    </small>
                    <v-btn block color="primary" depressed @click="login" class="mt-5">
                        Anmelden
                    </v-btn>
                    </div>
                    <div v-else style="width: 400px" class="text-center">
                        <p class="text-uppercase">Willkommen zurück!</p>
                        <a href="https://wedding-flow.de"><h1 class="logo mb-0 pb-0">Weddingflow</h1></a>
                        <div >
                            <v-img
                                :src="require('@/assets/images/drawSVGs/builder.svg')"
                                max-width="150px"
                                class="d-inline-flex mt-3"
                                style="transform: scaleX(-1);"/><br>
                            Wir nehmen gerade einige Änderungen vor und sind daher vorrübergehend nicht erreichbar. Bitte versuche es später noch einmal.
                        </div>
                        <v-btn v-if="!retry" block color="primary" depressed @click="retryEnabled()" class="mt-5">
                            Erneut versuchen
                        </v-btn>
                        <Spinner class="pb-5" v-else></Spinner>

                    </div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>

import {mapGetters} from "vuex";
import {error, msgObj} from "@/helper/helper";

const Message = () => import("@/components/generalUI/Message");
const Spinner = () => import("@/components/generalUI/Spinner");

export default {
    name: "ForgotPassword",
    components: {
        Message,
        Spinner
    },
    data() {
        return {
            retry: false,
            message: msgObj(),
            email: '',
            password: '',
            error: '',
            loaded: true,
            rules: {
                required: value => !!value || 'Erforderlich',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'Ungültige E-mail'
                },
                min: v => v.length >= 6 || 'Mindestens 6 Zeichen',
            },
        }
    },
    computed: {
        ...mapGetters('auth', {
            loggedIn: 'loggedIn',
            user: 'user',
            loginEnabled: 'loginEnabled',
        }),
    },
    methods: {
        async login() {
            this.loaded = false
            this.$store.dispatch('auth/loginViaEmail', {email: this.email, password: this.password}).then(() => {
                this.$router.push('/planner/Dashboard')
            }).then(() => {
                this.loaded = true
            }).catch((err) => {
                this.message = error(err)
                this.loaded = true
            })
        },
        retryEnabled(){
            this.retry= true
            this.$store.dispatch('auth/getIsLoginDisabled').then(() => {
            }).then(() => {
                setTimeout(() => {
                    if (this.loggedIn) {
                        this.$router.push('/planner/Dashboard')
                    }
                }, 1500)
            }).finally(()=>{
                setTimeout(() => {
                    this.retry= false
                }, 1500)
            })
        }
    },
    mounted() {

        this.$store.dispatch('auth/getIsLoginDisabled').then(() => {
            setTimeout(() => {
                if (this.loggedIn) {
                    this.$router.push('/planner/Dashboard')
                }
            }, 1500)
        })
    }
}
</script>

<style scoped>

v-row {
    width: 100vw !important;
}

h1 {
    font-weight: 300;
}

v-card {
    backdrop-filter: blur(14px) !important;
    background-color: rgba(255, 255, 255, 0.2) !important;
}

.background {
    width: 100vw;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center left;
}

.login-row {
    top: 50%;
    display: flex;
}

.transparent-card {
    backdrop-filter: blur(9px) !important;
    background-color: rgba(255, 255, 255, 0.75) !important;
    animation: fadeIn 3s;
    -webkit-animation: fadeIn 3s;
    -moz-animation: fadeIn 3s;
    -o-animation: fadeIn 3s;
    -ms-animation: fadeIn 3s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

</style>
