<template>
  <v-container :class="getClassRelative()" id="pdfInvoice" style="top:2cm; left: 0">
    <div :class="getClassRelative() + ' ' + getClassRight()" style="left: 2.5cm">

      <v-row :class="getClassAbsolute()" style="top:2cm; right: 1.5cm; padding-right: 3.5cm">
        <v-col style="text-align: right;">
          <h3 style="font-weight: bold" v-if="!getStorno()">Rechnung</h3>
          <h3 style="font-weight: bold" v-else>Stornorechnung</h3>
        </v-col>

      </v-row>
      <v-row :class="getClassAbsolute()" v-if="profilePic && profilePic !== ''">
        <v-col>
          <img :src="profilePic" style="max-height: 2.2cm; max-width: 2.5cm">
        </v-col>
      </v-row>
      <v-row :class="getClassAbsolute()" style=" top:2.5cm;">
        <v-col>
          <small style="font-size: 11px"><b><span v-if="invoiceSettings.company">{{ invoiceSettings.company }} -</span>
            {{ invoiceSettings.name }}</b> |
            {{ invoiceSettings.street }} | {{ invoiceSettings.city }}
          </small><br>
          {{ invoice.company }}<br>
          {{ invoice.name }}<br>
          {{ invoice.street }}<br>
          {{ invoice.city }}<br> <br><br><br>
        </v-col>
      </v-row>
      <div style=" padding-top:8cm;">
        <v-row>
          <h5 style="padding-left: 0.3cm">{{ invoice.header }}</h5> <br><br>
        </v-row>
        <v-row style="padding-right: 1cm">
          <v-col>
            <h5 style="font-weight: bold">Betreff</h5>
            {{ invoice.subject }}
          </v-col>
          <v-col>
            <h5 style="font-weight: bold">Rechnungsnummer</h5>
            {{ invoice.invoiceNumber }}<span v-if="getStorno()">-S</span>
          </v-col>
          <v-col>
            <h5 style="font-weight: bold">Leistungs- / Lieferzeitraum </h5>
            {{ invoice.date }}
          </v-col>
          <v-col>
            <h5 style="font-weight: bold">Rechnungsdatum</h5>
            {{ getStringDate(invoice.invoiceDate) }}
          </v-col>
          <v-col cols="1">
          </v-col>
        </v-row>
        <v-row style="padding-right: 1cm">
          <v-col style="font-weight: bold" cols="3">
            Bezeichnung
          </v-col>
          <v-col style="font-weight: bold" cols="2" class="text-end">
            Preis
          </v-col>
          <v-col style="font-weight: bold" cols="2" class="text-end">
            Menge
          </v-col>
          <v-col style="font-weight: bold" cols="2" class="text-end">
            Steuersatz
          </v-col>
          <v-col style="font-weight: bold" cols="2" class="text-end">
            Summe (brutto)
          </v-col>
        </v-row>
        <v-row v-for="(position, index) of positions"
               :key="position.title"
               style="padding-right: 1cm"
        >
          <v-col cols="3">
            {{ position.title }}<br v-if="position.description && position.description !==''">
            <span style="font-size: 12px"
                  v-if="position.description && position.description !==''">{{ position.description }}</span>
          </v-col>
          <v-col cols="2" class="text-end">
            <span v-if="getStorno()">-</span>{{ replaceDot(position.price) }}€
          </v-col>
          <v-col cols="2" class="text-end">
            {{ position.quant }} {{ position.einheit }}
          </v-col>
          <v-col cols="2" class="text-end">
            {{ position.steuersatz }}%
          </v-col>
          <v-col cols="2" class="text-end">
            <span v-if="getStorno()">-</span>{{ replaceDot(position.sum) }}€
          </v-col>
          <div v-if="index === 5 || index === 18 || index === 20" class="pageBreaker"></div>
        </v-row>
        <v-row style="text-align: right; padding-top: 0.2cm; padding-right: 1cm">

          <v-col>
            <p style="margin: 0 0">Gesamtpreis (netto): <span
                v-if="getStorno()">-</span>{{ replaceDot(getSumFromPositionsNetto()) }}€</p>
            <p style="margin: 0 0">Umsatzsteuer:
              <span v-if="getStorno()">-</span>{{ replaceDot(getSumFromPositions() - getSumFromPositionsNetto()) }}€</p>
            <p style="margin: 0 0">Gesamtpreis (brutto): <span
                v-if="getStorno()">-</span>{{ replaceDot(getSumFromPositions()) }}€</p>
          </v-col>
          <v-col cols="1">

          </v-col>
        </v-row>

        <v-row v-if="invoiceSettings.kleinunternehmerB">
          <p>{{ invoiceSettings.kleinunternehmer }}</p>
        </v-row>
        <v-row>
          <h5 style="padding-left: 0.3cm">{{ invoice.footer }}</h5>
        </v-row>
        <v-row style="font-size: 13px; padding-top: 1.7cm">
          <v-col style="width: 10cm">
            <p style="margin: 0 0">
              <b style="font-weight: bold" v-if="invoiceSettings.company">{{ invoiceSettings.company }}
                <br></b>
              {{ invoiceSettings.name }}<br>
              {{ invoiceSettings.street }} <br>
              {{ invoiceSettings.city }}
            </p>
            <p style="margin: 0 0">
              <span v-if="invoiceSettings.ustId">USt-ID: {{ invoiceSettings.ustId }}</span>
              <br>
              <span v-if="invoiceSettings.steuernummer">Steuernummer: {{
                  invoiceSettings.steuernummer
                }}</span>
            </p>

          </v-col>
          <v-col>
            <small>
              <b style="font-weight: bold">Kontaktdaten</b>
              <br>
              <span v-if="invoiceSettings.phone">Telefon: {{ invoiceSettings.phone }}</span>
              <br>
              <span v-if="invoiceSettings.email">E-Mail: {{ invoiceSettings.email }}</span>
            </small>
          </v-col>
          <v-col>
            <small>
              <b style="font-weight: bold">Bankverbindung</b>
              <br>
              <span v-if="invoiceSettings.Kontoinhaber">Kontoinhaber: {{
                  invoiceSettings.Kontoinhaber
                }}</span>
              <br>
              <span v-if="invoiceSettings.IBAN">IBAN: {{ invoiceSettings.IBAN }}</span>
              <br>
              <span v-if="invoiceSettings.BIC">BIC: {{ invoiceSettings.BIC }}</span>
            </small>
          </v-col>
          <v-col cols="1">

          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>
<script>
/**
 * Snackbar to show user successful and not successful tries to edit, create or delete a value
 * @displayName Alert
 */
// import VueHtml2pdf from 'vue-html2pdf'
import {date2String, dotToKomma} from "@/helper/helper";
//const Doughnut = () => import("@/components/generalUI/charts/Doughnut");

export default {
  name: "GeneratePdf",
  components: {
    // VueHtml2pdf,
    // Doughnut
  },
  props: {
    /**
     * Contains data to display the alert accordingly
     */
    /**
     * Contains data to display the alert accordingly
     */
    fileName: {
      type: String,
      default() {
        return ''
      }
    },
    invoiceSettings: {
      type: Object,
      default() {
        return {}
      }
    },
    invoice: {
      type: Object,
      default() {
        return {}
      }
    },
    positions: {
      type: Array,
      default() {
        return []
      }
    },
    print: {
      type: Boolean,
      default() {
        return true
      }
    },
    invoiceStatus: Number,
    profilePic: String,
    status: {
      type: Number,
      default() {
        return 1
      }
    },
  },
  methods: {
    generateReport() {
      this.$emit('generated')
    },
    replaceDot(float) {
      return dotToKomma(float)
    },
    isZero(number) {
      if (number < 0) {
        return 0
      } else {
        return number
      }
    },
    getStorno() {
      return this.status === 3;
    },
    getStringDate(date) {
      return date2String(date)
    },
    getSumFromPositions() {
      let sum = 0
      for (let position of this.positions) {
        sum = sum + position.sum
      }
      return sum
    },
    getSumFromPositionsNetto() {
      let sum = 0
      for (let position of this.positions) {
        sum = sum + position.price * position.quant
      }
      return sum
    },
    getClassAbsolute() {
      if (!this.print) {
        return ''
      } else {
        return 'absoluteC'
      }
    },
    getClassRelative() {
      if (!this.print) {
        return ''
      } else {
        return 'relativeC'
      }
    },
    getClassRight() {
      if (!this.print) {
        return ''
      } else {
        return 'rightC'
      }
    },
  },
}

</script>

<style>

.absoluteC {
  position: absolute;

}

.relativeC {
  position: relative;

}

.rightC {
  padding-right: 2.0cm

}

#pdfInvoice * {
  font-weight: unset;
  font-size: unset
}

h1 {
  font-weight: bold;
  font-size: larger;
  padding-top: 20px
}

h2 {
  font-weight: bold;
  font-size: unset;
}

.breakText {
  break-before: page;
  margin-top: 30px;
}
</style>
