import {databases, storage} from "@/config/server-api";
import {Query} from "appwrite";
import "./locations";

import {getError} from "@/config/errors";
import {
    weddingCollection,
    vendorCollection,
    weddingVendorsCollection,
    customerCollection,
    weddingStatusCollection,
    historyEntryCollection,
    weddingFilesCollection,
    weddingFileBucket,
    timePlanCollection,
    timePlanEntryCollection,
    budgetPlanCollection,
    singleBudgetCollection,
    budgetPlanCategoryCollection, locationCollection
} from "@/config/awSettings"
import router from "@/router";
import {getMillsFromTime} from "@/helper/helper";

const delay = ms => new Promise(res => setTimeout(res, ms));

export default {
    namespaced: true,
    state: {
        weddings: [], // Contains Information of all weddings for a planer
        weddingsOpen: [], // Contains open weddings general Information for a planer
        weddingsClosed: [], // Contains closed weddings general Information for a planer
        /**
         *
         */
        wedding: {}, // Contains detailed wedding Information
        vendorLinks: [],
        weddingsForSelection: [],
        weddingsForCustomer: [],
        weddingCreatedId: '',
        weddingsByStatus: {},
        weddingHistory: []
    },
    getters: {
        weddings: (state) => state.weddings,
        weddingsClosed: (state) => state.weddingsClosed,
        wedding: (state) => state.wedding,
        weddingsForSelection: (state) => state.weddingsForSelection,
        weddingsForCustomer: (state) => state.weddingsForCustomer,
        vendorLinks: (state) => state.vendorLinks,
        weddingCreatedId: (state) => state.weddingCreatedId,
        weddingsByStatus: (state) => state.weddingsByStatus,
        weddingHistory: (state) => state.weddingHistory,

    },
    actions: {
        /**
         * Erstellt eine neue Hochzeit
         *
         * @param dispatch
         * @param payload
         * @returns {Promise<void>}
         */
        // eslint-disable-next-line no-empty-pattern
        createWedding({}, payload) {
            return new Promise((resolve, reject) => {
                if (payload.newWedding.customerId) {
                    let importId
                    if (payload.newWedding.$id) {
                        importId = payload.newWedding.$id
                    } else if (payload.newWedding.id) {
                        importId = payload.newWedding.id
                    } else {
                        importId = 'unique()'
                    }
                    delete payload.newWedding['id'];
                    delete payload.newWedding['$id'];
                    const promiseCreateDoc = databases.createDocument(weddingCollection, importId, {
                        plannerId: payload.uid,
                        customerId: payload.newWedding.customerId,
                        wedDate: payload.newWedding.wedDate,
                        status: payload.newWedding.status,
                        type: payload.newWedding.type,
                        price: payload.newWedding.price,

                    })

                    promiseCreateDoc.then(function (doc) {
                        if (payload.openAfterCreate) {
                            router.push('/weddings/wedding/' + doc.$id).then(() => {
                                resolve(doc.$id)
                            })
                        }
                        resolve(doc.$id)
                    }, function (error) {
                        console.log(error)
                        reject(getError(error, "E0080"))
                    })
                } else {
                    reject(getError('Bitte lege zuerst fest für welchen Kunden du die Hochzeit planst.', "E0081"))
                }
            })
        },
        /**
         * Verknüpft einen Dienstleister mit einer Hochzeit
         *
         * @param payload
         */
        linkVendor({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                const promiseCreateDoc = databases.createDocument(weddingVendorsCollection, "unique()", {
                    vendorId: payload.linkedVendor.vendorId,
                    weddingId: payload.weddingId,
                    role: payload.linkedVendor.role,
                    status: payload.linkedVendor.status,
                    plannerId: payload.uid
                })
                promiseCreateDoc.then(function () {
                    dispatch('getVendorsForWedding', {uid: payload.uid, weddingId: payload.weddingId})

                    resolve()
                }, function (error) {
                    console.log(error)
                    reject(getError(error, "E0082"))
                })
            })
        },
        /**
         * Löscht eine Hochzeit
         *
         * @param dispatch
         * @param payload
         * @returns {Promise<unknown>}
         */
        deleteWedding({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                // vendorLinks löschen
                databases.listDocuments(weddingVendorsCollection, [
                    Query.equal("plannerId", payload.uid),
                    Query.equal("weddingId", payload.weddingId)
                ], 100).then((vendorDocs) => {
                    for (let vendorDoc of vendorDocs.documents) {
                        databases.deleteDocument(weddingVendorsCollection, vendorDoc.$id).catch((err) => {
                            console.log(err)
                        })
                    }
                })

                // historyEntry löschen
                databases.listDocuments(historyEntryCollection, [
                    Query.equal("plannerId", payload.uid),
                    Query.equal("weddingId", payload.weddingId)
                ], 100).then((historyDocs) => {
                    for (let historyDoc of historyDocs.documents) {
                        databases.deleteDocument(historyEntryCollection, historyDoc.$id).catch((err) => {
                            console.log(err)
                        })
                    }
                })

                // Files löschen
                databases.listDocuments(weddingFilesCollection, [
                    Query.equal("plannerId", payload.uid),
                    Query.equal("weddingId", payload.weddingId)
                ], 100).then((fileDocs) => {
                    for (let fileDoc of fileDocs.documents) {
                        storage.deleteFile(weddingFileBucket, fileDoc.docId).then(() => {
                            databases.deleteDocument(weddingFilesCollection, fileDoc.$id).catch((err) => {
                                console.log(err)
                            })
                        })
                    }
                })

                // Timeplan löschen
                databases.listDocuments(timePlanCollection, [Query.equal("weddingId", payload.weddingId)], 100
                ).then((timeplanDocs) => {
                    for (let timeplanDoc of timeplanDocs.documents) {
                        databases.listDocuments(timePlanEntryCollection, [Query.equal("timePlanId",
                            timeplanDoc.timePlanId
                        )], 100).then((timePlanEntryDocs) => {
                            for (let timePlanEntryDoc of timePlanEntryDocs.documents) {
                                databases.deleteDocument(timePlanEntryCollection, timePlanEntryDoc.$id).catch((err) => {
                                    console.log(err)
                                })
                            }
                        })
                        databases.deleteDocument(timePlanCollection, timeplanDoc.$id).catch((err) => {
                            console.log(err)
                        })
                    }
                })

                // Budgetplan löschen
                databases.listDocuments(budgetPlanCollection, [Query.equal("weddingId",
                    payload.weddingId
                )], 100).then((budgetPlanDocs) => {
                    for (let budgetPlanDoc of budgetPlanDocs.documents) {
                        databases.listDocuments(singleBudgetCollection, [Query.equal("budgetPlanId",
                            budgetPlanDoc.$id
                        )], 100).then((docs) => {
                            for (let singleBud of docs.documents) {
                                databases.deleteDocument(singleBudgetCollection, singleBud.$id).then(() => {
                                }).catch((err) => {
                                    console.log(err)
                                })
                            }
                            databases.listDocuments(budgetPlanCategoryCollection, [Query.equal("budgetPlanId",
                                budgetPlanDoc.$id
                            )], 100).then((cats) => {
                                for (let cat of cats.documents) {
                                    databases.deleteDocument(budgetPlanCategoryCollection, cat.$id).then(() => {
                                        console.log('Kategorie ' + cat.name + ' gelöscht.')
                                    }).catch((err) => {
                                        console.log(err)
                                    })
                                }
                                databases.deleteDocument(budgetPlanCollection, budgetPlanDoc.$id).then(() => {
                                    resolve()
                                }).catch((err) => {
                                    console.log(err)
                                })
                            })
                        }).catch((err) => {
                            console.log(err)
                        })
                    }
                })
                // Wedding löschen
                const promiseDeleteDoc = databases.deleteDocument(weddingCollection, payload.weddingId);
                promiseDeleteDoc.then(function () {
                    dispatch('setWeddingsEmpty').then(() => {
                        dispatch('getWeddings', {uid: payload.uid}).then(() => {
                            resolve()
                        })
                    })
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0083"))
                });
            })
        },
        /**
         * leert die Liste der Hochzeiten eines Planers
         *
         * @param commit
         */
        setWeddingsEmpty({commit}) {
            return new Promise((resolve) => {
                let emptyList = []
                commit('setWeddings', emptyList)
                resolve()
            })
        },
        /**
         * Holt alle Weddings zu einem Hochzeitplaner aus der Datenbank
         *
         * @param commit
         * @param payload
         * @returns {Promise<unknown>}
         */
        getWeddings({commit}, payload) {
            return new Promise((resolve, reject) => {
                    let weddings = []
                    let weddingsClosed = []
                    const promiseGetDocs = databases.listDocuments(weddingCollection, [Query.equal("plannerId",
                        payload.uid
                    )], 0)
                    promiseGetDocs.then(async function (documentListX) {
                        if (documentListX.total === 0) {
                            resolve([])
                        }
                        let pages = Math.ceil(documentListX.total / 25)
                        for (let i = 0; i < pages; i++) {
                            databases.listDocuments(weddingCollection, [Query.equal("plannerId",
                                payload.uid
                            )], 25, 25 * i).then((documentList) => {
                                for (let doc of documentList.documents) {
                                    if (doc.customerId !== null && doc.customerId !== '') {
                                        const promiseGetDoc = databases.getDocument(customerCollection, doc.customerId)
                                        let customer = {
                                            firstname: '',
                                            lastname: '',
                                            partnerFirstname: '',
                                        }
                                        promiseGetDoc.then(function (doc2) {
                                            let properDate = new Date(doc2.$createdAt * 1000);
                                            customer = {
                                                firstname: doc2.firstname,
                                                lastname: doc2.lastname,
                                                partnerFirstname: doc2.partnerFirstname,
                                                partnerLastname: doc2.partnerLastname,
                                                email: doc2.email,
                                                notes: doc2.notes,
                                                tel: doc2.tel,
                                                id: doc2.$id,
                                                created: properDate
                                            }
                                            let customerName = getCustomerName(customer.firstname, customer.partnerFirstname)
                                            let wedding = {
                                                values: {
                                                    customerName,
                                                    wedDate: new Date(doc.wedDate),
                                                    status: doc.status,
                                                    type: doc.type,
                                                },
                                                labels: doc.labels,
                                                export: doc,
                                                customerFirstName: customer.firstname,
                                                customerPartnerName: customer.partnerFirstname,
                                                wedDate: doc.wedDate,
                                                id: doc.$id,
                                                type: doc.type,
                                                isArchived: doc.isArchived
                                            }
                                            delete wedding.export['$collection'];
                                            delete wedding.export['$read'];
                                            delete wedding.export['$write'];

                                            if (doc.isArchived) {
                                                weddingsClosed.push(wedding)
                                            } else {
                                                weddings.push(wedding)
                                            }
                                        }, (err) => {
                                            console.log(err); // Failure
                                            reject(getError(err, "E0084"))
                                        })
                                    }
                                }
                            }).catch((err) => {
                                console.log(err)
                            }).finally(() => {
                                if (i === pages - 1) {
                                    commit('setWeddings', weddings)
                                    commit('setWeddingsClosed', weddingsClosed)
                                    resolve(weddings)
                                }
                            })
                            await delay(100)
                        }
                    }, function (error) {
                        console.log(error); // Failure
                        reject(getError(error, "E0085"))
                    })
                }
            )
        },
        getWeddingsForCustomer({commit}, payload) {
            return new Promise((resolve, reject) => {
                    let weddings = []
                    const promiseGetDocs = databases.listDocuments(weddingCollection, [
                        Query.equal("plannerId", payload.uid),
                        Query.equal("customerId", payload.customerId)
                    ], 100)
                    promiseGetDocs.then(function (documentList) {
                        for (let doc of documentList.documents) {
                            if (doc.customerId !== null && doc.customerId !== '') {
                                let customerName = getCustomerName(payload.customer.firstname, payload.customer.partnerFirstname)
                                let wedding = {
                                    values: {
                                        customerName,
                                        wedDate: new Date(doc.wedDate),
                                        status: doc.status,
                                    },
                                    customerFirstName: payload.customer.firstname,
                                    customerPartnerName: payload.customer.partnerFirstname,
                                    wedDate: doc.wedDate,
                                    id: doc.$id,
                                    isArchived: doc.isArchived
                                }
                                weddings.push(wedding)
                            }
                        }
                        commit('setWeddingsForCustomer', weddings)
                        resolve(weddings)
                    }, function (error) {
                        console.log(error); // Failure
                        reject(getError(error, "E0085"))
                    })
                }
            )
        },
        getWeddingsByStatus({commit}, payload) {
            return new Promise((resolve, reject) => {
                    let weddings = {}
                    let weddingsClosed = []
                    databases.listDocuments(weddingStatusCollection, [Query.equal("plannerId",
                        payload.uid
                    )], 100).then((docs) => {
                        for (let status of docs.documents) {
                            weddings[status.name] = []
                        }
                        const promiseGetDocs = databases.listDocuments(weddingCollection, [Query.equal("plannerId",
                            payload.uid
                        )], 0)
                        promiseGetDocs.then(async function (documentListX) {
                            if (documentListX.total === 0) {
                                commit('setWeddingsByStatus', weddings)
                                commit('setWeddingsClosed', weddingsClosed)
                                resolve(weddings)
                            }
                            let pages = Math.ceil(documentListX.total / 25)
                            for (let i = 0; i < pages; i++) {
                                databases.listDocuments(weddingCollection, [Query.equal("plannerId",
                                    payload.uid
                                )], 25, 25 * i).then((documentList) => {
                                    for (let doc of documentList.documents) {
                                        if (doc.customerId !== null && doc.customerId !== '') {
                                            const promiseGetDoc = databases.getDocument(customerCollection, doc.customerId)
                                            let customer = {
                                                firstname: '',
                                                lastname: '',
                                                partnerFirstname: '',
                                            }
                                            promiseGetDoc.then(function (doc2) {
                                                let properDate = new Date(doc2.$createdAt * 1000);
                                                customer = {
                                                    firstname: doc2.firstname,
                                                    lastname: doc2.lastname,
                                                    partnerFirstname: doc2.partnerFirstname,
                                                    partnerLastname: doc2.partnerLastname,
                                                    email: doc2.email,
                                                    notes: doc2.notes,
                                                    tel: doc2.tel,
                                                    id: doc2.$id,
                                                    created: properDate
                                                }
                                                let customerName = getCustomerName(customer.firstname, customer.partnerFirstname)
                                                let wedding = {
                                                    values: {
                                                        customerName,
                                                        wedDate: new Date(doc.wedDate),
                                                        status: doc.status,
                                                        type: doc.type,
                                                    },
                                                    labels: doc.labels,
                                                    customerFirstName: customer.firstname,
                                                    customerPartnerName: customer.partnerFirstname,
                                                    wedDate: doc.wedDate,
                                                    type: doc.type,
                                                    id: doc.$id,
                                                    isArchived: doc.isArchived
                                                }
                                                if (doc.isArchived) {
                                                    weddingsClosed.push(wedding)
                                                } else {
                                                    if (weddings[wedding.values.status]) {
                                                        weddings[wedding.values.status].push(wedding)
                                                    }
                                                }
                                            }, (err) => {
                                                console.log(err); // Failure
                                                reject(getError(err, "E0084"))
                                            })
                                        }
                                    }
                                }).catch((err) => {
                                    console.log(err)
                                }).finally(() => {
                                    if (i === pages - 1) {
                                        commit('setWeddingsByStatus', weddings)
                                        commit('setWeddingsClosed', weddingsClosed)
                                        resolve(weddings)
                                    }
                                })
                                await delay(100)
                            }
                        }, function (error) {
                            console.log(error); // Failure
                            reject(getError(error, "E0085"))
                        })
                    })
                }
            )
        }
        ,
        /**
         * Liest die Vendor-Infos zu einer WeddingVendor-Verlinkung aus
         *
         * @param commit
         * @param payload
         * @returns {Promise<unknown>}
         */
        getVendorFromVendorLink({commit}, payload) {
            let vendorLink = payload.vendorLink
            return new Promise((resolve, reject) => {
                const promiseGetDoc = databases.getDocument(vendorCollection, payload.vendorLink.vendorId)
                promiseGetDoc.then(function (doc) {
                    vendorLink.name = doc.name
                    commit('addVendorLink', vendorLink)
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0086"))
                });
            })
        }
        ,
        /**
         * leert die Liste der WeddingVendor-Verlinkungen eines Planers
         *
         * @param commit
         */
        setVendorsEmpty({commit}) {
            let emptyList = []
            commit('setVendorLinks', emptyList)
        }
        ,
        /**
         * Liest alle WeddingVendor-Links zu einer Hochzeit eines Planers aus der Datenbank und füllt diese mit Informationen zum Vendor
         *
         * @param dispatch
         * @param payload
         * @returns {Promise<unknown>}
         */
        getVendorsForWedding({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDocs = databases.listDocuments(weddingVendorsCollection, [
                    Query.equal("plannerId", payload.uid),
                    Query.equal("weddingId", payload.weddingId)
                ], 100)
                promiseGetDocs.then(function (documentList) {
                    for (let doc of documentList.documents) {
                        let vendorLink = {
                            vendorId: doc.vendorId,
                            role: doc.role,
                            status: doc.status,
                            id: doc.$id,
                        }
                        dispatch('getVendorFromVendorLink', {vendorLink, uid: payload.uid})
                    }
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0087"))
                });
            })
        }
        ,
// eslint-disable-next-line no-empty-pattern
        createHistoryEntry({commit}, payload) {
            return new Promise((resolve, reject) => {

                const promiseCreateDoc = databases.createDocument(historyEntryCollection, "unique()", payload.historyEntry)
                promiseCreateDoc.then(function (doc) {
                    let date = doc.$createdAt * 1000;
                    if (doc.date) {
                        date = doc.date;
                    }
                    commit('addToWeddingHistory', {
                        dateText: getStringDate(doc.$createdAt * 1000),
                        subject: doc.title,
                        text: doc.text,
                        date,
                        time: doc.time,
                        id: doc.$id,
                        type: doc.type

                    })
                    resolve()
                }, function (error) {
                    console.log(error)
                    reject(error)
                })
            })
        }
        ,
// eslint-disable-next-line no-empty-pattern
        deleteHistoryEntry({}, payload) {
            return new Promise((resolve, reject) => {
                const promiseCreateDoc = databases.deleteDocument(historyEntryCollection, payload.id)
                promiseCreateDoc.then(function () {
                    resolve()
                }, function (error) {
                    console.log(error)
                    reject(error)
                })
            })
        }
        ,
// eslint-disable-next-line no-empty-pattern
        editLinkVendor({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                console.log(payload)
                const promiseUpdateDoc = databases.updateDocument(weddingVendorsCollection, payload.id, {
                    vendorId: payload.linkedVendor.vendorId,
                    weddingId: payload.weddingId,
                    role: payload.linkedVendor.role,
                    status: payload.linkedVendor.status,
                    plannerId: payload.uid
                })
                promiseUpdateDoc.then((doc) => {
                    console.log(payload.weddingId)
                    dispatch('getVendorsForWedding', {uid: payload.uid, weddingId:  payload.weddingId })
                    resolve(doc)
                }).catch((e) => {
                    console.log(e);
                    reject(reject(getError("Fehler beim Anlegen der Kategorien zum neuen Budgetplan.", ""), e))
                })
            })
        }
        ,
// eslint-disable-next-line no-empty-pattern
        editHistoryEntry({}, payload) {
            return new Promise((resolve, reject) => {
                console.log(payload.date)
                /*
                 plannerId: this.user.id,
                 entryId: payload,
                 date : new Date(this.date),
                 time : this.time,
                 text: this.text,
                 subject: this.subject,
                 type: this.type
                 */
                const promiseUpdateDoc = databases.updateDocument(historyEntryCollection, payload.entryId, {
                    date: payload.date,
                    time: payload.time,
                    text: payload.text,
                    title: payload.subject,
                    type: payload.type
                });
                promiseUpdateDoc.then((doc) => {
                    resolve(doc)
                }).catch((e) => {
                    console.log(e);
                    reject(reject(getError("Fehler beim Anlegen der Kategorien zum neuen Budgetplan.", ""), e))
                })
            })
        },
        // eslint-disable-next-line no-empty-pattern
        getCustomer({}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDoc = databases.getDocument(customerCollection, payload.customerId)
                promiseGetDoc.then(function (doc2) {
                    let customer = {
                        firstname: doc2.firstname,
                        lastname: doc2.lastname,
                        partnerFirstname: doc2.partnerFirstname,
                        partnerLastname: doc2.partnerLastname,
                        email: doc2.email,
                        notes: doc2.notes,
                        tel: doc2.tel,
                        id: doc2.$id,
                        created: doc2.$createdAt * 1000
                    }
                    resolve(getCustomerName(customer.firstname, customer.partnerFirstname))
                }, (error) => {
                    console.log(error); // Failure
                    reject(getError(error, "E0088"))
                })
            })
        },
        // eslint-disable-next-line no-empty-pattern
        getLocationName({}, payload) {
            return new Promise((resolve) => {
                const promiseGetDoc = databases.getDocument(locationCollection, payload.locationId)
                promiseGetDoc.then(function (doc2) {
                    resolve(doc2.name)
                }, () => {
                    resolve('')
                })
            })
        },
        /**
         * Liest eine Hochzeit aus der Datenbank
         *
         * @param commit
         * @param dispatch
         * @param payload
         * @returns {Promise<unknown>}
         */
        getWedding({commit, dispatch}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDoc = databases.getDocument(weddingCollection, payload.weddingId)
                promiseGetDoc.then(function (doc) {
                    dispatch('getCustomer', {customerId: doc.customerId}).then((customerName) => {
                        dispatch('getLocationName', {locationId: doc.location}).then((locationName) => {
                            let properWedDate
                            if (doc.wedDate) {
                                properWedDate = new Date(doc.wedDate);
                            } else {
                                properWedDate = null
                            }
                            let wedding = {
                                id: doc.$id,
                                customerName,
                                locationName,
                                notes: doc.notes,
                                wedDate: properWedDate,
                                status: doc.status,
                                type: doc.type,
                                price: doc.price,
                                labels: doc.labels,
                                locationId: doc.location,
                                locations: doc.locations,
                                customerId: doc.customerId,
                                isArchived: doc.isArchived,
                                created: doc.$createdAt * 1000
                            }
                            commit('setWedding', wedding)
                            dispatch('getVendorsForWedding', {weddingId: payload.weddingId, uid: payload.uid})
                            commit('deleteWeddingHistory')
                            commit('addToWeddingHistory', {
                                subject: 'Hochzeit erstellt',
                                dateText: getStringDate(doc.$createdAt * 1000),
                                text: doc.text,
                                date: doc.$createdAt * 1000
                            })
                            resolve()
                        }).catch((error) => {
                            console.log(error); // Failure
                            reject(error)
                        })
                    }).catch((error) => {
                        console.log(error); // Failure
                        reject(error)
                    })
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0089"))
                });
            })
        }
        ,
        resetHistoryForWedding({commit, dispatch}, payload) {
            console.log("works")
            commit('deleteWeddingHistory').catch((error) => {
                console.log(error);
            })
            console.log(payload)
            dispatch('getHistoryForWedding', payload).catch((error) => {
                console.log(error);
            })
        },
        getHistoryForWedding({commit}, payload) {
            console.log(payload)
            return new Promise((resolve, reject) => {
                const promiseGetDoc = databases.listDocuments(historyEntryCollection, [
                    Query.equal("plannerId", payload.uid),
                    Query.equal("weddingId", payload.weddingId)
                ], 0)
                promiseGetDoc.then(async function (docsX) {
                    if (docsX.total === 0) {
                        resolve()
                    }
                    let pages = Math.ceil(docsX.total / 25)
                    for (let i = 0; i < pages; i++) {
                        databases.listDocuments(historyEntryCollection, [
                            Query.equal("plannerId", payload.uid),
                            Query.equal("weddingId", payload.weddingId)
                        ], 25, 25 * i).then((docs) => {
                            for (let doc of docs.documents) {
                                let date = doc.$createdAt * 1000;
                                let dateTime = doc.$createdAt * 1000;
                                if (doc.date) {
                                    dateTime = doc.date + getMillsFromTime(doc.time)
                                    date = doc.date;
                                }
                                commit('addToWeddingHistory', {
                                    dateText: getStringDate(date),
                                    subject: doc.title,
                                    text: doc.text,
                                    date: dateTime,
                                    time: doc.time,
                                    id: doc.$id,
                                    type: doc.type

                                })
                            }
                        }).catch((err) => {
                            console.log(err)
                        }).finally(() => {
                            if (i === pages - 1) {
                                resolve()
                            }
                        })
                        await delay(100)
                    }
                }, function (error) {
                    console.log(error); // Failure
                    reject(error)
                });
            })
        }
        ,
        /**
         * Löscht eine WeddingVendor-Verknüpfung
         *
         * @param dispatch
         * @param payload
         * @returns {Promise<unknown>}
         */
// eslint-disable-next-line no-empty-pattern
        deleteVendorLink({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                const promiseDeleteDoc = databases.deleteDocument(weddingVendorsCollection, payload.vendorLinkId);
                promiseDeleteDoc.then(function () {
                    dispatch('setVendorsEmpty').then(() => {
                        dispatch('getVendorsForWedding', {uid: payload.uid, weddingId: payload.weddingId})
                    })
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0090"))
                });
            })
        },

        /**
         * Holt alle Weddings zu einer plannerId und setzt sie als Auswahl für wedding Dropdowns
         *
         * @param commit
         * @param payload
         * @returns {Promise<unknown>}
         */
        getWeddingsForSelection({commit}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDocs = databases.listDocuments(weddingCollection, [
                    Query.equal("plannerId", payload.uid),
                ])
                promiseGetDocs.then(function (documentList) {
                    let weddings = []
                    for (let doc of documentList.documents) {
                        const promiseGetDoc = databases.getDocument(customerCollection, doc.customerId)
                        promiseGetDoc.then(function (doc2) {
                            let customer = {
                                firstname: doc2.firstname,
                                partnerFirstname: doc2.partnerFirstname,
                                id: doc2.$id,
                                created: doc2.$createdAt
                            }
                            let customerName = getCustomerName(customer.firstname, customer.partnerFirstname)
                            let wedding = {
                                text: customerName + ' | ' + getStringDate(doc.wedDate),
                                customer: doc.customerId,
                                value: doc.$id
                            }
                            if (!doc.isArchived) {
                                weddings.push(wedding)
                            }
                        }, (err) => {
                            console.log(err)
                            reject(getError(err, "E0091"))
                        })
                    }
                    commit('setWeddingForSelection', weddings)
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0092"))
                });
            })
        }
        ,

    },
    mutations: {
        setWeddings(state, payload) {
            state.weddings = payload
        }
        ,
        setWeddingsClosed(state, payload) {
            state.weddingsClosed = payload
        }
        ,
        setWeddingForSelection(state, payload) {
            state.weddingsForSelection = payload
        }
        ,
        setWeddingsForCustomer(state, payload) {
            state.weddingsForCustomer = payload
        }
        ,
        setWedding(state, payload) {
            state.wedding = payload
        }
        ,
        setVendorLinks(state, payload) {
            state.vendorLinks = payload
        }
        ,
        setWeddingCreatedId(state, payload) {
            state.weddingCreatedId = payload
        }
        ,
        setWeddingsByStatus(state, payload) {
            state.weddingsByStatus = payload
        }
        ,
        addToWeddingHistory(state, payload) {
            state.weddingHistory.push(payload)
        }
        ,
        deleteWeddingHistory(state) {
            state.weddingHistory = []
        }
        ,
        addVendorLink(state, payload) {
            let isContained = false
            for (let vendorLink of state.vendorLinks) {
                if (vendorLink.id === payload.id) {
                    isContained = true
                }
            }
            if (!isContained) {
                state.vendorLinks.push(payload)
            }
        }
        ,
    }
}

function getCustomerName(firstname, partnerFirstname) {
    if (firstname !== '' && partnerFirstname === '') {
        return firstname
    } else if (firstname === '' && partnerFirstname !== '') {
        return partnerFirstname
    } else if (firstname !== '' && partnerFirstname !== '') {
        return firstname + " und " + partnerFirstname
    } else {
        return ''
    }
}

function getStringDate(date) {
    if (date !== null) {
        const newDate = new Date(date)
        let options = {year: 'numeric', month: 'numeric', day: 'numeric'}
        return newDate.toLocaleDateString('de-DE', options)
    } else {
        return ""
    }
}

