import Vue from 'vue'
import Vuex from 'vuex'

import customer from "@/store/modulesAW/customer";
import sort from "@/store/modulesAW/sort";
import auth from "@/store/modulesAW/auth"
import vendor from "@/store/modulesAW/vendor";
import wedding from "@/store/modulesAW/wedding";
import mail from "@/store/modulesAW/mail";
import task from "@/store/modulesAW/task";
import calendar from "@/store/modulesAW/calendar";
import file from "@/store/modulesAW/file";
import settings from "@/store/modulesAW/settings";
import budgetPlan from "@/store/modulesAW/budgetplan";
import timePlan from "@/store/modulesAW/timeplan";
import payment from "@/store/modulesAW/payment";
import admin from "@/store/modulesAW/admin";
import locations from "@/store/modulesAW/locations";
import notification from "@/store/modulesAW/notification";
import general from "@/store/modulesAW/general"
import support from "@/store/modulesAW/support";
import invoice from "@/store/modulesAW/invoice";
import deleteAccount from "@/store/modulesAW/deleteAccount";
import seatPlan from "@/store/modulesAW/seatplan";

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        admin,
        customer,
        sort,
        auth,
        vendor,
        wedding,
        mail,
        task,
        calendar,
        file,
        settings,
        budgetPlan,
        timePlan,
        notification,
        payment,
        locations,
        general,
        support,
        invoice,
        deleteAccount,
        seatPlan
    }
})
