import {databases} from "@/config/server-api";
import {
    guestCollection,
    seatPlanCollection, tableCollection,
} from "@/config/awSettings";
import {Query} from "appwrite";
import {getError} from "@/config/errors";


export default {
    namespaced: true,
    state: {
        tables: [],
        guests: [],
        seatPlan: {},
        seatPlans: [],
        seatPlanTemplates: [],
        isInCreation: false,
        tablesSelection: []
    },
    getters: {
        tables: (state) => state.tables,
        tablesSelection: (state) => state.tablesSelection,
        guests: (state) => state.guests,
        seatPlan: (state) => state.seatPlan,
        seatPlans: (state) => state.seatPlans,
        seatPlanTemplates: (state) => state.seatPlanTemplates,
        isInCreation: (state) => state.isInCreation,
    },
    actions: {
        /**
         * Erstellt einen neuen Sitzplan
         *
         * @param commit
         * @param payload
         */
        // eslint-disable-next-line no-unused-vars
        createSeatPlan({commit}, payload) {
            return new Promise((resolve, reject) => {
                let newDoc = {
                    name: payload.name,
                    description: '',
                    weddingId: payload.weddingId,
                    plannerId: payload.uid,
                }
                databases.createDocument(seatPlanCollection, "unique()", newDoc).then(() => {
                    resolve()
                }).catch((err) => {
                    console.log(err)
                    reject(err)
                })
            })
        },
        createTable({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                let newDoc = {
                    name: payload.name,
                    height: payload.height,
                    width: payload.width,
                    form: payload.form,
                    seats: payload.seats,
                    color: payload.color,
                    SeatPlanId: payload.seatPlanId,
                    h: payload.h,
                    w: payload.w,
                    x: payload.x,
                    y: payload.y,
                }
                databases.createDocument(tableCollection, "unique()", newDoc).then(() => {
                    dispatch('getSeatPlan', {seatPlanId: payload.seatPlanId})
                    resolve()
                }).catch((err) => {
                    console.log(err)
                    reject(err)
                })
            })
        },
        createGuest({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                let newDoc = {
                    name: payload.name,
                    type: payload.type,
                    tableId: payload.tableId,
                    count: payload.count,
                    SeatPlanId: payload.seatPlanId,

                }
                databases.createDocument(guestCollection, "unique()", newDoc).then(() => {
                    dispatch('getSeatPlan', {seatPlanId: payload.seatPlanId})
                    resolve()
                }).catch((err) => {
                    console.log(err)
                    reject(err)
                })
            })
        },

        deleteGuest({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                const promiseDeleteDoc = databases.deleteDocument(guestCollection, payload.id);
                promiseDeleteDoc.then(function () {
                    dispatch('getSeatPlan', {seatPlanId: payload.seatPlanId})
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, error))
                });
            })
        },
        deleteTable({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                const promiseDeleteDoc = databases.deleteDocument(tableCollection, payload.id);
                promiseDeleteDoc.then(function () {
                    dispatch('getSeatPlan', {seatPlanId: payload.seatPlanId})
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, error))
                });
            })
        },
        getSeatPlansForWedding({commit}, payload) {
            return new Promise((resolve, reject) => {
                let seatPlans = []
                const promiseGetDocs = databases.listDocuments(seatPlanCollection, [Query.equal("weddingId",
                    payload.weddingId
                )], 100)
                promiseGetDocs.then(function (documentList) {
                    for (let doc of documentList.documents) {
                        console.log(doc)

                        seatPlans.push({
                            id: doc.$id,
                            name: doc.name,
                            description: doc.description,
                        })
                        console.log(seatPlans)
                    }
                    commit('setSeatPlans', seatPlans)
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject('Fehler beim lesen der Sitzpläne zur Hochzeit.')
                })
            })
        },
        getSeatPlan({commit}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetPlan = databases.getDocument(seatPlanCollection, payload.seatPlanId)
                promiseGetPlan.then((doc) => {
                    const seatPlan = {
                        id: doc.$id,
                        name: doc.name,
                        description: doc.description,
                        weddingId: doc.weddingId
                    }
                    commit('setSeatPlan', seatPlan)

                    //getGuests
                    const promiseGetGuests = databases.listDocuments(guestCollection, [Query.equal("SeatPlanId",
                        payload.seatPlanId
                    )], 100)
                    promiseGetGuests.then((documentList2) => {
                        let guests = []
                        let tableGuests = {}
                        for (let doc3 of documentList2.documents) {
                            const guest = {
                                id: doc3.$id,
                                name: doc3.name,
                                type: doc3.type,
                                tableId: doc3.tableId,
                                count: doc3.count,
                            }
                            if (tableGuests[guest.tableId]) {
                                let list = tableGuests[guest.tableId]
                                list.push(guest)
                                tableGuests[guest.tableId] = list
                            } else {
                                let list = []
                                list.push(guest)
                                tableGuests[guest.tableId] = list
                            }
                            guests.push(guest)
                        }

                        commit('setGuests', guests)
                        // get Tables
                        const promiseGetTables = databases.listDocuments(tableCollection, [Query.equal("SeatPlanId",
                            payload.seatPlanId
                        )], 100)
                        promiseGetTables.then((documentList) => {
                            let tables = []
                            let tablesSelection = []

                            for (let doc2 of documentList.documents) {
                                let takenSeats = 0
                                for (let guest of guests) {
                                    if (guest.tableId === doc2.$id) {
                                        takenSeats = takenSeats + guest.count
                                    }
                                }

                                const table = {
                                    id: doc2.$id,
                                    h: Number(doc2.h),
                                    w: Number(doc2.w),
                                    x: Number(doc2.x),
                                    y: Number(doc2.y),
                                    form: doc2.form,
                                    seats: doc2.seats,
                                    color: doc2.color,
                                    name: doc2.name,
                                    takenSeats,
                                    guests: tableGuests[doc2.$id]
                                }
                                const tableSelection = {
                                    value: doc2.$id,
                                    text: doc2.name + ' | ' + takenSeats + '/' + doc2.seats,

                                }
                                tables.push(table)
                                tablesSelection.push(tableSelection)
                            }
                            commit('setTables', tables)
                            commit('setTablesSelection', tablesSelection)
                            resolve()
                        }).catch((err) => {
                            console.log(err)
                            reject(err)
                        })


                    }).catch((err) => {
                        console.log(err)
                        reject(err)
                    })


                }).catch((err) => {
                    console.log(err)
                    reject(err)
                })
            })
        },
        // eslint-disable-next-line no-unused-vars
        updatePositions({commit}, payload) {
            return new Promise((resolve, reject) => {
                const promiseUpdateDoc = databases.updateDocument(tableCollection, payload.tableId, {
                    x: payload.x.toString(),
                    y: payload.y.toString(),
                    w: payload.w.toString(),
                    h: payload.h.toString()
                });
                promiseUpdateDoc.then(function () {
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(error)
                });
            })
        },
        // eslint-disable-next-line no-empty-pattern
        deleteSeatPlan({}, payload) {
            return new Promise((resolve, reject) => {
                databases.listDocuments(tableCollection, [Query.equal("SeatPlanId",
                    payload.seatPlanId
                )]).then((docs) => {
                    for (let table of docs.documents) {
                        databases.deleteDocument(tableCollection, table.$id).then(() => {
                        }).catch((err) => {
                            console.log(err)
                        })
                    }
                    databases.listDocuments(guestCollection, [Query.equal("SeatPlanId",
                        payload.seatPlanId
                    )]).then((cats) => {
                        for (let guest of cats.documents) {
                            databases.deleteDocument(guestCollection, guest.$id).catch((err) => {
                                console.log(err)
                            })
                        }
                        databases.deleteDocument(seatPlanCollection, payload.seatPlanId).then(() => {
                            resolve()
                        }).catch((err) => {
                            console.log(err)
                            reject(err)
                        })
                    })
                }).catch((err) => {
                    console.log(err)
                    reject(err)
                })
            })
        },
        updateTable({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                console.log(payload.table)
                const promiseUpdateDoc = databases.updateDocument(tableCollection, payload.id, payload.table);
                promiseUpdateDoc.then(function () {

                    dispatch('getSeatPlan', {seatPlanId: payload.seatPlanId})
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(error)
                });
            })
        },
        updateGuest({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                const promiseUpdateDoc = databases.updateDocument(guestCollection, payload.id, payload.guest);
                promiseUpdateDoc.then(function () {
                    dispatch('getSeatPlan', {seatPlanId: payload.seatPlanId})
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(error)
                });
            })
        }
    },
    mutations: {
        setSeatPlans(state, payload) {
            state.seatPlans = payload
        },
        setSeatPlan(state, payload) {
            state.seatPlan = payload
        },
        setTables(state, payload) {
            state.tables = payload
        },
        setTablesSelection(state, payload) {
            state.tablesSelection = payload
        },
        setGuests(state, payload) {
            state.guests = payload
        },
    }
}
