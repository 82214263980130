<template>
  <v-row>
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <v-col cols="12" sm="4" lg="2">
      <h4>Leistungen
        <v-tooltip right color="" max-width="200px">
          <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon class="d-inline-flex" size="20">
                          mdi-help
                        </v-icon>
                      </span>
          </template>
          Lege fest welchen Leistungen ein Dienstleister anbietet. Bspw.
          "Catering", "Videografie" etc.
        </v-tooltip>
      </h4>
      <h5 class="grey--text">
        (Dienstleister)
      </h5>
    </v-col>
    <v-col>
      <div v-for="vendorService of vendorServices" :key="vendorService.id" class="pb-3">
        {{ vendorService.name }}
        <v-tooltip right color="red">
          <template v-slot:activator="{ on, attrs }">
                        <span
                            v-bind="attrs"
                            v-on="on"
                        >
                      <v-icon @click="sureToDeleteDialog = true; selectedId = vendorService.id">
                      mdi-delete
                      </v-icon>
                        </span>
          </template>
          Status löschen
        </v-tooltip>

      </div>
      <SureToDelete
          class="mt-0 pt-0 mb-0 pb-0"
          :dialog="sureToDeleteDialog"
          :text="'Bist du Sicher, dass du den Status unwiderruflich löschen möchtest?'"
          @cancel="sureToDeleteDialog=false"
          @sure="deleteVendorService(); sureToDeleteDialog=false"
      ></SureToDelete>
      <v-row class="align-center">
        <v-col cols="8" md="6">
          <v-text-field placeholder="Neuer Status" v-model="newVendorService"></v-text-field>
        </v-col>
        <v-col cols="4" md="6">
            <a  @click="addVendorService(newVendorService)" class="d-inline">
              Hinzufügen
            </a>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters} from "vuex";

const Message = () => import("@/components/generalUI/Message");
const SureToDelete = () => import("@/components/generalUI/SureToDelete");
/**
 * Snackbar to show user successful and not successful tries to edit, create or delete a value
 * @displayName Alert
 */
export default {
  name: "VendorServices",
  computed: {
    ...mapGetters('settings', {
      vendorServices: 'vendorServices',
    }),
  },
  components: {
    Message,
    SureToDelete
  },
  data() {
    return {
      newVendorService: '',
      message: {
        show: false,
        text: "",
        color: ""
      },
      sureToDeleteDialog:false,
      selectedId:""
    }
  },
  mounted() {
    this.$store.dispatch('settings/getVendorServices', {
      uid: this.user.id,
    }).catch((err) => {
      this.message = {
        show: true,
        text: err,
        color: "error",
      }
      setTimeout(() => this.message.show = false, 5000);
    })
  },
  props: {
    user: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  methods: {
    deleteVendorService() {
    console.log(this.selectedId)
      this.$store.dispatch('settings/deleteVendorServices', {
        uid: this.user.id,
        id: this.selectedId
      }).then(() => {
        this.message = {
          show: true,
          text: "Leistung erfolgreich gelöscht.",
          color: "success",
        }
        setTimeout(() => this.message.show = false, 5000);
      }).catch((err) => {
        this.message = {
          show: true,
          text: err,
          color: "error",
        }
        setTimeout(() => this.message.show = false, 5000);
      })
    },
    addVendorService(name) {
      if (name !== '') {
        this.$store.dispatch('settings/addVendorService', {
          uid: this.user.id,
          name
        }).then(() => {
          this.newVendorService = ''
          this.message = {
            show: true,
            text: "Leistung erfolgreich angelegt.",
            color: "success",
          }
          setTimeout(() => this.message.show = false, 5000);
        }).catch((err) => {
          this.message = {
            show: true,
            text: err,
            color: "error",
          }

          setTimeout(() => this.message.show = false, 5000);
        })
      }
    },
  }
}
</script>
