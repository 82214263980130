import {databases} from "@/config/server-api";
import {Query} from "appwrite";
import {getError} from "@/config/errors";
import {
    vendorCategoryCollection,
    weddingStatusCollection,
    vendorStatusCollection,
    vendorCollection,
    budgetPlanStandardCategoryCollection,
    customFieldCollection,
    customFieldValueCollection,
    customerStatusCollection,
    myProductsCollection,
    stilCollection, vendorServicesCollection, weddingLabelCollection, invoiceSettingCollection
} from "@/config/awSettings"

export default {
    namespaced: true,
    state: {
        weddingStatus: [],
        vendorStatus: [],
        customerStatus: [],
        weddingStatusForSelection: [],
        vendorStatusForSelection: [],
        customerStatusForSelection: [],
        categories: [],
        myProducts: [],
        myProductsForSelection: [],
        categoryNames: [],
        budgetPlanCategories: [],
        budgetPlanCategoryNames: [],
        customFields: {},
        customFieldsForEntity: [],
        stils: [],
        stilNames: [],
        vendorServices: [],
        vendorServiceNames: [],
        weddingLabels: [],
        weddingLabelNames: [],
        invoiceSettings: {}
    },
    getters: {
        weddingStatus: (state) => state.weddingStatus,
        vendorStatus: (state) => state.vendorStatus,
        customerStatus: (state) => state.customerStatus,
        weddingStatusForSelection: (state) => state.weddingStatusForSelection,
        vendorStatusForSelection: (state) => state.vendorStatusForSelection,
        customerStatusForSelection: (state) => state.customerStatusForSelection,
        categories: (state) => state.categories,
        categoryNames: (state) => state.categoryNames,
        myProducts: (state) => state.myProducts,
        myProductsForSelection: (state) => state.myProductsForSelection,
        budgetPlanCategories: (state) => state.budgetPlanCategories,
        budgetPlanCategoryNames: (state) => state.budgetPlanCategoryNames,
        customFields: (state) => state.customFields,
        customFieldsForEntity: (state) => state.customFieldsForEntity,
        stils: (state) => state.stils,
        stilNames: (state) => state.stilNames,
        vendorServices: (state) => state.vendorServices,
        vendorServiceNames: (state) => state.vendorServiceNames,
        weddingLabels: (state) => state.weddingLabels,
        weddingLabelNames: (state) => state.weddingLabelNames,
        invoiceSettings: (state) => state.invoiceSettings,
    },
    actions: {
        /**
         * Liest alle Vendor Kategorien
         *
         * @param commit
         * @param payload
         * @returns {Promise<unknown>}
         */
        getCategories({commit}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDocs = databases.listDocuments(vendorCategoryCollection, [Query.equal("plannerId",
                    payload.uid
                )])
                promiseGetDocs.then(function (documentList) {
                    let categoryL = []
                    let categoryNames = []
                    for (let doc of documentList.documents) {
                        let category = {
                            id: doc.$id,
                            name: doc.name,
                            icon: doc.icon
                        }
                        categoryL.push(category)
                        categoryNames.push({text: category.name, value: category.id})
                    }
                    commit('setCategories', categoryL);
                    commit('setCategoriesNames', categoryNames)
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0040"))
                });
            })
        },
        getStil({commit}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDocs = databases.listDocuments(stilCollection, [Query.equal("plannerId",
                    payload.uid
                )])
                promiseGetDocs.then(function (documentList) {
                    let stilList = []
                    let stilNames = []
                    for (let doc of documentList.documents) {
                        let stil = {
                            id: doc.$id,
                            name: doc.name,
                        }
                        stilList.push(stil)
                        stilNames.push({text: stil.name, value: stil.id})
                    }
                    commit('setStils', stilList);
                    commit('setStilNames', stilNames)
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0040"))
                });
            })
        },
        getVendorServices({commit}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDocs = databases.listDocuments(vendorServicesCollection, [Query.equal("plannerId",
                    payload.uid
                )])
                promiseGetDocs.then(function (documentList) {
                    let vendorServiceList = []
                    let vendorServiceNames = []
                    for (let doc of documentList.documents) {
                        let vendorService = {
                            id: doc.$id,
                            name: doc.name,
                        }
                        vendorServiceList.push(vendorService)
                        vendorServiceNames.push({text: vendorService.name, value: vendorService.id})
                    }
                    commit('setVendorServices', vendorServiceList);
                    commit('setVendorServiceNames', vendorServiceNames)
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0040"))
                });
            })
        },

        /**
         * Liest alle Budgetplan Kategorien
         *
         * @param commit
         * @param payload
         * @returns {Promise<unknown>}
         */
        getBudgetPlanCategories({commit}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDocs = databases.listDocuments(budgetPlanStandardCategoryCollection, [Query.equal("plannerId",
                    payload.uid
                )])
                promiseGetDocs.then(function (documentList) {
                    let categoryL = []
                    let categoryNames = []
                    for (let doc of documentList.documents) {
                        let category = {
                            id: doc.$id,
                            name: doc.name,
                            icon: doc.icon
                        }
                        categoryL.push(category)
                        categoryNames.push({text: category.name, value: category.id})
                    }
                    commit('setBudgetPlanCategories', categoryL);
                    commit('setBudgetPlanCategoriesNames', categoryNames)
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0040"))
                });
            })
        },
        // eslint-disable-next-line no-empty-pattern
        getCustomFieldCount({}, payload){
            return new Promise((resolve, reject) => {
                databases.listDocuments(customFieldCollection, [Query.equal("plannerId",
                    payload.uid
                )],0).then((docs)=>{
                    resolve(docs.total)
                }).catch((err)=>{
                    reject(err)
                })
            })
        },
        getCustomFields({commit}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDocs = databases.listDocuments(customFieldCollection, [Query.equal("plannerId",
                    payload.uid
                )],100)
                promiseGetDocs.then(function (documentList) {
                    let customFieldsWedding = []
                    let customFieldsCustomer = []
                    let customFieldsVendor = []
                    let customFieldsEvent = []
                    let customFieldsTask = []
                    let customFieldsLocation = []
                    for (let doc of documentList.documents) {
                        let customField = {
                            id: doc.$id,
                            plannerId: payload.uid,
                            entity: doc.entity,
                            type: doc.type,
                            showOnCreate: doc.showOnCreate,
                            isRequired: doc.isRequired,
                            position: doc.position,
                            name: doc.name,
                        }
                        switch (customField.entity) {
                            case 'customer':
                                customFieldsCustomer.push(customField)
                                break;
                            case 'vendor':
                                customFieldsVendor.push(customField)
                                break;
                            case 'wedding':
                                customFieldsWedding.push(customField)
                                break;
                            case 'event':
                                customFieldsEvent.push(customField)
                                break;
                            case 'task':
                                customFieldsTask.push(customField)
                                break;
                            case 'location':
                                customFieldsLocation.push(customField)
                                break;
                        }
                    }
                    let customFields = {
                        customer: customFieldsCustomer,
                        vendor: customFieldsVendor,
                        wedding: customFieldsWedding,
                        event: customFieldsEvent,
                        task: customFieldsTask,
                        location: customFieldsLocation
                    }
                    commit('setCustomFields', customFields);
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0040"))
                });
            })
        },
        setCustomFieldsForEntityEmpty({commit}) {
            return new Promise((resolve) => {
                commit('setCustomFieldsForEntity', [])
                resolve()
            })
        },
        getCustomFieldsForEntity({commit}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDocs = databases.listDocuments(customFieldCollection, [Query.equal("entity",
                    payload.entity
                )])
                promiseGetDocs.then(function (documentList) {
                    for (let doc of documentList.documents) {
                        let customField = {
                            id: doc.$id,
                            plannerId: payload.uid,
                            entity: doc.entity,
                            type: doc.type,
                            showOnCreate: doc.showOnCreate,
                            isRequired: doc.isRequired,
                            position: doc.position,
                            name: doc.name,
                            value: null,
                            valueId: null
                        }
                        const promiseGetDocs2 = databases.listDocuments(customFieldValueCollection, [
                            Query.equal("customFieldId", customField.id),
                            Query.equal("entryId", payload.entryId),
                        ])
                        promiseGetDocs2.then(function (documentList2) {

                            if (documentList2.total > 0) {
                                customField.value = documentList2.documents[0].value
                                customField.valueId = documentList2.documents[0].$id
                            } else {
                                customField.value = null
                                customField.valueId = null
                            }
                            commit('addCustomFieldsForEntity', customField);
                        }).catch((err) => {
                            console.log(err)
                            reject(err)
                        })
                    }
                }, function (error) {
                    console.log(error); // Failure
                    reject("Fehler beim Lesen der individuellen Felder.")
                });
            });
        },
        getMyProducts({commit}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDocs = databases.listDocuments(myProductsCollection, [
                    Query.equal("plannerId", payload.uid),
                ])
                promiseGetDocs.then(function (documentList) {
                    let products = []
                    for (let doc of documentList.documents) {
                        let product = {
                            id: doc.$id,
                            name: doc.name,
                            price: doc.price,
                        }
                        products.push(product)
                    }
                    commit('setMyProducts', products);
                    resolve()
                }).catch((err) => {
                    console.log(err)
                    reject(err)
                })
            })
        },
        getMyProductsForSelection({commit}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDocs = databases.listDocuments(myProductsCollection, [
                    Query.equal("plannerId", payload.uid),
                ])
                promiseGetDocs.then(function (documentList) {
                    let products = []
                    for (let doc of documentList.documents) {
                        let product = {
                            value: doc.name,
                            text: doc.name,
                            price: doc.price
                        }
                        products.push(product)
                    }
                    commit('setMyProductsForSelection', products);
                    resolve()
                }).catch((err) => {
                    console.log(err)
                    reject(err)
                })
            })
        },
        /**
         * Liest alle Status
         *
         * @param commit
         * @param payload
         * @returns {Promise<unknown>}
         */
        getStatus({commit}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDocs = databases.listDocuments(weddingStatusCollection, [
                    Query.equal("plannerId", payload.uid),
                    Query.equal("group", "standard")
                ], 100)
                promiseGetDocs.then(function (documentList) {
                    let statusL = []
                    for (let doc of documentList.documents) {
                        let status = {
                            id: doc.$id,
                            name: doc.name,
                            position: doc.position,
                            group: doc.group
                        }
                        statusL.push(status)
                        statusL.sort(function (a, b) {
                            return a.position - b.position;
                        })
                    }

                    commit('setWeddingStatus', statusL)
                    const promiseGetDocs = databases.listDocuments(vendorStatusCollection, [Query.equal("plannerId",
                        payload.uid
                    )], 100)
                    promiseGetDocs.then(function (documentList2) {
                        let statusL = []
                        for (let doc2 of documentList2.documents) {
                            let status = {
                                id: doc2.$id,
                                name: doc2.name,
                            }
                            statusL.push(status)
                        }
                        commit('setVendorStatus', statusL)
                        const promiseGetDocs3 = databases.listDocuments(customerStatusCollection, [Query.equal("plannerId",
                            payload.uid
                        )], 100)
                        promiseGetDocs3.then(function (documentList3) {
                            let statusL = []
                            for (let doc3 of documentList3.documents) {
                                let status = {
                                    id: doc3.$id,
                                    name: doc3.name,
                                    color: doc3.color

                                }
                                statusL.push(status)
                            }
                            commit('setCustomerStatus', statusL)
                            resolve()
                        }, function (error) {
                            console.log(error); // Failure
                            reject(getError(error, "E0041"))
                        });
                        resolve()
                    }, function (error) {
                        console.log(error); // Failure
                        reject(getError(error, "E0041"))
                    });
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0042"))
                });
            })
        },
        getInvoiceSettings({commit}, payload) {
            return new Promise((resolve) => {
                let promise = databases.listDocuments(invoiceSettingCollection, [
                    Query.equal("plannerId", payload.uid)])
                promise.then((docs) => {
                    if (docs.documents.length < 1) {
                        let setting = {
                            company: '',
                            name: '',
                            street: '',
                            city: '',
                            ustId: '',
                            steuernummer: '',
                            Kontoinhaber: '',
                            IBAN: '',
                            BIC: '',
                            email: '',
                            phone: '',
                            nextInvoice: 1,
                            nextQuote: 1,
                            steuersatz: 0,
                            kleinunternehmerB: false,
                            kleinunternehmer: 'Gemäß § 19 UStG wird aufgrund der Kleinunternehmerregelung keine Umsatzsteuer berechnet.',
                            footer: '',
                            header: ''
                        }
                        commit('setInvoiceSettings', setting)
                        resolve()
                    } else {
                        let doc = docs.documents[0]
                        let setting = {
                            company: doc.company,
                            name: doc.name,
                            street: doc.street,
                            city: doc.city,
                            ustId: doc.ustId,
                            steuernummer: doc.steuernummer,
                            Kontoinhaber: doc.Kontoinhaber,
                            IBAN: doc.IBAN,
                            BIC: doc.BIC,
                            email: doc.email,
                            phone: doc.phone,
                            nextInvoice: doc.nextInvoice,
                            nextQuote: doc.nextQuote,
                            steuersatz: doc.steuersatz,
                            kleinunternehmerB: doc.kleinunternehmerB,
                            kleinunternehmer: doc.kleinunternehmer,
                            footer: doc.footer,
                            header: doc.header
                        }
                        commit('setInvoiceSettings', setting)
                        resolve()
                    }

                })
            })
        },
        createOrUpdateInvoiceSettings({dispatch}, payload) {
            return new Promise((resolve) => {
                let promise = databases.listDocuments(invoiceSettingCollection, [
                    Query.equal("plannerId", payload.uid)])
                promise.then((docs) => {
                    if (docs.documents.length < 1) {
                        let object = payload.newInvoiceSettings
                        object.plannerId = payload.uid
                        databases.createDocument(invoiceSettingCollection, 'unique()',
                            object
                        ).then(() => {
                            dispatch('getInvoiceSettings', {uid: payload.uid})
                            resolve()
                        })
                    } else {
                        databases.updateDocument(invoiceSettingCollection, docs.documents[0].$id,
                            payload.newInvoiceSettings
                        ).then(() => {
                            dispatch('getInvoiceSettings', {uid: payload.uid})
                            resolve()

                        })
                    }
                })
            })
        },
        getWeddingLabels({commit}, payload) {
            return new Promise((resolve) => {
                let promise = databases.listDocuments(weddingLabelCollection, [
                    Query.equal("plannerId", payload.uid)], 100)
                promise.then((docs) => {
                    let labelL = []
                    let labelNames = []
                    for (let doc of docs.documents) {
                        let label = {
                            id: doc.$id,
                            name: doc.name,
                            color: doc.color
                        }
                        labelL.push(label)
                        labelNames.push({text: doc.name, value: doc.id, color: doc.color})
                    }
                    commit('setWeddingLabels', labelL)
                    commit('setWeddingLabelNames', labelNames)
                    resolve()
                })
            })
        },
        /**
         * Liest alle Status
         *
         * @param commit
         * @param payload
         * @returns {Promise<unknown>}
         */
        getStatusForSelection({commit}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDocs = databases.listDocuments(weddingStatusCollection, [
                    Query.equal("plannerId", payload.uid),
                    Query.equal("group", "standard")
                ], 100)
                promiseGetDocs.then(function (documentList) {
                    let statusL = []
                    for (let doc of documentList.documents) {
                        let status = {
                            value: doc.name,
                            text: doc.name,
                        }
                        statusL.push(status)
                    }
                    commit('setWeddingStatusForSelection', statusL)
                    const promiseGetDocs = databases.listDocuments(vendorStatusCollection, [Query.equal("plannerId",
                        payload.uid
                    )], 100)
                    promiseGetDocs.then(function (documentList2) {
                        let statusL = []
                        for (let doc2 of documentList2.documents) {
                            let status = {
                                value: doc2.name,
                                text: doc2.name,

                            }
                            statusL.push(status)
                        }
                        commit('setVendorStatusForSelection', statusL)
                        const promiseGetDocs3 = databases.listDocuments(customerStatusCollection, [Query.equal("plannerId",
                            payload.uid
                        )], 100)
                        promiseGetDocs3.then(function (documentList3) {
                            let statusL = []
                            for (let doc3 of documentList3.documents) {
                                let status = {
                                    value: doc3.name,
                                    text: doc3.name,
                                    color: doc3.color
                                }
                                statusL.push(status)
                            }
                            commit('setCustomerStatusForSelection', statusL)
                            resolve()
                        }, function (error) {
                            console.log(error); // Failure
                            reject(getError(error, "E0043"))
                        });
                        resolve()
                    }, function (error) {
                        console.log(error); // Failure
                        reject(getError(error, "E0043"))
                    });
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0044"))
                });
            })
        },
        /**
         * Erstellt ein CustomField
         *
         * @param dispatch
         * @param payload
         * @returns {Promise<unknown>}
         */
        createCustomField({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                let showOnCreate = false
                if (payload.isRequired) {
                    showOnCreate = true
                } else {
                    showOnCreate = payload.showOnCreate
                }
                const promiseCreateDoc = databases.createDocument(customFieldCollection, "unique()", {
                    plannerId: payload.uid,
                    entity: payload.entity,
                    type: payload.type,
                    name: payload.name,
                    showOnCreate,
                    isRequired: payload.isRequired,
                    position: payload.position,
                })
                promiseCreateDoc.then(function () {
                    dispatch('getCustomFields', {uid: payload.uid})
                    resolve()
                }, function (error) {
                    console.log(error)
                    reject("Fehler beim Erstellen des Individuellen Felds.")
                })
            })
        },
        createCustomFieldValue({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                const promiseCreateDoc = databases.createDocument(customFieldValueCollection, "unique()", {
                    customFieldId: payload.customFieldId,
                    entryId: payload.entryId,
                    value: payload.value,
                })
                promiseCreateDoc.then(function () {
                    dispatch('setCustomFieldsForEntityEmpty', {})
                    dispatch('getCustomFieldsForEntity', {entity: payload.entity, entryId: payload.entryId})
                    resolve()
                }, function (error) {
                    console.log(error)
                    reject("Fehler beim Erstellen des Individuellen Feld-Wertes.")
                })
            })
        },
        /**
         * Erstellt eine Kategorie
         *
         * @param dispatch
         * @param payload
         * @returns {Promise<unknown>}
         */
        addVendorCategory({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                const promiseCreateDoc = databases.createDocument(vendorCategoryCollection, "unique()", {
                    plannerId: payload.uid,
                    name: payload.name,
                    icon: payload.icon,
                })
                promiseCreateDoc.then(function () {
                    dispatch('getStatus', {uid: payload.uid})
                    dispatch('getCategories', {uid: payload.uid})
                    resolve()
                }, function (error) {
                    console.log(error)
                    reject(error)
                })
            })
        },
        addCustomerStatus({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                const promiseCreateDoc = databases.createDocument(customerStatusCollection, "unique()", {
                    plannerId: payload.uid,
                    name: payload.name,
                    color: payload.color
                })
                promiseCreateDoc.then(function () {
                    dispatch('getStatus', {uid: payload.uid})
                    resolve()
                }, function (error) {
                    console.log(error)
                    reject(error)
                })
            })
        },
        addBudgetPlanCategory({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                const promiseCreateDoc = databases.createDocument(budgetPlanStandardCategoryCollection, "unique()", {
                    plannerId: payload.uid,
                    name: payload.name,
                    icon: payload.icon,
                })
                promiseCreateDoc.then(function () {
                    dispatch('getBudgetPlanCategories', {uid: payload.uid}).then(() => {
                        resolve()
                    })
                }, function (error) {
                    console.log(error)
                    reject(getError(error, "E0045"))
                })
            })
        },
        /**
         * Erstellt eine Status
         *
         * @param dispatch
         * @param payload
         * @returns {Promise<unknown>}
         */
        addVendorStatus({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                databases.createDocument(vendorStatusCollection, "unique()", {
                    plannerId: payload.uid,
                    name: payload.name
                }).then(function () {
                    dispatch('getStatus', {uid: payload.uid})
                    dispatch('getCategories', {uid: payload.uid})
                    resolve()
                }, function (error) {
                    console.log(error)
                    reject(getError(error, "E0046"))
                })
            })
        },
        addStil({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                databases.createDocument(stilCollection, "unique()", {
                    plannerId: payload.uid,
                    name: payload.name
                }).then(function () {
                    dispatch('getStil', {uid: payload.uid})
                    resolve()
                }, function (error) {
                    console.log(error)
                    reject(getError(error, "E0046"))
                })
            })
        },
        addVendorService({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                databases.createDocument(vendorServicesCollection, "unique()", {
                    plannerId: payload.uid,
                    name: payload.name
                }).then(function () {
                    dispatch('getVendorServices', {uid: payload.uid})
                    resolve()
                }, function (error) {
                    console.log(error)
                    reject(getError(error, "E0046"))
                })
            })
        },
        addMyProduct({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                databases.createDocument(myProductsCollection, "unique()", {
                    plannerId: payload.uid,
                    name: payload.name,
                    price: payload.price,
                }).then(function () {
                    dispatch('getMyProducts', {uid: payload.uid})
                    resolve()
                }, function (error) {
                    console.log(error)
                    reject(error)
                })
            })
        },
        addWeddingStatus({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                databases.listDocuments(weddingStatusCollection, [Query.equal("plannerId",
                    payload.uid
                )], 100).then((doc) => {
                    databases.createDocument(weddingStatusCollection, "unique()", {
                        plannerId: payload.uid,
                        name: payload.name,
                        position: doc.total + 1,
                        group: "standard"
                    }).then(function () {
                        dispatch('getStatus', {uid: payload.uid})
                        dispatch('getCategories', {uid: payload.uid})
                        resolve()
                    }, function (error) {
                        console.log(error)
                        reject(getError(error, "E0046"))
                    })
                })
            })
        },
        addWeddingLabel({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                databases.createDocument(weddingLabelCollection, "unique()", {
                    plannerId: payload.uid,
                    name: payload.name,
                    color: payload.color
                }).then(function () {
                    dispatch('getWeddingLabels', {uid: payload.uid})
                    resolve()
                }, function (error) {
                    console.log(error)
                    reject(getError(error, "E0046"))
                })
            })
        },
        weddingStatusUp({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                if (payload.newPosition > 0) {
                    databases.updateDocument(weddingStatusCollection, payload.id, {position: payload.newPosition}).then(() => {
                        databases.listDocuments(weddingStatusCollection, [Query.equal("plannerId", payload.uid)
                        ], 100).then((docs) => {
                            for (let doc of docs.documents) {
                                if (doc.position === payload.newPosition && doc.$id !== payload.id) {
                                    databases.updateDocument(weddingStatusCollection, doc.$id, {position: doc.position + 1}).then(() => {
                                    }).catch((err) => {
                                        console.log(err)
                                    }).then(() => {
                                        dispatch('getStatus', {uid: payload.uid})
                                        resolve()
                                    })
                                }
                            }
                        })
                    }).catch((err) => {
                        console.log(err)
                        reject(err)
                    })
                } else {
                    reject("Dies ist bereits der oberste Eintrag.")
                }

            })
        },
        weddingStatusDown({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                if (payload.newPosition > 0) {
                    databases.updateDocument(weddingStatusCollection, payload.id, {position: payload.newPosition}).then(() => {
                        databases.listDocuments(weddingStatusCollection, [Query.equal("plannerId", payload.uid)
                        ], 100).then((docs) => {
                            for (let doc of docs.documents) {
                                if (doc.position === payload.newPosition && doc.$id !== payload.id) {
                                    databases.updateDocument(weddingStatusCollection, doc.$id, {position: doc.position - 1}).then(() => {
                                    }).catch((err) => {
                                        console.log(err)
                                    }).then(() => {
                                        dispatch('getStatus', {uid: payload.uid})
                                        resolve()
                                    })
                                }
                            }
                        })
                    }).catch((err) => {
                        console.log(err)
                        reject(err)
                    })
                } else {
                    reject("Dies ist bereits der oberste Eintrag.")
                }

            })
        },
        // eslint-disable-next-line no-empty-pattern
        deleteCustomFieldValues({}, payload) {
            return new Promise(() => {
                const promiseGetDocs = databases.listDocuments(customFieldValueCollection, [Query.equal("customFieldId",
                    payload.id
                )], 100)
                promiseGetDocs.then(function (documentList) {
                    for (let customFieldValue of documentList.documents) {
                        const promiseDeleteDoc = databases.deleteDocument(customFieldValueCollection, customFieldValue.$id);
                        promiseDeleteDoc.catch((error) => {
                            console.log(error); // Failure
                        });
                    }
                })
            })
        },
        /**
         * Löscht einen Individuelles Feld
         *
         * @param dispatch
         * @param payload
         * @returns {Promise<unknown>}
         */
        deleteCustomField({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                const promiseDeleteDoc = databases.deleteDocument(customFieldCollection, payload.id);
                promiseDeleteDoc.then(function () {
                    dispatch('getCustomFields', {uid: payload.uid})
                    resolve()
                }, (error) => {
                    console.log(error); // Failure
                    reject("Fehler beim Löschen des individuellen Felds.")
                });
            })
        },
        /**
         * Löscht einen Kategorie einer übergebenen Collection
         *
         * @param dispatch
         * @param payload
         * @returns {Promise<unknown>}
         */
        deleteCategory({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDocs = databases.listDocuments(vendorCollection, [Query.equal("plannerId",
                    payload.uid
                )], 100)
                promiseGetDocs.then(function (documentList) {
                    let isUsed = false
                    for (let vendor of documentList.documents) {
                        if (vendor.category === payload.id) {
                            isUsed = true
                        }
                    }
                    if (!isUsed) {
                        const promiseDeleteDoc = databases.deleteDocument(vendorCategoryCollection, payload.id);
                        promiseDeleteDoc.then(function () {
                            dispatch('getCategories', {uid: payload.uid})
                            resolve()
                        }, function (error) {
                            console.log(error); // Failure
                            reject(getError(error, "E0047"))
                        });
                    } else {
                        reject(getError("Es gibt noch Dienstleister, die dieser Kategorie zugeordnet sind.", "E0048"))
                    }
                }, (err) => {
                    console.log(err)
                    reject(err)
                })
            })
        },

        deleteBudgetPlanCategory({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                const promiseDeleteDoc = databases.deleteDocument(budgetPlanStandardCategoryCollection, payload.id);
                promiseDeleteDoc.then(function () {
                    dispatch('getBudgetPlanCategories', {uid: payload.uid})
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0047"))
                });
            })
        }
        ,
        /**
         * Löscht einen Datensatz einer übergebenen Collection
         *
         * @param dispatch
         * @param payload
         * @returns {Promise<unknown>}
         */
        deleteStatus({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                let collectionId = "";
                switch (payload.statusCollection) {
                    case"vendorStatus":
                        collectionId = vendorStatusCollection
                        break;
                    case"customerStatus":
                        collectionId = customerStatusCollection
                        break;
                    case "weddingStatus":
                        collectionId = weddingStatusCollection
                        break;
                }
                const promiseDeleteDoc = databases.deleteDocument(collectionId, payload.id);
                promiseDeleteDoc.then(function () {
                    dispatch('getStatus', {uid: payload.uid})
                    dispatch('getCategories', {uid: payload.uid})
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0047"))
                });
            })
        },
        deleteWeddingLabel({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                const promiseDeleteDoc = databases.deleteDocument(weddingLabelCollection, payload.id);
                promiseDeleteDoc.then(function () {
                    dispatch('getWeddingLabels', {uid: payload.uid})
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0047"))
                });
            })
        },
        deleteMyProduct({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                const promiseDeleteDoc = databases.deleteDocument(myProductsCollection, payload.id);
                promiseDeleteDoc.then(function () {
                    dispatch('getMyProducts', {uid: payload.uid})
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(error)
                });
            })
        },
        deleteStil({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                const promiseDeleteDoc = databases.deleteDocument(stilCollection, payload.id);
                promiseDeleteDoc.then(function () {
                    dispatch('getStil', {uid: payload.uid})
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(error)
                });
            })
        },

        deleteVendorServices({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                const promiseDeleteDoc = databases.deleteDocument(vendorServicesCollection, payload.id);
                promiseDeleteDoc.then(function () {
                    dispatch('getVendorServices', {uid: payload.uid})
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(error)
                });
            })
        },
    },
    mutations: {
        setWeddingStatus(state, payload) {
            state.weddingStatus = payload
        },
        setWeddingLabels(state, payload) {
            state.weddingLabels = payload
        },
        setWeddingLabelNames(state, payload) {
            state.weddingLabelNames = payload
        },
        setVendorStatus(state, payload) {
            state.vendorStatus = payload
        },
        setCustomerStatus(state, payload) {
            state.customerStatus = payload
        },
        setWeddingStatusForSelection(state, payload) {
            state.weddingStatusForSelection = payload
        },
        setVendorStatusForSelection(state, payload) {
            state.vendorStatusForSelection = payload
        },
        setCustomerStatusForSelection(state, payload) {
            state.customerStatusForSelection = payload
        },
        setCategories(state, payload) {
            state.categories = payload
        },
        setMyProducts(state, payload) {
            state.myProducts = payload
        },
        setMyProductsForSelection(state, payload) {
            state.myProductsForSelection = payload
        },

        setCategoriesNames(state, payload) {
            state.categoryNames = payload
        },
        setBudgetPlanCategories(state, payload) {
            state.budgetPlanCategories = payload
        },
        setStils(state, payload) {
            state.stils = payload
        },
        setStilNames(state, payload) {
            state.stilNames = payload
        },
        setVendorServices(state, payload) {
            state.vendorServices = payload
        },
        setVendorServiceNames(state, payload) {
            state.vendorServiceNames = payload
        },
        setBudgetPlanCategoriesNames(state, payload) {
            state.BudgetPlanCategoryNames = payload
        },
        setCustomFields(state, payload) {
            state.customFields = payload
        },
        setCustomFieldsForEntity(state, payload) {
            state.customFieldsForEntity = payload
        },
        addCustomFieldsForEntity(state, payload) {
            state.customFieldsForEntity.push(payload)
        },
        setInvoiceSettings(state, payload) {
            state.invoiceSettings = payload
        },
    }
}
