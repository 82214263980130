<template>
    <v-container class="pb-16">
        <Message
                :message="message"
                @closeAlert="message.show = false"
        ></Message>
        <v-row class="pb-8">
            <v-col col="8">
                <h1>{{ seatPlan.name }}
                    <v-tooltip right color="red">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                           <a @click="deleteSeatPlan()" class="d-inline-flex mt-5 ps-3 pe-3">
                             <v-icon class="d-inline-flex" size="20">
                               mdi-delete
                             </v-icon>
                           </a>
                      </span>
                    </template>
                    Sitzplan löschen
                </v-tooltip>
                </h1>

                <p>{{ seatPlan.description }}</p>
                <v-row>

                </v-row>
            </v-col>
            <v-col class="col-12 col-md-6 col-lg-4" >
                <v-card @click="$router.push(seatPlan.id+'/anordnung')" class="pb-3 rounded-xl pt-3" flat
                        style="background-color: #FFFFFF">
                    <v-row>
                        <v-col cols="8">
                            <v-card-title>
                                <h5>Sitzplan anordnen</h5>

                            </v-card-title>
                            <v-card-text>
                                Plane die Anordnung der Tische und Gäste. <br> <br>
                                <v-btn class="primary" >Anordnung bearbeiten</v-btn>
                            </v-card-text>
                        </v-col>
                        <v-col cols="4" class="align-self-center">
                            <v-img
                                :src="require('@/assets/images/drawSVGs/specialEvent.svg')"
                                width="100px">
                            </v-img>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>


        <v-row>
            <v-col class="me-3 col-12 col-md-6">
                <TableList
                    :tables="tables"
                    :newTable="newTable"
                ></TableList>
            </v-col>
            <v-col class="ms-3">
                <GuestList
                    :guests="guests"
                    :tables="tables"
                    :tablesSelection="tablesSelection"
                ></GuestList>
            </v-col>
        </v-row>


    </v-container>
</template>

<script>
import {error, msgObj, success} from "@/helper/helper";
import {mapGetters} from "vuex";

const Message = () => import("@/components/generalUI/Message");

const GuestList = () => import("@/components/entities/wedding/SeatPlan/GuestList.vue");
const TableList = () => import("@/components/entities/wedding/SeatPlan/TableList.vue");


/**
 * User can plan budget for own wedding with different categories.
 * Every category has items and each item's budget can be planned
 * @displayName BudgetPlan
 *
 */
export default {
    components: {
        Message,
        GuestList,
        TableList,

    },
    name: "TimePlan",
    data() {
        return {
            message: msgObj(),
            newTable: {
                seats: 4,
                type: 'round',
                name: 'Tisch ' + (this.tables.length+1)
            }
        }
    },
    computed: {
        ...mapGetters('seatPlan', {
            guests: 'guests',
            tables: 'tables',
            seatPlan: 'seatPlan',
            tablesSelection: 'tablesSelection'
        }),
    },
    /**
     * Check if user is logged in and then get budgetplan of logged user
     *
     */
    mounted() {
        this.$store.dispatch('auth/checkLogin', {}).then((res) => {
            this.$store.dispatch('seatPlan/getSeatPlan', {
                uid: res,
                seatPlanId: this.$route.params.id
            }).then(() => {

            }).catch((err) => {
                this.message = error(err)
            })
        })
    },
    methods: {
        getTakenSeats(tableID) {
            let countTakenSeats = 0
            for(let guest of this.guests){
                if(guest.table === tableID){
                    console.log(guest.count)
                    countTakenSeats = countTakenSeats + guest.count
                }
            }
            console.log(countTakenSeats)
            return countTakenSeats
        },
        deleteSeatPlan(){
            this.$store.dispatch('seatPlan/deleteSeatPlan', {
                seatPlanId: this.$route.params.id,
            }).then(() => {
                this.message = success('Der Sitzplan wurde erfolgreich gelöscht.')
                this.$router.push("/weddings/wedding/" + this.$route.params.weddingId)
            }).catch((err) => {
                this.message = error(err)
            })
        },
        getTables() {
            return [
                {
                    id: 1,
                    height: 200,
                    lenth: 300,
                    form: 'round',
                    seats: 5,
                    takenSeats: this.getTakenSeats(1),
                    color: 'blue',
                    name: 'nahe Buffet',
                },
                {
                    id: 2,
                    height: 200,
                    lenth: 300,
                    form: 'reckt',
                    seats: 6,
                    takenSeats: this.getTakenSeats(2),
                    color: 'green',
                    name: 'Fenster',
                },
                {
                    id: 3,
                    height: 200,
                    lenth: 300,
                    form: 'round',
                    seats: 4,
                    takenSeats: this.getTakenSeats(3),
                    color: 'green',
                    name: '',


                }
            ]
        }
    },
}
</script>

<style scoped>
.row {
    border-width: thin;
}

v-container {
    height: 150vh;
}
</style>
