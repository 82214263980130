
import {databases, functions} from "@/config/server-api";
import {bugReportCollection} from "@/config/awSettings";



export default {
    namespaced: true,
    state: {
    },
    getters: {

    },
    actions: {
        // eslint-disable-next-line no-empty-pattern
        saveBugReport({}, payload) {
            return new Promise((resolve, reject) => {
                const promiseCreateDoc = databases.createDocument(bugReportCollection, "unique()", {
                    bug: payload.bug,
                    what: payload.subject,
                    optimizing: payload.optimizing,
                    uid: payload.uid,
                    how: payload.description,
                    where: payload.email,
                    solved: false
                })
                promiseCreateDoc.then((doc)=>{
                    functions.createExecution('63d27cf3a1f84c8f8f59','', false).then(()=>{
                        resolve( doc)
                    }).catch((err)=>{
                        console.log(err)
                        resolve( doc)
                    })
                }).catch(err=>{
                    reject(err)
                })
               console.log(payload)
            })
        }
    },
    mutations: {
    }
}
