<template>
  <div>
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>

    <Dialog
        :dialog="dialog"
        :title="'Neuen Termin anlegen'"
        :values="eventCreateFields"
        :successButton="'Erstellen'"
        :icon="'mdi-calendar'"
        :headLines="[
            {text: 'Start', position: 2},
            {text: 'Ende', position: 4},
            ]"
        :cut="2"
        @hideDialog="hideDialog"
        @emitNewData="createEvent"
        @dataChanged="dataChanged"
    ></Dialog>
  </div>

</template>

<script>
import {mapGetters} from "vuex";
import {error, msgObj, success} from "@/helper/helper";

const Message = () => import("@/components/generalUI/Message");
const Dialog = () => import("@/components/dialog/FormDialogNew");

export default {
  name: "CreateCustomer",
  components: {
    Dialog,
    Message
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user',
    }),
  },
  data() {
    return {
      message: msgObj(),
    }
  },
  props: {
    dialog: Boolean,
    eventCreateFields: {
      type: Array,
      default() {
        return [
          {
            title: 'Name',
            value: '',
            id: 0,
            type: 'string',
          },
          {
            title: 'Details',
            value: null,
            id: 1,
            type: 'string',
          },
          {
            title: 'Datum (Start)*',
            value: null,
            id: 2,
            type: 'date',
          },
          {
            title: 'Uhrzeit (Start)',
            value: null,
            id: 3,
            type: 'time',
          },
          {
            title: 'Datum (Ende)*',
            value: null,
            id: 4,
            type: 'date',
          },
          {
            title: 'Uhrzeit (Ende)',
            value: null,
            id: 5,
            type: 'time',
          },
        ]
      }
    }
  },
  methods: {
    hideDialog() {
      this.$emit('hideDialog')
    },
    /**
     * Wird ausgeführt, wenn sich ein Feldwert geändert hat
     *
     * @param payload: Enthält das Feld das geändert wurde
     */
    dataChanged(payload) {
      if (payload.title === 'Datum (Start)*') {
        console.log(payload)
        for (let field of this.eventCreateFields) {
          if (field.title === 'Datum (Ende)*') {
            field.value = payload.value
          }
        }
      }
      if (payload.title === 'Uhrzeit (Start)') {
        console.log(payload)
        for (let field of this.eventCreateFields) {
          if (field.title === 'Uhrzeit (Ende)') {
            field.value = payload.value
          }
        }
      }
    },
    createEvent(newValues) {
      let newEvent = this.getEventFromInput(newValues)
      this.$store.dispatch('calendar/createEvent', {
        event: newEvent,
        uid: this.user.id
      }).then(() => {
            this.hideDialog()
            this.message = success('Termin wurde erfolgreich angelegt.')
          }
      ).catch((err) => {
        this.message = error(err)
      })
    },
    getEventFromInput(newValues) {
      let newEvent = {
        name: '',
        start: null,
        end: null,
        timeStart: '',
        timeEnd: '',
        color: '#6D7E6D',
        details: ''
      }
      for (let value of newValues) {
        switch (value.title) {
          case 'Name':
            newEvent.name = value.value
            break;
          case 'Datum (Start)*':
            if (value.value) {
              let date = new Date(value.value)
              newEvent.start = date.getTime()
            } else {
              newEvent.start = null
            }
            break;
          case 'Datum (Ende)*':
            if (value.value) {
              let date = new Date(value.value)
              newEvent.end = date.getTime()
            } else {
              newEvent.end = null
            }
            break;
          case 'Uhrzeit (Ende)':
            newEvent.timeEnd = value.value
            break;
          case 'Uhrzeit (Start)':
            newEvent.timeStart = value.value
            break;
          case 'Farbe':
            newEvent.color = value.value
            break;
          case 'Details':
            newEvent.details = value.value
            break;
        }
      }
      return newEvent
    },
  },

}
</script>
