<template>
  <v-app style="background-color: #F7F7F7">

    <div>
      <Toolbar
          v-if="loggedIn && $route.path !== '/fast-geschafft' && !$route.path.includes('avv')&& !$route.path.includes('pdf')"
          @showMobileDrawer="showMobileDrawer"
      ></Toolbar>

      <NavigationDrawer
          :drawer="drawer"
          v-if="loggedIn && $route.path !== '/fast-geschafft' && !$route.path.includes('avv') && !$route.path.includes('pdf')"
          @closeDrawer="closeDrawer()"
      ></NavigationDrawer>


      <v-main class="mainContent" v-if="checkIfRouteShouldGoToLogin2">

        <!-- TESTUMGEBUNG HINWEIS -->
        <!--<v-card style="background-color: #7F001D " class="pa-6 ms-8 me-8 mb-8 white--text">
          <h4>Dies ist die Testumgebung von Wedding-Flow. Speichere keine personenbezogenen Daten von Dir oder Deinen Kunden.</h4>
        </v-card>-->

        <router-view/>
      </v-main>

      <div class="text-center pt-16 pb-16 " v-else>
        <div v-if="toLongNoLogin">
          <p class="logo pl-lg-5 pr-lg-8 mr-lg-5  text-center " style="font-size: 1.7rem"
             @click="$router.push('/');$vuetify.goTo('#header')">
            WeddingFlow
          </p>
          <p class="text-h4">Bitte logge dich erneut ein, um auf diesen Bereich zuzugreifen.</p>
          <v-btn class="primary" @click="$router.push('/login')">Zum Login</v-btn>
        </div>
        <Spinner v-else></Spinner>
      </div>

      <BottomNavigation v-if="loggedIn && !$route.path.includes('avv') && !$route.path.includes('pdf')" @toggleMenu="drawer = !drawer"
                        class="d-flex d-lg-none"></BottomNavigation>

      <Footer v-if="!($route.path === '/registrieren') && !$route.path.includes('avv')" class="pb-16 pb-lg-2 " id="footerLeiste"></Footer>
    </div>
  </v-app>
</template>

<script>

import {mapGetters} from "vuex";
import {debug} from "@/config/errors"
import {checkIfRouteShouldGoToLogin} from "@/helper/helper"

const NavigationDrawer = () => import('@/components/Navigation/NavigationDrawer')
const Toolbar = () => import('@/components/Navigation/Toolbar')
const Footer = () => import('@/components/Navigation/Footer')
const BottomNavigation = () => import('@/components/Navigation/BottomNavigation')
const Spinner = () => import("@/components/generalUI/Spinner");

export default {
  components: {Footer, NavigationDrawer, Toolbar, BottomNavigation, Spinner},
  data() {
    return {
      drawer: null,
      permanent: null,
      debug,
      toLongNoLogin: false
    }
  },
  computed: {
    ...mapGetters('auth', {
      loggedIn: 'loggedIn',
      user: 'user'
    }),
  },
  mounted() {
    this.$store.dispatch('auth/checkLogin', {}).then(()=>{
    }).catch((e) => {
        if (checkIfRouteShouldGoToLogin(this.$route.path)) {
            this.$router.push('/login')
        }
      console.log(e)
    })
  },
  watch: {
    $route() {
      if (window.innerWidth <= 1060) {
        this.drawer = false;
      }
    }
  },
  methods: {
    closeDrawer() {
      if (this.drawer === true) {
        this.drawer = !this.drawer
      }
    },
    checkIfRouteShouldGoToLogin2() {
      return checkIfRouteShouldGoToLogin(this.$route.path)
    },
    showMobileDrawer() {
      if (this.drawer) this.drawer = !this.drawer
      this.drawer = !this.drawer
    },
  }
}
</script>
<style lang="scss">
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"), url("./assets/fonts/Montserrat-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Brown Sugar";
  src: local("Brown Sugar"), url("./assets/fonts/BrownSugar/BrownSugar.ttf") format("truetype");
}

@import '~shepherd.js/dist/css/shepherd.css';
* {
  font-family: 'Montserrat', sans-serif;
  scroll-behavior: smooth;
  transition: .5s !important;
  max-width: 100vw;
}

.logo {
  font-family: "Brown Sugar", sans-serif;
}

.logo:hover {
  cursor: pointer;
}

#app {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

a {
  text-decoration: none;
}

.backGroundGrey {
  background-color: #F3F4F3;
}

::-webkit-scrollbar {
  height: 12px;
  width: 12px;
  background: white;
}

::-webkit-scrollbar-thumb {
  background: #B19983;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar-thumb:hover {
  background: #CECAB7;
}

.scale:hover {
  transform: scale(1.2);
}

.mainContent {
  min-height: 90vh;
}

.detailCard {
  padding: 5vh 5%;
}

.fadeIn {
  animation: fadeIn 3s;
}

.fadeInFast {
  animation: fadeIn .5s;
}

.fadeInDelay {
  animation: fadeInDelay 1s;


}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInDelay {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
