import router from "@/router";
import {account, databases, functions, teams} from "@/config/server-api";
import {getError} from "@/config/errors"
const delay = ms => new Promise(res => setTimeout(res, ms));

import {
    plannerAccountCollection,
    vendorCategoryCollection,
    vendorStatusCollection,
    weddingStatusCollection,
    budgetPlanStandardCategoryCollection,
    baseUrl,
    paymenStatusCollection,
    customerStatusCollection,
    weddingLabelCollection,
    stilCollection,
    vendorServicesCollection,
    myProductsCollection,
    AdminSettingsCollection
} from "@/config/awSettings"

export default {
    namespaced: true,
    state: {
        user: {},
        loggedIn: false,
        progress: 0,
        progressSettings: 0,
        totalProgressSettings: 0,
        loginEnabled: true
    },
    getters: {
        user: (state) => state.user,
        loggedIn: (state) => state.loggedIn,
        progress: (state) => state.progress,
        progressSettings: (state) => state.progressSettings,
        totalProgressSettings: (state) => state.totalProgressSettings,
        loginEnabled: (state) => state.loginEnabled,
    },
    actions: {
        getIsLoginDisabled({commit}) {
            return new Promise((resolve) => {
                const promiseGetDoc = databases.getDocument(AdminSettingsCollection, '643d7c77ee2c2f68279d')
                promiseGetDoc.then(function (planerDoc) {
                    console.log(planerDoc.value)
                    if (planerDoc.value) {
                        commit('setLoginEnabled', false)
                    } else {
                        commit('setLoginEnabled', true)
                    }
                    resolve()
                }).catch((err) => {
                    console.log(err)
                    commit('setLoginEnabled', false)
                    resolve()
                })
            })
        },
        /**
         * Loggt einen User anhand von E-Mail-Adresse und Passwort ein
         *
         * @param dispatch
         * @param payload
         * @returns {Promise<unknown>}
         */
        loginViaEmail({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                const promiseCreateSession = account.createEmailSession(payload.email, payload.password)
                promiseCreateSession.then(function (session) {
                    dispatch("checkUser", session.userId).then((user) => {
                        resolve(user)
                    }).catch((e) => {
                        console.log(e)
                        reject(getError(e, "E0002"));
                    })
                }, (error) => { // Fehler createSession
                    console.log(error)
                    reject(getError(error, "E0001"))
                })
            })
        },
        // eslint-disable-next-line no-empty-pattern
        forgotPassword({}, payload) {
            return new Promise((resolve, reject) => {
                const promise = account.createRecovery(payload.email, baseUrl + '/#/resetPasswort');
                promise.then(function () {
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(error)
                });
            })
        },
        // eslint-disable-next-line no-empty-pattern
        verifyMail({}) {
            return new Promise((resolve, reject) => {
                const url = baseUrl
                console.log(url)
                const promise = account.createVerification(url);
                promise.then(function () {
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(error)
                });
            })
        },
        // eslint-disable-next-line no-empty-pattern
        completeVerifyMail({}, payload) {
            return new Promise((resolve, reject) => {
                const promise = account.updateVerification(payload.uid, payload.secret);
                promise.then(function () {
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(error)
                });
            })
        },
        // eslint-disable-next-line no-empty-pattern
        resetPassword({}, payload) {
            return new Promise((resolve, reject) => {
                const promise = account.updateRecovery(payload.userId, payload.secret, payload.password, payload.confirmPassword);
                promise.then(function () {
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(error)
                });
            })
        },
        /**
         *  Holt den Planer zu einer ID und speichert die wichtigsten Daten im LocalStorage
         *
         * @param commit
         * @param id
         */
        checkUser({commit}, id) {
            return new Promise((resolve, reject) => {
                const promiseGetDoc = databases.getDocument(plannerAccountCollection, id)
                promiseGetDoc.then(function (planerDoc) {
                    let user = {
                        id: id,
                        firstname: planerDoc.firstname,
                        lastname: planerDoc.lastname,
                        companyName: planerDoc.companyName,
                        street: planerDoc.street,
                        streetNumber: planerDoc.streetNumber,
                        plz: planerDoc.plz,
                        city: planerDoc.city,
                        created: planerDoc.$createdAt * 1000,
                        paymentStatus: '',
                        paymentPlan: 0,
                        emailVerification: true,
                        stripeId: '',
                        affiliateCode: ''
                    }
                    const promiseGetDoc = databases.getDocument(paymenStatusCollection, id)
                    promiseGetDoc.then(function (paymentDoc) {
                        user.paymentStatus = paymentDoc.status
                        user.paymentPlan = paymentDoc.plan
                        user.stripeId = paymentDoc.stripeId
                        user.affiliateCode = paymentDoc.affiliateCode
                    }).finally(() => {
                        account.get().then(() => {
                            //wenn Payment nicht gefunden trotzdem mit Plan 0 (Testphase) okay
                            localStorage.setItem('userName', user.firstname + ' ' + user.lastname)
                            localStorage.setItem('companyName', user.companyName)
                            localStorage.setItem('userID', user.id)
                            commit("setUser", user)
                            commit("setLoggedIn", true)
                            if (user.paymentPlan === 0 && getDays(user.created) < 0) {
                                router.push('/testphase-abgelaufen').catch((err) => {
                                    console.log(err)
                                    reject(err)
                                }).then(() => {
                                    resolve(user)
                                })
                            }
                            resolve(user)
                        }).catch((err) => {
                            console.log(err)
                            reject(err)
                        })
                    })
                }).catch(() => {
                    // Kein Planer Doc
                    reject('Dein Account wurde gelöscht. Solltest du deinen Account nicht selbst gelöscht haben, kontaktiere bitte unseren Support.')
                })
            })
        },

        /**
         * Registriert einen neuen Benutzer, loggt diesen ein und erstellt das Dokument für den Planer
         *
         * @param dispatch
         * @param commit
         * @param payload user = {firstname:String, lastname:String, password: String}
         * @returns {Promise<unknown>}
         */
        newUser({dispatch, commit}, payload) {
            return new Promise((resolve, reject) => {
                const promiseCreateAccount = account.create("unique()", payload.user.email, payload.user.password)
                promiseCreateAccount.then(function () {
                    commit('setProgress', 10)
                    let newUser = {
                        firstname: payload.user.firstname,
                        lastname: payload.user.lastname,
                        companyName: payload.user.companyName,
                        street: payload.user.street,
                        streetNumber: payload.user.streetNumber,
                        plz: payload.user.plz,
                        city: payload.user.city,
                    }
                    const promiseCreateSession = account.createEmailSession(payload.user.email, payload.user.password)
                    promiseCreateSession.then(function (session) {
                        commit('setProgress', 20)
                        const promiseCreateUserDoc = databases.createDocument(plannerAccountCollection, session.userId, newUser)
                        promiseCreateUserDoc.then(function () {
                            router.push('/createAccount').catch((e) => console.log(e))
                            commit('setProgress', 30)
                            dispatch('addStandardSettings', {userId: session.userId}).then(() => {
                                commit('setProgress', 70)
                                dispatch('verifyMail').catch((err) => {
                                    console.log(err); // Failed
                                }).finally(() => {
                                    commit('setProgress', 80)
                                    dispatch('addUserToTestPhase', 'email=' + payload.user.email + '&lastname=' + payload.user.lastname + '&firstname=' + payload.user.firstname).then(() => {
                                    }).catch((err) => {
                                        console.log(err); // Failed
                                    }).finally(() => {
                                        commit('setProgress', 90)
                                        const promise = teams.create(session.userId, session.userId);
                                        promise.then(function () {
                                            commit('setProgress', 100)
                                            console.log("User erfolgreich angelegt")
                                            router.push('/planner/Dashboard?newAccount=true').catch((e) => console.log(e)).then(() => {
                                                location.reload()
                                                resolve()
                                            })
                                        }, function (error) {
                                            console.log(error); // Failure
                                            reject(error)
                                        });
                                    })
                                })
                            }).catch((err) => {
                                console.log(err); // Failed
                                reject(getError(err, "E0005"))
                            })
                        }, function (error) {
                            console.log(error); // Failed CreateDocument
                            reject(getError(error, "E0004"))
                        });
                    }, function (error) {
                        console.log(error); // Failed CreateSession
                        reject(getError(error, "E0003"))
                    })
                }, function (error) {
                    console.log(error); // Failed CreateAccount
                    reject(error)
                })
            })
        },
// eslint-disable-next-line no-empty-pattern
        addUserToTestPhase({}, payload) {
            return new Promise((resolve, reject) => {
                const promise = functions.createExecution('6367d238d54a5a5572c1', payload);
                promise.then(function (res) {
                    resolve(res); // Success
                }, function (error) {
                    console.log(error); // Failure
                    reject(error)
                });
            })
        },
        // eslint-disable-next-line no-empty-pattern
        asyncCheck({}, ) {
            return new Promise((resolve) => {
                    resolve(); // Success
            })
        },
        addStandardSettings({commit, dispatch}, payload) {
            return new Promise( (resolve) => {
                dispatch('asyncCheck').finally(async ()=>{
                    let standardSettings = getDefaultSettings()
                    let vendorCategorySett = standardSettings.VendorCategory
                    let vendorStatusSett = standardSettings.VendorStatus
                    let weddingStatusSett = standardSettings.WeddingStatus
                    let budgetPlanCategory = standardSettings.budgetPlanCategory

                    let customerStatus = standardSettings.customerStatus
                    let weddingLabels = standardSettings.weddingLabels
                    let stil = standardSettings.stil
                    let services = standardSettings.services
                    let myTypes = standardSettings.myTypes

                    let totalLength = vendorCategorySett.length + vendorStatusSett.length + weddingStatusSett.length +
                        budgetPlanCategory.length + customerStatus.length + weddingLabels.length + stil.length + services.length + myTypes.length
                    commit('setTotalProgressSettings', totalLength)
                    let progress = 0

                    for (let setting of vendorCategorySett) {
                        setting.plannerId = payload.userId
                        const promiseCreateUserDoc = databases.createDocument(vendorCategoryCollection, 'unique()', setting)
                        promiseCreateUserDoc.catch((err) => {
                            console.log(err)
                        }).then(() => {
                        }).finally(async () => {
                            progress++
                            commit('setProgressSettings', progress)
                            if (progress === totalLength) {
                                resolve()
                            }
                            await delay(100)
                        })
                        await delay(100)
                    }
                    await delay(100)
                    for (let setting of vendorStatusSett) {
                        setting.plannerId = payload.userId
                        const promiseCreateUserDoc = databases.createDocument(vendorStatusCollection, 'unique()', setting)
                        promiseCreateUserDoc.catch((err) => {
                            console.log(err)
                        }).finally(async() => {
                            progress++
                            commit('setProgressSettings', progress)
                            if (progress === totalLength) {
                                resolve()
                            }
                            await delay(100)
                        })
                        await delay(100)
                    }
                    for (let setting of weddingStatusSett) {
                        setting.plannerId = payload.userId
                        const promiseCreateUserDoc = databases.createDocument(weddingStatusCollection, 'unique()', setting)
                        promiseCreateUserDoc.catch((err) => {
                            console.log(err)
                        }).finally(async() => {
                            progress++
                            if (progress === totalLength) {
                                resolve()
                            }
                            await delay(100)
                        })
                        await delay(100)

                    }
                    for (let setting of budgetPlanCategory) {
                        setting.plannerId = payload.userId
                        const promiseCreateUserDoc = databases.createDocument(budgetPlanStandardCategoryCollection, 'unique()', setting)
                        promiseCreateUserDoc.catch((err) => {
                            console.log(err)
                        }).finally(async() => {
                            progress++
                            if (progress === totalLength) {
                                resolve()
                            }
                            await delay(100)
                        })
                        await delay(100)
                    }
                    for (let setting of customerStatus) {
                        setting.plannerId = payload.userId
                        const promiseCreateUserDoc = databases.createDocument(customerStatusCollection, 'unique()', setting)
                        promiseCreateUserDoc.catch((err) => {
                            console.log(err)
                        }).finally(async() => {
                            progress++
                            if (progress === totalLength) {
                                resolve()
                            }
                            await delay(100)
                        })
                        await delay(100)
                    }
                    for (let setting of weddingLabels) {
                        setting.plannerId = payload.userId
                        const promiseCreateUserDoc = databases.createDocument(weddingLabelCollection, 'unique()', setting)
                        promiseCreateUserDoc.catch((err) => {
                            console.log(err)
                        }).finally(async() => {
                            progress++
                            commit('setProgressSettings', progress)
                            if (progress === totalLength) {
                                resolve()
                            }
                            await delay(100)
                        })
                        await delay(100)
                    }
                    for (let setting of stil) {
                        setting.plannerId = payload.userId
                        const promiseCreateUserDoc = databases.createDocument(stilCollection, 'unique()', setting)
                        promiseCreateUserDoc.catch((err) => {
                            console.log(err)
                        }).finally(async() => {
                            progress++
                            commit('setProgressSettings', progress)
                            if (progress === totalLength) {
                                resolve()
                            }
                            await delay(100)
                        })
                        await delay(100)
                    }
                    for (let setting of services) {
                        setting.plannerId = payload.userId
                        const promiseCreateUserDoc = databases.createDocument(vendorServicesCollection, 'unique()', setting)
                        promiseCreateUserDoc.catch((err) => {
                            console.log(err)
                        }).finally(async() => {
                            progress++
                            commit('setProgressSettings', progress)
                            if (progress === totalLength) {
                                resolve()
                            }
                            await delay(100)
                        })
                        await delay(100)
                    }
                    for (let setting of myTypes) {
                        setting.plannerId = payload.userId
                        const promiseCreateUserDoc = databases.createDocument(myProductsCollection, 'unique()', setting)
                        promiseCreateUserDoc.catch((err) => {
                            console.log(err)
                        }).finally(async () => {
                            progress++
                            commit('setProgressSettings', progress)
                            if (progress === totalLength) {
                                resolve()
                            }
                            await delay(100)
                        })
                        await delay(100)
                    }
                })
            })
        },
        // eslint-disable-next-line no-empty-pattern
        changePassword({}, payload) {
            return new Promise((resolve, reject) => {
                const promise = account.updatePassword(payload.newPassword, payload.oldPassword);
                promise.then(function () {
                    //TODO: Mail, dass Passwort geändert wurde
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(error)
                });
            })
        },

        /**
         * Löscht alle aktiven Sessions und den LocalStorage
         *
         * @param commit
         * @returns {Promise<void>}
         */
        async signedOut({commit}) {
            return new Promise((resolve, reject) => {
                const promiseSessions = account.getSessions()
                promiseSessions.then(function (sessions) {
                    let sessionList = sessions.sessions
                    for (let session of sessionList) {
                        account.deleteSession(session.$id).then(function () {
                            console.log("logged Out")
                            commit("setLoggedIn", false)
                            commit("setUser", {})
                            localStorage.clear()
                            router.push('/login')
                            resolve()
                        }, (err) => {
                            reject(getError(err, "E0007"))
                        })
                    }
                })
            })
        },
        /**
         * Prüft, ob der User bereits eingeloogt ist
         *
         * @param dispatch
         * @returns {Promise<unknown>}
         */
        checkLogin({dispatch}) {
            return new Promise((resolve, reject) => {
                    const promiseSessions = account.getSessions()
                    promiseSessions.then(function (sessions) {
                        let sessionList = sessions.sessions
                            dispatch("checkUser", sessionList[0].userId).then(() => {
                                resolve(sessionList[0].userId)
                            }).catch((e) => {
                                console.log(e)
                                reject(e);
                            })
                            resolve(sessionList[0].userId)
                        resolve()
                    }).catch((err)=>{
                        reject(err)
                    })
                }
            )
        }
    },
    mutations: {
        setUser(state, payload) {
            state.user = payload
        },
        setLoggedIn(state, payload) {
            state.loggedIn = payload
        },
        setProgress(state, payload) {
            state.progress = payload
        },
        setProgressSettings(state, payload) {
            state.progressSettings = payload
        },
        setTotalProgressSettings(state, payload) {
            state.totalProgressSettings = payload
        },
        setLoginEnabled(state, payload) {
            state.loginEnabled = payload
        },
    }
}

function getDefaultSettings() {
    return {
        "VendorCategory": [
            {
                "name": "Musiker",
                "plannerId": "",
                "icon": "mdi mdi-music"
            },
            {
                "name": "Trauredner",
                "plannerId": "",
                "icon": "mdi mdi-microphone-variant"
            },
            {
                "name": "Catering",
                "plannerId": "",
                "icon": "mdi mdi-cupcake"
            },
            {
                "name": "Location",
                "plannerId": "",
                "icon": "mdi mdi-home-city"
            },
            {
                "name": "Blumen & Deko",
                "plannerId": "",
                "icon": "mdi mdi-flower-tulip"
            },
            {
                "name": "Fotograf / Videograf",
                "plannerId": "",
                "icon": "mdi mdi-camera"
            }
        ],
        "VendorStatus": [
            {
                "name": "Gebucht",
                "plannerId": ""
            },
            {
                "name": "Offen",
                "plannerId": ""
            },
            {
                "name": "Angefragt",
                "plannerId": ""
            },
            {
                "name": "Abgelehnt",
                "plannerId": ""
            },
        ],
        "WeddingStatus": [
            {
                "name": "Abgeschlossen",
                "position": 3,
                "plannerId": "",
                "group": "standard"
            },
            {
                "name": "Anfrage erhalten",
                "position": 1,
                "plannerId": "",
                "group": "standard"
            },
            {
                "name": "In Planung",
                "position": 2,
                "plannerId": "",
                "group": "standard"
            }
        ],
        'budgetPlanCategory': [
            {
                "name": "Unterhaltung",
                "plannerId": "",
                "icon": "mdi mdi-account-music"
            },
            {
                "name": "Blumen & Deko",
                "plannerId": "",
                "icon": "mdi mdi-flower-poppy"
            },
            {
                "name": "Location",
                "plannerId": "",
                "icon": "mdi mdi-home-map-marker"
            },
        ],
        'customerStatus': [
            {
                "name": "Kunde",
                "plannerId": "",
                "color": "#6D7E6D"
            },
            {
                "name": "Interessent",
                "plannerId": "",
                "color": "#B19983"
            },
            {
                "name": "Ex-Kunde",
                "plannerId": "",
                "color": "#CECAB7"
            },
        ],
        'weddingLabels': [
            {
                "name": "Label 1",
                "plannerId": "",
                "color": "#6D7E6D"
            },
            {
                "name": "Label 2",
                "plannerId": "",
                "color": "#B19983"
            },
            {
                "name": "Label 3",
                "plannerId": "",
                "color": "#CECAB7"
            },
        ],
        'stil': [
            {
                "name": "Modern",
                "plannerId": "",
            },
            {
                "name": "Rustikal",
                "plannerId": "",
            },
            {
                "name": "Klassisch",
                "plannerId": "",
            },
        ],
        'services': [
            {
                "name": "Catering",
                "plannerId": "",
            },
            {
                "name": "Fotografie",
                "plannerId": "",
            },
            {
                "name": "Videografie",
                "plannerId": "",
            },
        ],
        'myTypes': [
            {
                "name": "Komplettplanung",
                "plannerId": "",
            },
            {
                "name": "Teilplanung",
                "plannerId": "",
            },
            {
                "name": "Location-Scouting",
                "plannerId": "",
            },
            {
                "name": "Freie Traurede",
                "plannerId": "",
            },
        ],
    }
}

function getDays(date) {
    return 14 - Math.ceil((Date.now() - date) / (1000 * 3600 * 24));
}
