<template>
    <div>
        <v-dialog
                style="z-index: 999"
                v-model="dialog"
                persistent
                :max-width="this.size.width"
        >
            <v-card class="rounded-xl pa-4">
                <v-card-title class="text-h5" id="title">
                    <v-icon class="pe-3" color="secondary" size="40" v-if="this.icon">
                        {{ icon }}
                    </v-icon>
                    {{ title }}
                </v-card-title>
                <v-card-text>
                    <v-form ref="formular">
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="6" class="pe-md-6 pt-md-9">
                                    <div v-for="data in values" :key="data.index">
                                        <div v-if="data.id < cut">
                                            <div v-for="headline of headLines" :key="headline.position">
                                                <h3 v-if="headline.position === data.id">{{ headline.text }}</h3>
                                            </div>

                                            <AutoComplete
                                                    :rules="getRules(data)"
                                                    v-if="data.type === 'autoComplete'"
                                                    :data="data"
                                                    @sendData="sendData($event)"
                                                    @newEntry="newEntry($event)"
                                                    :label="data.title"
                                                    :readonly="data.readonly"
                                            ></AutoComplete>
                                            <div v-else-if="data.type === 'colorPicker'">
                                                <small>Farbe</small>
                                                <div v-if="data.form === 'round'" @click="toggleColor"
                                                     class="rounded-xl"
                                                     style="height: 25px;width: 25px"
                                                     :style="'background-color: ' + data.value"></div>
                                                <div v-else-if="data.form === 'quad'" @click="toggleColor"
                                                     style="height: 25px;width: 25px"
                                                     :style="'background-color: ' + data.value"></div>
                                                <div v-else @click="toggleColor" class=""
                                                     style="height: 25px;width: 40px"
                                                     :style="'background-color: ' + data.value"></div>
                                            </div>


                                            <MultipleAutoComplete
                                                    v-else-if="data.type === 'multipleAutoComplete'"
                                                    :rules="getRules(data)"
                                                    :data="data"
                                                    @sendData="sendData($event)"
                                                    @newEntry="newEntry($event)"
                                                    :label="data.title"
                                                    :readonly="data.readonly"
                                            ></MultipleAutoComplete>

                                            <TextField
                                                    :rules="getRules(data)"
                                                    v-if="data.type === 'string'"
                                                    :data="data"
                                                    @sendData="sendData($event)"
                                                    :label="data.title"
                                                    :readonly="data.readonly"
                                            />
                                            <TextField
                                                    :rules="getRules(data)"
                                                    v-if="data.type === 'number'"
                                                    :data="data"
                                                    @sendData="sendData($event)"
                                                    :label="data.title"
                                                    :readonly="data.readonly"
                                                    :type="'number'"
                                            />
                                            <v-textarea
                                                    :rules="getRules(data)"
                                                    v-if="data.type === 'textarea'"
                                                    :data="data"
                                                    @sendData="sendData($event)"
                                                    :label="data.title"
                                                    :readonly="data.readonly"
                                            ></v-textarea>
                                            <Checkbox
                                                    :rules="getRules(data)"
                                                    v-if="data.type === 'boolean'"
                                                    :data="data"
                                                    @sendData="sendData($event)"
                                                    :readonly="data.readonly"
                                            />
                                            <DatePicker
                                                    :rules="getRules(data)"
                                                    v-if="data.type === 'date'"
                                                    :data="data"
                                                    @sendData="sendData($event)"
                                                    :readonly="data.readonly"
                                            ></DatePicker>
                                            <TimePicker
                                                    :rules="getRules(data)"
                                                    v-if="data.type === 'time'"
                                                    :data="data"
                                                    @sendData="sendData($event)"
                                                    :readonly="data.readonly"
                                            ></TimePicker>
                                            <DateTimePicker
                                                    :rules="getRules(data)"
                                                    v-if="data.type === 'dateTime'"
                                                    :data="data"
                                                    @sendData="sendData($event)"
                                                    :readonly="data.readonly"
                                            ></DateTimePicker>
                                        </div>
                                    </div>
                                </v-col>
                                <v-col cols="12" md="6" class="ps-md-6">
                                    <div v-for="data in values" :key="data.index">
                                        <div v-if="data.id >= cut">
                                            <div v-for="headline of headLines" :key="headline.position">
                                                <h3 v-if="headline.position === data.id">{{ headline.text }}</h3>
                                            </div>
                                            <AutoComplete
                                                    :rules="getRules(data)"
                                                    v-if="data.type === 'autoComplete'"
                                                    :data="data"
                                                    @sendData="sendData($event)"
                                                    @newEntry="newEntry($event)"
                                                    :label="data.title"
                                                    :readonly="data.readonly"
                                            ></AutoComplete>
                                            <MultipleAutoComplete
                                                    v-else-if="data.type === 'multipleAutoComplete'"
                                                    :rules="getRules(data)"
                                                    :data="data"
                                                    @sendData="sendData($event)"
                                                    @newEntry="newEntry($event)"
                                                    :label="data.title"
                                                    :readonly="data.readonly"
                                            ></MultipleAutoComplete>
                                            <TextField
                                                    :rules="getRules(data)"
                                                    v-if="data.type === 'string'"
                                                    :data="data"
                                                    @sendData="sendData($event)"
                                                    :label="data.title"
                                                    :readonly="data.readonly"
                                            />
                                            <TextField
                                                    :rules="getRules(data)"
                                                    v-if="data.type === 'number'"
                                                    :data="data"
                                                    @sendData="sendData($event)"
                                                    :label="data.title"
                                                    :readonly="data.readonly"
                                                    :type="'number'"
                                            />
                                            <v-textarea
                                                    :rules="getRules(data)"
                                                    v-if="data.type === 'textarea'"
                                                    :data="data"
                                                    @sendData="sendData($event)"
                                                    :label="data.title"
                                                    :readonly="data.readonly"
                                            ></v-textarea>
                                            <Checkbox
                                                    :rules="getRules(data)"
                                                    v-if="data.type === 'boolean'"
                                                    :data="data"
                                                    @sendData="sendData($event)"
                                                    :readonly="data.readonly"
                                            />
                                            <DatePicker
                                                    :rules="getRules(data)"
                                                    v-if="data.type === 'date'"
                                                    :data="data"
                                                    @sendData="sendData($event)"
                                                    :readonly="data.readonly"
                                            ></DatePicker>
                                            <TimePicker
                                                    :rules="getRules(data)"
                                                    v-if="data.type === 'time'"
                                                    :data="data"
                                                    @sendData="sendData($event)"
                                                    :readonly="data.readonly"
                                            ></TimePicker>
                                            <DateTimePicker
                                                    :rules="getRules(data)"
                                                    v-if="data.type === 'dateTime'"
                                                    :data="data"
                                                    @sendData="sendData($event)"
                                                    :readonly="data.readonly"
                                            ></DateTimePicker>

                                        </div>
                                        <div v-if="showColor && data.type === 'colorPicker'">
                                            <v-color-picker placeholder="Tischfarbe" v-model="data.value" :value="data.value"
                                                            @sendData="sendData($event)"
                                            ></v-color-picker>
                                            <v-text-field label="HEX" class="pa-3" :value="data.value" v-model="data.value" placeholder="HEX"
                                                          @sendData="sendData($event)"
                                            ></v-text-field>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>

                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="primary"
                            text
                            @click="resetForm"
                    >
                        Abbrechen
                    </v-btn>
                    <v-btn
                            color="primary"
                            @click="editBusinessInfo"
                    >
                        {{ successButton }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import TextField from "@/components/dialog/fields/TextField";
import Checkbox from "@/components/dialog/fields/Checkbox";
import AutoComplete from "@/components/dialog/fields/AutoComplete";
import MultipleAutoComplete from "@/components/dialog/fields/MultipleAutoComplete";
import DatePicker from "@/components/dialog/fields/DatePicker";
import DateTimePicker from "@/components/dialog/fields/DateTimePicker";
import TimePicker from "@/components/dialog/fields/TimePicker";

//TODO: Bei Klick auf enter -> emit

/**
 * Dialog to edit business information depending on category of vendor
 * @displayName Dialog
 */
export default {
    name: "Dialog",
    components: {
        Checkbox,
        TextField,
        AutoComplete,
        MultipleAutoComplete,
        DatePicker,
        DateTimePicker,
        TimePicker
    },
    props: {
        /**
         * Contains the title for the dialog
         */
        title: {
            type: String,
            default: ''
        },
        /**
         * Contains if the dialog is visible or not
         * @values true, false
         */
        dialog: {
            type: Boolean,
            default: false
        },
        /**
         * Contains all values to display input areas
         */
        values: {
            type: Array,
            default() {
                return []
            }
        },
        size: {
            type: Object,
            default() {
                return {
                    width: 900,
                    height: 'auto'
                }
            }
        },
        /**
         * Display Text of the finish Button
         */
        successButton: {
            type: String,
            default: ''
        },
        /**
         * Classname of the Icon to display in HeadLine
         */
        icon: {
            type: String,
            default: ''
        },
        headLines: {
            type: Array,
            default() {
                return []
            }
        },
        /**
         * Zeigt an an welcher Stelle vertikal gespalten wird
         */
        cut: Number
    },
    data() {
        return {
            newValue: {},
            actualRules: [],
            startSet: false,
            showColor: false,
            color: ''
        }
    },
    computed: {
        newValues() {
            return this.values
        },
    },
    methods: {
        toggleColor() {
            this.showColor = !this.showColor
        },
        formatDate(date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${month}/${day}/${year}`
        },
        parseDate(date) {
            if (!date) return null

            const [month, day, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        getRules(data) {
            if (data?.required) {
                return ["required"]
            }
        },
        /**
         * Edits the business information
         * @public
         */
        editBusinessInfo() {
            /**
             * Emits the edited business information
             * @property {Object} businessInfo contains the edited business information
             */
            this.$emit('emitNewData', this.newValues)
        },
        /**
         * Stops editing the business information
         * @public
         */
        resetForm() {
            //this.$refs.formular.reset()
            /**
             * Emits to hide the dialog
             */
            this.$emit('hideDialog')
        },
        /**
         * The returned value of textfield or checkbox will be edited in new values Object
         * @param {Object} payload contains the edited business information
         */
        sendData(payload) {
            console.log(payload)
            const index = this.newValues.findIndex(item => item.id === payload.id)
            console.log(index)
            this.newValues[index] = payload
            this.$emit('dataChanged', payload)
        },

        newEntry(payload) {

            this.$emit('createEntry', {entity: payload.entity})
        },
    }
}
</script>

<style scoped>

</style>
