import {databases} from "@/config/server-api";
import {getError} from "@/config/errors";

import {
    bugReportCollection,
    plannerAccountCollection, UpdateInfoCollection,
} from "@/config/awSettings"
import {Query} from "appwrite";

export default {
    namespaced: true,
    state: {
        users: [],
        bugs: [],
        bugsSolved: []
    },
    getters: {
        users: (state) => state.users,
        bugs: (state) => state.bugs,
        bugsSolved: (state) => state.bugsSolved,
    },
    actions: {
        /**
         * Erstellt einen neuen Lead
         *
         * @param payload
         * @returns {Promise<unknown>}
         */
        getUsers({commit}) {
            return new Promise((resolve, reject) => {
                databases.listDocuments(plannerAccountCollection).then((res)=>{
                    commit('addUsers', res.documents)
                }).catch((error)=> {
                    console.log(error)
                    reject(getError(error,"E0101"))
                })
            })
        },
        getBugs({commit}) {
            return new Promise((resolve, reject) => {
                databases.listDocuments(bugReportCollection, [Query.equal("solved",
                    false)],100).then((res)=>{
                    commit('setBugs', res.documents)
                }).catch((error)=> {
                    console.log(error)
                    reject(getError(error,"E0101"))
                }).then(()=>{
                    databases.listDocuments(bugReportCollection, [Query.equal("solved",
                        true)],100).then((res)=>{
                        commit('setBugsSolved', res.documents)
                    }).catch((error)=> {
                        console.log(error)
                        reject(getError(error,"E0101"))
                    })
                })
            })
        },
        setBugSolved({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                databases.updateDocument(bugReportCollection, payload.id, {
                    solved: payload.solved
                }).then(()=>{
                    dispatch('getBugs')
                }).catch((error)=> {
                    console.log(error)
                    reject(getError(error,"E0101"))
                })
            })
        },
        // eslint-disable-next-line no-empty-pattern
        async createMessageForAll({}, payload) {
            return new Promise((resolve, reject) => {
                let features = []
                Object.entries(payload.features).forEach(([key, value]) => {
                    features.push(value)
                    console.log(key)
                });
                if(!payload.icon || payload.icon === ''){
                    payload.icon = 'mdi mdi-rocket-launch-outline'
                }
                databases.createDocument(UpdateInfoCollection, 'unique()', {
                    read: false,
                    icon: payload.icon,
                    text: payload.message,
                    title: payload.title,
                    features
                }).then(() => {
                    console.log(": Nachricht erstellt")
                    resolve()
                }).catch((error) => {
                    console.log(": Fehler: " + error)
                    reject(error)
                })
            })
        },
    },
    mutations: {
        addUsers(state, payload) {
            state.users = payload
        },
        setBugs(state, payload) {
            state.bugs = payload
        },
        setBugsSolved(state, payload) {
            state.bugsSolved = payload
        },
    }
}
