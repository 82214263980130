<template>
  <v-container style="position: relative; padding-left:2.5cm; padding-right:2.5cm" id="pdfBudgetPlan">

    <v-row v-if="profilePic && profilePic !== ''">
      <v-col>
        <img :src="profilePic" style="max-height: 2.2cm; max-width: 4.2cm">
      </v-col>
    </v-row>
    <v-row>
      <v-col class="" style="font-weight: bold">
        Gesamtbudget:
        <a>
          <h2>
            <span> {{ replaceDot(budget) }}€ </span>
          </h2>
        </a>
      </v-col>
      <v-col style="text-align: right; font-weight: bold">
        Geschätzte Kosten:
        <h2>{{ replaceDot(budgetPlan.sumPlanned) }}€</h2>
      </v-col>
      <v-col style="text-align: right; font-weight: bold">
        Tatsächliche Kosten:
        <h2>{{ replaceDot(budgetPlan.sumRealCost) }}€</h2>
      </v-col>
      <v-col style="text-align: right; font-weight: bold">
        Bereits ausgegeben:
        <h2 v-if="budgetPlan.sumSpent >budget" style="color:darkred; font-weight: bold">
          {{ budgetPlan.sumSpent }}€</h2>
        <h2 v-else>{{ replaceDot(budgetPlan.sumSpent) }}€</h2>
      </v-col>
    </v-row>
    <div v-for="(item, index) of budgetPlan.budgetList" :key="item.id" style="margin-top: 40px">
      <v-row style="">
        <v-col cols="1" class="text-left" v-if="item.icon">
          <v-icon slot="prependIcon" size="35">
            {{ item.icon }}
          </v-icon>
        </v-col>
        <v-col style="font-weight: bold" class="text-left">
          {{ item.name }}
        </v-col>
        <v-col style="text-align: right">
          <span style="font-weight: bold">Geplant: </span><br>
          <b>
            {{ replaceDot(item.sumPlanned) }}€
          </b>
        </v-col>
        <v-col style="text-align: right">
          <span style="font-weight: bold">Tatsächliche Kosten: </span><br>
          <b>
            {{ replaceDot(item.sumRealCost) }}€
          </b>
        </v-col>
        <v-col style="text-align: right">
          <span style="font-weight: bold">Ausgegeben: </span><br>
          <b>
            {{ replaceDot(item.sumSpent) }}€
          </b>
        </v-col>
      </v-row>
      <div v-for="(budget, ind) of item.singleBudget" :key="budget"
           style="">
        <div v-if="checkIfPageBrakeNeeded(index,ind, item.singleBudget.length)" class="pageBreaker" style="margin-bottom: 1.5cm"></div>

        <v-row >

          <v-col cols="1" class="text-left">
          </v-col>
          <v-col>
            {{ budget.name }}
          </v-col>
          <v-col style="text-align: right">
            {{ replaceDot(budget.planned) }}€
          </v-col>
          <v-col style="text-align: right">
            {{ replaceDot(budget.realCost) }}€
          </v-col>
          <v-col style="text-align: right">
            {{ replaceDot(budget.spent) }}€
          </v-col>
        </v-row>
      </div>

    </div>
  </v-container>
</template>
<script>
/**
 * Snackbar to show user successful and not successful tries to edit, create or delete a value
 * @displayName Alert
 */
import {dotToKomma, error} from "@/helper/helper";
//const Doughnut = () => import("@/components/generalUI/charts/Doughnut");

export default {
  name: "GeneratePdf",
  components: {
    // Doughnut
  },

  props: {
    /**
     * Contains data to display the alert accordingly
     */
    /**
     * Contains data to display the alert accordingly
     */
    fileName: {
      type: String,
      default() {
        return ''
      }
    },
    budgetPlan: {
      type: Object,
      default() {
        return {}
      }
    },
    profilePic: String,
    chartDataBudget: {
      type: Object,
      default() {
        return {}
      }
    },
    chartDataCategories: {
      type: Object,
      default() {
        return {}
      }
    },
    categorySums: Object,
    budget: Number
  },
  data() {
    return {
      finishedEntries: 10,
      first: true
    }
  },
  mounted() {
    this.first = true
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    checkIfPageBrakeNeeded(indCat, indEntry, length)
    {

      if(this.first){
        this.finishedEntries = 10
        this.first = false
        return false
      }
      this.finishedEntries =  this.finishedEntries +1

      if( this.finishedEntries % 16=== 0){

        return true
      }
      else{
        return false
      }
    },
    replaceDot(float) {
      return dotToKomma(float)
    },
    isZero(number) {
      if (number < 0) {
        return 0
      } else {
        return number
      }
    },
    changeName(name) {
      this.$store.dispatch('customer/editField', {
        entity: "budgetPlan",
        uid: this.user.id,
        id: this.$route.params.id,
        newValue: name,
        row: 'name'
      }).then(() => {
        this.budgetPlan.name = name
      }).catch((err) => {
        this.message = error(err)
      })
    },
  },


}
</script>

<style>
#pdfBudgetPlan * {
  font-weight: unset;
  font-size: unset
}

h1 {
  font-weight: bold;
  font-size: larger;
  padding-top: 20px
}

h2 {
  font-weight: bold;
  font-size: unset;
}

@page {
  margin: 0;
  padding-top: 1555px;
}

.breakText {
  break-before: page;
  margin-top: 30px;
}
</style>

