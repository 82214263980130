<template>
  <div class="container vendors pb-8 fadeInFast">
    <HeadlineTableView
        :icon="'mdi mdi-handshake'"
        :headline="'Dienstleister'"
        :button-icon="'mdi mdi-handshake'"
        :button-text="'Neuer Dienstleister'"
        :entity="'vendor'"
        @buttonClick="openDialogCreateVendor()"
    ></HeadlineTableView>

    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <v-card flat class="rounded-xl pa-2 pa-sm-4">
      <v-row class="align-center">
        <v-col cols="12" md="6">
          <Tab
              :tabs="['Liste','Karte','Archiv']"
              :register="register"
              @changed="register=$event"
          >
          </Tab>
        </v-col>
        <v-col cols="12" md="2">
          <a v-if="!showFilter" @click="showFilter=true">Filter
            <v-icon>mdi-arrow-down</v-icon>
          </a>
          <a v-if="showFilter" @click="showFilter=false; activeFilters = []; filter()">Filter leeren
            <v-icon>mdi-arrow-up</v-icon>
          </a>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field type="text" v-model="searchText" label="Suche Dienstleister..."
                        class="me-6 searchInput" @keyup="search"/>
        </v-col>
      </v-row>

      <v-row v-if="showFilter" class="pb-6 ps-lg-6 pe-lg-6 justify-content-center" style="z-index: 999">
        <v-col cols="12" sm="6" lg="3">
          <MultipleAutoComplete
              :data="{items:categories, title:'Kategorie', row: 'category'}"
              @sendData="setFilter"
          ></MultipleAutoComplete>
        </v-col>
        <v-col cols="12" sm="6" lg="2">
          <MultipleAutoComplete
              :data="{items:[{text: '€',value: 1},{text: '€€',value: 2},{text: '€€€',value: 3}], title:'Preisklasse', row: 'priceClass'}"
              @sendData="setFilter"
          ></MultipleAutoComplete>
        </v-col>
        <v-col cols="12" sm="6" lg="2">
          <MultipleAutoComplete
              :data="{items:stil, title:'Stil', row: 'stil'}"
              @sendData="setFilter"
          ></MultipleAutoComplete>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
          <MultipleAutoComplete
              :data="{items:services, title:'Leistungen', row: 'services'}"
              @sendData="setFilter"
          ></MultipleAutoComplete>
        </v-col>
          <v-col cols="12" sm="4" lg="2">
              <MultipleAutoComplete
                      :data="{items:getAllCountries(), title:'Land', row: 'country'}"
                      @sendData="setFilter"
              ></MultipleAutoComplete>
          </v-col>
      </v-row>
      <div v-if="loaded">
        <TableView
            v-if="register === 0"
            :headLines="headLines"
            :rows="vendorsSearched"
            :archiveEnabled="true"
            @archiveEntries="archiveVendors"
            @deleteEntries="deleteVendors"
            @openDetail="openDetailVendor"
            :textNoResults="'Hier werden deine Dienstleister angezeigt.'"
        ></TableView>
        <div
            v-if="register === 1"
        >
          <Map
              :markers="vendorsSearched"
          ></Map>
        </div>
        <TableView
            v-if="register === 2"
            :headLines="headLines"
            :rows="vendorsSearchedArchived"
            @deleteEntries="deleteVendors"
            @openDetail="openDetailVendor"
            :textNoResults="'Hier werden deine archivierten Dienstleister angezeigt.'"
        ></TableView>
      </div>
      <Spinner v-else></Spinner>
      <CreateVendor
          :dialog="dialog"
          @hideDialog="hideDialog"
          :openAfterCreate="true"
      ></CreateVendor>
    </v-card>

  </div>
</template>
<script>

import {mapGetters} from "vuex"
import {error, getAllCountries, msgObj, success} from "@/helper/helper";

const MultipleAutoComplete = () => import("@/components/dialog/fields/MultipleAutoComplete");
const Message = () => import("@/components/generalUI/Message");
const TableView = () => import("@/components/tableView/TableView");
const HeadlineTableView = () => import("@/components/tableView/HeadlineTableView");
const CreateVendor = () => import("@/components/dialog/createDialogs/CreateVendor");
const Spinner = () => import("@/components/generalUI/Spinner");
const Tab = () => import("@/components/generalUI/Tab");
const Map = () => import("@/components/generalUI/Map");
const delay = ms => new Promise(res => setTimeout(res, ms));

export default {
  name: 'Vendors',
  components: {
    TableView,
    Message,
    HeadlineTableView,
    CreateVendor,
    Spinner,
    MultipleAutoComplete,
    Tab,
    Map
  },
  mounted() {
    window.scrollTo(0, 0)
    this.$store.dispatch('auth/checkLogin', {}).then((res) => {
      this.$store.dispatch('vendor/getVendors', {
        uid: res
      }).then((vends) => {
        this.loaded = true
        console.log("!!!this.vendorsSearched")
        console.log(vends)

        for(let ven of vends){
          this.message = error("Teste21")
          this.$store.dispatch('file/getFilesForVendor', {
            uid: res,
            weddingId: ven.$id
          }).catch((err) => {
            this.message = error(err)
          }).then((res)=>{
            this.message = error("test")
            ven['docs'] = res
          }).finally(()=>{
            this.message = error("test2")
          })
        }
      }).catch((err) => {
        this.message = error(err)
      })
    })
  },
  computed: {
    ...mapGetters('vendor', {
      vendors: 'vendors',
      vendorsArchived: 'vendorsArchived',
      vendorsSearched: 'vendorsSearched',
      vendorsSearchedArchived: 'vendorsSearchedArchived'
    }),
    ...mapGetters('auth', {
      user: 'user',
    }),
    ...mapGetters('settings', {
      categories: 'categoryNames',
      stil: 'stilNames',
      services: 'vendorServiceNames'
    }),
  },
  data() {
    return {
      dialog: false,
      register: 0,
      message: msgObj(),
      loaded: false,
      searchText: '',
      activeFilters: {},
      showFilter: false,
      headLines: [
        {text: "Unternehmen", sort: 'name'},
        {text: "Kategorie", sort: 'category'},
        {text: "Preisklasse", sort: 'priceClass'},
        {text: "Stil"},
        {text: "Leistungen"},
      ],
    }
  },
  methods: {
      getAllCountries,
    openDialogCreateVendor() {
      this.dialog = true
    },
    openDetailVendor(id) {
      this.$router.push("dienstleister/dienstleister/" + id)
    },
    hideDialog() {
      this.dialog = false
    },
    setFilter(payload) {
      if (payload.value.length < 1) {
        delete this.activeFilters[payload.row]
      } else {
        this.activeFilters[payload.row] = payload.value
      }
      this.filter()
    },
    getDeleteText(length) {
      if (length === 1) {
        return "Dienstleister erfolgreich gelöscht."
      } else {
        return length + " Dienstleister erfolgreich gelöscht."
      }
    },
    filter() {
      if (this.register === 2) {
        this.$store.dispatch('sort/filter', {activeFilters: this.activeFilters, list: this.vendorsArchived}
        ).then((results) => {
          this.$store.dispatch('vendor/setVendorsSearchedArchived', results)
        })
      }else{
        this.$store.dispatch('sort/filter', {activeFilters: this.activeFilters, list: this.vendors}
        ).then((results) => {
          this.$store.dispatch('vendor/setVendorsSearched', results)
        })
      }
    },
    search() {
      if (this.register === 2) {
        this.$store.dispatch('sort/search', {search: this.searchText, list: this.vendorsArchived}
        ).then((results) => {
          this.$store.dispatch('vendor/setVendorsSearchedArchived', results)
        })
      }else{
        this.$store.dispatch('sort/search', {search: this.searchText, list: this.vendors}
        ).then((results) => {
          this.$store.dispatch('vendor/setVendorsSearched', results)
        })
      }
    },
    async deleteVendors(ids) {
      let i = 0
      this.loaded = false
      for (let id of ids) {
        this.$store.dispatch('vendor/deleteVendor', {
          uid: this.user.id,
          vendorId: id,
        }).catch((err) => {
          this.message = error(err)
        }).finally(() => {
          if (i === ids.length - 1) {
            this.loaded = true
            this.message = success(this.getDeleteText(ids.length))
          }
        })
        await delay(100)
        i++
      }
    },
    async archiveVendors(ids) {
      let i = 0
      this.loaded = false
      for (let id of ids) {
        this.$store.dispatch('customer/editField', {
          uid: this.user.id,
          row: 'isArchived',
          newValue: true,
          id,
          entity: 'vendors'
        }).catch((err) => {
          this.message = error(err)
        }).finally(() => {
          if (i === ids.length - 1) {
            this.loaded = true
            this.$store.dispatch('vendor/getVendors', {
              uid: this.user.id
            }).then(() => {
              if (ids.length === 1) {
                this.message = success("Dienstleister erfolgreich archiviert.")
              } else {
                this.message = success(ids.length + " Dienstleister erfolgreich archiviert.")
              }
            }).catch((err) => {
              this.message = error(err)
            })
          }
        })
        await delay(100)
        i++
      }
    },
  }
}
</script>
<style>

</style>
