import {databases, functions, storage} from "@/config/server-api";
import {Query} from "appwrite";
import router from "@/router";
import {getError} from "@/config/errors";
import {
    locationCollection, stilCollection, weddingFileBucket, weddingFilesCollection
} from "@/config/awSettings"
import {latLng} from "leaflet";

const delay = ms => new Promise(res => setTimeout(res, ms));


export default {
    namespaced: true,
    state: {
        /**
         * Contains general locations Information
         */
        locations: [],
        /**
         * Contains general location Information
         */
        location: {},
        /**
         * Kunden für die Selektion bei Dropdowns
         */
        locationsArchived: [],
        locationsSearched: [],
        locationsSearchedArchived: [],
        locationsForSelection: [],
    },
    getters: {
        locations: (state) => state.locations,
        locationsArchived: (state) => state.locationsArchived,
        location: (state) => state.location,
        locationsSearched: (state) => state.locationsSearched,
        locationsSearchedArchived: (state) => state.locationsSearchedArchived,
        locationsForSelection: (state) => state.locationsForSelection,
    },
    actions: {
        /**
         * Erstellt einen neuen Kunden
         *
         * @param dispatch
         * @param commit
         * @param payload
         */
        // eslint-disable-next-line no-empty-pattern
        createLocation({commit}, payload) {
            return new Promise((resolve, reject) => {
                let newDoc = payload.newLocation
                newDoc.plannerId = payload.uid
                let importId
                if (payload.newLocation.$id) {
                    importId = payload.newLocation.$id
                } else if (payload.newLocation.id) {
                    importId = payload.newLocation.id
                } else {
                    importId = 'unique()'
                }
                delete payload.newLocation['id'];
                delete payload.newLocation['$id'];
                const promiseCreateDoc = databases.createDocument(locationCollection, importId, newDoc)
                promiseCreateDoc.then(function (doc) {
                    commit('setLocationCreatedId', doc.$id)
                    if (payload.openAfterCreate) {
                        router.push('/locations/location/' + doc.$id).then(() => {
                            resolve(doc.$id)
                        })
                    } else {
                        resolve(doc.$id)
                    }
                }, function (error) {
                    console.log(error)
                    reject(getError(error, "E0020"))
                })
            })
        },
        /**
         * Löscht einen Kunden
         *
         * @param dispatch
         * @param payload
         * @returns {Promise<unknown>}
         */
        deleteLocation({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                // Files löschen
                databases.listDocuments(weddingFilesCollection, [
                    Query.equal("plannerId", payload.uid),
                    Query.equal("locationId", payload.locationId)
                ]).then((fileDocs) => {
                    for (let fileDoc of fileDocs.documents) {
                        storage.deleteFile(weddingFileBucket, fileDoc.docId).then(() => {
                            databases.deleteDocument(weddingFilesCollection, fileDoc.$id).catch((err) => {
                                console.log(err)
                            })
                        })
                    }
                })

                const promiseDeleteDoc = databases.deleteDocument(locationCollection, payload.locationId);
                promiseDeleteDoc.then(function () {
                    dispatch('getLocations', {uid: payload.uid})
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0021"))
                });
            })
        },
        getLocationsForSelection({commit}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDocs = databases.listDocuments(locationCollection, [
                    Query.equal("plannerId", payload.uid)
                ], 0)
                promiseGetDocs.then(async function (documentListX) {
                    if (documentListX.total === 0) {

                        resolve()
                    }
                    let pages = Math.ceil(documentListX.total / 25)
                    let locations = []
                    for (let i = 0; i < pages; i++) {
                        databases.listDocuments(locationCollection, [
                            Query.equal("plannerId", payload.uid)
                        ], 25, i * 25).then((documentList) => {
                            for (let doc of documentList.documents) {
                                let location = {
                                    text: doc.name,
                                    value: doc.$id
                                }
                                if (!doc.isArchived) {
                                    locations.push(location)
                                }
                                if (i === pages - 1) {
                                    commit('setLocationsForSelection', locations)
                                    resolve()
                                }
                            }
                        })
                        await delay(100)
                    }
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0022"))
                });
            })
        },
        /**
         * Holt und setzt alle Location eines bestimmten PLaners
         *
         * @param commit
         * @param payload
         * @returns {Promise<unknown>}
         */
        getLocations({commit, dispatch}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDocs = databases.listDocuments(locationCollection, [
                    Query.equal("plannerId", payload.uid)
                ], 0)
                promiseGetDocs.then(async function (documentListX) {
                    if (documentListX.total === 0) {
                        resolve()
                    }
                    let pages = Math.ceil(documentListX.total / 25)
                    let locations = []
                    let locationsArchive = []
                    for (let i = 0; i < pages; i++) {
                        databases.listDocuments(locationCollection, [
                            Query.equal("plannerId", payload.uid)
                        ], 25, i * 25).then((documentList) => {
                            for (let doc of documentList.documents) {
                                dispatch('getStil', {stilIds: doc.stil}).then((stils) => {
                                    let groesseInnen = doc.groesseInnen
                                    let groesseAussen = doc.groesseAussen
                                    if (!groesseInnen || groesseInnen === 0) {
                                        groesseInnen = ''
                                    } else {
                                        groesseInnen = groesseInnen + ' m²'
                                    }
                                    if (!groesseAussen || groesseAussen === 0) {
                                        groesseAussen = ''
                                    } else {
                                        groesseAussen = groesseAussen + ' m²'
                                    }
                                    let location = {
                                        values: {
                                            name: doc.name,
                                            stil: stils,
                                            priceClass: doc.priceClass,
                                            maxPersonen: doc.maxPersonen,
                                            groesseInnen: groesseInnen,
                                            groesseAussen: groesseAussen,
                                            country: doc.country,
                                        },
                                        export: doc,
                                        latLng: latLng(doc.lat, doc.lng),
                                        isArchived: doc.isArchived,
                                        id: doc.$id,
                                    }
                                    delete location.export['$collection'];
                                    delete location.export['$read'];
                                    delete location.export['$write'];

                                    if (doc.isArchived) {
                                        locationsArchive.push(location)
                                    } else {
                                        locations.push(location)
                                    }
                                }).catch((error) => {
                                    console.log(error); // Failure
                                    reject(error)
                                }).finally(() => {
                                    if (i === pages - 1) {
                                        commit('setLocations', locations)
                                        commit('setSearchedLocations', locations)
                                        commit('setSearchedLocationsArchive', locationsArchive)
                                        commit('setLocationsArchive', locationsArchive)
                                        resolve()
                                    }
                                })
                            }
                        })
                        await delay(100)
                    }
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0022"))
                });
            })
        },

        /*
        .then(() => {

                        }).catch((err) => {
                            console.log(err)
                        }).finally(() => {
                            if (i === pages - 1) {

                            }
                        })
                        await delay(100)
         */
        // eslint-disable-next-line no-empty-pattern
        getCoordinates({}, payload) {
            return new Promise((resolve, reject) => {
                functions.createExecution('63e2a62e092f6a1c23a9', payload.address).then((ex) => {
                    if (JSON.parse(ex.response).resp[0]) {
                        resolve(JSON.parse(ex.response).resp[0].location)
                    } else {
                        reject('Koordinaten konnten nicht ermittelt werden.')
                    }
                }).catch((err) => {
                    console.log(err); // Failure
                    reject(err)
                })
            })
        },
        /**
         * liest einen speziellen Location aus der Datenbank
         * @param commit
         * @param payload
         * @returns {Promise<unknown>}
         */
        getLocation({commit, dispatch}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDoc = databases.getDocument(locationCollection, payload.locationId)
                promiseGetDoc.then(function (doc) {
                    dispatch('getStil', {stilIds: doc.stil}).then((stils) => {
                        let groesseInnen = doc.groesseInnen
                        let groesseAussen = doc.groesseAussen
                        if (!groesseInnen || groesseInnen === 0) {
                            groesseInnen = ''
                        }
                        if (!groesseAussen || groesseAussen === 0) {
                            groesseAussen = ''
                        }
                        let location = {
                            name: doc.name,
                            maxPersonen: doc.maxPersonen,
                            groesseInnen: groesseInnen,
                            groesseAussen: groesseAussen,
                            street: doc.street,
                            stil: stils,
                            houseNumber: doc.houseNumber,
                            plz: doc.plz,
                            city: doc.city,
                            email: doc.email,
                            contactName: doc.contactName,
                            phone: doc.phone,
                            country: doc.country,
                            priceClass: doc.priceClass,
                            id: doc.$id,
                            created: doc.$createdAt * 1000,
                            isArchived: doc.isArchived,
                            lat: doc.lat,
                            lng: doc.lng,
                            latLng: latLng(doc.lat, doc.lng)
                        }
                        commit('setLocation', location)
                        resolve()
                    }).catch((error) => {
                        console.log(error); // Failure
                        reject(error)
                    })
                }, function (error) {
                    console.log(error); // Failure
                    reject(error)
                });
            })
        },
        // eslint-disable-next-line no-empty-pattern
        getStil({}, payload) {
            return new Promise((resolve, reject) => {
                let stilList = [];
                payload.stilIds?.forEach((stilId) => {
                    const promiseGetDocs = databases.getDocument(stilCollection, stilId)
                    promiseGetDocs.then(function (doc) {
                        let stil = {
                            name: doc.name,
                            value: doc.$id
                        }
                        stilList.push(stil)
                    }, function (error) {
                        console.log(error); // Failure
                        reject(error)
                    });
                })
                resolve(stilList)
            })
        },
        setLocationsSearched({commit}, payload) {
            return new Promise((resolve) => {
                commit('setSearchedLocations', payload)
                resolve()
            })
        },
        setLocationsSearchedArchived({commit}, payload) {
            return new Promise((resolve) => {
                commit('setSearchedLocationsArchive', payload)
                resolve()
            })
        }
    },
    mutations: {
        setLocations(state, payload) {
            state.locations = payload
        },
        setLocationsForSelection(state, payload) {
            state.locationsForSelection = payload
        },
        setLocationsArchive(state, payload) {
            state.locationsArchived = payload
        },
        setLocation(state, payload) {
            state.location = payload
        },
        setLocationCreatedId(state, payload) {
            state.locationCreatedId = payload
        },
        setSearchedLocations(state, payload) {
            state.locationsSearched = payload
        },
        setSearchedLocationsArchive(state, payload) {
            state.locationsSearchedArchived = payload
        },
    }
}
