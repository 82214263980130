<template>
    <v-card flat class="mb-3 rounded-xl" @click="openWedding(element.id)" style="background-color: #F3F4F3">
      <v-card-title class="mb-0 pb-0" >
        <v-icon class="pe-2">mdi-ring</v-icon>
        <h6>{{ element.customerFirstName }} und {{ element.customerPartnerName }}</h6>
      </v-card-title>
      <div>
        <v-container class="mt-0 pt-0 mb-0 pb-0">
          <v-row class="mt-0 pt-0 mb-0 pb-0 ms-0 me-1" >
            <v-col cols="3" class="pa-1" v-for="label of element.labels" :key="label.name">
              <v-tooltip bottom :color="getFullLabel(label).color" max-width="200px">
                <template v-slot:activator="{ on, attrs }">
                                  <span v-bind="attrs" v-on="on">
                                    <div class="rounded-xl " style="min-height: 8px" :style="'background-color: '+getFullLabel(label).color">

                                    </div>
                                  </span>
                </template>
                {{getFullLabel(label).name}}
              </v-tooltip>
            </v-col>
          </v-row>
        </v-container>
        <v-card-text class="mt-2 pt-2">
          <b>{{ element.type }}</b><br>
          {{ getStringDate(element.wedDate) }}<br>
        </v-card-text>
      </div>
    </v-card>
</template>

<script>
import {date2String} from "@/helper/helper";

/**
 * Snackbar to show user successful and not successful tries to edit, create or delete a value
 * @displayName Alert
 */
export default {
  name: "WeddingSnippet",
  props: {
    /**
     * Contains data to display the alert accordingly
     */
    element: {
      type: Object,
      default(){
        return {}
      }
    },
    weddingLabels: []
  },
  methods:{
    getFullLabel(name){
      for(let label of this.weddingLabels){
        if(label.name === name){
          return label
        }
      }
      return {
        name: 'Gelöschtes Label',
        color: 'grey'
      }
    },
    getStringDate(date) {
      return date2String(date)
    },
    openWedding(id) {
      this.$router.push('/weddings/wedding/' + id)
    }
  }
}
</script>
