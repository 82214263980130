<template>
<div>
  <Message
      :message="message"
      @closeAlert="message.show = false"
  ></Message>

  <v-virtual-scroll
      :items="items"
      height="300"
      item-height="64"
      v-if="items.length > 0"
  >

    <template v-slot:default="{ item }">

      <v-list-item :key="item.id" >
        <v-list-item-action>

          <v-btn
              fab
              small
              depressed
              color="primary"
              @click="downloadFile(item.$id)"
          >
            <v-icon>mdi-file</v-icon>
          </v-btn>

        </v-list-item-action>

        <v-list-item-content>

          <v-list-item-title>
            <strong>{{ item.name }}</strong>

          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-row>
            <v-col>
              <span v-if="item.isTitlePic" @click="setTitlePic(item.entryID, false)"><a><small>Titelbild entfernen</small><v-icon   color="primary">mdi-image</v-icon></a></span>
              <span v-else-if="!item.isTitlePic && item.name.toUpperCase().includes('.JPG') || item.name.toUpperCase().includes('.PNG')" @click="setTitlePic(item.entryID, true)"><a><small>Titelbild festlegen</small><v-icon color="lightgrey">mdi-image</v-icon></a></span>
              <v-icon class="ms-3" @click="deleteFile(item.$id, weddingId)">mdi-delete</v-icon>
            </v-col>
          </v-row>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
    </template>
  </v-virtual-scroll>
  <NoResultSnippet
      v-else
      :text="noResultsText">
  </NoResultSnippet>
</div>

</template>

<script>
import {error, msgObj, success} from "@/helper/helper";
const NoResultSnippet = () => import("@/components/generalUI/NoResultSnippet");
const Message = () => import("@/components/generalUI/Message");
import {mapGetters} from "vuex";


export default {
  name: "Files",
  components: {
    NoResultSnippet,
    Message
  },
  data(){
    return{
      message: msgObj(),
    }
  },
  props: {
    /**
     * Contains data to display the alert accordingly
     */
    items: {
      type: Array,
      default(){
        return []
      }
    },
    weddingId: String,
    noResultsText: String
  },
  computed:{
    ...mapGetters('auth', {
      user: 'user',
    }),
  },
  methods:{
    downloadFile(path) {
      this.$store.dispatch('file/downloadFile', {
        path
      }).catch((err)=>{
        this.message = error(err)
      })
    },
    deleteFile(path, weddingId) {
      this.$store.dispatch('file/deleteFile', {
        path,
        uid: this.user.id,
        weddingId: weddingId
      }).then(()=>{
        this.message = success('Datei wurde erfolgreich gelöscht.')
      })
    },
    setTitlePic(id, status) {
      this.$store.dispatch('general/editField', {
        row: 'isTitlePic',
        entity: 'weddingFiles',
        newValue: status,
        id
      }).then(()=>{
        location.reload()
        this.message = success('Titelbild erfolgreich angepasst.')
      })
    },
  }
}
</script>
