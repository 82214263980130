import {
    templateCollection, templateTimePlanEntriesCollection,
    timePlanCollection, timePlanEntryCollection, vendorCollection
} from "@/config/awSettings"
import {databases} from "@/config/server-api";
import {Query} from "appwrite";
import {getError} from "@/config/errors";
const delay = ms => new Promise(res => setTimeout(res, ms));


export default {
    namespaced: true,
    state: {
        timePlan: {},
        timePlans: [],
        timePlanEntries: [],
        searchedEntries: [],
        timePlanTemplates: [],
        isInCreation: false,
        savedTotal: 0,
        savedActual: 0,
    },
    getters: {
        timePlan: (state) => state.timePlan,
        timePlans: (state) => state.timePlans,
        timePlanEntries: (state) => state.timePlanEntries,
        searchedEntries: (state) => state.searchedEntries,
        timePlanTemplates: (state) => state.timePlanTemplates,
        isInCreation:  (state) => state.isInCreation,
        savedTotal:  (state) => state.savedTotal,
        savedActual:  (state) => state.savedActual,
    },

    actions: {
        // eslint-disable-next-line no-empty-pattern
        createTimePlan({commit}, payload) {
            return new Promise((resolve, reject) => {
                let newDoc = {
                    name: payload.name,
                    description: '',
                    weddingId: payload.weddingId,
                    plannerId: payload.uid,
                    date: payload.date
                }
                if (payload.templateId) {
                    databases.createDocument(timePlanCollection, "unique()", newDoc).then((createdDoc) => {
                        const promiseGetDocs = databases.listDocuments(templateTimePlanEntriesCollection, [
                            Query.equal("plannerId", payload.uid),
                            Query.equal("timePlanId", payload.templateId),
                        ],100)
                        promiseGetDocs.then(function (documentList) {
                            let totalEntry = Object.keys(documentList.documents).length
                            let actualEntry = 0
                            commit('setSavedTotal', totalEntry)
                            for (let doc of documentList.documents) {
                                console.log(createdDoc.$id)
                                let newEntry = {
                                    timePlanId: createdDoc.$id,
                                    name: doc.name,
                                    start: doc.start,
                                    end: doc.end,
                                    description: doc.description,
                                    vendors: doc.vendors,
                                }
                                const promiseCreateDoc = databases.createDocument(timePlanEntryCollection, "unique()", newEntry)
                                promiseCreateDoc.catch((error) => {
                                    console.log(error)
                                    reject(getError("Fehler beim Anlegen der Einträge zum Tagesablauf.", ""))
                                }).finally(()=>{
                                    actualEntry++
                                    commit('setSavedActual', actualEntry)
                                    if(totalEntry === actualEntry){
                                        resolve()
                                    }
                                })
                            }
                        }).catch((err) => {
                            console.log(err)
                            reject(err)
                        })
                    }).catch((err) => {
                        console.log(err)
                        reject(err)
                    })
                } else {
                    databases.createDocument(timePlanCollection, "unique()", newDoc).then(() => {
                        resolve()
                    }).catch((err) => {
                        console.log(err)
                        reject(err)
                    })
                }
            })
        },
        getTimePlanTemplates({commit}, payload) {
            return new Promise((resolve, reject) => {
                let timePlans = []
                const promiseGetDocs = databases.listDocuments(templateCollection, [
                    Query.equal("plannerId", payload.uid),
                    Query.equal("type", 'timePlan'),
                ],100)
                promiseGetDocs.then(function (documentList) {
                    for (let doc of documentList.documents) {
                        timePlans.push({
                            id: doc.$id,
                            name: doc.name,
                            description: doc.description,
                        })
                    }
                    commit('setTimePlanTemplates', timePlans)
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject(error)
                })
            })
        },
        setEntriesSearched({commit}, payload){
            console.log("535")
            console.log(payload)
            return new Promise((resolve) => {
                commit('setSearchedEntries', payload)
                resolve()
            })
        },
        saveAsTemplate({commit}, payload) {
            return new Promise((resolve, reject) => {
                databases.createDocument(templateCollection, 'unique()', {
                    type: 'timePlan',
                    plannerId: payload.uid,
                    name: payload.name,
                    description: payload.description,
                }).then(async (doc) => {
                    let templatePlanId = doc.$id
                    let totalEntry = Object.keys(payload.timePlanEntries).length
                    let actualEntry = 0
                    commit('setSavedTotal', totalEntry)
                    for (let entry of payload.timePlanEntries) {
                        let vendors = []
                        for (let vendor of entry.vendors) {
                            vendors.push(vendor.id)
                        }
                        databases.createDocument(templateTimePlanEntriesCollection, 'unique()', {
                            plannerId: payload.uid,
                            name: entry.name,
                            timePlanId: templatePlanId,
                            start: entry.start,
                            end: entry.end,
                            description: entry.description,
                            vendors,
                        }).catch((err) => {
                            console.log(err)
                            reject(err)
                        }).finally(()=>{
                            actualEntry++
                            commit('setSavedActual', actualEntry)
                            if(actualEntry === totalEntry){
                                resolve()
                            }
                        })
                        await delay(250)
                    }
                }).catch((err) => {
                    console.log(err)
                    reject(err)
                })
            })
        },
        // eslint-disable-next-line no-empty-pattern
        deleteTemplate({}, payload) {
            return new Promise((resolve, reject) => {
                databases.listDocuments(templateTimePlanEntriesCollection, [
                    Query.equal("timePlanId", payload.templateId)
                ],100).then((docs) => {
                    for (let doc of docs.documents) {
                        databases.deleteDocument(templateTimePlanEntriesCollection, doc.$id).catch((err) => {
                            console.log(err)
                            reject(err)
                        })
                    }
                    databases.deleteDocument(templateCollection, payload.templateId).catch((err) => {
                        console.log(err)
                        reject(err)
                    })
                }).catch((err) => {
                    console.log(err)
                    reject(err)
                })
            })
        },
        getTimePlansForWedding({commit}, payload) {
            return new Promise((resolve, reject) => {
                let timePlans = []
                const promiseGetDocs = databases.listDocuments(timePlanCollection, [Query.equal("weddingId",
                    payload.weddingId
                )],100)
                promiseGetDocs.then(function (documentList) {
                    for (let doc of documentList.documents) {
                        let name = 'Zeitplan'
                        if (doc.name) {
                            name = doc.name
                        }
                        timePlans.push({
                            id: doc.$id,
                            name,
                            description: doc.description,
                        })
                    }
                    commit('setTimePlans', timePlans)
                    resolve()
                }, function (error) {
                    console.log(error); // Failure
                    reject('Fehler beim lesen der Zeipläne zur Hochzeit.')
                })
            })
        },
        getTimePlan({commit}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetPlan = databases.getDocument(timePlanCollection, payload.timePlanId)
                promiseGetPlan.then((doc) => {
                    const timePlan = {
                        categoryId: doc.$id,
                        name: doc.name,
                        description: doc.description,
                        date: doc.date,
                        weddingId: doc.weddingId
                    }
                    commit('setTimePlan', timePlan)
                    resolve()
                }).catch((err) => {
                    console.log(err)
                    reject(err)
                })
            })
        },
        // eslint-disable-next-line no-empty-pattern
        editTimePlanName({}, payload) {
            return new Promise((resolve, reject) => {
                databases.updateDocument(timePlanCollection, payload.timePlanId, {
                    name: payload.name,
                }).then(() => {
                    resolve()
                }).catch((e) => {
                    console.log(e)
                    reject(e)
                });
            })

        },
        // eslint-disable-next-line no-empty-pattern
        editTimePlanDescription({}, payload) {
            return new Promise((resolve, reject) => {
                databases.updateDocument(timePlanCollection, payload.timePlanId, {
                    description: payload.description,
                }).then(() => {
                    resolve()
                }).catch((e) => {
                    console.log(e)
                    reject(e)
                });
            })

        },
        // eslint-disable-next-line no-empty-pattern
        editTimePlanDate({}, payload) {
            return new Promise((resolve, reject) => {
                databases.updateDocument(timePlanCollection, payload.timePlanId, {
                    date: new Date(payload.date).getTime(),
                }).then(() => {
                    resolve()
                }).catch((e) => {
                    console.log(e)
                    reject(e)
                });
            })

        },
        // eslint-disable-next-line no-empty-pattern
        deleteTimePlanEntry({}, payload) {
            return new Promise((resolve, reject) => {
                databases.deleteDocument(timePlanEntryCollection, payload.entryId).then((doc) => {
                    resolve(doc)
                }).catch(e => reject(e))
            })
        },
        // eslint-disable-next-line no-empty-pattern
        deleteTimePlan({}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetPlanEntries = databases.listDocuments(timePlanEntryCollection, [Query.equal("timePlanId",
                    payload
                )],100)
                promiseGetPlanEntries.then((docs) => {
                        for (let doc of docs.documents) {
                            databases.deleteDocument(timePlanEntryCollection, doc.$id).catch((err) => {
                                console.log(err)
                            })
                        }
                        databases.deleteDocument(timePlanCollection, payload).then((doc) => {
                            resolve(doc)
                        }).catch(e => reject(e))
                })

            })
        },
        getTimePlanEntries({commit}, payload) {
            console.log(payload)
            return new Promise((resolve, reject) => {
                const promiseGetPlanEntries = databases.listDocuments(timePlanEntryCollection, [Query.equal("timePlanId",
                    payload.timePlanId
                )],100)
                promiseGetPlanEntries.then((docs) => {
                    let entryList = []

                    for (let doc of docs.documents) {
                        //wandelt doc.start in sekunden anzahl um -> daher sortierbar
                        let hms = doc.start;   // your input string
                        let a = hms.split(':'); // split it at the colons
                        let seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60;
                        let vendors = []
                        let vendor = {}
                        for (let vendorId of doc.vendors) {
                            const promiseGetDocs = databases.getDocument(vendorCollection, vendorId)
                            promiseGetDocs.then(function (doc) {
                                vendor = {
                                    id: vendorId,
                                    name: doc.name,
                                }
                                vendors.push(vendor)
                            }).catch((err) => {
                                console.log(err)
                            })
                        }
                        let entry = {
                            $id: doc.$id,
                            name: doc.name,
                            start: doc.start,
                            end: doc.end,
                            description: doc.description,
                            vendors: vendors,
                            seconds: seconds
                        }
                        entryList.push(entry)
                    }
                    //sortiert array auf Sekundenanzahl absierend
                    entryList = entryList.sort(({seconds: a}, {seconds: b}) => a - b);
                    commit('setTimePlanEntries', entryList)
                    resolve()
                }).catch((err) => {
                    console.log(err)
                    reject(err)
                })
            })
        },
        // eslint-disable-next-line no-empty-pattern
        editEntry({}, payload) {
            return new Promise((resolve, reject) => {
                //@click="changeEntry(item.$id);checkedEntry({index: index, start: item.start, itemEnd: item.end,itemName: item.name , itemDescription: item.description})"
                let startHMS = payload.start;   // your input string
                let a = startHMS.split(':'); // split it at the colons
                let startSeconds = (+a[0]) * 60 * 60 + (+a[1]) * 60;

                let endHMS = payload.end;   // your input string
                let b = endHMS.split(':'); // split it at the colons
                let endSeconds = (+b[0]) * 60 * 60 + (+b[1]) * 60;
                if (endSeconds < startSeconds) {
                    reject(getError("", "E0130"))
                }
                const promiseUpdateDoc = databases.updateDocument(timePlanEntryCollection, payload.entryId, {
                    start: payload.start,
                    end: payload.end,
                    name: payload.name,
                    description: payload.description,
                    vendors: payload.vendors
                });
                promiseUpdateDoc.then((doc) => {
                    resolve(doc)
                }).catch((e) => {
                    console.log(e);
                    reject(reject(getError("Fehler beim Anlegen der Kategorien zum neuen Budgetplan.", ""), e))
                })
            })
        },
        sortEntryList({commit, state}, payload) {
            return new Promise((resolve) => {
                let entryList = state.searchedEntries
                if (payload === true) {
                    entryList = entryList.sort(({seconds: a}, {seconds: b}) => a - b);
                    commit('setTimePlanEntries', entryList)
                    resolve()
                } else {
                    entryList = entryList.sort(({seconds: a}, {seconds: b}) => b - a);
                    commit('setTimePlanEntries', entryList)
                    resolve()
                }
            })
        },
        filterEntries({commit, state, dispatch}, payload) {
            return new Promise((resolve, reject) => {
                dispatch('getTimePlanEntries', {timePlanId: payload.timePlanId}).then(() => {
                    let entryList = state.timePlanEntries
                    let newList = []
                    for (let entry of entryList) {
                        if (entry.vendors.includes(payload.filterVendor)) {
                            newList.push(entry)
                        }
                    }
                    commit('setTimePlanEntries', newList)
                    resolve()
                }).catch((err) => {
                    console.log(err)
                    reject(err)
                })
            })
        },
        // eslint-disable-next-line no-empty-pattern
        createTimePlanEntry({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                let startHMS = payload.newStart;   // your input string
                let a = startHMS.split(':'); // split it at the colons
                let startSeconds = (+a[0]) * 60 * 60 + (+a[1]) * 60;

                let endHMS = payload.newEnd;   // your input string
                let b = endHMS.split(':'); // split it at the colons
                let endSeconds = (+b[0]) * 60 * 60 + (+b[1]) * 60;
                if (endSeconds < startSeconds) {
                    reject("die Endzeit muss nach der Startzeit sein")
                }
                const promiseGetPlan = databases.createDocument(timePlanEntryCollection, 'unique()', {
                    timePlanId: payload.timePlanId,
                    name: payload.newTitle,
                    start: payload.newStart,
                    end: payload.newEnd,
                    description: payload.newDescription,
                    vendors: payload.newVendors
                })
                promiseGetPlan.then((doc) => {
                    dispatch('getTimePlanEntries', {timePlanId: payload.timePlanId})
                    resolve(doc)
                }).catch((err) => {
                    console.log(err)
                    reject(err)
                })
            })
        },
    },
    mutations: {
        setTimePlan(state, payload) {
            state.timePlan = payload
        },
        setTimePlans(state, payload) {
            state.timePlans = payload
        },
        setTimePlanEntry(state, payload) {
            state.timePlanEntry = payload
        },
        setTimePlanEntries(state, payload) {
            state.timePlanEntries = payload
        },
        setTimePlanTemplates(state, payload) {
            state.timePlanTemplates = payload
        },
        setIsInCreation(state, payload) {
            state.isInCreation = payload
        },
        setSearchedEntries(state, payload) {
            state.searchedEntries = payload
        },
        setSavedTotal(state, payload) {
            state.savedTotal = payload
        },
        setSavedActual(state, payload) {
            state.savedActual = payload
        },
    }
}
