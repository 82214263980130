<template>
  <v-row>
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <v-col cols="12" sm="4" lg="2">
      <h4>Hochzeitsstatus
        <v-tooltip right color="" max-width="200px">
          <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon class="d-inline-flex" size="20">
                          mdi-help
                        </v-icon>
                      </span>
          </template>
          Lege in welchem Status sich eine Hochzeit befinden kann
        </v-tooltip>
      </h4>
      <h5 class="grey--text">
        (Hochzeiten)
      </h5>
    </v-col>
    <v-col >
      <div v-for="status of weddingStatus" :key="status.id" class="pb-3">
        {{status.position}}. {{ status.name }}
        <v-tooltip right color="" v-if="status.position >1">
          <template v-slot:activator="{ on, attrs }">
            <span
                v-bind="attrs"
                v-on="on"
            >
              <v-icon @click="moveStatus('up', status.position-1, status.id)">
                mdi-arrow-up
              </v-icon>
            </span>
          </template>
          Nach oben
        </v-tooltip>
        <v-tooltip right color="" v-if="status.position < weddingStatus.length">
          <template v-slot:activator="{ on, attrs }">
            <spanv
                v-bind="attrs"
                v-on="on"
            >
              <v-icon @click="moveStatus('down', status.position+1, status.id)">
                mdi-arrow-down
              </v-icon>
            </spanv>
          </template>
          Nach unten
        </v-tooltip>
        <v-tooltip right color="red" v-if="status.position === weddingStatus.length">
          <template v-slot:activator="{ on, attrs }">
                        <spanv
                            v-bind="attrs"
                            v-on="on"
                        >
                          <v-icon @click="sureToDeleteDialog = true; selectedId = status.id">
                            mdi-delete
                          </v-icon>
                        </spanv>
          </template>
          Status löschen
        </v-tooltip>
      </div>
      <SureToDelete
          class="pt-0 mt-0 pb-0 mb-0"
          :dialog="sureToDeleteDialog"
          :text="'Bist du Sicher, dass du den Status löschen möchtest? Bitte prüfe zunächst, ob sich noch Hochzeiten in diesem Status befinden.'"
          @cancel="sureToDeleteDialog=false"
          @sure="deleteStatus('weddingStatus'); sureToDeleteDialog=false"
      ></SureToDelete>
      <v-row class="align-center">
        <v-col cols="8" md="6">
          <v-text-field placeholder="Neuer Status" v-model="newWeddingStatus"></v-text-field>
        </v-col>
        <v-col cols="4" md="6">
          <a  @click="addWeddingStatus(newWeddingStatus)" class="d-inline">
            Hinzufügen
          </a>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters} from "vuex";
import {error, msgObj, success} from "@/helper/helper";
const Message = () => import("@/components/generalUI/Message");
const SureToDelete = () => import("@/components/generalUI/SureToDelete");
/**
 * Snackbar to show user successful and not successful tries to edit, create or delete a value
 * @displayName Alert
 */
export default {
  name: "WeddingStatus",
  computed: {
    ...mapGetters('settings', {
      weddingStatus: 'weddingStatus',
    }),
  },
  components:{
    Message,
    SureToDelete
  },
  props:{
    user: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  mounted() {
    this.$store.dispatch('settings/getStatus', {
      uid: this.user.id,
    }).catch((err) => {
      this.message = error(err)
    })
  },
  data(){
    return {
      newWeddingStatus: '',
      message: msgObj(),
      sureToDeleteDialog:false,
      selectedId:""
    }
  },

  methods: {
    deleteStatus(statusCollection) {
      this.$store.dispatch('settings/deleteStatus', {
        uid: this.user.id,
        id: this.selectedId,
        statusCollection,
      }).then(() => {
        this.message = success('Status erfolgreich gelöscht.')
      }).catch((err) => {
        this.message = error(err)
      })
    },
    addWeddingStatus(name) {
      if (name !== '') {
        this.$store.dispatch('settings/addWeddingStatus', {
          uid: this.user.id,
          name
        }).then(() => {
          this.newWeddingStatus = ''
          this.message = success('Feld erfolgreich angelegt.')
        }).catch((err) => {
          this.message = error(err)
        })
      }
    },
    moveStatus(direction, newPosition, id){
      let method
      if(direction === 'up'){
        method = 'weddingStatusUp'
      }else if(direction === 'down'){
        method = 'weddingStatusDown'
      }

      this.$store.dispatch('settings/'+method, {
        uid: this.user.id,
        newPosition,
        id
      }).then(() => {
        this.message = success('Feld erfolgreich angelegt.')
      }).catch((err) => {
        this.message = error(err)
      })
    }
  }
}
</script>
