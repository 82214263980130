<template>
    <v-container class="pb-16 ">
        <Message
                :message="message"
                @closeAlert="message.show = false"
        ></Message>
        <v-row>
            <v-col class="top">
                <v-card class="pa-6 rounded-xl " flat>
                    <v-form ref="formular">
                        <h2>Neuer Gast</h2>
                        <v-row>
                            <v-col cols="12" md="4" class="ps-md-6">
                                <v-text-field
                                    v-model="newGuest.name"
                                    label="Name"
                                    :readonly="false"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" class="ps-md-6">
                                <v-text-field
                                    v-model="newGuest.count"
                                    label="Personenanzahl"
                                    :readonly="false"
                                    type="number"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" class="ps-md-6">
                                <v-autocomplete
                                    :data="tablesSelection"
                                    :label="'Tisch'"
                                    :items="tablesSelection"
                                    v-model="newGuest.tableId"
                                    item-text="text"
                                    item-value="value"
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row class="justify-content-end align-content-end text-right">
                            <v-col class="d-flex justify-content-end align-content-center text-center">
                                <v-btn class="d-flex primary" @click="createGuest">Hinzufügen</v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card class="rounded-xl pa-6" flat>

                <h1 class=" pb-6">Gäste</h1>
                <v-row v-for="guest of guests" :key="guest.id" class="customerRow">
                    <v-col>
                        {{ guest.name }}
                    </v-col>
                    <v-col>
                        <v-icon>mdi-account</v-icon>
                        {{ guest.count }}
                    </v-col>
                    <v-col>
                        <div v-if="guest.tableId">
                            <div v-if="getTableField(guest.tableId, 'seats')">
                                <v-icon v-if="getTableField(guest.tableId, 'form') === 'round'" :color="getTableField(guest.tableId, 'color')">
                                    mdi-circle
                                </v-icon>
                                <v-icon v-else-if="getTableField(guest.tableId, 'form') === 'reckt'" :color="getTableField(guest.tableId, 'color')">
                                    mdi-rectangle
                                </v-icon>
                                <v-icon v-else-if="getTableField(guest.tableId, 'form') === 'quad'" :color="getTableField(guest.tableId, 'color')">
                                    mdi-triangle
                                </v-icon>
                                <v-icon :color="getTableField(guest.tableId, 'color')" v-else>
                                    mdi-rectangle
                                </v-icon>
                                {{getTableField(guest.tableId, 'name')}}<br>
                                <v-icon class="pe-2">mdi-seat</v-icon> {{getTableField(guest.tableId, 'takenSeats')}}
                                /{{getTableField(guest.tableId, 'seats')}}
                            </div>
                            <div class="red--text" v-else>
                                Tisch wurde gelöscht
                            </div>

                        </div>

                    </v-col>
                    <v-col class="col-2">
                        <v-icon @click="startEdit(guest)">mdi-pencil</v-icon>
                        <v-icon @click="deleteGuest(guest.id)">mdi-delete</v-icon>
                    </v-col>
                </v-row>
                </v-card>

            </v-col>

        </v-row>
        <Dialog
            :dialog="dialogEdit"
            :title="'Tisch bearbeiten'"
            :values="editFields"
            :successButton="'Bestätigen'"
            :icon="'mdi mdi-circle'"
            @hideDialog="dialogEdit=false"
            @emitNewData="edit"
            :cut="4"
        ></Dialog>
    </v-container>
</template>

<script>


import {mapGetters} from "vuex";

const Message = () => import("@/components/generalUI/Message");
import {error, msgObj, success} from "@/helper/helper";
const Dialog = () => import("@/components/dialog/FormDialogNew");


/**
 * User can plan budget for own wedding with different categories.
 * Every category has items and each item's budget can be planned
 * @displayName BudgetPlan
 *
 */
export default {
    components: {
        Message,
        Dialog
    },
    name: "GuestList",
    props: {
        guests: Array,
        tables: Array,
        tablesSelection: Array,
        SeatPlanId: String,
    },
    data() {
        return {
            message: msgObj(),
            newGuest: {
                count: 1
            },
            dialogEdit: false,
            editFields: [

            ]
        }
    },
    computed: {
        ...mapGetters('auth', {
            user: 'user',
        }),
        ...mapGetters('seatPlan', {
            tables: 'tables',
        }),
    },
    /**
     * Check if user is logged in and then get budgetplan of logged user
     *
     */
    mounted() {
        this.$store.dispatch('auth/checkLogin', {}).then((res) => {
            this.$store.dispatch('seatPlan/getSeatPlan', {
                uid: res,
                seatPlanId: this.$route.params.id
            }).then(() => {
                this.newTable = {
                    seats: 4,
                    type: 'round',
                    name: 'Tisch ' + (this.tables.length + 1),
                    color: '#6D7E6D'

                }
            }).catch((err) => {
                this.message = error(err)
            })
        })
    },
    methods: {
        getTableField(id, field){
            for(let table of this.tables){
                if(table.id === id){
                    return table[field]
                }
            }
        },
        createGuest() {
            this.$store.dispatch('seatPlan/createGuest', {
                seatPlanId: this.$route.params.id,
                name: this.newGuest.name,
                count: this.newGuest.count,
                tableId: this.newGuest.tableId,
            }).then(() => {
                this.message = success('Gast erstellt')
            }).catch((err) => {
                this.message = error(err)
            })
        },
        deleteGuest(id) {
            this.$store.dispatch('seatPlan/deleteGuest', {
                id,
                seatPlanId: this.$route.params.id
            }).then(() => {
                this.message = success('Gast gelöscht')
            }).catch((err) => {
                this.message = error(err)
            })
        },
        edit(valuesChanged){
            let guest = {}
            let id = ''
            for(let val of valuesChanged){
                switch(val.title){
                    case "Tisch":
                        guest.tableId = val.value
                        break;
                    case "Personenanzahl":
                        guest.count = val.value
                        break;
                    case "Name":
                        guest.name = val.value
                        break;
                    case "Gast ID":
                        id = val.value
                        break;
                }
            }
            this.$store.dispatch('seatPlan/updateGuest', {
                uid: this.user.id,
                id,
                guest,
                seatPlanId: this.$route.params.id,
            }).then(()=>{
                this.message = success('Gast erfolgreich gespeichert')
                this.dialogEdit = false
            }).catch((err)=>{
                this.message = error(err)

            })
        },
        startEdit(guest) {
            this.editFields = [
                {
                    title: 'Gast ID',
                    value: guest.id,
                    id: 0,
                    type: 'string',
                    readonly:true
                },
                {
                    title: 'Name',
                    value: guest.name,
                    id: 1,
                    type: 'string',
                },
                {
                    title: 'Personenanzahl',
                    value: guest.count,
                    id: 2,
                    type: 'number'
                },
                {
                    title: 'Tisch',
                    value: guest.tableId,
                    id: 3,
                    items: this.tablesSelection,
                    type: 'autoComplete',
                },

            ]
            this.dialogEdit = true
        },
    }


}
</script>

<style scoped>
.row {
    border-width: thin;
}

v-container {
    type: 150vh;
}
.customerRow {
    border-top: dotted 1px #F1E7E2;
    border-bottom: dotted 1px #F1E7E2;
    transition: 0.4s;
}


.customerRow:hover {
    transform: scale(1.01);
    transition: 0.4s;
    background-color: #F3F4F3 !important;
}

</style>
