<template>
  <v-container class="fadeIn">
    <HeadlineTableView
        :icon="'mdi-cogs'"
        :headline="'Einstellungen'"
    ></HeadlineTableView>
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <v-row justify="center">
      <v-expansion-panels accordion class="rounded-xl pa-8">
        <v-expansion-panel active>
          <v-expansion-panel-header><h3>Benutzereinstellungen</h3></v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-col cols="12" md="6">
              <!--<UploadPicture class="pb-16">
              </UploadPicture>-->
              <KeyValueBlock
                  :key-values="getUserSettings()"
                  @editEntry="editEntry"
              >
              </KeyValueBlock>
              <div class="row  pt-3">
                <div class="col-4">
                  Passwort ändern:
                </div>

                <div class="col-8">
                  <a @click="dialogPassword=true">Passwort ändern</a>
                </div>
                <ChangePassword :dialog="dialogPassword"
                                @hideDialog="dialogPassword=false"></ChangePassword>
              </div>
            </v-col>
          </v-expansion-panel-content>
        </v-expansion-panel>


        <v-expansion-panel>
          <v-expansion-panel-header><h3>Individuelle Felder</h3></v-expansion-panel-header>
          <v-expansion-panel-content>
            <CustomFieldSettings :user="user"></CustomFieldSettings>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header><h3>Rechnungen und Angebote</h3></v-expansion-panel-header>
          <v-expansion-panel-content>
            <InvoiceSettings :user="user"></InvoiceSettings>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header><h3>Status und Kategorien</h3></v-expansion-panel-header>
          <v-expansion-panel-content>
            <StatusAndFieldSettings :user="user"></StatusAndFieldSettings>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>Unternehmens
              <wbr>
              einstellungen
            </h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container>
              <KeyValueBlock
                  :key-values="getCompanySettings()"
                  @editEntry="editEntry">
              </KeyValueBlock>
              <v-row>
                <v-col cols="4">
                  Logo:
                </v-col>
                <v-col cols="8">
                  <v-img
                      :src="profilePic"
                      max width="350px"
                      class="d-inline-flex"
                  />
                  <br>
                  <v-btn
                      color="primary"
                      class=""
                      @click="onButtonClick"
                  >
                    <v-icon left>
                      mdi-upload
                    </v-icon>
                    Datei hochladen
                  </v-btn>
                  <br>
                  <v-btn
                      v-if="profilePic"
                      color="primary"
                      class="mt-2"
                      @click="deleteProfilePic"
                  >
                    <v-icon left>
                      mdi-cancel
                    </v-icon>
                    Bild löschen
                  </v-btn>
                </v-col>
              </v-row>

              <input
                  ref="uploader"
                  class="d-none"
                  type="file"
                  accept=""
                  @change="uploadPic"
              >
            </v-container>

          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>Partnermodule
            </h3>

          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col>
                <v-list two-line class="">
                  <v-list-item>
                    <v-list-item-icon>


                      <label class="switch">
                        <input
                            :checked="checked"
                            @change="changeInput"
                            @input="$emit('input', $event.target.checked)"
                            value="1" type="checkbox"
                        >
                        <span class="slider round"></span>
                      </label>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="">Earwig Vermittlung</v-list-item-title>
                      <small>
                        Professionelle Hochzeitsdienstleister in Deutschland finden und mit einem Klick importieren.
                      </small>

                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col>
                <v-list two-line class="">
                  <v-list-item>
                    <v-list-item-icon>
                      <label class="switch">
                        <input
                            :checked="checked"
                            @change="changeInput"
                            @input="$emit('input', $event.target.checked)"
                            value="1" type="checkbox"
                        >
                        <span class="slider round"></span>
                      </label>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="">
                        Voicemail With Love
                        <v-tooltip right color="primary" max-width="200px">
                          <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon>mdi-help</v-icon>
                      </span>
                          </template>
                          Buche Audio-Gästebücher für deine Hochzeiten.
                        </v-tooltip>
                      </v-list-item-title>
                      <small>
                        Buche Audio-Gästebücher für deine Hochzeiten.

                      </small>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col>
                <v-list two-line class="">
                  <v-list-item>
                    <v-list-item-icon>

                      <label class="switch">
                        <input
                            :checked="checked"
                            @change="changeInput"
                            @input="$emit('input', $event.target.checked)"
                            value="1" type="checkbox"
                        >
                        <span class="slider round"></span>
                      </label>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="">

                        Mein Lieblingsmoment

                        <v-tooltip right color="primary" max-width="200px">
                          <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon>mdi-help</v-icon>
                      </span>
                          </template>
                          Du kannst aus deinen Hochzeiten direkt eine digitale Einladungskarte in Auftrag geben.
                        </v-tooltip>
                      </v-list-item-title>
                      <small>Individuelle und digitale Einladungskarten erstellen lassen</small>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>

          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>
              Zahlung, Rechnungen und Abonnement
            </h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container>
              <v-row>
                <v-col>
                  <h4>Vertrag zur Auftragsverarbeitung</h4>
                  <a @click="$router.push('/avv/'+user.id)">Download PDF</a>
                </v-col>
              </v-row>
            </v-container>

            <v-container>
              <v-row>
                <v-col cols="12" md="8">
                  <h4>Limits</h4>
                  <h5>Speicherplatz</h5>
                  <v-progress-linear
                      height="8px"
                      rounded
                      color="primary"
                      :value="getPercent(bytesToGB(usedMemory),10)"
                      v-if="user.paymentPlan === 0 ||user.paymentPlan === 1"
                  ></v-progress-linear>
                  <v-progress-linear
                      height="8px"
                      rounded
                      color="primary"
                      :value="getPercent(bytesToGB(usedMemory),20)"
                      v-else
                  ></v-progress-linear>

                  {{ bytesToGB(usedMemory) }} GB
                  /
                  <span v-if="user.paymentPlan === 0||user.paymentPlan === 1">10 GB</span>
                  <span v-if="user.paymentPlan === 2">20 GB</span>

                </v-col>
              </v-row>
            </v-container>

            <v-container>
              <div v-if="user.paymentPlan === 0">
                <p>Deine Testphase <b>{{ getDays(user.created) }}</b>. Wähle jetzt einen Plan, um
                  direkt weiter planen zu können.</p>
                <v-btn class="primary" @click="$router.push('/testphase-abgelaufen')">Plan wählen
                </v-btn>
              </div>
              <div v-if="user.paymentPlan === 1">
                <div v-if="user.stripeId !== ''">
                  <p>Du nutzt den <b> Essential Plan</b>.</p>
                  <v-btn class="primary" @click="goToPortal()">Abonement verwalten</v-btn>
                </div>
                <div v-else>
                  Dein Konto wird gerade eingerichtet. Schaue in ein paar Tagen wieder vorbei, um
                  deine
                  Zahlungseinstellungen selbst verwalten zu können.
                </div>
              </div>
              <div v-if="user.paymentPlan === 2">
                <div v-if="user.stripeId !== ''">
                  <p>Du nutzt den <b> Premium Plan</b>.</p>
                  <v-btn class="primary" @click="goToPortal()">Abonement verwalten</v-btn>
                </div>
                <div v-else>
                  Dein Konto wird gerade eingerichtet. Schaue in ein paar Tagen wieder vorbei, um
                  deine
                  Zahlungseinstellungen selbst verwalten zu können.
                </div>
              </div>
            </v-container>
            <v-dialog
                v-model="dialogPortal"
                persistent
                max-width="700px"
            >
              <v-card class="pa-16 text-center">
                <h2>Wir leiten dich nun zum Zahlungsdienstleister weiter. Du kannst dort deine Rechnungen einsehen und
                  dein Abonnement verwalten.</h2>
                <v-img
                    :src="require('@/assets/images/drawSVGs/stripePay.svg')"
                    max-width="200px"
                    class="d-inline-flex mt-3"
                />
                <v-card-text>
                  <v-progress-linear
                      indeterminate
                      height="8px"
                      rounded
                      color="primary"
                      class="mt-3"
                  ></v-progress-linear>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>
              Account
            </h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container>
              <v-row>
                <v-col cols="4">
                  Account löschen:
                </v-col>
                <v-col cols="8">
                  <a @click="startDeleteAcc=true" style="color:lightcoral">Account endgültig
                    löschen</a>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

    </v-row>
    <v-row class="pa-5">
      <v-col class="col-lg-6 offset-lg-3">

        <v-card class="pb-3 rounded-xl pt-3" flat
                style="background-color: #FFF">
          <v-row>
            <v-col cols="8">
              <v-card-title>
                <h5>Freunde und Kollegen einladen und 20€ Prämie sichern!</h5>
              </v-card-title>
              <v-card-text>
                Für jede erfolgreiche Einladung (3 Monate bezahltes Abo) erhältst du 20€ Gutschrift. Die eingeladene
                Person erhält einen Rabatt für die ersten 3 Monate oder das Jahresabonnement.<br> <br>
                <span class="primary--text text-subtitle-2">
                      DEIN PERSÖNLICHER CODE:
                    </span>
                <br>
                <div v-if="user.affiliateCode">
                  <span class="primary--text text-subtitle-1 ">{{ user.affiliateCode }}</span>
                  <div class="pt-3">
                    Teilen:
                    <a class="text-primary ps-1"
                       :href="'whatsapp://send?text=Mit dem Code '+user.affiliateCode+' erhältst du 15% Rabatt auf deine ersten 3 Monate oder das Jahresabonnement von Wedding-Flow.'">
                      <v-icon class="text-primary">
                        mdi-whatsapp
                      </v-icon>
                    </a>

                    <a class="ps-3 text-primary"
                       :href="'mailto:?body=Mit dem Code '+user.affiliateCode+' erhältst du 15% Rabatt auf deine ersten 3 Monate oder das Jahresabonnement von Wedding-Flow.&subject=Einladung zu Wedding-Flow'">
                      <v-icon class="text-primary">
                        mdi-mail
                      </v-icon>
                    </a>
                  </div>

                </div>
                <small v-else>Hier wirst du in einigen Tagen deinen persönlichen Rabattcode finden. Wenn du bereits
                  Einladungen versenden möchtest setze dich gerne mit uns in Kontakt.</small>

              </v-card-text>
            </v-col>
            <v-col cols="4" class="align-self-center">
              <v-img
                  :src="require('@/assets/images/drawSVGs/shareLove.svg')"
                  width="100px">
              </v-img>
            </v-col>
          </v-row>
        </v-card>

      </v-col>


    </v-row>
    <v-dialog
        v-model="startDeleteAcc"
        persistent
        :max-width="800"
    >
      <v-card class="rounded-xl pa-4">
        <v-card-title class="text-h5 " id="title">
          ACHTUNG: Account löschen
        </v-card-title>
        <v-card-text>
          <h4>
            Sind Sie sicher, dass Sie Ihren Account und ALLE dazugehörigen Daten UNWIDERRUFLICH löschen
            möchten?
          </h4>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="startDeleteAcc = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="error"
              @click="sureToDeleteAccount()"
          >
            Fortfahren
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="sureToDeleteAcc"
        persistent
        :max-width="800"
    >
      <v-card class="rounded-xl pa-4">
        <v-card-title class="text-h5 " id="title">
          ACHTUNG: Account löschen
        </v-card-title>
        <v-card-text>
          <h4>
            Mit dem Fortfahren werden folgende Daten unwiderruflich gelöscht. Sind Sie sicher, dass Sie
            fortfahren möchten?
          </h4>
          <Spinner v-if="!deleteAccLoaded">

          </Spinner>
          <div v-else>
            <v-container v-if="!isDeleting">
              <v-row>
                <v-col md="4" sm="6" cols="8">
                  Kunden / Brautpaare:
                </v-col>
                <v-col md="8" sm="6" col="4">
                  {{ count.customers }}
                </v-col>
              </v-row>
              <v-row>
                <v-col md="4" sm="6" cols="8">
                  Hochzeiten:
                </v-col>
                <v-col md="8" sm="6" col="4">
                  {{ count.weddings }}
                </v-col>
              </v-row>
              <v-row>
                <v-col md="4" sm="6" cols="8">
                  Dienstleister:
                </v-col>
                <v-col md="8" sm="6" col="4">
                  {{ count.vendor }}
                </v-col>
              </v-row>
              <v-row>
                <v-col md="4" sm="6" cols="8">
                  Locations:
                </v-col>
                <v-col md="8" sm="6" col="4">
                  {{ count.location }}
                </v-col>
              </v-row>
              <v-row>
                <v-col md="4" sm="6" cols="8">
                  Events:
                </v-col>
                <v-col md="8" sm="6" col="4">
                  {{ count.events }}
                </v-col>
              </v-row>
              <v-row>
                <v-col md="4" sm="6" cols="8">
                  Aufgaben:
                </v-col>
                <v-col md="8" sm="6" col="4">
                  {{ count.tasks }}
                </v-col>
              </v-row>

              <v-row>
                <v-col md="4" sm="6" cols="8">
                  Kontakt Personen:
                </v-col>
                <v-col md="8" sm="6" col="4">
                  {{ count.contactPerson }}
                </v-col>
              </v-row>
              <v-row>
                <v-col md="4" sm="6" cols="8">
                  Budgetpläne:
                </v-col>
                <v-col md="8" sm="6" col="4">
                  {{ count.budgetPlan }}
                </v-col>
              </v-row>
              <v-row>
                <v-col md="4" sm="6" cols="8">
                  Tagesabläufe:
                </v-col>
                <v-col md="8" sm="6" col="4">
                  {{ count.timePlan }}
                </v-col>
              </v-row>

              <v-row>
                <v-col md="4" sm="6" cols="8">
                  Templates:
                </v-col>
                <v-col md="8" sm="6" col="4">
                  {{ count.template }}
                </v-col>
              </v-row>
              <v-row>
                <v-col md="4" sm="6" cols="8">
                  Rechnungen und Angebote:
                </v-col>
                <v-col md="8" sm="6" col="4">
                  {{ count.invoice }}
                </v-col>
              </v-row>

            </v-container>
            <v-container v-else>
              {{ deleted }} / {{ progressed }} (Errors: {{ errors }})
              <v-progress-linear active>

              </v-progress-linear>
            </v-container>
            <v-checkbox label="Ich bestätige, dass die Daten UNWIDERRUFLICH gelöscht werden."
                        v-model="sureToDeleteAccBool" v-if="!isDeleting"></v-checkbox>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="sureToDeleteAcc = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="error"
              v-if="sureToDeleteAccBool && deleteAccLoaded"
              @click="deleteAccount()"
          >
            Fortfahren
          </v-btn>
          <v-btn
              color="error"
              v-else
              disabled
          >
            Fortfahren
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>

import {mapGetters} from "vuex";
import {msgObj, error, success} from "@/helper/helper";

const Spinner = () => import("@/components/generalUI/Spinner");
const Message = () => import("@/components/generalUI/Message");
const HeadlineTableView = () => import("@/components/tableView/HeadlineTableView");
const KeyValueBlock = () => import("@/components/detailView/KeyValueBlock");
const StatusAndFieldSettings = () => import("@/components/entities/Settings/statusAndFieldSettings/StatusAndFieldSettings");
const CustomFieldSettings = () => import("@/components/entities/Settings/CustomFieldSettings");
const ChangePassword = () => import("@/components/entities/Settings/ChangePassword");
const InvoiceSettings = () => import("@/components/entities/Settings/InvoiceSettings");


export default {
  name: "Dashboard",
  components: {
    KeyValueBlock,
    HeadlineTableView,
    Message,
    StatusAndFieldSettings,
    CustomFieldSettings,
    ChangePassword,
    Spinner,
    InvoiceSettings
  },

  computed: {
    ...mapGetters('auth', {
      user: 'user',
    }),
    ...mapGetters('payment', {
      portalUrl: 'portalUrl',
    }),
    ...mapGetters('file', {
      usedMemory: 'memoryUsed',
      profilePic: 'profilePic'
    }),
    ...mapGetters('deleteAccount', {
      count: 'count',
      deleted: 'deleted',
      progressed: 'progressed',
      errors: 'errors',
    }),
  },
  data() {
    return {
      message: msgObj(),
      dialogPassword: false,
      upload: null,
      startDeleteAcc: false,
      deleteAccLoaded: false,
      isDeleting: false,
      sureToDeleteAcc: false,
      sureToDeleteAccBool: false,
      dialogPortal: false,
      checked: this.value

    }
  },
  watch: {
    checked() {
      this.$emit('input', this.checked)
    }
  },
  mounted() {
    this.$store.dispatch('auth/checkLogin', {}).then((res) => {
      this.$store.dispatch('file/getMemoryUsed', {
        uid: res,
      }).catch((err) => {
        this.message = error(err)
      })
      this.$store.dispatch('file/getProfilePic', {
        uid: res,
      }).catch((err) => {
        this.message = error(err)
      })
    })
  },
  methods: {
    goToPortal() {
      this.dialogPortal = true
      this.$store.dispatch('payment/getPortalForUser', {
        stripeId: this.user.stripeId,
      }).catch((err) => {
        this.message = error(err)
      }).then((url) => {
        window.open(url, '_blank').focus();
      }).finally(() => {
        this.dialogPortal = false
      })
    },
    onButtonClick() {
      this.isSelecting = true
      window.addEventListener('focus', () => {
        this.isSelecting = false
      }, {once: true})
      this.$refs.uploader.click()
    },
    deleteProfilePic() {
      this.$store.dispatch('file/deleteProfilePic').then(() => {
      }).catch((err) => {
        this.message = error(err)
      }).then(() => {
        this.message = success('Bild wurde erfolgreich gelöscht.')
        this.$store.dispatch('file/getProfilePic').then(() => {
        }).catch((err) => {
          this.message = error(err)
        })
      })
    },
    sureToDeleteAccount() {
      this.startDeleteAcc = false
      this.sureToDeleteAcc = true
      this.$store.dispatch('deleteAccount/getUserData', {uid: this.user.id}).catch((err) => {
        this.startDeleteAcc = false
        this.sureToDeleteAcc = false
        this.message = error(err)
      }).then(() => {
        this.deleteAccLoaded = true
      })
    },
    deleteAccount() {
      this.isDeleting = true
      this.$store.dispatch('support/saveBugReport', {
        bug: false,
        subject: 'Account gelöscht',
        optimizing: false,
        uid: this.user.id,
        description: 'Account gelöscht',
        email: 'Account gelöscht',
        solved: false
      }).then(() => {
        this.$store.dispatch('deleteAccount/deleteAccount', {uid: this.user.id})
            .then(() => {
              this.$store.dispatch('auth/signedOut', {uid: this.user.id}).then(() => {
              }).catch((err) => {
                this.message = error(err)
              })
            }).catch((err) => {
          this.message = error(err)
        })
      }).catch((err) => {
        this.message = error(err)
      })
    },
    uploadPic(e) {
      let file = e.target.files[0]
      if (file.size < 5097152) {
        this.$store.dispatch('file/createOrUpdateProfilePic', {file, uid: this.user.id}).then(() => {
        }).catch((err) => {
          this.message = error(err)
        }).then(() => {
          this.message = success('Datei wurde erfolgreich hochgeladen.')
          this.$store.dispatch('file/getProfilePic').then(() => {
          }).catch((err) => {
            this.message = error(err)
          })
        })
      } else {
        this.message = error('Datei darf maximal 5MB haben.')
      }

    },
    goTo(url) {
      window.location.href = url
    },
    editEntry(payload) {
      this.$store.dispatch('customer/editField', {
        entity: "planner",
        id: this.user.id,
        newValue: payload.newValue,
        row: payload.row
      }).catch((err) => {
        this.message = error(err)
      })
    },
    getPercent(value, total) {
      return (value / total) * 100
    },
    bytesToGB(byte) {
      return (byte / (1000 * 1000 * 1000)).toFixed(2);
    },
    changeInput() {
      this.checked = !this.checked
      this.$emit('input', !this.value)
    },
    getDays(date) {
      let number = 14 - Math.ceil((Date.now() - date) / (1000 * 3600 * 24));
      if (number === 1) {
        return 'läuft in einem Tag ab'
      } else if (number === 0) {
        return 'läuft heute ab'
      } else if (number < 0) {
        return 'ist abgelaufen'
      } else {
        return 'läuft in ' + number + ' Tagen ab'
      }
    },
    getUserSettings() {
      return [
        {
          title: 'Vorname',
          value: this.user.firstname,
          editBtn: {
            icon: 'mdi-check-circle',
            entity: 'settings',
            row: 'firstname'
          }
        },
        {
          title: 'Nachname',
          value: this.user.lastname,
          editBtn: {
            icon: 'mdi-check-circle',
            entity: 'settings',
            row: 'lastname'
          }
        },
      ]
    },
    getCompanySettings() {
      return [
        {
          title: 'Name',
          value: this.user.companyName,
          editBtn: {
            icon: 'mdi-check-circle',
            entity: 'settings',
            row: 'companyName'
          }
        },
      ]
    }
  }
}
</script>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 22px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  -webkit-transition: .7s;
  transition: .7s;
  border: 1px solid #ffffff;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: -3px;
  bottom: -2px;
  background-color: grey;
  -webkit-transition: .4s;
  transition: .4s;
  border: solid 1.5px #fff;
}

input:checked + .slider {
  background-color: transparent;
}

input:focus + .slider {
  box-shadow: 0 0 1px #6D7E6D;
}

input:checked + .slider:before {
  -webkit-transform: translateX(31px);
  -ms-transform: translateX(31px);
  transform: translateX(15px);
  background: #6D7E6D;
  -webkit-transition: .7s;
  transition: .7s;
  border: solid 2px #fff;
}

.slider.round {
  border-radius: 24px;
  border-color: #6D7E6D;
}

.slider.round:before {
  border-radius: 50%;
  border-color: grey;
}
</style>
