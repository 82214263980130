<template>
  <div>
    <carousel
        v-if="!changeViewWeddings"
        class="ms-8 me-8"
        :perPageCustom="[[1,1],[450,1],[768, 2], [1280, 3]]"
        :navigationEnabled="true"
        :paginationActiveColor="'#6D7E6D'"
        :paginationColor="'#B19983'"
    >
      <slide
          v-for="wedding of weddings"
          :key="wedding.id"
          class="pa-2 pb-8">
        <WeddingSnippet
            style="min-height: 200px"
            :element="wedding"
            :weddingLabels="weddingLabels"
        ></WeddingSnippet>
      </slide>
    </carousel>
    <v-row v-else>
      <v-col  v-for="wedding of weddings"
              :key="wedding.id" cols="12" md="6" lg="4">
        <WeddingSnippet
            style="min-height: 200px"
            :element="wedding"
            :weddingLabels="weddingLabels"
        ></WeddingSnippet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {Carousel, Slide} from 'vue-carousel';
import {date2String, error} from "@/helper/helper";
import {mapGetters} from "vuex";
const WeddingSnippet = () => import("@/components/entities/wedding/WeddingSnippet");


export default {
  name: "Message",
  components:{
    Carousel,
    Slide,
    WeddingSnippet
  },
  props: {
    /**
     * Contains data to display the alert accordingly
     */
    weddings: {
      type: Array,
      default(){
        return []
      }
    },
    changeViewWeddings: Boolean
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user',
    }),
    ...mapGetters('settings', {
      weddingLabels: 'weddingLabels',
    }),
  },
  mounted() {
    this.$store.dispatch('auth/checkLogin', {}).then((res) => {

      this.$store.dispatch('settings/getWeddingLabels', {
        uid: res,
      }).catch((err) => {
        this.message = error(err)
      }).then(() => {
        this.loaded = true
      })
    })
  },
  methods:{
    openWedding(id){
      this.$emit('openWedding', id)
    },
    getStringDate(date) {
      return date2String(date)
    },
  }
}
</script>
