<template>
    <div class="pa-3">
        <Message
                :message="message"
                @closeAlert="message.show = false"
        ></Message>
        <v-container v-if="loaded" class="fadeIn">
            <Tour v-if="loadedTour"></Tour>
            <WelcomeWidget
                    v-if="$route.query.newAccount"
                    :dialog="dialogWelcome"
                    @hideDialog="dialogWelcome = false"
                    @startTour="dialogWelcome = false;loadedTour = true"
                    :userName="user.firstname"
            ></WelcomeWidget>

            <v-row>
                <v-col cols="12" lg="12" md="12" sm="12" class="ps-0 pe-3">
                    <v-card flat style="background-color: #F3F4F3" class="rounded-xl pa-md-12 pa-8" id="welcomeBack">
                        <v-row class="align-center">
                            <v-col cols="12" md="8" class="pe-lg-16">
                                <v-card-title class="pb-0 ps-0 ms-0">
                                    <h4 class="primary--text">Willkommen zurück!</h4>
                                </v-card-title>
                                <v-card-title class="pt-0">
                                    <h6 v-if="notifications.length > 0">Du hast {{ countUnread }} neue
                                        Benachrichtigungen</h6>
                                </v-card-title>
                                <v-list v-if="notifications.length > 0" style="background-color: #F3F4F3">
                                    <a v-for="notification in notifications" :key="notification.id">
                                        <v-list-item
                                                @click="$router.push('/notifications/notification/'+notification.id);menu = false">
                                            <v-list-item-action>
                                                <v-icon>{{ notification.icon }}</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-title>{{ notification.title }}</v-list-item-title>
                                            <v-list-item-action>
                                                <v-icon v-if="notification.link">mdi-link</v-icon>
                                            </v-list-item-action>
                                            <v-list-item-action-text>
                                                {{ getStringDate(notification.created) }}
                                            </v-list-item-action-text>
                                        </v-list-item>
                                    </a>
                                </v-list>
                                <v-list style="background-color: #F3F4F3" v-else>
                                    <v-list-item style="background-color: #F3F4F3">
                                        <v-list-item-title>Keine neuen Benachrichtigungen</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                                <div class="pe-3 pt-2">
                                    <a @click="$router.push('/notifications')">Alle Benachrichtigungen </a>
                                </div>
                            </v-col>
                            <v-col cols="12" md="4" class="text-center">
                                <v-img
                                        v-if="notifications.length < 1"
                                        :src="require('@/assets/images/drawSVGs/allDone.svg')"
                                        max-width="150px"
                                        class="d-inline-flex"
                                />
                                <v-img
                                        v-else
                                        :src="require('@/assets/images/drawSVGs/mailbox.svg')"
                                        max-width="150px"
                                        class="d-inline-flex"
                                        style="transform: scaleX(-1);"/>
                            </v-col>
                        </v-row>

                    </v-card>
                </v-col>
                <!--
                <v-col cols="12" lg="5" md="6" sm="6" class="ps-0 pe-0">
                  <v-card flat style="background-color: #F3F4F3" class="rounded-xl pa-8" id="firstSteps">
                    <v-row class="align-center">
                      <v-col cols="12" class="">
                        <v-card-title class="pb-0 ps-0 ms-0">
                          <h4 class="primary--text">Erste Schritte</h4>
                        </v-card-title>
                        <v-card-title class="pt-0">
                        </v-card-title>
                        <v-list style="background-color: #F3F4F3" >
                          <a v-for="firstStep in firstSteps" :key="firstStep.id">
                            <v-list-item class="ps-0">
                              <v-list-item-action>
                                <v-icon v-if="firstStep.done" :value="true">mdi-check</v-icon>
                                <v-checkbox readonly v-else></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-title v-if="firstStep.done"><s>{{ firstStep.title }}</s></v-list-item-title>
                              <v-list-item-title v-else>{{ firstStep.title }}</v-list-item-title>
                              <v-list-item-action-text>
                                <v-icon>{{ firstStep.icon }}</v-icon>
                              </v-list-item-action-text>
                            </v-list-item>
                          </a>
                        </v-list>
                      </v-col>
                    </v-row>
                    <v-progress-linear rounded height="20px" value="20" class="white--text" background-color="#555555">1 / 5 Schritten erledigt (20%)</v-progress-linear>
                  </v-card>
                </v-col>-->
            </v-row>
            <v-row>
                <v-col cols="12" md="7" class="pe-3 pe-md-6">
                    <v-row class="align-content-center">
                        <v-card flat class="rounded-xl pa-2" style="width: 100%" id="upcomingWeddings">
                            <v-row>
                                <v-col cols="10" class="pa-6">
                                    <h2>Anstehende Hochzeiten ({{sortedWedding.length}})</h2>
                                </v-col>
                                <v-col cols="2" class="pa-6">
                                    <a @click="changeViewWeddings = !changeViewWeddings">
                                        <h2 class="d-inline">
                                            <v-icon >mdi
                                                mdi-view-grid-outline
                                            </v-icon>
                                        </h2>
                                        <small class="ps-1 d-inline">Ansicht </small>
                                    </a>
                                </v-col>
                            </v-row>
                            <v-col>
                                <Carousel :weddings="sortedWedding" :changeViewWeddings="changeViewWeddings"
                                          @openWedding="openWedding"></Carousel>
                            </v-col>
                        </v-card>

                        <v-col cols="12" class="pt-6 pe-0 ps-0 ">
                            <v-card flat class="pa-6 rounded-xl " id="openTasks">
                                <h2>
                                    Offene Aufgaben ({{ getUndoneTasks(tasks).length }})
                                </h2>
                                <VirtualScrollTasks
                                        v-if="loaded"
                                        :items="getUndoneTasks(tasks)"
                                        :noResultsText="'Du hast aktuell keine offenen Aufgaben'"
                                        @openEntry="openTask($event)"
                                ></VirtualScrollTasks>
                                <Spinner v-else></Spinner>
                            </v-card>
                        </v-col>

                    </v-row>
                </v-col>
                <v-col cols="12" md="5" class="pt-0 ps-0 pe-0">
                    <v-card flat class="pa-6 rounded-xl " id="upcomingEvents">

                        <h3 class="text-h5">Anstehende Termine</h3>
                        <VirtualScrollEvents
                                v-if="eventsLoaded && events.length >0"
                                :items="events"
                                :noResultsText="'Es gibt keine anstehenden Termine'"
                        ></VirtualScrollEvents>
                        <div v-else-if="eventsLoaded && events.length <1">Es gibt keine anstehenden Termine</div>
                        <Spinner v-else></Spinner>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <Spinner v-else></Spinner>
    </div>
</template>

<script>

import {mapGetters} from "vuex";
import {error, msgObj} from "@/helper/helper";

const VirtualScrollTasks = () => import("@/components/entities/Task/VirtualScrollTasks");
const VirtualScrollEvents = () => import("@/components/entities/calendar/VirtualScrollEvents");
const Spinner = () => import("@/components/generalUI/Spinner");
const Message = () => import("@/components/generalUI/Message");
const Tour = () => import("@/components/entities/Tour/DashboardTour");
const WelcomeWidget = () => import("@/components/entities/Tour/WelcomeWidget");
const Carousel = () => import("@/components/entities/wedding/WeddingCarousel");

export default {
    name: "Dashboard",
    components: {
        WelcomeWidget,
        VirtualScrollTasks,
        VirtualScrollEvents,
        Spinner,
        Carousel,
        Message,
        Tour,

    },
    computed: {
        ...mapGetters('auth', {
            user: 'user',
        }),
        ...mapGetters('wedding', {
            weddings: 'weddings',
        }),
        ...mapGetters('task', {
            tasks: 'tasks',
        }),
        ...mapGetters('calendar', {
            events: 'upcomingEvents',
        }),
        ...mapGetters('notification', {
            notifications: 'notificationsUnRead',
            countUnread: 'countUnread'
        }),
    },
    mounted() {
        window.scrollTo(0, 0)
        this.getDashboardData()
        if (this.$route.query.startTour) {
            this.loadedTour = true
        }
    },
    data: () => ({
        eventsLoaded: false,
        dialogWelcome: true,
        loaded: false,
        loadedTour: false,
        tab: null,
        text: 'center',
        width: 2,
        message: msgObj(),
        changeViewWeddings: false,
        sortedWedding: [],
        firstSteps: [
            {title: '1. Erstelle einen Dienstleister', icon: 'mdi-account', done: true},
            {title: '2. Erstelle eine Location', icon: 'mdi-location', done: false},
            {title: '3. Lege einen Kunden an', icon: 'mdi-account', done: false},
            {title: '4. Erstelle eine Hochzeit zu einem Kunden', icon: 'mdi-account', done: false},
            {title: '5. Ersten Dienstleister erstellen', icon: 'mdi-account', done: false},
        ]
    }),
    methods: {
        sortByDate() {
            this.$store.dispatch('sort/sort', {
                objectArray: this.events,
                sortField: 'start',
                sortStyle: 'ASC'
            }).catch((err) => {
                this.message = error(err)
            })
        },
        getDashboardData() {
            this.$store.dispatch('auth/checkLogin').then((uid) => {
                this.$store.dispatch('wedding/setWeddingsEmpty').then(() => {
                    this.$store.dispatch('wedding/getWeddings', {
                        uid
                    }).then((weddings) => {
                        setTimeout(()=>{
                            this.getWeddings(weddings, 'open')
                            this.loaded = true
                            this.$store.dispatch('task/setTasksEmpty').then(() => {
                                this.$store.dispatch('task/getTasks', {
                                    uid
                                }).then(() => {
                                    if (this.tasks.length > 0) {
                                        this.$store.dispatch('sort/sort', {
                                            objectArray: this.tasks,
                                            sortField: 'prio',
                                            sortStyle: 'ASC'
                                        }).catch((err) => {
                                            this.message = error(err)
                                        })
                                    }
                                    this.$store.dispatch('calendar/getEvents', {
                                        uid
                                    }).then(() => {
                                        if (this.events.length > 0) {
                                            setTimeout(() => {
                                                this.sortByDate()
                                                this.eventsLoaded = true
                                            }, 1000);
                                        }else{
                                            this.eventsLoaded = true
                                        }
                                    }).catch((err) => {
                                        this.message = error(err)
                                        this.eventsLoaded = true
                                    });
                                }).catch((err) => {
                                    this.message = error(err)
                                })
                            }).catch((err) => {
                                this.message = error(err)
                            })
                        },1000)
                    }).catch((err) => {
                        this.message = error(err)
                    })
                }).catch((err) => {
                    this.message = error(err)
                })
                this.$store.dispatch('notification/getNotifications', {
                    uid,
                    onlyUnread: true,
                    limit: 2
                }).catch((err) => {
                    this.message = error(err)
                })
            })
        },
        openWedding(id) {
            this.$router.push('/weddings/wedding/' + id)
        },
        getWeddings(weddings, status) {
            let specificWeddings = []
            for (let wedding of weddings) {
                if (status === 'Abgeschlossen') {
                    if (wedding.status === status) {
                        specificWeddings.push(wedding)
                    }
                } else {
                    if (wedding.status !== 'Abgeschlossen') {
                        specificWeddings.push(wedding)
                    }
                }
            }
            if(weddings.length >0){
                this.$store.dispatch('sort/sort', {
                    sortStyle: 'ASC',
                    sortField: 'wedDate',
                    objectArray: specificWeddings,
                })
                this.sortedWedding = specificWeddings
            }else{
                this.sortedWedding = []
            }
        },
        getUndoneTasks(tasks) {
            let undoneTasks = []
            for (let task of tasks) {
                if (task.status !== "Erledigt") {
                    undoneTasks.push(task)
                }
            }
            return undoneTasks
        },
        getStringDate(date) {
            if (date !== null) {
                const newDate = new Date(date)
                let options = {year: 'numeric', month: 'numeric', day: 'numeric'}
                return newDate.toLocaleDateString('de-DE', options)
            } else {
                return ""
            }
        },
        testMessage() {
            this.$store.dispatch('notification/createNotification', {
                uid: this.user.id,
                notification: {
                    title: 'test',
                    text: 'test sad asd ad',
                    icon: 'mdi-rocket',
                }
            }).catch((err) => {
                this.message = error(err)
            })
        }
    }
}
</script>

<style lang="sass" scoped>

.v-card.on-hover.theme--dark
  background-color: rgba(#FFF, 0.8)

  > .v-card__text
    color: #000
</style>
<style scoped>
.border {
    border-right: 1px solid grey
}

.backGroundGrey {
    background-color: #F5F5F5;
}

</style>
