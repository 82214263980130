
export function error(msg) {
    setTimeout(() => this.message.show = false, 5000);
    return {
        show: true,
        text: msg,
        color: "error",
    }
}
export function getAllCountries(){
    return [
        {text: 'DE | Deutschland', value: 'DE'},
        {text: 'AT | Österreich', value: 'AT'},
        {text: 'CH | Schweiz', value: 'CH'},
        {text: 'FR | Frankreich', value: 'FR'},
        {text: 'GR | Griechenland', value: 'GR'},
        {text: 'IT | Italien', value: 'IT'},
        {text: 'NL | Niederlande', value: 'NL'},
        {text: 'US | Vereinigte Staaten', value: 'US'},

        {text: 'AD | Andorra', value: 'AD'},
        {text: 'AE | Vereinigte Arabische Emirate', value: 'AE'},
        {text: 'AF | Afghanistan', value: 'AF'},
        {text: 'AG | Antigua und Barbuda', value: 'AG'},
        {text: 'AI | Anguilla', value: 'AI'},
        {text: 'AL | Albanien', value: 'AL'},
        {text: 'AM | Armenien', value: 'AM'},
        {text: 'AO | Angola', value: 'AO'},
        {text: 'AR | Argentinien', value: 'AR'},
        {text: 'AU | Australien', value: 'AU'},
        {text: 'AZ | Aserbaidschan', value: 'AZ'},
        {text: 'BA | Bosnien und Herzegowina', value: 'BA'},
        {text: 'BB | Barbados', value: 'BB'},
        {text: 'BD | Bangladesch', value: 'BD'},
        {text: 'BE | Belgien', value: 'BE'},
        {text: 'BF | Burkina Faso', value: 'BF'},
        {text: 'BG | Bulgarien', value: 'BG'},
        {text: 'BH | Bahrain', value: 'BH'},
        {text: 'BI | Burundi', value: 'BI'},
        {text: 'BJ | Benin', value: 'BJ'},
        {text: 'BM | Bermuda', value: 'BM'},
        {text: 'BN | Brunei', value: 'BN'},
        {text: 'BO | Bolivien', value: 'BO'},
        {text: 'BR | Brasilien', value: 'BR'},
        {text: 'BS | Bahamas', value: 'BS'},
        {text: 'BT | Bhutan', value: 'BT'},
        {text: 'BW | Botswana', value: 'BW'},
        {text: 'BY | Weißrussland', value: 'BY'},
        {text: 'BZ | Belize', value: 'BZ'},
        {text: 'CA | Kanada', value: 'CA'},
        {text: 'CD | Demokratische Republik Kongo', value: 'CD'},
        {text: 'CF | Zentralafrikanische Republik', value: 'CF'},
        {text: 'CG | Kongo', value: 'CG'},
        {text: 'CI | Elfenbeinküste', value: 'CI'},
        {text: 'CL | Chile', value: 'CL'},
        {text: 'CM | Kamerun', value: 'CM'},
        {text: 'CN | China', value: 'CN'},
        {text: 'CO | Kolumbien', value: 'CO'},
        {text: 'CR | Costa Rica', value: 'CR'},
        {text: 'CU | Kuba', value: 'CU'},
        {text: 'CV | Kap Verde', value: 'CV'},
        {text: 'CY | Zypern', value: 'CY'},
        {text: 'CZ | Tschechien', value: 'CZ'},
        {text: 'DJ | Dschibuti', value: 'DJ'},
        {text: 'DM | Dominica', value: 'DM'},
        {text: 'DK | Dänemark', value: 'DK'},
        {text: 'DO | Dominikanische Republik', value: 'DO'},
        {text: 'DZ | Algerien', value: 'DZ'},
        {text: 'EC | Ecuador', value: 'EC'},
        {text: 'EC | Ecuador', value: 'EC'},
        {text: 'EE | Estland', value: 'EE'},
        {text: 'EG | Ägypten', value: 'EG'},
        {text: 'ER | Eritrea', value: 'ER'},
        {text: 'ES | Spanien', value: 'ES'},
        {text: 'ET | Äthiopien', value: 'ET'},
        {text: 'FI | Finnland', value: 'FI'},
        {text: 'FJ | Fidschi', value: 'FJ'},
        {text: 'FK | Falklandinseln', value: 'FK'},
        {text: 'FM | Mikronesien', value: 'FM'},
        {text: 'FO | Färöer', value: 'FO'},
        {text: 'GA | Gabun', value: 'GA'},
        {text: 'GB | Vereinigtes Königreich', value: 'GB'},
        {text: 'GD | Grenada', value: 'GD'},
        {text: 'GE | Georgien', value: 'GE'},
        {text: 'GF | Französisch-Guayana', value: 'GF'},
        {text: 'GG | Guernsey', value: 'GG'},
        {text: 'GH | Ghana', value: 'GH'},
        {text: 'GI | Gibraltar', value: 'GI'},
        {text: 'GL | Grönland', value: 'GL'},
        {text: 'GM | Gambia', value: 'GM'},
        {text: 'GN | Guinea', value: 'GN'},
        {text: 'GP | Guadeloupe', value: 'GP'},
        {text: 'GQ | Äquatorialguinea', value: 'GQ'},
        {text: 'GS | Südgeorgien und die Südlichen Sandwichinseln', value: 'GS'},
        {text: 'GT | Guatemala', value: 'GT'},
        {text: 'GU | Guam', value: 'GU'},
        {text: 'GW | Guinea-Bissau', value: 'GW'},
        {text: 'GY | Guyana', value: 'GY'},
        {text: 'HK | Hongkong', value: 'HK'},
        {text: 'HN | Honduras', value: 'HN'},
        {text: 'HR | Kroatien', value: 'HR'},
        {text: 'HT | Haiti', value: 'HT'},
        {text: 'HU | Ungarn', value: 'HU'},
        {text: 'ID | Indonesien', value: 'ID'},
        {text: 'IE | Irland', value: 'IE'},
        {text: 'IL | Israel', value: 'IL'},
        {text: 'IM | Isle of Man', value: 'IM'},
        {text: 'IN | Indien', value: 'IN'},
        {text: 'IO | Britisches Territorium im Indischen Ozean', value: 'IO'},
        {text: 'IQ | Irak', value: 'IQ'},
        {text: 'IR | Iran', value: 'IR'},
        {text: 'IS | Island', value: 'IS'},
        {text: 'JE | Jersey', value: 'JE'},
        {text: 'JM | Jamaika', value: 'JM'},
        {text: 'JO | Jordanien', value: 'JO'},
        {text: 'JP | Japan', value: 'JP'},
        {text: 'KE | Kenia', value: 'KE'},
        {text: 'KG | Kirgisistan', value: 'KG'},
        {text: 'KH | Kambodscha', value: 'KH'},
        {text: 'KI | Kiribati', value: 'KI'},
        {text: 'KM | Komoren', value: 'KM'},
        {text: 'KN | St. Kitts und Nevis', value: 'KN'},
        {text: 'KP | Nordkorea', value: 'KP'},
        {text: 'KR | Südkorea', value: 'KR'},
        {text: 'KW | Kuwait', value: 'KW'},
        {text: 'KY | Kaimaninseln', value: 'KY'},
        {text: 'KZ | Kasachstan', value: 'KZ'},
        {text: 'LA | Laos', value: 'LA'},
        {text: 'LB | Libanon', value: 'LB'},
        {text: 'LC | St. Lucia', value: 'LC'},
        {text: 'LI | Liechtenstein', value: 'LI'},
        {text: 'LK | Sri Lanka', value: 'LK'},
        {text: 'LR | Liberia', value: 'LR'},
        {text: 'LS | Lesotho', value: 'LS'},
        {text: 'LT | Litauen', value: 'LT'},
        {text: 'LU | Luxemburg', value: 'LU'},
        {text: 'LV | Lettland', value: 'LV'},
        {text: 'LY | Libyen', value: 'LY'},
        {text: 'MA | Marokko', value: 'MA'},
        {text: 'MC | Monaco', value: 'MC'},
        {text: 'MD | Moldawien', value: 'MD'},
        {text: 'ME | Montenegro', value: 'ME'},
        {text: 'MF | Saint-Martin', value: 'MF'},
        {text: 'MG | Madagaskar', value: 'MG'},
        {text: 'MH | Marshallinseln', value: 'MH'},
        {text: 'MK | Nordmazedonien', value: 'MK'},
        {text: 'ML | Mali', value: 'ML'},
        {text: 'MM | Myanmar', value: 'MM'},
        {text: 'MN | Mongolei', value: 'MN'},
        {text: 'MO | Macau', value: 'MO'},
        {text: 'MP | Nördliche Marianen', value: 'MP'},
        {text: 'MQ | Martinique', value: 'MQ'},
        {text: 'MR | Mauretanien', value: 'MR'},
        {text: 'MS | Montserrat', value: 'MS'},
        {text: 'MT | Malta', value: 'MT'},
        {text: 'MU | Mauritius', value: 'MU'},
        {text: 'MV | Malediven', value: 'MV'},
        {text: 'MW | Malawi', value: 'MW'},
        {text: 'MX | Mexiko', value: 'MX'},
        {text: 'MY | Malaysia', value: 'MY'},
        {text: 'MZ | Mosambik', value: 'MZ'},
        {text: 'NA | Namibia', value: 'NA'},
        {text: 'NC | Neukaledonien', value: 'NC'},
        {text: 'NE | Niger', value: 'NE'},
        {text: 'NF | Norfolkinsel', value: 'NF'},
        {text: 'NG | Nigeria', value: 'NG'},
        {text: 'NI | Nicaragua', value: 'NI'},
        {text: 'NO | Norwegen', value: 'NO'},
        {text: 'NP | Nepal', value: 'NP'},
        {text: 'NR | Nauru', value: 'NR'},
        {text: 'NU | Niue', value: 'NU'},
        {text: 'NZ | Neuseeland', value: 'NZ'},
        {text: 'OM | Oman', value: 'OM'},
        {text: 'PA | Panama', value: 'PA'},
        {text: 'PE | Peru', value: 'PE'},
        {text: 'PF | Französisch-Polynesien', value: 'PF'},
        {text: 'PG | Papua-Neuguinea', value: 'PG'},
        {text: 'PH | Philippinen', value: 'PH'},
        {text: 'PK | Pakistan', value: 'PK'},
        {text: 'PL | Polen', value: 'PL'},
        {text: 'PM | St. Pierre und Miquelon', value: 'PM'},
        {text: 'PN | Pitcairninseln', value: 'PN'},
        {text: 'PR | Puerto Rico', value: 'PR'},
        {text: 'PS | Palästina', value: 'PS'},
        {text: 'PT | Portugal', value: 'PT'},
        {text: 'PW | Palau', value: 'PW'},
        {text: 'PY | Paraguay', value: 'PY'},
        {text: 'QA | Katar', value: 'QA'},
        {text: 'RE | Réunion', value: 'RE'},
        {text: 'RO | Rumänien', value: 'RO'},
        {text: 'RS | Serbien', value: 'RS'},
        {text: 'RU | Russland', value: 'RU'},
        {text: 'RW | Ruanda', value: 'RW'},
        {text: 'SA | Saudi-Arabien', value: 'SA'},
        {text: 'SB | Salomonen', value: 'SB'},
        {text: 'SC | Seychellen', value: 'SC'},
        {text: 'SD | Sudan', value: 'SD'},
        {text: 'SE | Schweden', value: 'SE'},
        {text: 'SG | Singapur', value: 'SG'},
        {text: 'SH | St. Helena', value: 'SH'},
        {text: 'SI | Slowenien', value: 'SI'},
        {text: 'SJ | Spitzbergen und Jan Mayen', value: 'SJ'},
        {text: 'SK | Slowakei', value: 'SK'},
        {text: 'SL | Sierra Leone', value: 'SL'},
        {text: 'SM | San Marino', value: 'SM'},
        {text: 'SN | Senegal', value: 'SN'},
        {text: 'SO | Somalia', value: 'SO'},
        {text: 'SR | Suriname', value: 'SR'},
        {text: 'SS | Südsudan', value: 'SS'},
        {text: 'ST | São Tomé und Príncipe', value: 'ST'},
        {text: 'SV | El Salvador', value: 'SV'},
        {text: 'SX | Sint Maarten', value: 'SX'},
        {text: 'SY | Syrien', value: 'SY'},
        {text: 'SZ | Swasiland', value: 'SZ'},
        {text: 'TC | Turks- und Caicosinseln', value: 'TC'},
        {text: 'TD | Tschad', value: 'TD'},
        {text: 'TF | Französische Süd- und Antarktisgebiete', value: 'TF'},
        {text: 'TG | Togo', value: 'TG'},
        {text: 'TH | Thailand', value: 'TH'},
        {text: 'TJ | Tadschikistan', value: 'TJ'},
        {text: 'TK | Tokelau', value: 'TK'},
        {text: 'TL | Osttimor', value: 'TL'},
        {text: 'TM | Turkmenistan', value: 'TM'},
        {text: 'TN | Tunesien', value: 'TN'},
        {text: 'TO | Tonga', value: 'TO'},
        {text: 'TR | Türkei', value: 'TR'},
        {text: 'TT | Trinidad und Tobago', value: 'TT'},
        {text: 'TV | Tuvalu', value: 'TV'},
        {text: 'TW | Taiwan', value: 'TW'},
        {text: 'TZ | Tansania', value: 'TZ'},
        {text: 'UA | Ukraine', value: 'UA'},
        {text: 'UG | Uganda', value: 'UG'},
        {text: 'UM | United States Minor Outlying Islands', value: 'UM'},
        {text: 'UY | Uruguay', value: 'UY'},
        {text: 'UZ | Usbekistan', value: 'UZ'},
        {text: 'VA | Vatikanstadt', value: 'VA'},
        {text: 'VC | St. Vincent und die Grenadinen', value: 'VC'},
        {text: 'VE | Venezuela', value: 'VE'},
        {text: 'VG | Britische Jungferninseln', value: 'VG'},
        {text: 'VI | Amerikanische Jungferninseln', value: 'VI'},
        {text: 'VN | Vietnam', value: 'VN'},
        {text: 'VU | Vanuatu', value: 'VU'},
        {text: 'WF | Wallis und Futuna', value: 'WF'},
        {text: 'WS | Samoa', value: 'WS'},
        {text: 'YE | Jemen', value: 'YE'},
        {text: 'YT | Mayotte', value: 'YT'},
        {text: 'ZA | Südafrika', value: 'ZA'},
        {text: 'ZM | Sambia', value: 'ZM'},
        {text: 'ZW | Simbabwe', value: 'ZW'}
    ]
}


export function checkIfRouteShouldGoToLogin(route) {
    console.log(route)
    if(
        route.includes('registrieren') ||
        route.includes('login') ||
        route.includes('impressum') ||
        route.includes('avv') ||
        route.includes('agb') ||
        route.includes('datenschutzerklaerung') ||
        route.includes('forgotPassword') ||
        route.includes('resetPasswort') ||
        route.includes('createAccount') ||
        route.includes('fast-geschafft') ||
        route.includes('accountDeleted') ||
        route.includes('settings') ||
        route.includes('activateMyAccount!wedFlow2022!')
    ){
        return false
    }else{
        return true
    }
}

export function success(msg) {
    setTimeout(() => this.message.show = false, 5000);
    return {
        show: true,
        text: msg,
        color: "success",
    }
}

export function msgObj() {
    return {
        show: false,
        text: "",
        color: ""
    }
}

export function date2String(date) {
    if (date !== null) {
        const newDate = new Date(date)
        if (newDate.getTime() === 0) {
            return ''
        }
        let options = {year: 'numeric', month: 'numeric', day: 'numeric'}
        return newDate.toLocaleDateString('de-DE', options)
    } else {
        return ''
    }
}

export function dotToKomma(float) {
    if (float) {
        let string = (Math.round(float * 100) / 100).toString()
        let vorKomma = string.split('.')[0]
        let nachKomma = string.split('.')[1]

        if (nachKomma && nachKomma.length > 0) {
            if (nachKomma.length === 1) {
                return trenner(vorKomma) + ',' + nachKomma + '0'
            } else {
                return trenner(vorKomma) + ',' + nachKomma
            }
        } else {
            return trenner(vorKomma) + ',' + '00'
        }
    } else {
        return '0,00'
    }
}

export function getTimeFromMillis(time) {
    return new Date(time).toISOString().substr(0, 10);
}

export function setFontBasedOnDigitAmount(string) {
    let array = string.split("");
    let arrayLength = array.length
    if(10 > arrayLength)
    {
        return 18
    }
    else if( 10 < arrayLength < 100 ){
        return 16
    }
    else if( 100 < arrayLength < 500 ){
        return 14
    }
    else if( 500 < arrayLength){
        return 12
    }
}

export function getMillsFromTime(time) {
    const hour = Number(time.split(':')[0])
    const minute = Number(time.split(':')[1])

    let hourM = hour * 60 * 60 * 1000
    const minuteM = minute * 60000

    hourM = hourM - (2 * 60 * 60 * 1000)

    // Eine Stunde dazu, damit Anzeige im Kalender passt
    hourM = hourM + (60 * 60 * 1000)

    return hourM + minuteM
}
export function getDateForPicker(date) {
    let month = '' + (date.getMonth() + 1),
        day = '' + date.getDate(),
        year = date.getFullYear();
    if (month.length < 2)
        month = '0' + month
    if (day.length < 2)
        day = '0' + day
    return [year, month, day].join('-')
}
export function trenner(number) {
    number = '' + number;
    if (number.length > 3) {
        let mod = number.length % 3;
        let output = (mod > 0 ? (number.substring(0, mod)) : '');
        for (let i = 0; i < Math.floor(number.length / 3); i++) {
            if ((mod === 0) && (i === 0))
                output += number.substring(mod + 3 * i, mod + 3 * i + 3);
            else
                // hier wird das Trennzeichen festgelegt mit '.'
                output += '.' + number.substring(mod + 3 * i, mod + 3 * i + 3);
        }
        return (output);
    } else return number;
}

export function getAllFieldsForEntity(entity){
    switch (entity) {
        case 'location':
            return [
                {text: 'Name', value: 'name'},
                {text: 'max. Personenanzahl', value: 'maxPersonen'},
                {text: 'Fläche innen', value: 'groesseInnen'},
                {text: 'Fläche außen', value: 'groesseAussen'},
                {text: 'Telefon', value: 'phone'},
                {text: 'E-Mail', value: 'email'},
                {text: 'Preiskategorie', value: 'priceClass'},
                {text: 'ID', value: 'id'},
                {text: 'Straße', value: 'street'},
                {text: 'Hausnummer', value: 'houseNumber'},
                {text: 'PLZ', value: 'plz'},
                {text: 'Ort', value: 'city'},
                {text: 'Latitude', value: 'lat'},
                {text: 'Longitude', value: 'lng'},
                {text: 'Archiviert', value: 'isArchived'},
            ]
        case 'vendor':
            return [
                {text: 'Name', value: 'name'},
                {text: 'Preiskategorie', value: 'priceClass'},
                {text: 'ID', value: 'id'},
                {text: 'Straße', value: 'street'},
                {text: 'Hausnummer', value: 'houseNumber'},
                {text: 'PLZ', value: 'plz'},
                {text: 'Ort', value: 'city'},
                {text: 'Latitude', value: 'lat'},
                {text: 'Longitude', value: 'lng'},
                {text: 'Archiviert', value: 'isArchived'},
                {text: 'Vorname (Kontakt)', value: 'firstname'},
                {text: 'Nachname (Kontakt)', value: 'lastname'},
                {text: 'Telefon (Kontakt)', value: 'tel'},
                {text: 'E-Mail (Kontakt)', value: 'email'},
                {text: 'Rolle (Kontakt)', value: 'role'},
            ]
        case 'customer':
            return [
                {text: 'Vorname', value: 'firstname'},
                {text: 'Nachname', value: 'lastname'},
                {text: 'Vorname (Partner)', value: 'partnerFirstname'},
                {text: 'Nachname (Partner)', value: 'partnerLastname'},
                {text: 'Telefon', value: 'tel'},
                {text: 'E-Mail', value: 'email'},
                {text: 'Telefon (Partner)', value: 'partnerTel'},
                {text: 'E-Mail (Partner)', value: 'partnerMail'},
                {text: 'Straße', value: 'street'},
                {text: 'Hausnummer', value: 'houseNumber'},
                {text: 'PLZ', value: 'plz'},
                {text: 'Ort', value: 'city'},
                {text: 'Archiviert', value: 'isArchived'},
            ]
        case 'wedding':
            return [
                {text: 'Hochzeitsdatum', value: 'wedDate'},
                {text: 'ID', value: 'id'},
                {text: 'Archiviert', value: 'isArchived'},
            ]
        default:
            return null
    }
}
