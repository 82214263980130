<template>
    <v-container class="pb-16">
        <Message
                :message="message"
                @closeAlert="message.show = false"
        ></Message>

        <v-row>
            <v-col cols="8">
                <h2 v-if="!nameEdit">
                    {{ timePlan.name }}
                    <v-icon v-if=" !nameEdit" @click="nameEdit = true; tpName = timePlan.name">mdi-pencil</v-icon>
                    <v-icon v-if=" !nameEdit" @click="deleteTimePlan()" class="ms-3">mdi-delete</v-icon>
                    <v-tooltip right color="secondary">
                        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
               <a @click="dialogVorlage =true" class="d-inline-flex mt-5 ps-3 pe-3">
                 <v-icon class="d-inline-flex" size="20">
                   mdi mdi-content-save
                 </v-icon>
               </a>
          </span>
                        </template>
                        Tagesablauf als Vorlage speichern
                    </v-tooltip>
                    <v-tooltip right color="secondary">
                        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
                  <v-icon slot="prepend" @click="$router.push('/pdf/timePlan/'+$route.params.id)">
            mdi-download
          </v-icon>
          </span>
                        </template>
                        Timeplan exportieren
                    </v-tooltip>


                </h2>
                <v-text-field v-model="tpName" label="Name"
                              v-if="nameEdit"></v-text-field>
                <v-icon v-if="nameEdit" @click=" nameEdit = false " class="ms-3 ">mdi-cancel</v-icon>
                <v-icon v-if="nameEdit" @click="editTimePlanName(); nameEdit = false ">mdi-check-circle</v-icon>
            </v-col>
            <v-hover v-slot="" close-delay="170">
                <v-col cols="4" class="text-end">
                    <div v-if="!dateEdit">
                        <h2 v-if="timePlan.date">
                            <v-icon class="pe-2">mdi-calendar</v-icon>
                            {{
                            new Date(timePlan.date).getDate() + "." + (new Date(timePlan.date).getMonth() + 1) + "." + new Date(timePlan.date).getFullYear()
                            }}
                            <v-icon v-if="!dateEdit" @click="dateEdit = true; tpDate = timePlan.date.toISOString">
                                mdi-pencil
                            </v-icon>

                        </h2>
                        <h1 v-else class=" grey--text">
                            <v-icon class="pe-2">mdi-calendar</v-icon>
                            <span v-if="timePlan.date ===0">XX.XX.XXXX</span>
                            <span v-else>{{ timePlan.date }}</span>
                            <v-icon v-if="!dateEdit" @click="dateEdit = true; tpDate = timePlan.date.toISOString">
                                mdi-pencil
                            </v-icon>

                        </h1>
                    </div>
                    <div v-if="dateEdit">
                        <v-menu
                                v-model="menu"
                                :close-on-content-click="false"
                                :close-on-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                        v-model="tpDate"
                                        :label="'Datum'"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                    v-model="tpDate"
                                    max-width="700"
                                    locale="de-de"
                                    @input="menu = false"
                            ></v-date-picker>
                        </v-menu>
                    </div>
                    <div>
                        <v-icon v-if="dateEdit" @click="editTimePlanDate(); dateEdit = false "> mdi-check-circle
                        </v-icon>
                        <v-icon v-if="dateEdit" @click="dateEdit = false " class="ms-3"> mdi-cancel</v-icon>
                    </div>
                </v-col>
            </v-hover>
        </v-row>
        <v-row class="">
            <v-col>
                <h2 class="subtitle-1" v-if="!descriptionEdit && timePlan.description === ''">
                    <i>Beschreibung hinzufügen</i>
                    <v-icon v-if=" !descriptionEdit"
                            @click="descriptionEdit = true; tpDescription = timePlan.description">
                        mdi-pencil
                    </v-icon>
                </h2>
                <h2 class="subtitle-1" v-else-if="!descriptionEdit ">
                    {{ timePlan.description }}
                    <v-icon v-if=" !descriptionEdit"
                            @click="descriptionEdit = true; tpDescription = timePlan.description">
                        mdi-pencil
                    </v-icon>
                </h2>
                <v-textarea v-if="descriptionEdit" v-model="tpDescription" label="Beschreibung"></v-textarea>
                <v-icon v-if="descriptionEdit" @click="editTimePlanDescription(); descriptionEdit = false">
                    mdi-check-circle
                </v-icon>
                <v-icon v-if="descriptionEdit" @click="descriptionEdit = false" class="ms-3"> mdi-cancel</v-icon>
            </v-col>
        </v-row>

        <v-row class="align-center">
            <v-col cols="4">
                <v-btn class="primary" @click="dialog=true">Neuer Eintrag</v-btn>
            </v-col>
            <v-col cols="4">
                <a @click="sortEntries()">
                    Reihenfolge ändern
                    <v-icon v-if="this.sortOrder === true">mdi-arrow-down</v-icon>
                    <v-icon v-if="this.sortOrder === false">mdi-arrow-up</v-icon>
                </a>
            </v-col>
            <v-col cols="4">
                <MultipleAutoComplete
                        :data="{items:vendors, title:'Dienstleister filtern', row: 'vendors'}"
                        @sendData="setFilter"
                ></MultipleAutoComplete>
            </v-col>
        </v-row>

        <v-row class="pa-2">
            <v-list width="100%" class="mt-3 rounded-xl" v-if="timePlanEntries.length >0">
                <div
                        v-for="(item, index) in searchedEntries"
                        :key="index"
                >
                    <v-list-item class="mt-4">
                        <v-container>
                            <v-row>
                                <v-col cols="2" lg="1" style="cursor: initial">
                                    <a class="pa-0 ma-0 ps-6"
                                       @click="checkedEntry({index: index, itemStart: item.start, itemEnd: item.end,itemName: item.name , itemDescription: item.description, itemVendors: item.vendors})">
                                        <v-icon
                                                class="mt-4"
                                                v-if="checkedIds.index === index && checkedIds.checked === true"
                                        >
                                            mdi-cancel
                                        </v-icon>
                                        <v-icon
                                                v-else
                                        >
                                            mdi-pencil
                                        </v-icon>
                                    </a>
                                </v-col>

                                <v-col cols="10" lg="2">
                                    <div class=" ">
                                        <h3 v-if="!(checkedIds.checked && checkedIds.index === index)"><b>{{
                                            item.start
                                            }} <span
                                                    v-if="item.end">- {{ item.end }}</span></b></h3>
                                        <v-menu v-if="checkedIds.checked && checkedIds.index === index"
                                                ref="menu"
                                                v-model="menu"
                                                :close-on-content-click="false"
                                                :nudge-right="40"

                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                        v-model="start"
                                                        label="Start"
                                                        prepend-icon="mdi-clock-time-four-outline"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-time-picker
                                                    v-model="start"
                                                    label="Start"

                                                    format="24hr"
                                                    max-width="700"
                                                    @input="menu = false"
                                                    @change="menu = false"
                                            ></v-time-picker>
                                        </v-menu>
                                        <v-menu v-if="checkedIds.checked && checkedIds.index === index"
                                                ref="menu"
                                                v-model="menu2"
                                                :close-on-content-click="false"
                                                :nudge-right="40"

                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                        v-model="end"
                                                        label="Ende"
                                                        prepend-icon="mdi-clock-time-four-outline"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-time-picker
                                                    v-model="end"
                                                    label="Ende"

                                                    format="24hr"
                                                    max-width="700"
                                                    @input="menu2 = false"
                                                    @change="menu2 = false"
                                            ></v-time-picker>
                                        </v-menu>
                                    </div>
                                </v-col>
                                <v-col cols="12" lg="4">
                                    <div class=" ">
                                        <div v-if="!(checkedIds.checked && checkedIds.index === index)">
                                            <h4 class="mb-0 pb-0"> {{ item.name }}</h4>
                                            <h5 class=" grey--text mt-0">{{ item.description }}</h5>
                                        </div>
                                        <v-text-field v-model="name" label="Name"
                                                      v-if="checkedIds.checked && checkedIds.index === index"></v-text-field>
                                        <v-text-field v-model="description" label="Beschreibung"
                                                      v-if="checkedIds.checked && checkedIds.index === index"></v-text-field>
                                    </div>
                                </v-col>

                                <v-col cols="11" lg="3">
                                    <div v-if="!(checkedIds.checked && checkedIds.index === index)">
                                        <div v-for="(vendor, index) of item.vendors" :key="index">
                                            <v-icon class="primary--text pe-2" @click="openVendor(vendor.id)">
                                                mdi-domain
                                            </v-icon>
                                            <span>{{ vendor.name }}</span>
                                        </div>
                                    </div>
                                    <div v-if="checkedIds.checked && checkedIds.index === index">
                                        <v-autocomplete
                                                v-model="vendorAutoComplete"
                                                multiple
                                                small-chips
                                                label="Dienstleister"
                                                deletable-chips
                                                :items="vendors"
                                                :key="index"
                                        ></v-autocomplete>
                                    </div>
                                </v-col>

                                <v-col cols="1" lg="1" class="text-end "
                                       v-if="!(checkedIds.checked && checkedIds.index === index)">
                                    <v-tooltip left color="red" class="text-end">
                                        <template v-slot:activator="{ on, attrs }">
                        <span
                                v-bind="attrs"
                                v-on="on"
                        >
                      <v-icon @click="deleteTimePlanEntry(item.$id)">mdi-delete</v-icon>
                        </span>
                                        </template>
                                        Eintrag löschen
                                    </v-tooltip>
                                </v-col>
                                <v-col cols="1" lg="2" class="text-end"
                                       v-if="checkedIds.checked && checkedIds.index === index">
                                    <v-tooltip left color="" class="text-end">
                                        <template v-slot:activator="{ on, attrs }">
                        <span
                                v-bind="attrs"
                                v-on="on"
                        >
                         <v-icon class="mt-4"
                                 @click="changeEntry({itemId: item.$id, spliceAmount: item.vendors.length});checkedEntry({index: index, itemStart: item.start, itemEnd: item.end,itemName: item.name , itemDescription: item.description, itemVendors: item.vendors})"
                         >
                                mdi-check-circle
                         </v-icon>
                        </span>
                                        </template>
                                        Änderungen Speichern
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-list-item>
                </div>
            </v-list>

        </v-row>

        <v-dialog
                v-model="dialogVorlage"
                persistent
                :max-width="800"
        >
            <v-card class="pa-8 rounded-xl">
                <v-card-title class="text-h5" id="title">
                    Tagesablauf als Vorlage speichern
                </v-card-title>
                <v-card-text>
                    <v-form ref="formular">
                        <TextField
                                :data="{title: 'Name', value: ''}"
                                @sendData="setTemplateName"
                        ></TextField>
                        <TextField
                                :data="{title: 'Beschreibung', value: ''}"
                                @sendData="setTemplateDesc"
                        ></TextField>
                    </v-form>
                    <div v-if="isSavingTemplate">
                        {{savedActual}}/{{savedTotal}} Einträge gespeichert<br>
                        <v-progress-linear
                            indeterminate
                            height="8px"
                            rounded
                            color="primary"
                            class="mt-3"
                        ></v-progress-linear>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="primary"
                            text
                            @click="dialogVorlage=false"
                    >
                        Abbrechen
                    </v-btn>
                    <v-btn
                            color="primary"
                            @click="saveAsTemplate"
                    >
                        Speichern
                    </v-btn>

                </v-card-actions>

            </v-card>
        </v-dialog>
        <CreateTimePlanEntry
                :dialog="dialog"
                :timePlanId="this.$route.params.id"
                @hideDialog="hideDialog"
                @created="getTimePLan"
        ></CreateTimePlanEntry>
    </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import {error, msgObj, success} from "@/helper/helper";

const Message = () => import("@/components/generalUI/Message");
const CreateTimePlanEntry = () => import("@/components/entities/wedding/TimePlan/CreateTimePlanEntry");
import TextField from "@/components/dialog/fields/TextField";

const MultipleAutoComplete = () => import("@/components/dialog/fields/MultipleAutoComplete");


/**
 * User can plan budget for own wedding with different categories.
 * Every category has items and each item's budget can be planned
 * @displayName BudgetPlan
 *
 */
export default {
    components: {
        Message,
        CreateTimePlanEntry,
        TextField,
        MultipleAutoComplete,
    },
    name: "TimePlan",
    data() {
        return {
            message: msgObj(),
            dialog: false,
            dialogVorlage: false,
            vendorNames: {},
            sortOrder: true,
            checkedIds: {checked: false, index: ""},
            end: "",
            start: "",
            name: "",
            description: "",
            menu: false,
            menu2: false,
            filterVendor: '',
            vendorNameList: [],
            vendorList: [],
            vendor: "",
            vendorAutoComplete: "",
            nameEdit: false,
            descriptionEdit: false,
            dateEdit: false,
            tpName: "",
            tpDescription: "",
            tpDate: "",
            templateDesc: '',
            templateName: '',
            activeFilters: {},
            isSavingTemplate: false
        }
    },
    computed: {
        ...mapGetters('timePlan', {
            timePlan: 'timePlan',
            timePlanEntries: 'timePlanEntries',
            searchedEntries: 'searchedEntries',
            savedActual: 'savedActual',
            savedTotal: 'savedTotal',

        }),
        ...mapGetters('vendor', {
            vendors: 'vendorsForSelection',
        }),
        ...mapGetters('auth', {
            user: "user"
        }),
    },
    /**
     * Check if user is logged in and then get budgetplan of logged user
     *
     */
    mounted() {
        this.getTimePLan()
    },
    methods: {
        getTimePLan() {
            this.$store.dispatch('timePlan/getTimePlan', {
                timePlanId: this.$route.params.id
            }).then(() => {
                this.$store.dispatch('timePlan/getTimePlanEntries', {
                    timePlanId: this.$route.params.id
                }).then(() => {
                    this.filterStart()
                    this.$store.dispatch('vendor/getVendorsForSelection', {
                        uid: this.user.id
                    }).catch((err) => {
                        this.message = error(err)
                    })
                }).catch((err) => {
                    this.message = error(err)
                })
            }).catch((err) => {
                this.message = error(err)
            })
        },
        setFilter(payload) {
            if (payload.value.length < 1) {
                delete this.activeFilters[payload.row]
                this.filterStart()
            } else {
                this.activeFilters[payload.row] = payload.value
                this.filter()
            }
        },
        filter() {
            this.$store.dispatch('sort/filter', {activeFilters: this.activeFilters, list: this.timePlanEntries}
            ).then((results) => {
                this.$store.dispatch('timePlan/setEntriesSearched', results)
            })
        },
        filterStart() {
            this.$store.dispatch('sort/filter', {activeFilters: this.activeFilters, list: this.timePlanEntries}
            ).then((results) => {
                this.$store.dispatch('timePlan/setEntriesSearched', results)
            })
        },
        setNotFilteredInvisible(results) {
            let i = 0
            let vendorsOfEntry
            for (let result of results) {
                if (i === 0) {
                    vendorsOfEntry = result.vendors
                }
                let filteredVendors = []
                for (let vend of vendorsOfEntry) {
                    for (const [key, value] of Object.entries(this.activeFilters)) {
                        console.log("test")
                        console.log(value[0])
                        console.log("found")
                        if (vend.id === value[0]) {
                            console.log("found")
                            filteredVendors.push(vend)
                            console.log(key)
                        }
                    }
                }
                result.vendors = filteredVendors
                i++
            }
        },
        setTemplateName(payload) {
            this.templateName = payload.value
        },
        setTemplateDesc(payload) {
            this.templateDesc = payload.value
        },
        saveAsTemplate() {
            this.isSavingTemplate = true

            this.$store.dispatch('timePlan/saveAsTemplate', {
                uid: this.user.id,
                timePlanId: this.$route.params.id,
                timePlanEntries: this.timePlanEntries,
                name: this.templateName,
                description: this.templateDesc
            }).catch((err) => {
                this.message = error(err)
            }).then(() => {
                this.message = success('Vorlage erfolgreich gespeichert.')
                this.isSavingTemplate = false
                this.dialogVorlage = false
            })
        },
        hideDialog() {
            this.dialog = false
        },
        deleteFilter() {
            this.filterVendor = ""
            this.$store.dispatch('timePlan/getTimePlanEntries', {
                timePlanId: this.$route.params.id
            }).catch((err) => {
                this.message = error(err)
            })
        },
        changeEntry(payload) {
            //this.vendorAutoComplete.splice(0,payload.spliceAmount)
            this.$store.dispatch('timePlan/editEntry', {
                plannerId: this.user.id,
                entryId: payload.itemId,
                name: this.name,
                end: this.end,
                start: this.start,
                description: this.description,
                vendors: this.vendorAutoComplete
            }).then(() => {
                this.checkedIds.checked = false
                this.checkedIds.index = ''
                this.getTimePLan()
            }).catch((err) => {
                this.message = error(err)
            })
        },
        filterEntries() {
            this.$store.dispatch('timePlan/filterEntries', {
                filterVendor: this.filterVendor,
                timePlanId: this.$route.params.id
            }).catch((err) => {
                this.message = error(err)
            })
        },
        objectArrayToStringArray(payload) {
            let newArray = []
            Array.prototype.forEach.call(payload.array, entry => {
                newArray.push(entry[payload.propertyName])
            })
            return newArray
        },
        checkedEntry(payload) {
            this.$store.dispatch('vendor/getVendorsForSelection', {
                uid: this.user.id
            }).catch((err) => {
                this.message = error(err)
            })
            this.checkedIds = {checked: !this.checkedIds.checked, index: payload.index}
            this.end = payload.itemEnd
            this.start = payload.itemStart
            this.name = payload.itemName
            this.description = payload.itemDescription
            this.vendorAutoComplete = this.objectArrayToStringArray({array: payload.itemVendors, propertyName: "id"})
        },
        getNameForVendor(id) {
            return new Promise((resolve) => {
                this.$store.dispatch('vendor/getVendorNameFromId', {
                    vendorId: id
                }).then((name) => {
                    this.vendorNameList.push({text: name, value: id})
                    this.vendorNames[id] = name

                }).catch((err) => {
                    this.message = error(err)
                    resolve(id)
                })
            })
        },
        getVendorName(id) {
            return this.vendorNames[id]
        },
        openVendor(id) {
            this.$router.push('/dienstleister/dienstleister/' + id)
        },
        deleteTimePlan() {
            this.$store.dispatch('timePlan/deleteTimePlan', this.$route.params.id)
                .then(() => this.$router.push('/weddings/wedding/' + this.timePlan.weddingId))
                .catch((err) => {
                    this.message = error(err)
                })
        },
        deleteTimePlanEntry(payload) {
            this.$store.dispatch('timePlan/deleteTimePlanEntry', {
                timePlanId: this.$route.params.id,
                entryId: payload
            }).then(() => {
                this.getTimePLan()
            }).catch((err) => {
                this.message = error(err)
            })
        },
        sortEntries() {
            this.sortOrder = !this.sortOrder
            this.$store.dispatch('timePlan/sortEntryList', this.sortOrder).catch((err) => {
                this.message = error(err)
            })
        },
        editTimePlanName() {
            this.$store.dispatch('timePlan/editTimePlanName', {
                timePlanId: this.$route.params.id,
                name: this.tpName
            }).then(() => {
                this.getTimePLan()
            }).catch((err) => {
                this.message = error(err)
            })
        },
        editTimePlanDescription() {
            console.log(this.tpName)
            this.$store.dispatch('timePlan/editTimePlanDescription', {
                timePlanId: this.$route.params.id,
                description: this.tpDescription
            }).then(() => {
                this.getTimePLan()
            }).catch((err) => {
                this.message = error(err)
            })
        },
        editTimePlanDate() {
            console.log(this.tpDate)
            this.$store.dispatch('timePlan/editTimePlanDate', {
                timePlanId: this.$route.params.id,
                date: this.tpDate
            }).then(() => {
                this.getTimePLan()
            }).catch((err) => {
                this.message = error(err)
            })
        },
    },
}
</script>

<style scoped>
.row {
    border-width: thin;
}

v-container {
    height: 150vh;
}
</style>
