<template>
  <v-container>
    <v-row style="position: absolute; top:2cm; right: 2.5cm">
      <v-col style="text-align: right; ">
      </v-col>
    </v-row>
    <v-row v-if="profilePic && profilePic !== ''">
      <v-col cols="1">
      </v-col>
      <v-col>
        <img :src="profilePic" style="max-height: 2.2cm; max-width: 4.2cm">
      </v-col>
    </v-row>
        <v-row>
          <v-col cols="1">
          </v-col>
            <v-col cols="6" class="">
              <div>
                <h3>
                  {{ timePlan.name }}
                </h3>
              </div>
            </v-col>
            <v-col cols="4" class="text-end">
              <h3>
                <v-icon class="pe-2">mdi-calendar</v-icon>
                {{
                  new Date(timePlan.date).getDate() + "." + (new Date(timePlan.date).getMonth() + 1) + "." + new Date(timePlan.date).getFullYear()
                }}
              </h3>
            </v-col>
          <v-col cols="1">
          </v-col>
        </v-row>
        <v-row class="">
          <v-col cols="1">
          </v-col>
          <v-col>
            <h4 class="subtitle-1">
              {{ timePlan.description }}
            </h4>
          </v-col>
        </v-row>
<div v-for="(item, index) of searchedEntries"
     :key="item.id"
     style="padding-top: 40px"
     :id = "'row' + index">
    <div v-if="index === 5 || index === 11 || index === 17 || index === 23" class="pageBreaker" style="margin-bottom: 1.5cm"></div>

  <v-row
        >

          <v-col cols="1">
          </v-col>
          <v-col cols="2">
                <span> <b>{{ item.start }}
                      <span v-if="item.end">- {{ item.end }} </span></b> </span>
              </v-col>
              <v-col cols="5">
                <div class="p-5">
                  <h4>
                    {{ item.name }}
                  </h4>
                  <p>{{ item.description }} </p>
                </div>
              </v-col>
              <v-col cols="4">
                <div v-for="(vendor, index) of item.vendors" :key="index">
                  <v-icon class="primary--text pe-2">mdi-domain</v-icon>
                  <span>{{ vendor.name }} </span>
                </div>
              </v-col>
        </v-row>
</div>
  </v-container>
</template>
<script>
/**
 * Snackbar to show user successful and not successful tries to edit, create or delete a value
 * @displayName Alert
 */
import {dotToKomma, error} from "@/helper/helper";
//const Doughnut = () => import("@/components/generalUI/charts/Doughnut");

export default {
  name: "GeneratePdf",
  components: {
    // Doughnut
  },
  data(){
    return{
      objectAmount: 0,
      pageAmount: 0,
      boolPageBreaker: false,
      blendedIndexes: [5, 10, 15],
      sumHeight: 0,
    }
  },
  props: {
    /**
     * Contains data to display the alert accordingly
     */
    /**
     * Contains data to display the alert accordingly
     */
    fileName: {
      type: String,
      default() {
        return ''
      }
    },
    profilePic: String,
    timePlan: {
      type: Object,
      default() {
        return {}
      }
    },
    timePlanEntries: {
      type: Object,
      default() {
        return {}
      }
    },
    searchedEntries: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  computed: {
    boolSize: function () {
        let timeLineHeight = document.getElementById('timeLine').clientHeight
        let screenSize = 1123
        let boolean = timeLineHeight - (screenSize * this.pageAmount) <= 300
        if (boolean){
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.pageAmount = this.pageAmount + 1
        }
        return boolean
    },
  },
  methods: {
    /*
        Generate Report using refs and calling the
        refs function generatePdf()
    */
    /*
        Generate Report using refs and calling the
        refs function generatePdf()
    */
    generateReport() {
      this.$refs.html2Pdf.generatePdf()
    },
    replaceDot(float) {
      return dotToKomma(float)
    },


    isZero(number) {
      if (number < 0) {
        return 0
      } else {
        return number
      }
    },
    getSumPlanned(entries) {
      let sum = 0.00
      for (let entry of entries) {
        sum = sum + entry.planned
      }
      return sum.toFixed(2)
    },
    getSumSpent(category, entries) {
      let sum = 0.00
      for (let entry of entries) {
        sum = sum + entry.spent
      }
      this.categorySums[category] = sum
      return sum.toFixed(2)
    },
    getTotalSumPlanned(categories) {
      let sum = 0.00
      for (let category of categories) {
        for (let singeBudget of category.singleBudget) {
          sum = sum + singeBudget.planned
        }
      }
      return sum.toFixed(2)
    },
    getTotalSumSpent(categories) {
      let sum = 0.00
      for (let category of categories) {
        for (let singeBudget of category.singleBudget) {
          sum = sum + singeBudget.spent
        }
      }
      return sum.toFixed(2)
    },
    changeName(name) {
      this.$store.dispatch('customer/editField', {
        entity: "budgetPlan",
        uid: this.user.id,
        id: this.$route.params.id,
        newValue: name,
        row: 'name'
      }).then(() => {
        this.budgetPlan.name = name
      }).catch((err) => {
        this.message = error(err)
      })
    },
  },


}
</script>


