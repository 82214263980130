<template>
    <div>
        <v-container class="">
            <v-row class="text-center">
                <v-col>
                    <v-img
                            :src="require('@/assets/images/drawSVGs/decide.svg')"
                            max-width="200px"
                            class="d-inline-flex mt-16 mb-16"
                            style="transform: scaleX(-1);"/>
                    <h1 class="text-h4">Vielen Dank, dass Du WeddingFlow ausprobiert hast!</h1>
                    <p class="text-subtitle-1">Wähle jetzt die Version, die zu Dir passt und plane unbegrenzt
                        weiter.</p>
                </v-col>
            </v-row>
        </v-container>


        <v-container id="preise" style="padding-top: 120px">
            <v-row class="align-center  justify-center">
                <v-col cols="12" md="6" lg="5" xl="4" class="pa-md-8">
                    <v-card class="pa-8 rounded-xl" flat>
                        <h2 class="pb-0 ps-2 text-subtitle-1 ">Essential
                        </h2>
                        <h4 class="pb-8 ps-2 text-h5">
                            Alles was Du zum Planen von Hochzeiten brauchst
                        </h4>
                        <v-list two-line>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="primary">
                                        mdi-post
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Daten verwalten</v-list-item-title>
                                    <v-list-item-subtitle>Hochzeiten, Brautpaare uvm. an einem Ort
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="primary">
                                        mdi-cogs
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Hochzeiten planen</v-list-item-title>
                                    <v-list-item-subtitle>Budgetpläne, Zeitabläufe etc.</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="primary">
                                        mdi-file
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>20 GB Speicher</v-list-item-title>
                                    <v-list-item-subtitle>Zum Speichern von Dateien</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="primary">
                                        mdi-text
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>3 Individuelle Felder</v-list-item-title>
                                    <v-list-item-subtitle>Zum Anpassen des Systems</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <v-divider></v-divider>

                        <div class="text-center pt-8">
                            <p><span class="text-h4 primary--text">19,97€</span> /
                                Monat</p>

                            <v-btn class="primary text-uppercase startBtn pt-7 pb-7 ps-6 pe-6"
                                   @click="goTo(urlEssential)">Weiter planen
                            </v-btn>
                        </div>
                    </v-card>
                </v-col>

                <v-col cols="12" md="6" lg="5" xl="4" class="pa-md-8">
                    <v-card class="pa-8 rounded-xl" flat>
                        <h2 class="pb-0 ps-2 text-subtitle-1">Premium
                        </h2>
                        <h4 class="pb-8 ps-2 text-h5">
                            Unbegrenzte Planung mit Deinem Team
                        </h4>
                        <v-list two-line>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="">
                                        mdi-arrow-up
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Alle Funktionen aus Essential</v-list-item-title>

                                </v-list-item-content>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="primary">
                                        mdi-account-group
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Mehrbenutzerfähig</v-list-item-title>
                                    <v-list-item-subtitle>Arbeite gemeinsam mit deinem Team</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="primary">
                                        mdi-mail
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Externe Einbindungen</v-list-item-title>
                                    <v-list-item-subtitle>E-Mail, Termine uvm.</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="primary">
                                        mdi-file
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>50 GB Speicher</v-list-item-title>
                                    <v-list-item-subtitle>Zum Speichern von Dateien</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon color="primary">
                                        mdi-text
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>20 Individuelle Felder</v-list-item-title>
                                    <v-list-item-subtitle>Passe das System beliebig an</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <v-divider></v-divider>
                        <div class="text-center pt-8">
                            <p><span class="text-h5 primary--text">Bald verfügbar</span></p>
                            <v-btn class="primary text-uppercase startBtn pt-7 pb-7 ps-6 pe-6" disabled>Weiter planen
                            </v-btn>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
            </v-row>
        </v-container>
        <v-container class="pt-16">
            <template>
                <v-simple-table class="rounded-xl pa-4 pb-9">
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-left">
                            </th>
                            <th class="text-center text-h6">
                                Essential
                            </th>
                            <th class="text-center text-h6">
                                Premium
                            </th>
                        </tr>
                        </thead>

                        <tbody v-for="category in categories"
                               :key="category.headLine"
                        >

                        <tr style="background-color: #FAFAFA">
                            <td class="pt-8 pb-3"><span class="text-h6 ">{{ category.headLine }}</span></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                        </tr>
                        <tr
                                v-for="item in category.functions"
                                :key="item.name"
                        >
                            <td class="pt-3 pb-3 ">
                                <span class="subtitle-1 ps-0 ps-md-6">{{ item.name }}</span> <br v-if="item.desc">
                                <p class="ps-0 ps-md-6">{{ item.desc }}</p>
                            </td>
                            <td class="text-center"><b><span v-if="item.essential ==='X'"><v-icon
                                    color="primary">mdi-check</v-icon></span><span
                                    v-else-if="item.essential ===''">X</span><span v-else>{{
                                item.essential
                                }}</span></b></td>
                            <td class="text-center"><b><span v-if="item.premium ==='X'"><v-icon
                                    color="primary">mdi-check</v-icon></span><span v-else>{{ item.premium }}</span></b>
                            </td>
                        </tr>
                        </tbody>
                        <tbody>
                        <tr style="background-color: #FAFAFA">
                            <td class="pt-8 pb-3"><span class="text-h6 ">Jetzt unverbindlich testen</span></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                        </tr>
                        <tr>
                            <td class="pt-3 pb-3 ">
                                <span class="subtitle-1 ps-0 ps-md-6">Kostenlos testen</span> <br>
                                <span class="ps-0 ps-md-6">Starte jetzt ohne Angabe einer Zahlungsmethode und wähle später den Plan, der zu Dir passt.</span>
                            </td>
                            <td class="text-center">
                                <v-btn class="disabled primary text-uppercase startBtn pt-7 pb-7 ps-6 pe-6"
                                       @click="goTo(urlEssential)">
                                    Weiter planen
                                </v-btn>
                                <br>
                            </td>
                            <td class="text-center ">
                                <p class="mb-8 mt-8">
                                    <v-btn class="disabled primary text-uppercase startBtn pt-7 pb-7 ps-6 pe-6"
                                           disabled>
                                        Weiter planen
                                    </v-btn>
                                    <br>
                                    <small class="">(bald verfügbar)</small>
                                </p>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </template>
        </v-container>

        <!-- Contact and Demo start -->
        <div class="" style="background-color: #FAFAFA">
            <v-container class="containerPadding" id="contact">
                <v-row class="text-center pb-8">
                    <v-col>
                        <h2 class="pb-2 text-subtitle-1 d-block">
                            Kontakt
                        </h2>
                        <h4 class="pb-8 text-h4 d-block">
                            Dein Ansprechpartner rund um Fragen zur Anwendung
                        </h4>
                    </v-col>
                </v-row>
                <v-row class="align-center justify-center">
                    <v-col cols="12" sm="6" md="4" class="text-center ">
                        <v-img
                                :src="require('@/assets/images/profilbild.png')"
                                position="bottom"
                                max-width="270px"
                                width="70%"
                                class="d-inline-flex rounded-circle"
                        />
                    </v-col>
                    <v-col cols="12" sm="6" md="4" class="ps-md-8">
                        <p>Bei offenen Fragen bin ich gerne für Dich erreichbar!</p>
                        <h5 class="text-h6">Benedikt Binzer</h5>
                        <p>
                            <v-icon color="secondary" size="20" class="scale">
                                mdi-phone
                            </v-icon>
                            <a href="tel:+4915739456571">+49 1573 9456571 </a><br>
                            <v-icon color="secondary" size="20" class="scale pe-1">
                                mdi-email
                            </v-icon>
                            <a href="mailto:benedikt@wedding-flow.de">benedikt@wedding-flow.de</a>
                        </p>
                    </v-col>

                    <v-col cols="12" sm="6" md="4" class="ps-md-8 pt-12 pt-md-0 text-center text-md-start">
                        <h4 class="text-h5 pb-3">Demo vereinbaren</h4>
                        <p>Buche gerne einen Termin und ich zeige Dir die Anwendung und die Vorteile, die sie
                            Dir
                            bietet.</p>
                        <v-btn class="primary text-uppercase startBtn pt-7 pb-7 ps-6 pe-6" @click="dialogDemo=true">
                            Termin buchen
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
            <v-container class="pt-16 mt-16 pb-6">
                <v-row>
                    <v-col class="text-center">
                        <a @click="startDeleteAcc=true" style="color:lightcoral">Account endgültig
                            löschen</a>
                    </v-col>
                </v-row>

            </v-container>
        </div>
        <v-dialog
                v-model="dialogGoTo"
                persistent
                max-width="700px"
        >
            <v-card class="pa-16 text-center">
                <h2>Wir leiten dich nun zum Zahlungsdienstleister weiter. Bitte schließe den Bezahlvorgang in diesem
                    Browserfenster ab.</h2>
                <v-img
                        :src="require('@/assets/images/drawSVGs/stripePay.svg')"
                        max-width="200px"
                        class="d-inline-flex mt-3"
                />
                <v-card-text>
                    <v-progress-linear
                            indeterminate
                            height="8px"
                            rounded
                            color="primary"
                            class="mt-3"
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog
                v-model="startDeleteAcc"
                persistent
                :max-width="800"
        >
            <v-card class="rounded-xl pa-4">
                <v-card-title class="text-h5 " id="title">
                    ACHTUNG: Account löschen
                </v-card-title>
                <v-card-text>
                    <h4>
                        Sind Sie sicher, dass Sie Ihren Account und ALLE dazugehörigen Daten UNWIDERRUFLICH löschen
                        möchten?
                    </h4>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="primary"
                            text
                            @click="startDeleteAcc = false"
                    >
                        Abbrechen
                    </v-btn>
                    <v-btn
                            color="error"
                            @click="sureToDeleteAccount()"
                    >
                        Fortfahren
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
                v-model="sureToDeleteAcc"
                persistent
                :max-width="800"
        >
            <v-card class="rounded-xl pa-4">
                <v-card-title class="text-h5 " id="title">
                    ACHTUNG: Account löschen
                </v-card-title>
                <v-card-text>
                    <h4>
                        Mit dem Fortfahren werden folgende Daten unwiderruflich gelöscht. Sind Sie sicher, dass Sie
                        fortfahren möchten?
                    </h4>
                    <Spinner v-if="!deleteAccLoaded">

                    </Spinner>
                    <div v-else>
                        <v-container v-if="!isDeleting">
                            <v-row>
                                <v-col md="4" sm="6" cols="8">
                                    Kunden / Brautpaare:
                                </v-col>
                                <v-col md="8" sm="6" col="4">
                                    {{ count.customers }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col md="4" sm="6" cols="8">
                                    Hochzeiten:
                                </v-col>
                                <v-col md="8" sm="6" col="4">
                                    {{ count.weddings }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col md="4" sm="6" cols="8">
                                    Dienstleister:
                                </v-col>
                                <v-col md="8" sm="6" col="4">
                                    {{ count.vendor }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col md="4" sm="6" cols="8">
                                    Locations:
                                </v-col>
                                <v-col md="8" sm="6" col="4">
                                    {{ count.location }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col md="4" sm="6" cols="8">
                                    Events:
                                </v-col>
                                <v-col md="8" sm="6" col="4">
                                    {{ count.events }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col md="4" sm="6" cols="8">
                                    Aufgaben:
                                </v-col>
                                <v-col md="8" sm="6" col="4">
                                    {{ count.tasks }}
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col md="4" sm="6" cols="8">
                                    Kontakt Personen:
                                </v-col>
                                <v-col md="8" sm="6" col="4">
                                    {{ count.contactPerson }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col md="4" sm="6" cols="8">
                                    Budgetpläne:
                                </v-col>
                                <v-col md="8" sm="6" col="4">
                                    {{ count.budgetPlan }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col md="4" sm="6" cols="8">
                                    Tagesabläufe:
                                </v-col>
                                <v-col md="8" sm="6" col="4">
                                    {{ count.timePlan }}
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col md="4" sm="6" cols="8">
                                    Templates:
                                </v-col>
                                <v-col md="8" sm="6" col="4">
                                    {{ count.template }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col md="4" sm="6" cols="8">
                                    Rechnungen und Angebote:
                                </v-col>
                                <v-col md="8" sm="6" col="4">
                                    {{ count.invoice }}
                                </v-col>
                            </v-row>

                        </v-container>
                        <v-container v-else>
                            {{ deleted }} / {{ progressed }} (Errors: {{ errors }})
                            <v-progress-linear  indeterminate stream >

                            </v-progress-linear>
                        </v-container>
                        <v-checkbox label="Ich bestätige, dass die Daten UNWIDERRUFLICH gelöscht werden."
                                    v-model="sureToDeleteAccBool" v-if="!isDeleting"></v-checkbox>
                    </div>
                </v-card-text>
                <v-card-actions v-if="!isDeleting">
                    <v-spacer></v-spacer>
                    <v-btn
                            color="primary"
                            text
                            @click="sureToDeleteAcc = false"
                    >
                        Abbrechen
                    </v-btn>
                    <v-btn
                            color="error"
                            v-if="sureToDeleteAccBool && deleteAccLoaded"
                            @click="deleteAccount()"
                    >
                        Fortfahren
                    </v-btn>
                    <v-btn
                            color="error"
                            v-else
                            disabled
                    >
                        Fortfahren
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import {urlEssential} from '@/config/awSettings'
import {error} from "@/helper/helper";
import {mapGetters} from "vuex";

const Spinner = () => import("@/components/generalUI/Spinner");

export default {
    name: "TestEnded",
    computed: {
        ...mapGetters('auth', {
            user: 'user',
        }),
        ...mapGetters('deleteAccount', {
            count: 'count',
            deleted: 'deleted',
            progressed: 'progressed',
            errors: 'errors',
        }),
    },
    components: {
        Spinner
    },
    data() {
        return {
            startDeleteAcc: false,
            deleteAccLoaded: false,
            sureToDeleteAcc: false,
            sureToDeleteAccBool: false,
            isDeleting: false,
            dialogGoTo: false,
            dialogDemo: false,
            urlEssential,
            categories: [
                {
                    headLine: 'Daten verwalten',
                    functions: [
                        {
                            name: 'Brautpaare und Dienstleister verwalten',
                            desc: '',
                            essential: 'X',
                            premium: 'X',
                        },
                        {
                            name: 'Aufgaben verwalten',
                            essential: 'X',
                            premium: 'X',
                        },
                        {
                            name: 'Termine verwalten',
                            essential: 'X',
                            premium: 'X',
                        },

                    ]
                },
                {
                    headLine: 'Hochzeiten planen',
                    functions: [
                        {
                            name: 'Dateien hochladen',
                            essential: '20GB',
                            premium: '50GB',
                        },
                        {
                            name: 'Budgetpläne',
                            essential: '1 / Hochzeit',
                            premium: 'unbegrenzt',
                        },
                        {
                            name: 'Zeitpläne',
                            essential: '1 / Hochzeit',
                            premium: 'unbegrenzt',
                        },
                    ]
                },
                {
                    headLine: 'Zusammenarbeit',
                    functions: [
                        {
                            name: 'Teammitglieder hinzufügen',
                            desc: '',
                            essential: '',
                            premium: 'X',
                        },
                        {
                            name: 'Datensätze mit dem Team teilen',
                            desc: 'Jedes Teammitglied kann selbst entscheiden, ob die erstellten Datensätze von anderen angesehen und bearbeitet werden können.',
                            essential: '',
                            premium: 'X',
                        }
                    ]
                },
                {
                    headLine: 'Anpassungen',
                    functions: [
                        {
                            name: 'Kategorien und Statuswerte bearbeiten',
                            desc: 'Statuswerte und Kategorien hinterlegen und das System so an deinen Arbeitsablauf anpassen.',
                            essential: 'X',
                            premium: 'X',
                        },
                        {
                            name: 'Individuelle Felder',
                            desc: 'Passe die Felder an, die Du in den unterschiedlichen Datensätzen speichern möchtest und ergänze das System um Informationen die Dir wichtig sind.',
                            essential: 'max. 3',
                            premium: 'X',
                        },

                    ]
                }
            ]
        }
    },
    mounted() {

    },

    methods: {
        goTo(url) {
            this.dialogGoTo = true
            setTimeout(() => {
                window.location.href = url
            }, 5000)
        },
        sureToDeleteAccount() {
            this.startDeleteAcc = false
            this.sureToDeleteAcc = true
            this.$store.dispatch('deleteAccount/getUserData', {uid: this.user.id}).catch((err) => {
                this.startDeleteAcc = false
                this.sureToDeleteAcc = false
                this.message = error(err)
            }).then(() => {
                this.deleteAccLoaded = true
            })
        },
        deleteAccount() {
            this.isDeleting = true
            this.$store.dispatch('support/saveBugReport', {
                bug: false,
                subject: 'Account gelöscht',
                optimizing: false,
                uid: this.user.id,
                description: 'Account gelöscht',
                email: 'Account gelöscht',
                solved: false
            }).catch((err) => {
                this.message = error(err)
            }).then(() => {
                this.$store.dispatch('deleteAccount/deleteAccount', {uid: this.user.id}).then(()=>{
                    this.$store.dispatch('auth/signedOut', {uid: this.user.id}).then(()=>{
                    }).catch((err) => {
                        this.message = error(err)
                    })
                }).catch((err) => {
                    this.message = error(err)
                })
            })
        },
    }
}
</script>
