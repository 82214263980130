<template>
<v-container>
  <v-container class="about" id="printarea">
    <Invoice
        v-if="$route.path.includes('/invoice/') && loaded"
        :invoiceSettings="invoiceSettings"
        :invoice="invoice"
        :positions="positions"
        :profilePic="profilePic"
        :status="invoice.status"
    >
    </Invoice>

    <BudgetPlan
        v-else-if="$route.path.includes('/budgetPlan/') && loaded"
        :budgetPlan="budgetPlan1"
        :budget="budget1"
        :profilePic="profilePic"
    >
    </BudgetPlan>
    <TimePlan
        v-else-if="$route.path.includes('/timePlan/') && loaded"
        :timePlan="timePlan"
        :timePlanEntries="timePlanEntries"
        :searchedEntries="searchedEntries"
        :profilePic="profilePic">
    </TimePlan>
  </v-container>
  <v-container class="about" id="screenarea">
    <p>
      PDF wird erstellt
    </p>
  </v-container>
</v-container>

</template>
<script>


import Invoice from "@/components/PdfExport/Invoice.vue";
import BudgetPlan from "@/components/PdfExport/budgetPlan.vue";
import TimePlan from "@/components/PdfExport/TimePlan.vue";
import {mapGetters} from "vuex";
import {error} from "@/helper/helper";
export default {
  name: 'PDF',
  components: {
    Invoice,
    BudgetPlan,
    TimePlan
  },
  props:{
  },
  data(){
    return {
      loaded: false,
      budget1: null,
      budgetPlan1: null,
      timePlan1: null,
      profilePic: ''
    }
  },
  computed: {
    ...mapGetters('invoice', {
      invoice: 'invoice',
      positions: 'positions',
    }),
    ...mapGetters('auth', {
      user: 'user',
    }),
    ...mapGetters('settings', {
      invoiceSettings: 'invoiceSettings',
    }),
    ...mapGetters('budgetPlan', {
      budget: 'budget',
      budgetPlan: 'budgetPlan',
      categoriesBudget: 'categoriesBudget'
    }),
    ...mapGetters('timePlan', {
      timePlan: 'timePlan',
      timePlanEntries: 'timePlanEntries',
      searchedEntries: 'searchedEntries',
    }),

  },
  mounted() {
    //Titel Entfernen
    const elements = document.getElementsByClassName('iubenda-tp-btn');
    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
    const element = document.getElementById('footerLeiste');

    element.parentNode.removeChild(element);

    this.$store.dispatch('file/getProfilePic').then((profilePicUrl) => {
      this.profilePic= profilePicUrl
      console.log(profilePicUrl)
      this.$store.dispatch('auth/checkLogin', {}).then((res) => {
        if(this.$route.path.includes('/invoice/')){
          if( this.invoice.isQuote){
            document.title = 'Angebot_'+ this.invoice.invoiceNumber
          }else{
            document.title = 'Rechnung_'+ this.invoice.invoiceNumber
          }
          this.$store.dispatch('invoice/getInvoice', {
            uid: res,
            invoiceId: this.$route.params.id
          }).then(() => {
            this.$store.dispatch('settings/getInvoiceSettings', {
              uid: res,
            }).then(()=>{
              this.loaded=true
              window.scrollTo(0, 0)
              setTimeout(()=>{
                print()
                this.$router.push('/rechnungen/rechnung/'+this.invoice.id).then(()=>{
                  location.reload()
                })
              },500)
            }).catch((err)=>{
              console.log(err)
            })
          }).catch((err)=>{
            console.log(err)
          })
        }else if(this.$route.path.includes('/budgetPlan/')){
          this.$store.dispatch('budgetPlan/getFullPlan', {
            budgetPlanId: this.$route.params.id
          }).then(() => {
            this.budget1 =this.budget
            this.budgetPlan1 = this.budgetPlan
            this.loaded=true
            setTimeout(()=>{
              print()
              this.$router.push('/weddings/wedding/'+this.budgetPlan.weddingId+'/budgetPlan/'+this.budgetPlan.id).then(()=>{
                location.reload()
              }).finally(()=>{
                this.loaded=true
              })
            },1000)

          }).catch((err) => {
            this.message = error(err)
          })
        }else if(this.$route.path.includes('/timePlan/')){
            this.$store.dispatch('timePlan/getTimePlan', {
          timePlanId: this.$route.params.id
        }).then(() => {
            this.timePlan1 = this.timePlan
            this.loaded=true
            setTimeout(()=>{

              print()
              this.$router.push('/weddings/wedding/'+this.timePlan.weddingId+'/timePlan/'+this.timePlan.categoryId).then(()=>{
                location.reload()
              }).finally(()=>{
                this.loaded=true
              })
              this.loaded = true
            },1000)

          }).catch((err) => {
            this.message = error(err)
          })
        }
      }).catch((err)=>{
        console.log(err)
      })
    })

  },
}
</script>
<style>
@media screen
{
  #screenarea
  {
    display: block;
  }
  #printarea
  {
    display: block;
  }
}
@media print {
  #printarea
  {
    display: block;
  }
  #screenarea
  {
    display: none;
  }
  .pageBreaker
  {
    page-break-before: always;
  }
}

</style>
