<template>
  <v-container id="avv">

    <div class=WordSection1>
      <p class=1StandardText align=center style='margin-top:12.0pt;margin-right:0cm;
margin-bottom:12.0pt;margin-left:0cm;text-align:center;line-height:normal'><a
          name=MerkPosIDE></a><span style='font-size:23.0pt'>Vertrag über die
Auftragsverarbeitung </span></p>

      <p class=1StandardText align=center style='margin-top:12.0pt;margin-right:0cm;
margin-bottom:12.0pt;margin-left:0cm;text-align:center;line-height:normal'><span
          style='font-size:23.0pt'>personenbezogener Daten gemäß Art. 28 DSGVO </span></p>

      <p class=1StandardText align=center style='margin-top:12.0pt;margin-right:0cm;
margin-bottom:12.0pt;margin-left:0cm;text-align:center;line-height:150%'><b><span
          style='line-height:150%'>&nbsp;</span></b></p>

      <p class=1StandardText align=center style='margin-top:12.0pt;margin-right:0cm;
margin-bottom:12.0pt;margin-left:0cm;text-align:center;line-height:150%'><b><span
          style='line-height:150%'>zwischen</span></b></p>

      <p class=1StandardText align=center style='margin-top:12.0pt;margin-right:0cm;
margin-bottom:12.0pt;margin-left:0cm;text-align:center;line-height:150%'><b><span
          style='line-height:150%'>&nbsp;</span></b></p>

      <p class=1StandardText align=center style='text-align:center;line-height:150%'><b><span
          style='line-height:150%'>{{ acUser.companyName }}</span></b></p>

      <p class=1StandardText align=center style='text-align:center;line-height:150%'><b><span
          style='line-height:150%'>{{ acUser.firstname }} {{ acUser.lastname }}</span></b></p>

      <p class=1StandardText align=center style='text-align:center;line-height:150%'><b><span
          style='line-height:150%'>{{ acUser.street }} {{ acUser.streetNumber }}</span></b></p>

      <p class=1StandardText align=center style='text-align:center;line-height:150%'><b><span
          style='line-height:150%'>{{ acUser.plz }} {{ acUser.city }}</span></b></p>

      <p class=1StandardText align=center style='margin-left:18.0pt;text-align:center;
line-height:150%'><b><span style='line-height:150%'>im Folgendem „AuftraggeberIn“
genannt </span></b></p>

      <p class=1StandardText style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:36.0pt;line-height:150%'><b><span style='line-height:150%'>&nbsp;</span></b></p>

      <p class=1StandardText align=center style='margin-top:12.0pt;margin-right:0cm;
margin-bottom:12.0pt;margin-left:0cm;text-align:center;line-height:150%'><b><span
          style='line-height:150%'>und</span></b></p>


      <p class=1StandardText align=center style='text-align:center;line-height:150%'><b><span
          style='line-height:150%'>Benedikt Binzer (wedding-flow.de)</span></b></p>

      <p class=1StandardText align=center style='text-align:center;line-height:150%'><b><span
          style='line-height:150%'>Hans-Riggenbach-Straße 8</span></b></p>

      <p class=1StandardText align=center style='text-align:center;line-height:150%'><b><span
          style='line-height:150%'>65795 Hattersheim</span></b></p>

      <p class=1StandardText align=center style='margin-top:12.0pt;margin-right:0cm;
margin-bottom:12.0pt;margin-left:0cm;text-align:center;line-height:150%'><b><span
          style='line-height:150%'>im Folgendem „AuftragnehmerIn“ genannt </span></b></p>

      <p class=1StandardText style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:36.0pt;line-height:150%'><b><span style='line-height:150%'>&nbsp;</span></b></p>

      <p class=1StandardText style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:0cm;line-height:150%'><b><span style='line-height:150%'>&nbsp;</span></b></p>

      <p class=1StandardText style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:0cm;line-height:150%'><span style='line-height:150%'>&nbsp;</span></p>

      <p class=1StandardText style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:0cm;line-height:150%'><span style='line-height:150%'>&nbsp;</span></p>

      <p class=1StandardText style='margin-top:12.0pt;margin-right:0cm;margin-bottom:
12.0pt;margin-left:0cm;line-height:150%'><span style='line-height:150%'>&nbsp;</span></p>

      <p class=MsoNormal><span style='line-height:115%'>&nbsp;</span></p>

      <p class=MsoNormal><span style='line-height:115%'>&nbsp;</span></p>

      <h1 class="breakText"><a name="_Toc343081685"></a><a name="_Toc343094511"></a><a
          name="_Toc343094932"></a><a name="_Toc343440148"></a><a name="_Toc499648545">1.<span
          style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Einführung</a></h1>

      <h3 style='margin-left:42.55pt;text-indent:0cm'><a name="_Toc499648546">Es ist
        erforderlich, dass die AuftragnehmerIn mit personenbezogenen Daten umgeht, für
        die die AuftraggeberIn als Verantwortliche im Sinne der datenschutzrechtlichen
        Vorschriften fungiert (nachfolgend „Auftraggeber-Daten“ genannt). Dieser
        Vertrag konkretisiert die datenschutzrechtlichen Rechte und Pflichten der
        Parteien im Zusammenhang mit dem Umgang des Auftragnehmers mit
        Auftraggeber-Daten. Für die im Rahmen dieses Vertrages verwendeten Begriffe
        gelten die Definitionen der Datenschutzgrundverordnung (DSGVO).</a></h3>

      <h1>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Vereinbarung<br>
        <br>
      </h1>

      <h2><a name="_Toc499648547">2.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Gegenstand und Dauer des Auftrages</a></h2>

      <h3>2.1.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Die AuftragnehmerIn erbringt für die AuftraggeberIn im Bereich
        Datenverwaltung von Kunden-, Auftrags-, sowie Geschäftspartnerdaten und den
        eigenen Terminen und Aufgaben der AuftraggeberIn. Dafür erhält die AuftragnehmerIn
        Zugriff auf personenbezogene Daten der AuftraggeberIn. Diese Vereinbarung
        erfolgt auf der Grundlage der DSGVO. Sie bezieht sich auf alle mit den
        vereinbarten Leistungen und Gegenleistungen zwischen den Vertragsparteien und
        auf die Vergabe und Durchführung von Unterauftragsverträgen.</h3>

      <h3>2.1.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Die Vereinbarung ist auf unbestimmte Zeit geschlossen und kann von
        beiden Parteien mit einer Frist von einen Monat zum Monatsende gekündigt
        werden.</h3>

      <h3 style='text-indent:0cm'>Die AuftraggeberIn kann den Vertrag jederzeit kündigen,
        wenn ein schwerwiegender Verstoß der AuftragnehmerIn gegen die Bestimmungen
        dieses Vertrages oder die DSGVO vorliegt, die AuftragnehmerIn eine Weisung der AuftraggeberIn
        nicht ausführen kann oder will oder die AuftragnehmerIn vertragliche oder
        gesetzlich vorgeschriebene Kontrollmaßnahmen der AuftraggeberIn vertragswidrig
        verweigert.</h3>

      <h2><a name="_Toc499648548">2.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Art und Zweck der Verarbeitung; Art der Daten; </a>Kategorien der
        Betroffenen</h2>

      <h3 style='margin-left:89.4pt'>2.2.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Im Wesentlichen geht es um Daten von Kunden und Aufträgen, sowie Geschäftspartnern
        und den eigenen Terminen und Aufgaben, sowie E-Mails, Rechnungen und Angeboten der AuftraggeberIn.</h3>

      <h3>2.2.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>&nbsp;</h3>

      <h2 style='text-indent:0cm'>Die AuftragnehmerIn darf die ihr von der AuftraggeberIn
        zur Verfügung gestellten personenbezogenen Daten ausschließlich für die
        Erbringung der vertragsgegenständlichen Leistungen verwenden. Eine
        weitergehende Verwendung der Daten, insbesondere zu eigenen Zwecken der AuftragnehmerIn
        oder zu Zwecken Dritter, ist unzulässig.</h2>

      <p class=1StandardText><span style='background:yellow'>&nbsp;</span></p>

      <h3 class="breakText">2.2.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Art der Daten </h3>

      <p class=1StandardText style='margin-left:70.9pt;line-height:150%'>Bei den
        Daten der AuftraggeberIn handelt es sich um Nutzerdaten und Daten von
        Geschäftspartnern und Kunden der Nutzer, die unter Verwendung Ihrer Marke von
        der AuftragnehmerIn erhoben werden. Datenfelder sind Name, Vorname,
        Unternehmensname, E-Mailadresse, Geburtsdatum sowie Liefer- und
        Rechnungsadresse und Bankkonten. Nutzer können Personenbezogene Daten und Daten
        zu Kunden (Name, Anschrift, E-Mail, Telefon, Status, etc.), Daten zu
        Geschäftspartnern (Name, Anschrift, E-Mail, Telefon, Status, Eigene Kategorien,
        etc.), Daten zu Aufträgen der Kunden (Wichtige Daten, Budget-pläne,
        Tagesabläufe, Gästelisten (Kontaktdaten der Gäste, Platzpläne etc.), Aufgaben, Historien zu Emails, Anrufen, sonstige
        Notizen, etc.), Daten zur Rechnungs und Angebotsstellung (Steuernummer, USt-ID, Mehrwertsteuer-ID, Rechnungsadressen, Rechnungsdaten, Positionen, Preise, Steuersätze etc.), sowie Termine und Aufgaben erfassen. Zusätzlich hat der Nutzer
        die Möglichkeit eigene Datenfelder anzulegen und diese nach Belieben zu füllen.</p>

      <h1>3.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Pflichten der AuftragnehmerIn </h1>

      <h2>3.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Die AuftragnehmerIn verarbeitet die Auftraggeber-Daten ausschließlich im
        Auftrag und nach Weisung der AuftraggeberIn i.S.v. Art. 28 DSGVO
        (Auftragsverarbeitung). Die AuftraggeberIn bleibt im datenschutzrechtlichen
        Sinne Verantwortliche (Art. 4 Nr. 7 DSGVO) und ist insbesondere für die
        Rechtmäßigkeit der auftragsgemäßen Erhebung, Verarbeitung und/oder Nutzung solcher
        Daten verantwortlich. Hiervon unberührt ist die Verpflichtung der AuftragnehmerIn
        selbst, die auf sie anwendbaren datenschutzrechtlichen Bestimmungen
        einzuhalten.</h2>

      <h2>3.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Die etwaige Erhebung, Verarbeitung und/oder Nutzung von Daten im Namen der
        AuftraggeberIn innerhalb der Europäischen Union (EU) bzw. des Europäischen
        Wirtschaftsraums (EWR).</h2>

      <h2>3.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Die AuftragnehmerIn darf Daten von Betroffenen nur im Rahmen des
        Auftrages und der Weisungen der AuftraggeberIn verarbeiten, außer es liegt ein
        Ausnahmefall im Sinne des Art. 28 Abs. 3 a) DSGVO vor. Die AuftragnehmerIn ist
        verpflichtet, den datenschutzrechtlichen Weisungen aus dem Hauptvertrag und den
        im Einzelfall von der AuftraggeberIn erteilten datenschutzrechtlichen Weisungen
        zur Erhebung, Verarbeitung und/oder Nutzung der ihrer Daten uneingeschränkt zu
        folgen. Weisungen bedürfen der Textform. Mündlich erteilte Weisungen sind
        unverzüglich in Textform zu bestätigen.</h2>

      <h2>3.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Ist die AuftragnehmerIn der Ansicht, eine Weisung verstoße gegen
        gesetzliche Vorschriften und/oder den Hauptvertrag, so ist die AuftragnehmerIn verpflichtet,
        die AuftraggeberIn hierauf unverzüglich hinzuweisen, sowie berechtigt, die
        Ausführung der Weisung bis zu einer Bestätigung der Weisung durch die AuftraggeberIn
        auszusetzen.</h2>

      <h2>3.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Anfragen Betroffener oder Dritter zur vereinbarten Auftragsverarbeitung
        sind unverzüglich an die AuftraggeberIn weiterzuleiten.</h2>

      <h2 class="breakText">3.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Die AuftragnehmerIn informiert die AuftraggeberIn unverzüglich über</h2>

      <h2 style='margin-left:92.75pt;text-indent:-18.0pt'><span style='font-family:
Wingdings'>§<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span>Störungen
        und Verstöße der AuftragnehmerIn oder der bei ihr beschäftigten Personen gegen
        datenschutzrechtliche Bestimmungen oder die im Auftrag getroffenen
        Festlegungen,</h2>

      <h2 style='margin-left:92.75pt;text-indent:-18.0pt'><span style='font-family:
Wingdings'>§<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span>den
        Verdacht auf Datenschutzverletzungen oder Unregelmäßigkeiten bei der
        Verarbeitung personenbezogener Daten sowie</h2>

      <h2 style='margin-left:92.75pt;text-indent:-18.0pt'><span style='font-family:
Wingdings'>§<span style='font:7.0pt "Times New Roman"'>&nbsp; </span></span>Kontrollhandlungen
        und Maßnahmen zuständiger Aufsichtsbehörden, soweit diese die
        vertragsgegenständlichen Leistungen betreffen. </h2>

      <h2>3.7<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Die AuftragnehmerIn unterstützt die AuftraggeberIn bei der Erfüllung ihrer
        Pflichten nach Art. 32 bis 36 DSGVO. Meldungen nach diesen Vorschriften erfolgen
        ausschließlich durch die AuftraggeberIn.</h2>

      <h2>3.8<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Die AuftragnehmerIn verpflichtet sich, bei der auftragsgemäßen
        Verarbeitung der personenbezogenen Daten der AuftraggeberIn die Vertraulichkeit
        zu wahren. Diese besteht auch nach Beendigung des Vertrages fort. Auskünfte
        über personenbezogene Daten aus dem Auftragsverhältnis an Dritte oder
        Betroffene darf die AuftragnehmerIn nur nach vorheriger Weisung oder Zustimmung
        durch die AuftraggeberIn erteilen.</h2>

      <h2>3.9<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Soweit die Daten in einer Privatwohnung verarbeitet werden sollen (z.B.
        Heimarbeit), ist vorher der Zugang zur Wohnung der Beschäftigten für
        Kontrollzwecke des Arbeitgebers vertraglich sicher zu stellen. Die Sicherheit
        der Verarbeitung i.S.d. Art. 32 DSGVO ist auch in diesem Fall zu gewährleisten.</h2>

      <h2>3.10<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Die AuftragnehmerIn bestätigt, dass ihr die für die Auftragsverarbeitung
        einschlägigen datenschutzrechtlichen Vorschriften der DSGVO bekannt sind. </h2>

      <h1>4.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Technische und organisatorische Maßnahmen nach Art. 32 DSGVO</h1>

      <h2>4.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Die AuftragnehmerIn verpflichtet sich, technische und organisatorische
        Maßnahmen (TOM`s) zur Wahrung der Vertraulichkeit, Verfügbarkeit, Integrität
        und Authentizität der ihr von der AuftraggeberIn zur Verfügung gestellten
        personenbezogenen Daten zu treffen, und zwar in dem durch die einschlägigen
        Datenschutzvorschriften (insbesondere Art. 24 und 32 DSGVO) vorgesehenen
        Umfang. Die TOMs sind in der Anlage 1 zu diesem Vertrag aufgeführt. </h2>

      <h2>4.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Die AuftragnehmerIn hat bei gegebenem Anlass, mindestens jedoch
        jährlich, eine Überprüfung, Bewertung und Evaluierung der Wirksamkeit der
        technischen und organisatorischen Maßnahmen zur Gewährleistung der Sicherheit
        der Verarbeitung durchzuführen (Art. 32 Abs. 1 lit. d DSGVO). Das Ergebnis samt
        vollständigem Auditbericht ist der AuftraggeberIn mitzuteilen.“</h2>

      <h2>4.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Die bei der AuftragnehmerIn getroffenen Maßnahmen sind von dieser in
        Abstimmung mit der AuftraggeberIn während der Vertragslaufzeit kontinuierlich
        weiterzuentwickeln und an veränderte Gegebenheiten anzupassen. Wesentliche
        Änderungen muss die AuftragnehmerIn mit der AuftraggeberIn in dokumentierter
        Form schriftlich abstimmen. </h2>

      <h2>4.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Die für die AuftraggeberIn verarbeiteten Daten sind von sonstigen
        Datenbeständen zu trennen. Kopien oder Duplikate hiervon dürfen ohne Wissen der
        AuftraggeberIn nicht angefertigt werden.</h2>

      <h2 class="breakText">4.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Datenträger, die von der AuftraggeberIn stammen bzw. für die AuftraggeberIn
        genutzt werden, werden besonders gekennzeichnet. Sie sind jederzeit angemes­sen
        aufzubewahren und dürfen unbefugten Personen nicht zugänglich sein. Ein- und
        Ausgänge sind zu dokumentieren.</h2>

      <h1>5.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Drittstaatentransfer</h1>

      <h2>5.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Die AuftragnehmerIn verpflichtet sich, die Verarbeitung und Nutzung der
        ihr von der AuftraggeberIn zur Verfügung gestellten personenbezogenen Daten,
        ausschließlich auf dem Gebiet der Bundesrepublik Deutschland, in einem anderen
        Mitgliedstaat der Europäischen Union oder in einem anderen Vertragsstaat des
        Abkommens über den Europäischen Wirtschaftsraum durchzuführen. </h2>

      <h2>5.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Eine Erhebung der Daten in Drittstaaten bedarf der vorherigen
        schriftlichen Zustimmung der AuftraggeberIn und unterliegt darüber hinaus den
        besonderen Anforderungen der Art. 44 ff. DSGVO (z. B. Angemessenheitsbeschluss
        der Kommission, Standarddatenschutzklauseln, genehmigte Verhaltensregeln).</h2>

      <h1>6.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Regelungen zur Berichtigung, Löschung und Sperrung von Daten;
        Datenrückgabe</h1>

      <h2>6.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Die AuftragnehmerIn verpflichtet sich, Daten, die im Rahmen des
        Auftrages mit der AuftraggeberIn verarbeitet wurden, nur entsprechend der
        vertraglichen Vereinbarung oder auf ausdrückliche Weisung der AuftraggeberIn zu
        berichtigen, zu löschen oder zu sperren. </h2>

      <h2>6.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Die AuftragnehmerIn hat nach Abschluss der vertragsgegenständlichen
        Leistungen oder auf Verlangen der AuftraggeberIn dieser die bereitgestellten
        Daten in einem zu vereinbarenden Format auszuhändigen. </h2>

      <h2>6.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Nach schriftlicher Freigabe durch die AuftraggeberIn sind die
        bereitgestellten Daten sodann datenschutzgerecht zu löschen, soweit keine
        gesetzlichen Aufbewahrungspflichten entgegenstehen. Auf Verlangen hat die AuftragnehmerIn
        der AuftraggeberIn das Löschungsprotokoll vorzulegen.</h2>

      <h1>7.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Qualitätssicherung und sonstige Pflichten der Auftragsnehmerin </h1>

      <h2>7.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Die AuftragnehmerIn hat zusätzlich zur Einhaltung der Regelungen dieses
        Auftrages gesetzliche Pflichten gemäß Art. 28 bis 33 DSGVO; insofern
        gewährleistet sie insbesondere die Einhaltung folgender Vorgaben:</h2>

      <p class=1StandardText style='margin-left:108.0pt;text-indent:-18.0pt;
line-height:150%'>a)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span>Führen eines Verarbeitungsverzeichnisses gemäß Art. 30 Abs. 2 DSGVO zu
        den von ihr im Auftrag der AuftraggeberIn durchgeführten
        Verarbeitungstätigkeiten. </p>

      <p class=1StandardText style='margin-left:108.0pt;text-indent:-18.0pt;
line-height:150%'>b)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span>Benennung eines Datenschutzbeauftragten: Sofern Art. 37 DSGVO die
        Benennung eines Datenschutzbeauftragten vorsieht, wird die AuftragnehmerIn einen
        Datenschutzbeauftragten bestellen, der seine Tätigkeit gemäß Art. 38 und 39
        DSGVO ausübt. Dessen jeweils aktuelle Kontaktdaten sind der AuftraggeberIn zum
        Zweck der direkten Kontaktaufnahme mitzuteilen. <span lang=EN-GB>Ein Wechsel
des Datenschutzbeauftragten wird der AuftraggeberIn unverzüglich mitgeteilt. </span></p>

      <p class="1StandardText breakText" style='margin-left:108.0pt;text-indent:-18.0pt;
line-height:150%'>c)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span>Vertraulichkeit: Die AuftragnehmerIn ist zudem verpflichtet, alle im
        Rahmen des Vertragsverhältnisses erlangten Kenntnisse von Geschäftsgeheimnissen
        und Datensicherheitsmaßnahmen der AuftraggeberIn vertraulich zu behandeln,
        insbesondere gemäß der Geschäftsgeheimnis-Richtlinie RL 2016/943/EU. Diese
        Verpflichtung bleibt auch nach Beendigung dieses Vertrages bestehen.</p>

      <p class=1StandardText style='margin-left:108.0pt;text-indent:-18.0pt;
line-height:150%'>d)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span>Einsatz geeigneten Personals: Die AuftragnehmerIn verpflichtet sich, zur
        Erbringung der vertragsgegenständlichen Leistungen nur solche Personen
        einzusetzen, die ihr unterstellt sind und durch geeignete Maßnahmen mit den
        gesetzlichen Vorschriften über den Datenschutz und die speziellen
        datenschutzrechtlichen Anforderungen dieses Vertrages vertraut gemacht wurden. Diese
        Personen müssen umfassend schriftlich zur Vertraulichkeit sowie zur Wahrung von
        Betriebs- und Geschäftsgeheimnissen der AuftraggeberIn verpflichtet worden
        sein. Die AuftragnehmerIn stellt sicher, dass die ihr unterstellten Personen
        personenbezogene Daten der AuftraggeberIn ausschließlich auf Weisung der AuftragnehmerIn
        verarbeiten. Dies ist der AuftraggeberIn auf ihr Verlangen hin in geeigneter
        Form nachzuweisen.</p>

      <p class=1StandardText style='margin-left:108.0pt;text-indent:-18.0pt;
line-height:150%'>e)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span>Umsetzung und Einhaltung aller für diesen Auftrag erforderlichen technischen
        und organisatorischen Maßnahmen gemäß Art. 28 Abs. 3 S. 2 lit. c, 32 DSGVO (Einzelheiten
        in Anlage 1 TOM`s).</p>

      <p class=1StandardText style='margin-left:108.0pt;text-indent:-18.0pt;
line-height:150%'>f)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Unterstützung der AuftraggeberIn durch die AuftragnehmerIn bei allen
        Anfragen der Aufsichtsbehörde und bei der Einhaltung der Pflichten aus den Art.
        32 bis 36 DSGVO. </p>

      <p class=1StandardText style='margin-left:108.0pt;text-indent:-18.0pt;
line-height:150%'>g)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span>Unverzügliches Informieren der AuftraggeberIn über Kontrollhandlungen
        und Maßnahmen der Aufsichtsbehörde, soweit sie sich auf diesen Auftrag
        beziehen. Dies gilt auch, wenn eine zuständige Behörde im Rahmen eines
        Ordnungswidrigkeits- oder Strafverfahrens in Bezug auf diese vertraglich
        vereinbarte Verarbeitung personenbezogener Daten bei der AuftragnehmerIn ermittelt.</p>

      <p class=1StandardText style='margin-left:108.0pt;text-indent:-18.0pt;
line-height:150%'>h)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
</span>Unterstützung der AuftraggeberIn, sofern sie einer Kontrolle der
        Aufsichtsbehörde, einem Ordnungswidrigkeits- oder Strafverfahren, dem
        Haftungsanspruch einer betroffenen Person oder eines Dritten oder einem anderen
        Anspruch im Zusammenhang mit der Auftragsverarbeitung bei der AuftragnehmerIn ausgesetzt
        ist.</p>

      <p class=1StandardText style='margin-left:108.0pt;text-indent:-18.0pt;
line-height:150%'>i)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Selbstkontrolle: Die AuftragnehmerIn kontrolliert regelmäßig die
        internen Prozesse sowie die technischen und organisatorischen Maßnahmen, um zu
        gewährleisten, dass die Verarbeitung in ihrem Verantwortungsbereich im Einklang
        mit den Anforderungen des geltenden Datenschutzrechts erfolgt und der Schutz
        der Rechte der betroffenen Person gewährleistet wird.</p>

      <p class="1StandardText breakText" style='margin-left:108.0pt;text-indent:-18.0pt;
line-height:150%'>j)<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Nachweisbarkeit der getroffenen technischen und organisatorischen
        Maßnahmen gegenüber der AuftraggeberIn im Rahmen ihrer Kontrollbefugnisse nach
        diesem Vertrag.</p>

      <h1>8.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Unterauftragsverhältnis </h1>

      <p class=1StandardText>&nbsp;</p>

      <h2>8.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Als Unterauftragsverhältnisse im Sinne dieser Regelung sind solche
        Dienstleistungen zu verstehen, die sich unmittelbar auf die Erbringung der
        Hauptleistung beziehen. Nicht hierzu gehören Nebenleistungen, die die AuftragnehmerIn
        z.B. als Telekommunikationsleistungen, Post-/Transportdienstleistungen, Wartung
        und Benutzerservice oder die Entsorgung von Datenträgern sowie sonstige
        Maßnahmen zur Sicherstellung der Vertraulichkeit, Verfügbarkeit, Integrität und
        Belastbarkeit der Hard- und Software von Datenverarbeitungsanlagen in Anspruch
        nimmt, sofern dabei ein Zugriff auf personenbezogene Daten ausgeschlossen
        werden kann. Die AuftragnehmerIn ist jedoch verpflichtet, zur Gewährleistung
        des Datenschutzes und der Datensicherheit der Daten der AuftraggeberIn auch bei
        ausgelagerten Nebenleistungen angemessene und gesetzeskonforme vertragliche
        Vereinbarungen sowie Kontrollmaßnahmen zu ergreifen.</h2>

      <h2>8.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Die AuftraggeberIn stimmt bereits mit Unterzeichnung dieses Vertrages den
        folgenden UnterauftragnehmerInnen zu. </h2>

      <p class=1StandardText>&nbsp;</p>

      <table class=MsoTableGrid border=1 cellspacing=0 cellpadding=0
             style='margin-left:62.1pt;border-collapse:collapse;border:none'>
        <tr>
          <td width=219 valign=top style='width:164.0pt;border:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt'>
            <p class=1StandardText>Name und Anschrift der Unterauftragsnehmerin </p>
          </td>
          <td width=300 valign=top style='width:224.7pt;border:solid windowtext 1.0pt;
  border-left:none;padding:0cm 5.4pt 0cm 5.4pt'>
            <p class=1StandardText>Beschreibung der Teilleistung </p>
          </td>
        </tr>
        <tr>
          <td width=219 valign=top style='width:164.0pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
            <p class=1StandardText>Sendinblue GmbH </p>
            <p class=1StandardText>Köpenicker Str. 126, </p>
            <p class=1StandardText>10179 Berlin</p>
          </td>
          <td width=300 valign=top style='width:224.7pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt'>
            <p class=1StandardText>Automatischer Versand von E-Mails, Newsletter und das
              Buchen von Meetings</p>
          </td>
        </tr>
        <tr>
          <td width=219 valign=top style='width:164.0pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt'>
            <p class=1StandardText>STRATO AG</p>
            <p class=1StandardText align=left style='text-align:left'><span
                style='color:black'>Otto-Ostrowski-Straße 7<br>
  10249 Berlin</span></p>
          </td>
          <td width=300 valign=top style='width:224.7pt;border-top:none;border-left:
  none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt'>
            <p class=1StandardText>Server-Hosting, Domains und E-Mail-Postfächer</p>
          </td>
        </tr>
      </table>

      <p class=1StandardText>&nbsp;</p>

      <h2>8.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Die AuftragnehmerIn ist im Rahmen ihrer vertraglichen Verpflichtungen
        zur Begründung weiterer Unterauftragsverhältnissen befugt. Sie setzt die AuftraggeberIn
        vorab darüber in Kenntnis und setzt ihr eine angemessene Frist, der
        Einschaltung der UnterauftragnehmerIn zu widersprechen. Erfolgt kein Widerspruch,
        gilt die Zustimmung als erteilt.</h2>

      <h2 class="breakText">8.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Die AuftragnehmerIn verpflichtet sich, die UnterauftragnehmerIn unter
        besonderer Berücksichtigung der Eignung der von der UnterauftragnehmerIn getroffenen
        technischen und organisatorischen Maßnahmen zum Schutz der personenbezogenen
        Daten sorgfältig auszuwählen und entsprechend den Vorgaben dieses Vertrags auf
        die Einhaltung der einschlägigen Datenschutzvorschriften zu verpflichten. Die
        Verpflichtung muss auch das Recht der AuftragnehmerIn umfassen, die Einhaltung
        der Datenschutzvorschriften direkt und im gleichen Umfang bei der UnterauftragnehmerIn
        zu überprüfen, wie dies nach diesem Vertrag der AuftraggeberIn bei der AuftragnehmerIn
        gestattet ist.</h2>

      <h2>8.5<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Der Vertrag mit der UnterauftragnehmerIn ist schriftlich oder in einem
        elektronischen Format abzufassen. Darin sind die Verantwortlichkeiten der AuftragnehmerIn
        und der UnterauftragnehmerIn transparent voneinander abzugrenzen. Werden
        mehrere UnterauftragnehmerInnen eingesetzt, so gilt dies auch für die
        Verantwortlichkeiten zwischen diesen UnterauftragnehmerIn.</h2>

      <h2>8.6<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Eine Beauftragung von UnterauftragnehmerInnen in Drittstaaten darf nur
        erfolgen, wenn die besonderen Voraussetzungen der Art. 44 ff. DSGVO erfüllt
        sind (z. B. Angemessenheitsbeschluss der Kommission,
        Standarddatenschutzklauseln, genehmigte Verhaltensregeln).</h2>

      <h2>8.7<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Die Weiterleitung von Daten an die UnterauftragnehmerIn ist erst
        zulässig, wenn die UnterauftragnehmerIn die Erfüllung aller Anforderungen der
        Art. 28 bis 36 DSGVO und dieses Vertrages erfüllt und nachgewiesen hat. Die AuftragnehmerIn
        prüft und dokumentiert dies. </h2>

      <h2>8.8<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Die AuftragnehmerIn hat die Einhaltung der Pflichten der UnterauftragnehmerIn
        regelmäßig, mindestens jedoch jährlich, angemessen zu überprüfen. Die Prüfung
        und ihr Ergebnis sind so zu dokumentieren, dass sie für einen fachkundigen
        Dritten nachvollziehbar sind. Die Dokumentation ist der AuftraggeberIn unaufgefordert
        vorzulegen. </h2>

      <h2>8.9<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Die AuftragnehmerIn haftet im Verhältnis zur AuftraggeberIn dafür, dass die
        UnterauftragnehmerIn den gesetzlichen und vertraglichen Datenschutzpflichten
        nachkommt, die ihr durch die UnterauftragnehmerIn, im Einklang mit diesem
        Vertrag, auferlegt wurden.</h2>

      <h1>9.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Kontrollrechte der AuftraggeberIn</h1>

      <h2>9.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Die AuftraggeberIn hat das Recht, im Benehmen mit der AuftragnehmerIn Überprüfungen
        durchzuführen oder durch im Einzelfall zu benennende Prüfer durchführen zu
        lassen. Sie hat das Recht, sich durch Stichprobenkontrollen, die in der Regel
        rechtzeitig anzumelden sind, von der Einhaltung dieser Vereinbarung durch die AuftragnehmerIn
        in deren Geschäftsbetrieb zu überzeugen. </h2>

      <h2>9.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Die AuftragnehmerIn stellt sicher, dass sich die AuftraggeberIn von der
        Einhaltung der Pflichten der AuftragnehmerIn nach Art. 28 DSGVO überzeugen
        kann. Die AuftragnehmerIn verpflichtet sich, der AuftraggeberIn auf Anforderung
        die erforderlichen Auskünfte zu erteilen und insbesondere die Umsetzung der
        technischen und organisatorischen Maßnahmen nachzuweisen. </h2>

      <h2 class="breakText">9.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Der Nachweis solcher Maßnahmen, die nicht nur den konkreten Auftrag
        betreffen, kann erfolgen durch aktuelle Testate, Berichte oder Berichtsauszüge
        unabhängiger Instanzen (z.B. Wirtschaftsprüfer, Revision,
        Datenschutzbeauftragter, IT-Sicherheitsabteilung, Datenschutzauditoren,
        Qualitätsauditoren).</h2>

      <h1>10.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Weisungsbefugnis der AuftraggeberIn</h1>

      <h2>10.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Mündliche Weisungen bestätigt die AuftraggeberIn unverzüglich (mind.
        Textform). </h2>

      <h2>10.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Die AuftragnehmerIn hat die AuftraggeberIn unverzüglich zu informieren,
        wenn sie der Meinung ist, eine Weisung verstoße gegen Datenschutzvorschriften. Die
        AuftragnehmerIn ist berechtigt, die Durchführung der entsprechenden Weisung
        solange auszusetzen, bis sie durch die AuftraggeberIn bestätigt oder geändert
        wird.</h2>

      <h2>10.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Für Unterstützungsleistungen, die nicht in der Leistungsbeschreibung
        enthalten und/oder nicht auf ein Fehlverhalten der AuftragnehmerIn zurückzuführen
        sind, kann die AuftragnehmerIn eine angemessene <span class=highlight>Vergütung</span>
        beanspruchen.</h2>

      <p class=1StandardText>&nbsp;</p>

      <h1>11.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Haftung und Schadensersatz</h1>

      <h2 style='text-indent:0cm'>AuftraggeberIn und AuftragnehmerIn haften gegenüber
        betroffener Personen entsprechend der Art. 82 DSGVO getroffenen Regelung.</h2>

      <p class=1StandardText>&nbsp;</p>

      <h1>12.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Informationspflichten, Schriftformklausel, Rechtswahl</h1>

      <h2>12.1<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Sollten die Daten der AuftraggeberIn bei der AuftragnehmerIn durch
        Pfändung oder Beschlagnahme, durch ein Insolvenz- oder Vergleichsverfahren oder
        durch sonstige Ereignisse oder Maßnahmen Dritter gefährdet werden, so hat die AuftragnehmerIn
        die AuftraggeberIn unverzüglich darüber zu informieren. Die AuftragnehmerIn wird
        alle in diesem Zusammenhang Verantwortlichen unverzüglich darüber informieren,
        dass die Hoheit und das Eigentum an den Daten ausschließlich bei der AuftraggeberIn
        als »Verantwortliche« im Sinne der Datenschutzgrundverordnung liegen.</h2>

      <h2>12.2<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Änderungen und Ergänzungen dieses Vertrages und all seiner Bestandteile
        – einschließlich etwaiger Zusicherungen der AuftragnehmerIn – bedürfen einer
        schriftlichen Vereinbarung, die auch in einem elektronischen Format (Textform)
        erfolgen kann, und des ausdrücklichen Hinweises darauf, dass es sich um eine
        Änderung bzw. Ergänzung dieser Bedingungen handelt. Dies gilt auch für den
        Verzicht auf dieses Formerfordernis.</h2>

      <h2>12.3<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Bei etwaigen Widersprüchen gehen Regelungen dieses Vertrages zum
        Datenschutz den Regelungen des Hauptvertrages vor. Sollten einzelne Teile dieses
        Vertrages unwirksam sein, so berührt dies die Wirksamkeit des Vertrages im
        Übrigen nicht.</h2>

      <h2>12.4<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span>Es gilt deutsches Recht.</h2>

      <p class=1StandardText>&nbsp;</p>

      <p class=MsoNormal style='margin-bottom:6.0pt;text-align:justify;line-height:
normal'>&nbsp;</p>

      <p class="MsoNormal breakText" style='margin-bottom:6.0pt;text-align:justify;line-height:
normal'><b>Anlagen:</b></p>

      <p class=MsoListParagraph style='margin-bottom:6.0pt;text-align:justify;
text-indent:-18.0pt;line-height:normal'><span style='font-family:"Arial",sans-serif'>1.<span
          style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
          style='font-family:"Arial",sans-serif'>Technische und Organisatorische
Maßnahmen (Anhang 1)</span></p>

      <p class=MsoNormal align=center style='margin-bottom:6.0pt;text-align:center;
line-height:normal'>&nbsp;</p>

      <p class=1StandardText style='margin-left:36.0pt'>&nbsp;</p>

      <b v-if="print"><p class=1StandardText style='margin-left:36.0pt; font-weight: bold'><b>Dem Vertrag wurde am
        {{ getStringDate(acUser.created) }}
        durch {{ acUser.firstname }} {{ acUser.lastname }} zugestimmt.</b></p></b>

      <p class=1StandardText style='margin-left:36.0pt'>&nbsp;</p>

      <p class=1StandardText style='margin-left:36.0pt'>&nbsp;</p>

      <span style='font-size:10.0pt;line-height:115%;font-family:"Arial",sans-serif'><br
          clear=all style='page-break-before:always'>
</span>

      <p class=MsoNormal><span style='font-size:11.0pt;line-height:115%'>&nbsp;</span></p>

      <p class=1StandardText align=right style='margin-left:36.0pt;text-align:right'><a
          name="_Hlk32309945"><b>Anhang 1</b></a></p>

      <p class=1StandardText align=left style='margin-left:36.0pt;text-align:left'>&nbsp;</p>

      <p class=MsoNormal align=center style='margin-top:0cm;margin-right:1.2pt;
margin-bottom:.7pt;margin-left:3.85pt;text-align:center;text-indent:-.5pt;
line-height:107%'><span style='font-size:23.0pt;line-height:107%'>Technische
und organisatorische</span></p>

      <p class=MsoNormal align=center style='margin-top:0cm;margin-right:0cm;
margin-bottom:.7pt;margin-left:3.85pt;text-align:center;text-indent:-.5pt;
line-height:107%'><span style='font-size:23.0pt;line-height:107%'>Maßnahmen zum
Datenschutz</span></p>

      <p class=MsoNormal align=center style='margin-top:0cm;margin-right:0cm;
margin-bottom:.7pt;margin-left:3.85pt;text-align:center;text-indent:-.5pt;
line-height:107%'>&nbsp;</p>

      <p class=MsoNormal align=center style='margin-top:0cm;margin-right:0cm;
margin-bottom:37.75pt;margin-left:2.65pt;text-align:center;line-height:107%'><b><span
          style='font-size:11.0pt;line-height:107%'>gemäß Art. 28 Abs. 3 lit. c, 32
DS-GVO i.V.m. Art. 5 Abs. 1, Abs. 2 DS-GVO</span></b></p>

      <p class=MsoNormal style='margin-bottom:1.45pt;line-height:150%'><b><span
          style='font-size:11.0pt;line-height:150%'>1. Vertraulichkeit</span></b></p>

      <p class=MsoNormal style='margin-bottom:5.65pt;line-height:150%'><b><span
          style='font-size:11.0pt;line-height:150%'>1.1 Zutrittskontrolle</span></b></p>

      <p class=MsoNormal style='margin-left:.2pt;line-height:150%'><span
          style='font-size:11.0pt;line-height:150%'>Unbefugten ist der Zutritt zu den vom
Auftragnehmer zwecks Erbringung der ihm übertragenen Leistungen genutzten
technischen Einrichtungen zu verwehren.</span></p>

      <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:.7pt;text-indent:-.5pt;line-height:107%'><b><span style='font-size:
11.0pt;line-height:107%'>Beim Auftragnehmer umgesetzte Maßnahmen:</span></b></p>

      <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:.7pt;text-indent:-.5pt;line-height:107%'><span style='font-size:
11.0pt;line-height:107%'>&nbsp;</span></p>

      <table class=TableGrid border=0 cellspacing=0 cellpadding=0 width=605
             style='width:453.45pt;margin-left:.4pt;border-collapse:collapse'>
        <tr style='height:17.5pt'>
          <td width=605 valign=top style='width:453.45pt;border:solid black 1.0pt;
  padding:3.0pt 5.75pt 0cm 5.35pt;height:17.5pt'>
            <p class=MsoNormal style='margin-bottom:0cm;line-height:107%'><span
                style='font-size:11.0pt;line-height:107%'>Türschloss</span></p>
          </td>
        </tr>
        <tr style='height:17.5pt'>
          <td width=605 valign=top style='width:453.45pt;border:solid black 1.0pt;
  border-top:none;padding:3.0pt 5.75pt 0cm 5.35pt;height:17.5pt'>
            <p class=MsoNormal style='margin-bottom:0cm;line-height:107%'><span
                style='font-size:11.0pt;line-height:107%'>&nbsp;</span></p>
          </td>
        </tr>
      </table>

      <p class=MsoNormal style='margin-bottom:5.65pt;line-height:107%'><span
          style='font-size:11.0pt;line-height:107%'>&nbsp;</span></p>

      <p class=MsoNormal style='margin-bottom:5.65pt;line-height:150%'><b><span
          style='font-size:11.0pt;line-height:150%'>1.2 Zugangskontrolle</span></b></p>

      <p class=MsoNormal style='margin-left:.2pt;line-height:150%'><span
          style='font-size:11.0pt;line-height:150%'>Es ist zu verhindern, dass die zur
Erbringung der in der beschriebenen IT-Dienstleistung notwendigen Einrichtungen
(Hardware, Betriebssysteme, Software) von Unbefugten genutzt werden können.</span></p>

      <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:.7pt;text-indent:-.5pt;line-height:107%'><b><span style='font-size:
11.0pt;line-height:107%'>Beim Auftragnehmer umgesetzte Maßnahmen:</span></b></p>

      <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:.7pt;text-indent:-.5pt;line-height:107%'><b><span style='font-size:
11.0pt;line-height:107%'>&nbsp;</span></b></p>

      <table class=TableGrid border=0 cellspacing=0 cellpadding=0 width=605
             style='width:453.4pt;margin-left:.5pt;border-collapse:collapse'>
        <tr style='height:17.6pt'>
          <td width=605 valign=top style='width:453.4pt;border:solid black 1.0pt;
  padding:2.75pt 5.75pt 0cm 5.3pt;height:17.6pt'>
            <p class=MsoNormal style='margin-bottom:0cm;line-height:107%'><span
                style='font-size:11.0pt;line-height:107%'>Verwendung von Nutzerkennung und
  individuellem Passwort</span></p>
          </td>
        </tr>
        <tr style='height:17.35pt'>
          <td width=605 valign=top style='width:453.4pt;border:solid black 1.0pt;
  border-top:none;padding:2.75pt 5.75pt 0cm 5.3pt;height:17.35pt'>
            <p class=MsoNormal style='margin-bottom:0cm;line-height:107%'><span
                style='font-size:11.0pt;line-height:107%'>Einsatz von Firewall und
  Virenscanner</span></p>
          </td>
        </tr>
      </table>

      <p class=MsoNormal style='margin-bottom:5.65pt;line-height:107%'><span
          style='font-size:11.0pt;line-height:107%'>&nbsp;</span></p>

      <p class="MsoNormal breakText" style='margin-bottom:5.65pt;line-height:150%'><b><span
          style='font-size:11.0pt;line-height:150%'>1.3 Zugriffskontrolle</span></b></p>

      <p class=MsoNormal style='margin-top:0cm;margin-right:10.8pt;margin-bottom:
10.0pt;margin-left:.2pt;line-height:150%'><span style='font-size:11.0pt;
line-height:150%'>Es ist sicherzustellen, dass die zur Benutzung eines
Datenverarbeitungssystems Berechtigten ausschließlich auf die ihrer
Zugriffsberechtigung unterliegenden Daten zugreifen können, und dass
personenbezogene Daten bei der Verarbeitung, Nutzung und nach der Speicherung
nicht unbefugt gelesen, kopiert oder verändert werden können.</span></p>

      <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:10.1pt;
margin-left:.7pt;text-indent:-.5pt;line-height:107%'><b><span style='font-size:
11.0pt;line-height:107%'>Beim Auftragnehmer umgesetzte Maßnahmen:</span></b></p>

      <table class=MsoTableGrid border=1 cellspacing=0 cellpadding=0 width=601
             style='width:450.8pt;border-collapse:collapse;border:none'>
        <tr style='height:19.85pt'>
          <td width=601 valign=top style='width:450.8pt;border:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:19.85pt'>
            <p class=MsoNormal style='margin-bottom:26.15pt;line-height:110%'><span
                style='font-size:11.0pt;line-height:110%'>Kontrollierte Vergabe von
  Zugriffsprivilegien auf Benutzerebene</span></p>
          </td>
        </tr>
        <tr style='height:19.85pt'>
          <td width=601 valign=top style='width:450.8pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt;height:19.85pt'>
            <p class=MsoNormal style='margin-bottom:26.15pt;line-height:110%'><span
                style='font-size:11.0pt;line-height:110%'>Berechtigungskonzept</span></p>
          </td>
        </tr>
        <tr style='height:19.85pt'>
          <td width=601 valign=top style='width:450.8pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt;height:19.85pt'>
            <p class=MsoNormal style='margin-bottom:26.15pt;line-height:110%'><span
                style='font-size:11.0pt;line-height:110%'>Festlegung der Befugnis zur
  Dateneingabe, -änderung, -löschung</span></p>
          </td>
        </tr>
      </table>

      <p class=MsoNormal style='margin-bottom:12.05pt;line-height:107%'><b><span
          style='font-size:11.0pt;line-height:107%'>&nbsp;</span></b></p>

      <p class=MsoNormal style='margin-bottom:5.65pt;line-height:150%'><b><span
          style='font-size:11.0pt;line-height:150%'>1.4 Trennungskontrolle</span></b></p>

      <p class=MsoNormal style='margin-left:.2pt;line-height:150%'><span
          style='font-size:11.0pt;line-height:150%'>Es ist sicherzustellen, dass zu
unterschiedlichen Zwecken erhobene Daten getrennt verarbeitet werden können.</span></p>

      <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:11.8pt;
margin-left:.7pt;text-indent:-.5pt;line-height:107%'><b><span style='font-size:
11.0pt;line-height:107%'>Beim Auftragnehmer umgesetzte Maßnahmen:</span></b></p>

      <table class=MsoTableGrid border=1 cellspacing=0 cellpadding=0
             style='margin-left:.7pt;border-collapse:collapse;border:none'>
        <tr style='height:19.85pt'>
          <td width=600 valign=top style='width:449.9pt;border:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:19.85pt'>
            <p class=MsoNormal style='margin-bottom:11.8pt;line-height:107%'><span
                style='font-size:11.0pt;line-height:107%'>Trennung von Entwicklungs-, Test-
  und Produktivsystem</span></p>
          </td>
        </tr>
      </table>

      <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:11.8pt;
margin-left:.7pt;text-indent:-.5pt;line-height:107%'><b><span style='font-size:
11.0pt;line-height:107%'>&nbsp;</span></b></p>

      <p class=MsoNormal style='margin-bottom:5.65pt;line-height:150%'><b><span
          style='font-size:11.0pt;line-height:150%'>1.5 Pseudonymisierung</span></b></p>

      <p class=MsoNormal style='margin-top:0cm;margin-right:4.55pt;margin-bottom:
10.0pt;margin-left:.2pt'><span style='font-size:11.0pt;line-height:115%'>Die
Verarbeitung personenbezogener Daten soll in einer Weise erfolgen, dass die
Daten ohne Hinzuziehung zusätzlicher Informationen nicht mehr einer
spezifischen betroffenen Person zugeordnet werden können, sofern diese
zusätzlichen Informationen gesondert aufbewahrt werden und entsprechenden
technischen und organisatorischen Maßnahmen unterliegen.</span></p>

      <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:.7pt;text-indent:-.5pt;line-height:107%'><b><span style='font-size:
11.0pt;line-height:107%'>Beim Auftragnehmer umgesetzte Maßnahmen:</span></b></p>

      <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:.7pt;text-indent:-.5pt;line-height:107%'><b><span style='font-size:
11.0pt;line-height:107%'>&nbsp;</span></b></p>

      <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:.7pt;text-indent:-.5pt;line-height:107%'><b><span style='font-size:
11.0pt;line-height:107%'>&nbsp;</span></b></p>

      <p class=MsoNormal style='margin-bottom:5.65pt;line-height:150%'><b><span
          style='font-size:11.0pt;line-height:150%'>1.7 Verschlüsselung</span></b></p>

      <p class=MsoNormal style='margin-top:0cm;margin-right:14.4pt;margin-bottom:
10.0pt;margin-left:.2pt'><span style='font-size:11.0pt;line-height:115%'>Die
Verarbeitung personenbezogener Daten soll in einer Weise erfolgen, die eine
unbeabsichtigte oder unrechtmäßige oder unbefugte Offenlegung dieser
verhindert. Hierzu dienen dem Stand der Technik entsprechende und als sicher
geltende Verschlüsselungsmechanismen.</span></p>

      <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:.7pt;text-indent:-.5pt;line-height:107%'><b><span style='font-size:
11.0pt;line-height:107%'>Durch den Auftragnehmer umgesetzte Maßnahmen:</span></b></p>

      <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:.7pt;text-indent:-.5pt;line-height:107%'><span style='font-size:
11.0pt;line-height:107%'>&nbsp;</span></p>

      <table class=MsoTableGrid border=1 cellspacing=0 cellpadding=0
             style='margin-left:.45pt;border-collapse:collapse;border:none'>
        <tr style='height:17.55pt'>
          <td width=600 valign=top style='width:449.9pt;border:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:17.55pt'>
            <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
  margin-left:.7pt;line-height:107%'><span style='font-size:11.0pt;line-height:
  107%'>Verschlüsselung von Passwörtern</span></p>
          </td>
        </tr>
      </table>

      <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:.7pt;text-indent:-.5pt;line-height:107%'><span style='font-size:
11.0pt;line-height:107%'>&nbsp;</span></p>

      <p class="MsoNormal breakText" style='margin-bottom:1.45pt;line-height:150%'><b><span
          style='font-size:11.0pt;line-height:150%'>2. Integrität</span></b></p>

      <p class=MsoNormal style='margin-bottom:5.65pt;line-height:150%'><b><span
          style='font-size:11.0pt;line-height:150%'>2.1 Eingabekontrolle</span></b></p>

      <p class=MsoNormal style='margin-left:.2pt;line-height:150%'><span
          style='font-size:11.0pt;line-height:150%'>Es muss nachträglich geprüft und
festgestellt werden können, ob und von wem personenbezogene Daten in Datenverarbeitungssysteme
eingegeben, verändert oder entfernt worden sind.</span></p>

      <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:.2pt'><b><span style='font-size:11.0pt;line-height:115%'>Beim
Auftragnehmer umgesetzte Maßnahmen:</span></b></p>

      <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:.2pt'><b><span style='font-size:11.0pt;line-height:115%'>&nbsp;</span></b></p>

      <table class=TableGrid border=0 cellspacing=0 cellpadding=0 width=605
             style='width:453.45pt;margin-left:.4pt;border-collapse:collapse'>
        <tr style='height:17.55pt'>
          <td width=605 valign=top style='width:453.45pt;border:solid black 1.0pt;
  padding:2.3pt 5.75pt 0cm 5.6pt;height:17.55pt'>
            <p class=MsoNormal style='margin-bottom:0cm;line-height:107%'><span
                style='font-size:11.0pt;line-height:107%'>Verwendung personalisierter Logins
  im Unternehmensnetzwerk</span></p>
          </td>
        </tr>
        <tr style='height:17.55pt'>
          <td width=605 valign=top style='width:453.45pt;border:solid black 1.0pt;
  border-top:none;padding:2.3pt 5.75pt 0cm 5.6pt;height:17.55pt'>
            <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
  margin-left:.7pt;line-height:107%'><span style='font-size:11.0pt;line-height:
  107%'>Systemseitige Protokollierung der Datenzugriffe</span></p>
          </td>
        </tr>
      </table>

      <p class=MsoNormal style='margin-bottom:5.65pt;line-height:107%'><span
          style='font-size:11.0pt;line-height:107%'>&nbsp;</span></p>

      <p class=MsoNormal style='margin-bottom:5.65pt;line-height:150%'><b><span
          style='font-size:11.0pt;line-height:150%'>2.2 Weitergabekontrolle</span></b></p>

      <p class=MsoNormal style='margin-top:0cm;margin-right:7.9pt;margin-bottom:10.0pt;
margin-left:.2pt;line-height:150%'><span style='font-size:11.0pt;line-height:
150%'>Es muss dafür gesorgt werden, dass personenbezogene Daten bei der
elektronischen Übertragung oder während ihres Transports oder ihrer Speicherung
auf Datenträger nicht unbefugt gelesen, kopiert, verändert oder entfernt werden
können, und dass überprüft und festgestellt werden kann, an welchen Stellen die
Übermittlung personenbezogener Daten durch Einrichtungen zur Datenübertragung
vorgesehen ist.</span></p>

      <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:.2pt'><span style='font-size:11.0pt;line-height:115%'>&nbsp;</span></p>

      <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:12.05pt;
margin-left:.7pt;text-indent:-.5pt;line-height:107%'><b><span style='font-size:
11.0pt;line-height:107%'>Beim Auftragnehmer umgesetzte Maßnahmen:</span></b></p>

      <table class=MsoTableGrid border=1 cellspacing=0 cellpadding=0
             style='margin-left:.7pt;border-collapse:collapse;border:none'>
        <tr style='height:19.85pt'>
          <td width=600 valign=top style='width:449.9pt;border:solid windowtext 1.0pt;
  padding:0cm 5.4pt 0cm 5.4pt;height:19.85pt'>
            <p class=MsoNormal style='margin-bottom:12.05pt;line-height:107%'><span
                style='font-size:11.0pt;line-height:107%'>Verschlüsselungsverfahren
  entsprechend dem Stand der Technik</span></p>
          </td>
        </tr>
        <tr style='height:33.1pt'>
          <td width=600 valign=top style='width:449.9pt;border:solid windowtext 1.0pt;
  border-top:none;padding:0cm 5.4pt 0cm 5.4pt;height:33.1pt'>
            <p class=MsoNormal style='margin-bottom:12.05pt;line-height:107%'><span
                style='font-size:11.0pt;line-height:107%'>Kontrollierte Vernichtung von
  Datenträgern entsprechend Datenschutz und IT-Sicherheitsrichtlinie</span></p>
          </td>
        </tr>
      </table>

      <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:.2pt'><span style='font-size:11.0pt;line-height:115%'>&nbsp;</span></p>

      <p class=MsoNormal style='margin-bottom:1.45pt;line-height:150%'><b><span
          style='font-size:11.0pt;line-height:150%'>3. Verfügbarkeit und Belastbarkeit</span></b></p>

      <p class=MsoNormal style='margin-bottom:5.65pt;line-height:150%'><b><span
          style='font-size:11.0pt;line-height:150%'>3.1 Verfügbarkeitskontrolle</span></b></p>

      <p class=MsoNormal style='margin-left:.2pt;line-height:150%'><span
          style='font-size:11.0pt;line-height:150%'>Es ist dafür Sorge zu tragen, dass
personenbezogene Daten gegen zufällige Zerstörung oder Verlust geschützt sind.</span></p>

      <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:.2pt'><b><span style='font-size:11.0pt;line-height:115%'>Beim
Auftragnehmer umgesetzte Maßnahmen:</span></b></p>

      <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:.2pt'><b><span style='font-size:11.0pt;line-height:115%'>&nbsp;</span></b></p>

      <table class=TableGrid border=0 cellspacing=0 cellpadding=0 width=605
             style='width:453.4pt;margin-left:.5pt;border-collapse:collapse'>
        <tr style='height:17.35pt'>
          <td width=605 valign=top style='width:453.4pt;border:solid black 1.0pt;
  padding:2.4pt 5.75pt 0cm 5.3pt;height:17.35pt'>
            <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
  margin-left:.7pt;line-height:107%'><span style='font-size:11.0pt;line-height:
  107%'>Regelmäßige Durchführung von Datensicherungen</span></p>
          </td>
        </tr>
        <tr style='height:17.5pt'>
          <td width=605 valign=top style='width:453.4pt;border:solid black 1.0pt;
  border-top:none;padding:2.4pt 5.75pt 0cm 5.3pt;height:17.5pt'>
            <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
  margin-left:.7pt;line-height:107%'><span style='font-size:11.0pt;line-height:
  107%'>Regelmäßige Durchführung von Updates </span></p>
          </td>
        </tr>
        <tr style='height:17.5pt'>
          <td width=605 valign=top style='width:453.4pt;border:solid black 1.0pt;
  border-top:none;padding:2.4pt 5.75pt 0cm 5.3pt;height:17.5pt'>
            <p class=MsoNormal style='margin-bottom:0cm;line-height:107%'><span
                style='font-size:11.0pt;line-height:107%'>Datensicherungs- und Backupkonzept</span></p>
          </td>
        </tr>
      </table>

      <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:5.65pt;
margin-left:70.9pt;line-height:107%'><span style='font-size:11.0pt;line-height:
107%'>&nbsp;</span></p>

      <p class=MsoNormal style='margin-bottom:5.65pt;line-height:150%'><b><span
          style='font-size:11.0pt;line-height:150%'>3.2 Rasche Wiederherstellbarkeit</span></b></p>

      <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:9.85pt;
margin-left:.2pt;text-align:justify;text-indent:.2pt;line-height:150%'><span
          style='font-size:11.0pt;line-height:150%'>Es müssen Maßnahmen getroffen werden,
um Verfügbarkeit der personenbezogenen Daten und den Zugang zu ihnen bei einem
physischen oder technischen Zwischenfall rasch wiederherzustellen.</span></p>

      <p class="MsoNormal breakText" style='margin-bottom:1.45pt;line-height:150%'><b><span
          style='font-size:11.0pt;line-height:150%'>4. Weitere Maßnahmenbereiche</span></b></p>

      <p class=MsoNormal style='margin-bottom:5.65pt;line-height:150%'><b><span
          style='font-size:11.0pt;line-height:150%'>4.1 Datenschutz-Managementsystem</span></b></p>

      <p class=MsoNormal style='margin-top:0cm;margin-right:55.95pt;margin-bottom:
10.0pt;margin-left:.2pt;line-height:150%'><span style='font-size:11.0pt;
line-height:150%'>Es muss ein Verfahren zur regelmäßigen Überprüfung, Bewertung
und Evaluierung des Datenschutzes und der Wirksamkeit der festgelegten
technischen und organisatorischen Maßnahmen implementiert sein.</span></p>

      <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:.7pt;text-indent:-.5pt;line-height:107%'><b><span style='font-size:
11.0pt;line-height:107%'>Beim Auftragnehmer umgesetzte Maßnahmen:</span></b></p>

      <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:.7pt;text-indent:-.5pt;line-height:107%'><b><span style='font-size:
11.0pt;line-height:107%'>&nbsp;</span></b></p>

      <table class=TableGrid border=0 cellspacing=0 cellpadding=0 width=605
             style='width:453.45pt;margin-left:.4pt;border-collapse:collapse'>
        <tr style='height:17.6pt'>
          <td width=605 valign=top style='width:453.45pt;border:solid black 1.0pt;
  padding:1.75pt 5.75pt 0cm 5.6pt;height:17.6pt'>
            <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
  margin-left:.5pt;line-height:107%'><span style='font-size:11.0pt;line-height:
  107%'>Dokumentation von datenschutzrelevanten Zwischenfällen</span></p>
          </td>
        </tr>
        <tr style='height:17.6pt'>
          <td width=605 valign=top style='width:453.45pt;border:solid black 1.0pt;
  border-top:none;padding:1.75pt 5.75pt 0cm 5.6pt;height:17.6pt'>
            <p class=MsoNormal style='margin-bottom:0cm;line-height:107%'><span
                style='font-size:11.0pt;line-height:107%'>Regelmäßige interne Audits</span></p>
          </td>
        </tr>
      </table>

      <p class=MsoNormal style='margin-bottom:5.7pt;line-height:107%'><b><span
          style='font-size:11.0pt;line-height:107%'>&nbsp;</span></b></p>

      <p class=MsoNormal style='margin-bottom:0cm;line-height:107%'><b><span
          style='font-size:11.0pt;line-height:107%'>4.2 Auftragskontrolle</span></b></p>

      <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:.2pt'><span style='font-size:11.0pt;line-height:115%'>&nbsp;</span></p>

      <p class=MsoNormal style='margin-left:.2pt;line-height:150%'><span
          style='font-size:11.0pt;line-height:150%'>Es muss dafür gesorgt werden, dass
personenbezogene Daten, die im Auftrag verarbeitet werden, nur entsprechend den
Weisungen des Auftraggebers verarbeitet werden können.</span></p>

      <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
margin-left:.7pt;text-indent:-.5pt;line-height:107%'><b><span style='font-size:
11.0pt;line-height:107%'>Beim Auftragnehmer umgesetzte Maßnahmen:</span></b></p>

      <table class=TableGrid border=0 cellspacing=0 cellpadding=0 width=605
             style='width:453.45pt;margin-left:.4pt;border-collapse:collapse'>
        <tr style='height:17.5pt'>
          <td width=605 valign=top style='width:453.45pt;border:solid black 1.0pt;
  padding:1.6pt 5.75pt 0cm 5.35pt;height:17.5pt'>
            <p class=MsoNormal style='margin-bottom:0cm;line-height:107%'><span
                style='font-size:11.0pt;line-height:107%'>Abschluss von AV-Verträgen mit
  Dienstleistern, Partnern und Kunden</span></p>
          </td>
        </tr>
        <tr style='height:17.5pt'>
          <td width=605 valign=top style='width:453.45pt;border:solid black 1.0pt;
  border-top:none;padding:1.6pt 5.75pt 0cm 5.35pt;height:17.5pt'>
            <p class=MsoNormal style='margin-bottom:0cm;line-height:107%'><span
                style='font-size:11.0pt;line-height:107%'>Sorgfältige Auswahl von Unterauftragsnehmern</span></p>
          </td>
        </tr>
      </table>

      <p class=MsoNormal>&nbsp;</p>

      <p class=MsoNormal><b><span style='font-size:14.0pt;line-height:115%'>&nbsp;</span></b></p>

    </div>

  </v-container>
</template>

<script>
import {mapGetters} from "vuex";

/**
 * Snackbar to show user successful and not successful tries to edit, create or delete a value
 * @displayName Alert
 */
export default {
  name: "AvvC",
  mounted() {
    if (this.print) {
      this.acUser = this.user
      const elements = document.getElementsByClassName('iubenda-tp-btn');
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }
      setTimeout(()=>{
        print()
      },1000)

    }else{
      this.acUser = this.newUser
    }
  },
  data(){
    return {
      acUser: {}
    }
  },
  props: {
    /**
     * Contains data to display the alert accordingly
     */
    print: Boolean,
    newUser: {
      Type: Object,
      default(){
        return {}
      }
    }
  },
  methods: {
    getStringDate(date) {
      if (date !== null) {
        const newDate = new Date(date)
        let options = {year: 'numeric', month: 'numeric', day: 'numeric'}
        return newDate.toLocaleDateString('de-DE', options)
      } else {
        return ""
      }
    },
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user',
    })
  },
}
</script>
<style>
#avv * {
  font-weight: unset;
  font-size: unset
}

h1 {
  font-weight: bold;
  font-size: larger;
  padding-top: 20px
}

h2 {
  font-weight: bold;
  font-size: unset;
}

@page {
  margin: 0;
  padding: 100px
}

.breakText {
  break-before: page;
  margin-top: 30px;
}

</style>
