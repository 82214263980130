<template>
  <div>
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <div v-if="uploadStatus">
    <v-btn
        color="primary"
        class=""
        @click="onButtonClick"
    >
      <v-icon left>
        mdi-upload
      </v-icon>
      Datei hochladen
    </v-btn>

    <input

        ref="uploader"
        class="d-none"
        type="file"
        accept=""
        @change="upload"
    >
    </div>
    <v-row v-else class="text-start ps-5">
      <v-col class="text-start">
        <Spinner class="text-start">

        </Spinner><small>Lädt hoch...</small>
      </v-col>

    </v-row>

  </div>
</template>

<script>


import {mapGetters} from "vuex";
import {error, msgObj, success} from "@/helper/helper";
const Message = () => import("@/components/generalUI/Message");
const Spinner = () => import("@/components/generalUI/Spinner");

/**
 * Snackbar to show user successful and not successful tries to edit, create or delete a value
 * @displayName Alert
 */
export default {
  name: "UploadPicture",
  data() {
    return {
      imageEdit: false,
      croppieImage: '',
      cropped: null,
      selectedFile: null,
      isSelecting: false,
      message: msgObj(),
    }
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user',
    }),
    ...mapGetters('file', {
      uploadStatus: 'uploadStatus',
    }),
  },

  components: {Message, Spinner},
  props: {
    /**
     * Contains data to display the alert accordingly
     */
    weddingId: {
      type: String,
      default() {
        return ""
      }
    },
    vendorId: {
      type: String,
      default() {
        return ""
      }
    },
    locationId: {
      type: String,
      default() {
        return ""
      }
    },
  },
  methods: {
    onButtonClick() {
      this.isSelecting = true
      window.addEventListener('focus', () => {
        this.isSelecting = false
      }, {once: true})
      this.$refs.uploader.click()
    },
    upload(e) {
      for (let file of e.target.files) {
        console.log('File Grösse: '+ (file.size/1019430.4) + "MB")
        if(file.size < 100*1019430.4){

          if(! this.weddingId){
            this.weddingId = ''
          }
          if(! this.vendorId){
            this.vendorId = ''
          }
          if(! this.locationId){
            this.locationId = ''
          }
          if(this.weddingId === '' && this.vendorId !== '' && this.locationId === ''){
            this.$store.dispatch('file/uploadFile', {file, uid: this.user.id, vendorId: this.vendorId}).then(() => {
              this.message = success('Datei wurde erfolgreich an der Hochzeit abgelegt.')
            }).catch((err)=>{
              this.message = error(err)
            })
          }else if(this.weddingId !== '' && this.vendorId === '' && this.locationId === ''){
            this.$store.dispatch('file/uploadFile', {file, uid: this.user.id, weddingId: this.weddingId}).then(() => {
              this.message = success('Datei wurde erfolgreich an der Hochzeit abgelegt.')
            }).catch((err)=>{
              this.message = error(err)

            })
          }else if(this.weddingId === '' && this.vendorId === '' && this.locationId !== ''){
            this.$store.dispatch('file/uploadFile', {file, uid: this.user.id, locationId: this.locationId}).then(() => {
              this.message = success('Datei wurde erfolgreich an der Hochzeit abgelegt.')
            }).catch((err)=>{
              this.message = error(err)
            })
          }
        }else{
          this.message = error('Datei darf maximal 100MB haben.')
        }
      }
    }
  }
}
</script>
