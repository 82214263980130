<template>
    <v-container class="pb-16">
        <Message
                :message="message"
                @closeAlert="message.show = false"
        ></Message>
        <v-row>
            <v-col>
                <v-card class="pa-6 rounded-xl " flat>
                    <v-form ref="formular">
                        <h2>Neuer Tisch</h2>
                        <v-row>
                            <v-col cols="12" md="4" class="ps-md-6">
                                <v-text-field
                                    v-model="newTable.name"
                                    label="Name"
                                    :readonly="false"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" class="ps-md-6">
                                <v-autocomplete
                                    :data="[{value: 'round', text: 'Rund'}]"
                                    :label="'Form'"
                                    :items="[{value: 'round', text: 'Rund'}, {value: 'reckt', text: 'Rechteckig'}]"
                                    v-model="newTable.type"
                                    item-text="text"
                                    item-value="value"
                                ></v-autocomplete>
                            </v-col>
                            <v-col>
                                <v-text-field
                                    v-model="newTable.seats"
                                    label="Plätze"
                                    :readonly="false"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col class="">
                                <small>Farbe</small>
                                <a>
                                    <div v-if="newTable.type === 'round'" @click="toggleColor" class="rounded-xl"
                                         style="height: 25px;width: 25px"
                                         :style="'background-color: ' + newTable.color"></div>
                                    <div v-else-if="newTable.type === 'quad'" @click="toggleColor"
                                         style="height: 25px;width: 25px"
                                         :style="'background-color: ' + newTable.color"></div>
                                    <div v-else @click="toggleColor" class="" style="height: 25px;width: 40px"
                                         :style="'background-color: ' + newTable.color"></div>
                                </a>
                            </v-col>

                            <v-row v-if="showColor">
                                <v-col class="ps-8">
                                    <v-color-picker placeholder="Tischfarbe" v-model="newTable.color"></v-color-picker>
                                    <v-text-field label="HEX" class="pa-3" placeholder="HEX"
                                                  v-model="newTable.color"></v-text-field>

                                </v-col>

                            </v-row>


                        </v-row>
                        <v-row class="justify-content-end align-content-end text-right">
                            <v-col class="d-flex justify-content-end align-content-center text-center">
                                <v-btn class="d-flex primary" @click="createTable">Hinzufügen</v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="col-12">
                <v-card class="rounded-xl pa-6" flat>

                <h1 class="pb-6">Tische</h1>
                <div v-for="table of tables" :key="table.id" class="">
                    <v-row @click="toggleGuestView(table.id)" class="customerRow pt-1 pb-3">
                        <v-col class="col-4 col-md-1">
                            <v-icon v-if="table.form === 'round'" :color="table.color">
                                mdi-circle
                            </v-icon>
                            <v-icon v-else-if="table.form === 'reckt'" :color="table.color">
                                mdi-rectangle
                            </v-icon>
                            <v-icon v-else-if="table.form === 'quad'" :color="table.color">
                                mdi-triangle
                            </v-icon>
                            <v-icon :color="table.color" v-else>
                                mdi-rectangle
                            </v-icon>
                        </v-col>
                        <v-col class="col">
                            {{ table.name }}
                        </v-col>
                        <v-col class="col-3">
                            <span v-if="table.takenSeats > table.seats" class="red--text">
                                <b>
                                    <v-icon class="pe-2">mdi-seat</v-icon>
                                {{table.takenSeats }} / {{ table.seats }}</b>
                            </span>
                            <span v-else-if="table.takenSeats === table.seats" >

                                    <v-icon class="pe-2">mdi-seat</v-icon>
                                {{table.takenSeats }} / {{ table.seats }}
                            </span>
                            <span v-else>
                             <b><v-icon class="pe-2">mdi-seat</v-icon>{{ table.takenSeats }} / {{ table.seats }}</b>
                         </span>
                        </v-col>

                        <v-col class="col-2">
                            <v-icon @click="startEdit(table)">mdi-pencil</v-icon>
                            <v-icon @click="deleteTable(table.id)">mdi-delete</v-icon>
                        </v-col>

                    </v-row>
                    <div v-if="activeTable === table.id">
                        <v-row v-for="guest of table.guests" :key="guest.id" class="ps-16 mb-0 mt-0">
                            <v-col class="col-3">
                                {{ guest.name }}
                            </v-col>
                            <v-col class="col-3">
                                <v-icon>mdi-account</v-icon>
                                {{ guest.count }}
                            </v-col>
                        </v-row>
                    </div>

                </div>
                </v-card>
            </v-col>

        </v-row>
        <Dialog
                :dialog="dialogEdit"
                :title="'Tisch bearbeiten'"
                :values="editFields"
                :successButton="'Bestätigen'"
                :icon="'mdi mdi-circle'"
                @hideDialog="dialogEdit=false"
                @emitNewData="edit"
                :cut="4"
        ></Dialog>
    </v-container>
</template>

<script>
import {error, msgObj, success} from "@/helper/helper";
import {mapGetters} from "vuex";

const Message = () => import("@/components/generalUI/Message");
const Dialog = () => import("@/components/dialog/FormDialogNew");


/**
 * User can plan budget for own wedding with different categories.
 * Every category has items and each item's budget can be planned
 * @displayName BudgetPlan
 *
 */
export default {
    components: {
        Message,
        Dialog,
    },
    name: "TableList",
    data() {
        return {
            message: msgObj(),
            newTable: {
                seats: 4,
                type: 'round',
                name: 'Tisch ' + (this.tables.length + 1),
                color: '#6D7E6D'
            },
            showColor: false,
            activeTable: '',
            dialogEdit: false,
            editFields: [

            ]
        }
    },
    computed: {
        ...mapGetters('auth', {
            user: 'user',
        }),
    },
    /**
     * Check if user is logged in and then get budgetplan of logged user
     *
     */
    props: {
        tables: Array,
        SeatPlanId: String,
    },
    mounted() {
        this.$store.dispatch('auth/checkLogin', {}).then((res) => {
            this.$store.dispatch('seatPlan/getSeatPlan', {
                uid: res,
                seatPlanId: this.$route.params.id
            }).then(() => {
                this.newTable = {
                    seats: 4,
                    type: 'round',
                    name: 'Tisch ' + (this.tables.length + 1),
                    color: '#6D7E6D'

                }
            }).catch((err) => {
                this.message = error(err)
            })
        })
    },
    methods: {
        getTakenSeats(tableID) {
            let countTakenSeats = 0
            for (let guest of this.guests) {
                if (guest.table === tableID) {
                    console.log(guest.count)
                    countTakenSeats = countTakenSeats + guest.count
                }
            }
            console.log(countTakenSeats)
            return countTakenSeats
        },
        createTable() {
            this.$store.dispatch('seatPlan/createTable', {
                seatPlanId: this.$route.params.id,
                name: this.newTable.name,
                seats: this.newTable.seats,
                form: this.newTable.type,
                color: this.newTable.color,
                h: '100',
                w: '100',
                y: '100',
                x: '100'
            }).then(() => {
                this.message = success('Tisch erstellt')
                this.newTable = {
                    seats: this.newTable.seats,
                    type: this.newTable.type,
                    name: 'Tisch ' + (this.tables.length + 1),
                    color: this.newTable.color
                }
            }).catch((err) => {
                this.message = error(err)
            })
        },
        deleteTable(id) {
            this.$store.dispatch('seatPlan/deleteTable', {
                id,
                seatPlanId: this.$route.params.id
            }).then(() => {
                this.message = success('Tisch gelöscht')
                this.newTable = {
                    seats: this.newTable.seats,
                    type: this.newTable.type,
                    name: 'Tisch ' + (this.tables.length + 1),
                    color: this.newTable.color

                }
            }).catch((err) => {
                this.message = error(err)
            })
        },
        edit(valuesChanged){
           let table = {}
            let id = ''
            for(let val of valuesChanged){
                switch(val.title){
                    case "Sitze":
                        table.seats = val.value
                        break;
                    case "Form":
                        table.form = val.value
                        break;
                    case "Farbe":
                        table.color = val.value
                        break;
                    case "Name":
                        table.name = val.value
                        break;
                    case "Tisch ID":
                        id = val.value
                        break;
                }
            }
            console.log(id)
            this.$store.dispatch('seatPlan/updateTable', {
                uid: this.user.id,
                id,
                table,
                seatPlanId: this.$route.params.id,
            }).then(()=>{
                this.message = success('Tisch erfolgreich gespeichert')
                this.dialogEdit = false
            }).catch((err)=>{
                this.message = error(err)

            })
        },
        startEdit(table) {
            this.editFields = [
                {
                    title: 'Tisch ID',
                    value: table.id,
                    id: 0,
                    type: 'string',
                    readonly:true
                },
                {
                    title: 'Name',
                    value: table.name,
                    id: 1,
                    type: 'string'

                },
                {
                    title: 'Sitze',
                    value: table.seats,
                    id: 2,
                    type: 'number'
                },
                {
                    title: 'Form',
                    value: table.form,
                    id: 3,
                    items: [{value: 'round', text: 'Rund'}, {value: 'reckt', text: 'Rechteckig'}],
                    type: 'autoComplete',
                },
                {
                    title: 'Farbe',
                    value: table.color,
                    id: 4,
                    type: 'colorPicker',
                    items: this.vendorStatusForSelection,
                },
            ]
            this.dialogEdit = true
        },
        toggleColor() {
            this.showColor = !this.showColor
        },
        toggleGuestView(id) {
            if (this.activeTable === id) {
                this.activeTable = ''
            } else {
                this.activeTable = id
            }
        },
    },
}
</script>

<style scoped>
.row {
    border-width: thin;
}

v-container {
    height: 150vh;
}

.customerRow {
    border-bottom: dotted 1px #F1E7E2;
    transition: 0.4s;
}


.customerRow:hover {
    transform: scale(1.01);
    transition: 0.4s;
    background-color: #F3F4F3 !important;

    cursor: pointer;
}
</style>
