<template>
  <div>
    <h2 v-if="title" class="text-h5 pt-8 pb-3">{{ title }}</h2>
    <KeyValue
        v-for="keyValue of keyValues"
        :key="keyValue.title"
        :title="keyValue.title"
        :btn="keyValue.btn"
        :edit-btn="keyValue.editBtn"
        :value="keyValue.value"
        :readonly="keyValue.readonly"
        :entity="keyValue.entity"
        @openEntry="openEntry"
        @editEntry="editEntry"
    >
    </KeyValue>
  </div>
</template>

<script>
/**
 * Snackbar to show user successful and not successful tries to edit, create or delete a value
 * @displayName Alert
 */

const KeyValue = () => import("@/components/detailView/KeyValueView");

export default {
  name: "Message",
  components:{
    KeyValue
  },
  props: {
    /**
     * Contains data to display the alert accordingly
     */
    title: {
      type: String,
      default(){
        return ''
      }
    },
    keyValues: {
      type: Array,
      default(){
        return []
      }
    },
  },
  methods:{
    openEntry(payload){
      this.$emit('openEntry', payload)
    },
    editEntry(payload){
      this.$emit('editEntry', payload)
    }
  }
}
</script>
