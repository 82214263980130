<template>
    <div>
        <v-container class="text-center">
            <h2>
                Bugs
            </h2>
            <v-row>
                <v-col>
                    Datum
                </v-col>
                <v-col>
                    Bug
                </v-col>
                <v-col>
                    Von
                </v-col>
                <v-col>
                    Betreff
                </v-col>
                <v-col>
                    Beschreibung
                </v-col>
                <v-col>
                    Gelöst
                </v-col>
            </v-row>
            <v-row v-for="bug of bugs" :key="bug.$id">
                <v-col>
                    {{ getDate(bug.$createdAt * 1000) }}
                </v-col>
                <v-col>
                    Bug:({{ bug.bug }})<br>
                    Otimizing: {{ bug.optimizing }}
                </v-col>
                <v-col>
                    {{ bug.where }} ({{ bug.uid }})
                </v-col>
                <v-col>
                    {{ bug.what }}
                </v-col>
                <v-col>
                    {{ bug.how }}
                </v-col>
                <v-col>
                    <span v-if="bug.solved">{{ bug.solved }}</span>
                    <span v-else><v-btn @click="markAsSolved(bug.$id)" class="error">Erledigen</v-btn></span>
                </v-col>
            </v-row>
        </v-container>

        <v-container class="text-center">
            <!--<h2 class="pb-8" style="padding-top: 28vh">User</h2>
            <v-simple-table class="rounded-xl pa-4 pb-9">
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class=" text-h6">
                            Name
                        </th>
                        <th class=" text-h6">
                            Name
                        </th>
                    </tr>
                    </thead>

                    <tbody
                    >
                    <tr
                            v-for="user in users"
                            :key="user.id"
                            class="text-start"
                    >
                        <td>
                            <span class="subtitle-1 ps-0 ps-md-6">{{ user.$id }}</span>
                        </td>
                        <td><span class="subtitle-1 ps-0 ps-md-6">{{ user.firstname }} {{ user.lastname }}</span></td>
                        <td><span class="subtitle-1 ps-0 ps-md-6">{{ user.id }}</span>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>-->

            <v-row
                    class="pt-8">
                <v-col>
                    <h3>Update Info erstellen</h3>
                    <v-form>
                        <v-text-field v-model="icon" label="icon (Optional sonst Rakete)">

                        </v-text-field>
                        <v-text-field v-model="title" label="Title">

                        </v-text-field>
                        <v-text-field v-model="message" label="Nachricht (optional unter 'Hier sind neueste Features')">

                        </v-text-field>
                        <div v-for="(vendor, index) of featureCount" :key="index">
                            <v-text-field
                                    :data="features[index]"
                                    label="Feature Titel"
                                    v-model="features[index]"
                            ></v-text-field>
                        </div>

                        <v-btn @click="featureCount ++">Weiteres Feature</v-btn>
                        <v-btn @click="createNotification" v-if="title" class="error">Erstellen</v-btn>
                    </v-form>
                </v-col>
            </v-row>
        </v-container>


        <v-container class="text-center">
            <h2>
                Gelöst
            </h2>
            <v-row>
                <v-col>
                    Datum
                </v-col>
                <v-col>
                    Bug
                </v-col>
                <v-col>
                    Von
                </v-col>
                <v-col>
                    Betreff
                </v-col>
                <v-col>
                    Beschreibung
                </v-col>
                <v-col>
                    Gelöst
                </v-col>
            </v-row>
            <v-row v-for="bug of bugsSolved" :key="bug.$id">
                <v-col>
                    {{ getDate(bug.$createdAt * 1000) }}
                </v-col>
                <v-col>
                    Bug: {{ bug.bug }}<br>
                    Otimizing: {{ bug.optimizing }}
                </v-col>
                <v-col>
                    {{ bug.where }} ({{ bug.uid }})
                </v-col>
                <v-col>
                    {{ bug.what }}
                </v-col>
                <v-col>
                    {{ bug.how }}
                </v-col>
                <v-col>
                    <span v-if="!bug.solved">{{ bug.solved }}</span>
                    <span v-else><v-btn @click="markAsUnsolved(bug.$id)">Erneuern</v-btn></span>
                </v-col>
            </v-row>
        </v-container>

    </div>
</template>

<script>

import {mapGetters} from "vuex";
import {date2String} from "@/helper/helper";

/**
 * Snackbar to show user successful and not successful tries to edit, create or delete a value
 * @displayName Alert
 */
export default {
    name: "CreateAccount",
    data() {
        return {
            title: '',
            message: '',
            icon: '',
            featureCount: 1,
            features: {}
        }
    },
    components: {},
    mounted() {
        this.$store.dispatch('admin/getUsers').catch((err) => {
            console.log(err)
        })
        this.$store.dispatch('admin/getBugs').catch((err) => {
            console.log(err)
        })
    },
    methods: {
        createNotification() {
            this.$store.dispatch('admin/createMessageForAll', {
                uid: this.user.id,
                users: this.users,
                message: this.message,
                icon: this.icon,
                title: this.title,
                features: this.features
            }).then(() => {
                this.title = ''
                this.message = ''
                this.icon = ''
                this.featureCount = 1
                this.features = {}
            })
        },
        markAsSolved(id) {
            this.$store.dispatch('admin/setBugSolved', {
                id,
                solved: true
            })
        },
        markAsUnsolved(id) {
            this.$store.dispatch('admin/setBugSolved', {
                id,
                solved: false
            })
        },
        getDate(date) {
            return date2String(date)
        }
    },
    computed: {
        ...mapGetters('admin', {
            users: 'users',
            bugs: 'bugs',
            bugsSolved: 'bugsSolved'

        }),
        ...mapGetters('auth', {
            user: 'user',
        }),
    },
}
</script>
