<template>
    <v-container class="pb-16">
        <Message
                :message="message"
                @closeAlert="message.show = false"
        ></Message>

        <v-row>
            <v-col class="text-center">
                <a @click="click1 = !click1; newName = budgetPlan.name" v-if="!click1" class="d-inline-flex">
                    <h1>
                        <span> {{ budgetPlan.name }} </span>
                        <v-icon class="d-inline-flex" size="20">
                            mdi-pencil
                        </v-icon>
                    </h1>
                </a>
                <v-tooltip right color="red">
                    <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
               <a @click="deleteBudgetPlan()" class="d-inline-flex mt-5 ps-3 pe-3">
                 <v-icon class="d-inline-flex" size="20">
                   mdi-delete
                 </v-icon>
               </a>
          </span>
                    </template>
                    Budgetplan löschen
                </v-tooltip>
                <v-tooltip right color="secondary">
                    <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
               <a @click="dialog =true" class="d-inline-flex mt-5 ps-3 pe-3">
                 <v-icon class="d-inline-flex" size="20">
                   mdi mdi-content-save
                 </v-icon>
               </a>
          </span>
                    </template>
                    Budgetplan als Vorlage speichern
                </v-tooltip>
                <v-tooltip right color="secondary">
                    <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
                  <v-icon slot="prepend" @click="$router.push('/pdf/budgetPlan/'+$route.params.id)">
            mdi-download
          </v-icon>
          </span>
                    </template>
                    Budgetplan exportieren
                </v-tooltip>
                <v-text-field
                        :value="budgetPlan.name" outlined dense
                        type="text" v-model="newName"
                        v-if="click1">
                    <v-icon slot="prepend" @click="click1 = !click1">
                        mdi-close
                    </v-icon>
                    <v-icon slot="prepend" @click="changeName(newName);click1 = !click1">
                        mdi-check-circle
                    </v-icon>
                </v-text-field>
            </v-col>
        </v-row>


        <v-row class="pb-12 ">
            <v-col v-if="!budgetGraphLoaded" cols="12" class="text-center">
                <Spinner></Spinner>
            </v-col>
            <v-col v-if="budgetGraphLoaded" cols="10" lg="3" offset="1" offset-lg="3" class="pa-4">
                <Doughnut :chartData="chartDataBudget"></Doughnut>
            </v-col>
            <v-col v-if="budgetGraphLoaded" cols="10" lg="3" offset="1" class="pa-4">
                <Doughnut :chartData="chartDataCategories"></Doughnut>
            </v-col>
        </v-row>


        <v-row class="pa-4 pa-lg-0 pa-sm-0">
            <v-col cols="6" sm="4" class=" offset-sm-1">
                Gesamtbudget:
                <a>
                    <h2 @click="click = !click" v-if="!click">
                        <v-icon class="d-inline-flex" size="20">
                            mdi-pencil
                        </v-icon>
                        <span> {{ replaceDot(budget) }}€ </span>
                    </h2>
                </a>
                <v-text-field
                        :value="budget" outlined dense
                        @change="changeBudget" type="number"
                        v-if="click">
                    <v-icon slot="prepend" @click="click = !click">
                        mdi-close
                    </v-icon>
                </v-text-field>
            </v-col>
            <v-col cols="6" sm="2">
                Geschätzte Kosten:
                <h2>{{ replaceDot(getTotalSumPlanned(budgetPlan.budgetList)) }}€</h2>
            </v-col>
            <v-col cols="6" sm="2">
                Tatsächliche Kosten:
                <h2 v-if="getTotalSumRealCost(budgetPlan.budgetList) >budget" style="color:darkred">
                    {{ getTotalSumRealCost(budgetPlan.budgetList) }}€</h2>
                <h2 v-else>{{ replaceDot(getTotalSumRealCost(budgetPlan.budgetList)) }}€</h2>
            </v-col>
            <v-col cols="12" sm="2">
                Bereits ausgegeben:
                <h2 v-if="getTotalSumSpent(budgetPlan.budgetList) >budget" style="color:darkred">
                    {{ getTotalSumSpent(budgetPlan.budgetList) }}€</h2>
                <h2 v-else>{{ replaceDot(getTotalSumSpent(budgetPlan.budgetList)) }}€</h2>
            </v-col>
        </v-row>

        <v-list class="mt-4 rounded-xl">
            <v-list-group
                    v-for="(item, index) in budgetPlan.budgetList"
                    :key="index"
                    no-action
            >
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-row>
                            <v-col cols="2" md="1" class="text-center">
                                <v-icon slot="prependIcon" size="35">
                                    {{ item.icon }}
                                </v-icon>
                            </v-col>
                            <v-col cols="6" sm="4" lg="4">
                                {{ item.name }}
                                <v-tooltip right color="red">
                                    <template v-slot:activator="{ on, attrs }">
                        <span
                                v-bind="attrs"
                                v-on="on"
                        >
                      <v-icon @click="deleteBudgetCategory(item.categoryId)">mdi-delete</v-icon>
                        </span>
                                    </template>
                                    Kategorie löschen
                                </v-tooltip>
                            </v-col>
                            <v-col cols="3" sm="2" class=" text-end">
                                <span class=" d-sm-none">Geplant:</span><br class=" d-sm-none">
                                <b>{{ replaceDot(getSumPlanned(item.singleBudget)) }}€</b>
                            </v-col>

                            <v-col cols="11" sm="2" class=" text-end">
                                <span class=" d-sm-none">Tatsächliche Kosten:</span><br class=" d-sm-none">
                                <b>{{ replaceDot(getSumRealCost(item.singleBudget)) }}€</b>

                            </v-col>

                            <v-col cols="4" sm="2" offset="7" offset-sm="0" class="ps-sm-10 text-end">
                                <span class="d-flex d-sm-none">Ausgegeben:</span>
                                <b v-if="getSumSpent(item.name, item.singleBudget) > getSumPlanned(item.singleBudget)"
                                   class="text-end"
                                   style="color:darkred">
                                    {{ replaceDot(getSumSpent(item.name, item.singleBudget)) }}€</b>
                                <b v-else>
                                    {{ replaceDot(getSumSpent(item.name, item.singleBudget)) }}€
                                </b>
                            </v-col>
                        </v-row>
                    </v-list-item-content>
                </template>
                <div class="mt-3"></div>
                <div
                        :id="'row'+ key"
                        class="row customerRow ps-3"
                        v-for="(subBudget, key) of item.singleBudget" v-bind:key="key"
                >
                    <v-col cols="2" sm="1" style="cursor: initial">
                        <a class="pa-0 ma-0 ps-6"
                           @click="checkedEntry({key: key, subBudgetName: subBudget.name, subBudgetPlanned: subBudget.planned, subBudgetSpent: subBudget.spent, subBudgetRealCost: subBudget.realCost})">
                            <v-icon
                                    class="mt-4"
                                    v-if="checkedIds.key === key && checkedIds.checked === true"
                            >
                                mdi-cancel
                            </v-icon>
                            <v-icon

                                    v-else
                            >
                                mdi-pencil

                            </v-icon>
                        </a>
                    </v-col>

                    <v-col cols="10" sm="4">
                        <div class=" ">
                            <h4 v-if="!(checkedIds.checked && checkedIds.key === key)">{{ subBudget.name }}</h4>
                            <v-text-field v-model="subBudgetName" label="Name"
                                          v-if="checkedIds.checked && checkedIds.key === key"></v-text-field>
                        </div>
                    </v-col>

                    <v-col cols="3" sm="2">
                        <div class=" ">
                            <h4 v-if="!(checkedIds.checked && checkedIds.key === key)"> {{
                                replaceDot(subBudget.planned)
                                }}€</h4>
                            <v-text-field v-model="subBudgetPlanned" label="Geplant"
                                          v-if="checkedIds.checked && checkedIds.key === key"></v-text-field>
                        </div>
                    </v-col>
                    <v-col cols="3" sm="2">
                        <div class=" ">
                            <h4 v-if="!(checkedIds.checked && checkedIds.key === key)"> {{
                                    replaceDot(subBudget.realCost)
                                }}€</h4>
                            <v-text-field v-model="subBudgetRealCost" label="Tatsächliche Kosten"
                                          v-if="checkedIds.checked && checkedIds.key === key"></v-text-field>
                        </div>
                    </v-col>
                    <v-col cols="3" sm="2" lg="2">
                        <div class=" ">
                            <h4 v-if="!(checkedIds.checked && checkedIds.key === key) && subBudget.spent > subBudget.planned"
                                style="color:darkred"> {{
                                replaceDot(subBudget.spent)
                                }}€ </h4>
                            <h4 v-if="!(checkedIds.checked && checkedIds.key === key) && subBudget.spent <= subBudget.planned">
                                {{
                                replaceDot(subBudget.spent)
                                }}€ </h4>
                            <v-text-field v-model="subBudgetSpent" label="Ausgegeben"
                                          v-if="checkedIds.checked && checkedIds.key === key"></v-text-field>
                        </div>
                    </v-col>
                    <v-col cols="3" sm="1">
                        <v-icon class="mt-4" v-if="checkedIds.checked && checkedIds.key === key"
                                @click="changeSingleBudget({subBudgetId: subBudget.$id});checkedEntry({key: key, subBudgetName: subBudget.name, subBudgetRealCost: subBudget.realCost, subBudgetPlanned: subBudget.planned, subBudgetSpent: subBudget.spent})">
                            mdi-check-circle
                        </v-icon>
                        <v-tooltip right color="red">
                            <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                      <v-icon v-if="!(checkedIds.checked && checkedIds.key === key)"
                              @click="deleteSingleBudget(subBudget.$id)">
                        mdi-delete
                      </v-icon>
                </span>
                            </template>
                            Eintrag löschen
                        </v-tooltip>

                    </v-col>

                </div>

                <v-row class="ps-3">
                    <v-col cols="1 " class="d-none d-sm-flex">
                    </v-col>
                    <v-col cols="4">
                        <v-text-field label="Name" v-model="singleBudgetName"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                        <v-text-field label="Geplantes Budget" v-model="planned"></v-text-field>
                    </v-col>
                    <v-col cols="2" sm="2">
                        <v-text-field label="Tatsächliche Kosten" v-model="realCost"></v-text-field>
                    </v-col>
                    <v-col cols="2" sm="2">
                        <v-text-field label="Ausgegebenes Budget" v-model="spent"></v-text-field>
                    </v-col>
                    <v-col>
                        <v-icon class="pt-5" @click="createSingleBudget(item.categoryId)">mdi-plus</v-icon>
                    </v-col>
                </v-row>
            </v-list-group>
        </v-list>
        <v-card flat class="mt-12 rounded-xl pa-4">
            <v-card-title>
                Neue Kategorie
            </v-card-title>
            <v-card-text>
                <v-row class="pt-6 ps-2 pe-2 align-center">
                    <v-col lg="1" cols="2" class="">
                        <a v-if="!chooseIcon" @click="chooseIcon=true">Icon</a>
                        <a v-if="chooseIcon" @click="chooseIcon=false; icon=''">Icon entfernen</a>
                        <div v-if="chooseIcon">
                            <IconPicker direction="left" text="" v-model="icon"></IconPicker>
                        </div>

                    </v-col>
                    <v-col lg="1" cols="2">
                        <v-icon class="d-inline ps-3">
                            {{ icon }}
                        </v-icon>
                    </v-col>
                    <v-col lg="5" cols="6">
                        <v-text-field label="Kategorie hinzufügen" v-model="budgetCategoryName"></v-text-field>
                    </v-col>
                    <v-col cols="2" lg="2">
                        <v-btn @click="createBudgetCategory()">
                            <v-icon>mdi-check-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-dialog
                v-model="dialog"
                persistent
                :max-width="800"
        >
            <v-card class="pa-8 rounded-xl">
                <v-card-title class="text-h5" id="title">
                    Budgetplan als Vorlage speichern
                </v-card-title>
                <v-card-text>
                    <v-form ref="formular">
                        <TextField
                                :data="{title: 'Name', value: ''}"
                                @sendData="setTemplateName"
                        ></TextField>
                        <TextField
                                :data="{title: 'Beschreibung', value: ''}"
                                @sendData="setTemplateDesc"
                        ></TextField>
                    </v-form>
                    <div v-if="savingTemplate">
                        {{savedActualC}}/{{savedTotalC}} Kategorien gespeichert<br>
                        {{savedActual}}/{{savedTotal}} Einträge gespeichert<br>
                        <v-progress-linear
                            indeterminate
                            height="8px"
                            rounded
                            color="primary"
                            class="mt-3"
                        ></v-progress-linear>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="primary"
                            text
                            @click="dialog=false"
                    >
                        Abbrechen
                    </v-btn>
                    <v-btn
                            color="primary"
                            @click="saveAsTemplate"
                    >
                        Speichern
                    </v-btn>

                </v-card-actions>

            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import {dotToKomma, error, msgObj, success} from "@/helper/helper";
import TextField from "@/components/dialog/fields/TextField";

const Message = () => import("@/components/generalUI/Message");
const Doughnut = () => import("@/components/generalUI/charts/Doughnut");
const Spinner = () => import("@/components/generalUI/Spinner");
const IconPicker = () => import("@/components/generalUI/IconPicker");

/**
 * User can plan budget for own wedding with different categories.
 * Every category has items and each item's budget can be planned
 * @displayName BudgetPlan
 *
 */
export default {
    components: {
        TextField,
        Message,
        Doughnut,
        Spinner,
        IconPicker,

    },
    name: "BudgetPlan",
    data() {
        return {
            message: msgObj(),
            icons: [
                "mdi-flower-poppy",
                "mdi-map-marker-outline",
                "mdi-hanger",
                "mdi-file-outline",
                "mdi-plus-circle",
                "mdi-music",
            ],
            userID: "",
            click: false,
            click1: false,
            isCreate: false,
            planned: "",
            spent: "",
            realCost: '',
            singleBudgetName: "",
            checkedIds: {checked: false, key: ""},
            chooseIcon: false,
            icon: "",
            newName: '',
            subBudgetName: "",
            subBudgetPlanned: "",
            subBudgetSpent: "",
            subBudgetRealCost: '',
            budgetCategoryName: "",
            dialog: false,
            chartDataBudget: {},
            chartDataCategories: {},
            categorySums: {},
            templateName: '',
            templateDesc: '',
            budgetGraphLoaded: false,
            savingTemplate: false,
        }
    },
    computed: {
        ...mapGetters('budgetPlan', {
            budget: 'budget',
            budgetPlan: 'budgetPlan',
            categoriesBudget: 'categoriesBudget',
            savedActual: 'savedActual',
            savedTotal: 'savedTotal',
            savedActualC: 'savedActualC',
            savedTotalC: 'savedTotalC',
        }),

        ...mapGetters('auth', {
            user: "user"
        }),

    },
    /**
     * Check if user is logged in and then get budgetplan of logged user
     *
     */
    mounted() {
        this.chartDataCategories = {}
        this.$store.dispatch('auth/checkLogin').then(() => {
            this.getFullPlan()
        }).catch((err) => {
            this.message = error(err)
        })
    },
    methods: {
        getFullPlan() {
            this.budgetGraphLoaded = false
            this.$store.dispatch('budgetPlan/getFullPlan', {
                budgetPlanId: this.$route.params.id
            }).then(() => {
                this.$store.dispatch('budgetPlan/getBudget', this.$route.params.id).catch((e) => console.log(e))
                    .then(() => {
                        setTimeout(() => {
                            this.chartDataBudget = {
                                labels: ["Noch verfügbar", "Ausgegeben"],
                                datasets: [{
                                    borderWidth: 3,
                                    backgroundColor: [
                                        '#6D7E6D',
                                        '#CECAB7'
                                    ],
                                    data: [(this.isZero(this.budget - this.getTotalSumSpent(this.budgetPlan.budgetList))).toFixed(2), this.getTotalSumSpent(this.budgetPlan.budgetList)]
                                }]
                            }
                            let categoryData = []
                            let labels = []
                            for (let [key, value] of Object.entries(this.categorySums)) {
                                labels.push(key)
                                categoryData.push(value.toFixed(2))
                            }
                            this.chartDataCategories = {
                                labels,
                                datasets: [{
                                    borderWidth: 3,
                                    backgroundColor: [
                                        '#B19983',
                                        '#CECAB7',
                                        '#EDE9D4',
                                        '#CFC6C1',
                                        '#F1E7E2',
                                    ],
                                    data: categoryData
                                }]
                            }
                            this.budgetGraphLoaded = true
                        }, 800);
                    })
            }).catch((err) => {
                this.message = error(err)
            })
        },
        /**
         * Change the budget of the user
         *
         * @param {Number} value contains value of new budget
         * @public
         */
        changeBudget(value) {
            this.$store.dispatch('budgetPlan/changeBudget', {
                budgetPlanId: this.$route.params.id,
                planned: value,
            }).then(() => {
                this.getFullPlan()
            })
            this.click = false
        },
        setTemplateName(payload) {
            this.templateName = payload.value
        },
        setTemplateDesc(payload) {
            this.templateDesc = payload.value
        },
        saveAsTemplate() {
            this.savingTemplate = true
            this.$store.dispatch('budgetPlan/saveAsTemplate', {
                uid: this.user.id,
                budgetPlanId: this.$route.params.id,
                budgetPlan: this.budgetPlan,
                name: this.templateName,
                description: this.templateDesc
            }).catch((err) => {
                this.message = error(err)
            }).then(() => {
                this.message = success('Vorlage erfolgreich gespeichert.')
                this.dialog = false
                this.savingTemplate = false
            })
        },
        replaceDot(float) {
            return dotToKomma(float)
        },
        isZero(number) {
            if (number < 0) {
                return 0
            } else {
                return number
            }
        },
        getSumPlanned(entries) {
            let sum = 0.00
            for (let entry of entries) {
                sum = sum + entry.planned
            }
            return sum.toFixed(2)
        },
        getSumRealCost(entries) {
            let sum = 0.00
            for (let entry of entries) {
                sum = sum + entry.realCost
            }
            return sum.toFixed(2)
        },
        getSumSpent(category, entries) {
            let sum = 0.00
            for (let entry of entries) {
                sum = sum + entry.spent
            }
            this.categorySums[category] = sum
            return sum.toFixed(2)
        },
        getTotalSumPlanned(categories) {
            let sum = 0.00
            for (let category of categories) {
                for (let singeBudget of category.singleBudget) {
                    sum = sum + singeBudget.planned
                }
            }
            return sum.toFixed(2)
        },
        getTotalSumRealCost(categories) {
            let sum = 0.00
            for (let category of categories) {
                for (let singeBudget of category.singleBudget) {
                    if(singeBudget.realCost){
                    sum = sum + singeBudget.realCost
                    }
                }
            }
            return sum.toFixed(2)
        },
        getTotalSumSpent(categories) {
            let sum = 0.00
            for (let category of categories) {
                for (let singeBudget of category.singleBudget) {
                    sum = sum + singeBudget.spent
                }
            }
            return sum.toFixed(2)
        },
        changeName(name) {
            this.$store.dispatch('customer/editField', {
                entity: "budgetPlan",
                uid: this.user.id,
                id: this.$route.params.id,
                newValue: name,
                row: 'name'
            }).then(() => {
                this.budgetPlan.name = name
            }).catch((err) => {
                this.message = error(err)
            })
        },
        deleteBudgetPlan() {
            this.$store.dispatch('budgetPlan/deleteBudgetPlan', {
                budgetPlanId: this.$route.params.id,
            }).then(() => {
                this.message = success('Der Budgetplan wurde erfolgreich gelöscht.')
                this.$router.push("/weddings/wedding/" + this.$route.params.weddingId)
            }).catch((err) => {
                this.message = error(err)
            })
        },

        /**
         * Change a single budget in a budget under Category
         *
         * @param {Object} item contains which item got planned and how much was planned/spent
         * @param {String} name contains name of the category the item belongs to
         * @param {Object} payload subBudgetId
         * @public
         */
        changeSingleBudget(payload) {
            if (this.subBudgetSpent instanceof String) {
                this.subBudgetSpent = this.subBudgetSpent.replace(',', '.')
            }
            if (this.subBudgetPlanned instanceof String) {
                this.subBudgetPlanned = this.subBudgetPlanned.replace(',', '.')
            }
            console.log(this.subBudgetRealCost)
                this.subBudgetRealCost = this.subBudgetRealCost.replace(',', '.')

            console.log(this.subBudgetRealCost)

            if(this.subBudgetRealCost === ''){
                this.subBudgetRealCost = 0
            }
            this.$store.dispatch('budgetPlan/editSingleBudget', {
                plannerId: this.user.id,
                subBudgetId: payload.subBudgetId,
                subBudgetName: this.subBudgetName,
                subBudgetSpent: this.subBudgetSpent,
                subBudgetPlanned: this.subBudgetPlanned,
                subBudgetRealCost: this.subBudgetRealCost,

            }).then(() => {
                console.log("test123")
                this.checkedIds.checked = false
                this.checkedIds.key = ''
                this.getFullPlan()
            })
        },
        openCreate(payload) {
            this.isCreate = true + payload.categoryId
        },
        closeCreate() {
            this.isCreate = false
        },
        createBudgetCategory() {
            this.$store.dispatch('budgetPlan/createBudgetCategory', {
                name: this.budgetCategoryName,
                plannerId: this.user.id,
                budgetPlanId: this.$route.params.id,
                icon: this.icon
            }).then(() => {
                this.$store.dispatch('budgetPlan/getFullPlan', {
                    budgetPlanId: this.$route.params.id
                })
                this.chooseIcon = false
                this.budgetCategoryName = ''
            }).catch((err) => {
                this.message = error(err)
            })

        },
        deleteSingleBudget(categoryId) {
            this.$store.dispatch('budgetPlan/deleteSingleBudget', categoryId)
                .then(() => {
                    this.$store.dispatch('budgetPlan/getFullPlan', {
                        budgetPlanId: this.$route.params.id
                    })
                }).catch((err) => {
                this.message = error(err)
            })
        },
        deleteBudgetCategory(categoryId) {
            this.$store.dispatch('budgetPlan/deleteBudgetCategory', {
                budgetPlanId: this.$route.params.id,
                categoryId: categoryId
            }).then(() => {
                this.$store.dispatch('budgetPlan/getFullPlan', {
                    budgetPlanId: this.$route.params.id
                })
            }).catch((err) => {
                this.message = error(err)
            })
        },
        createSingleBudget(payload) {
            if (this.planned) {
                this.planned = this.planned.replace(',', '.')
            } else {
                this.planned = null
            }
            if (this.spent) {
                this.spent = this.spent.replace(',', '.')
            } else {
                this.spent = null
            }
            if (this.realCost) {
                this.realCost = this.realCost.replace(',', '.')
            } else {
                this.realCost = null
            }
            this.$store.dispatch('budgetPlan/createSingleBudget', {
                name: this.singleBudgetName,
                plannerId: this.user.id,
                budgetPlanId: this.$route.params.id,
                planned: this.planned,
                spent: this.spent,
                realCost: this.realCost,
                categoryId: payload
            }).then(() => {
                this.getFullPlan()
                this.singleBudgetName = ''
                this.planned = ''
                this.spent = ''
                this.realCost = ''
            })
        },
        setCheckedEntries() {
            let checkedIds = []
            for (let [id, check] of Object.entries(this.checkedIds)) {
                if (check) {
                    checkedIds.push(id)
                }
            }
            this.checkedIdList = checkedIds
            this.allChecked = this.checkedIdList.length === this.rows.length;
        },
        checkedEntry(payload) {
            this.checkedIds = {checked: !this.checkedIds.checked, key: payload.key}
            this.subBudgetName = payload.subBudgetName
            this.subBudgetSpent = payload.subBudgetSpent
            this.subBudgetPlanned = payload.subBudgetPlanned
        }
    },
}
</script>

<style scoped>
v-container {
    height: 150vh;
}

.customerRow {
    border-top: dotted 1px #F1E7E2;
    border-bottom: dotted 1px #F1E7E2;
    transition: 0.4s;
}


.customerRow:hover {
    transform: scale(1.005);
    transition: 0.4s;
    background-color: #F3F4F3 !important;

    cursor: pointer;
}


</style>
