export default {
    namespaced: true,
    state: {},
    getters: {},
    actions: {
        // eslint-disable-next-line no-empty-pattern
        search({}, payload) {
            return new Promise((resolve) => {
                let words = payload.search.split(' ')
                let results = []
                let resultObject = {} // id: values
                if (words.length === 1) {
                    if (words[0] === '') {
                        resolve(payload.list)
                    }
                }
                for (let word of words) {
                    if (word !== '') {
                        for (let object of payload.list) {
                            let found = false
                            for (const [key, value] of Object.entries(object.values)) {
                                if (value) {
                                    if (Array.isArray(value)) {
                                        for (let val of value) {
                                            if (val.name.toString().toUpperCase().includes(word.toUpperCase())) {
                                                found = true
                                            }
                                        }
                                    }
                                    if (value.name) {
                                        if (value.name.toString().toUpperCase().includes(word.toUpperCase())) {
                                            found = true
                                        }
                                    } else {
                                        if (value.toString().toUpperCase().includes(word.toUpperCase())) {
                                            found = true
                                            console.log(key)
                                        }
                                    }
                                }
                            }
                            // mit ID in Objekt packen, damit es nicht doppelt auftritt
                            if (found) {
                                resultObject[object.id] = object
                            }
                        }
                    }
                }
                //Objekt in Liste umwandeln
                for (const [id, object] of Object.entries(resultObject)) {
                    console.log(id)
                    results.push(object)
                }
                resolve(results)
            })
        },
        // eslint-disable-next-line no-empty-pattern
        filter({}, payload) {
            return new Promise((resolve) => {
                    let results = []
                    for (let object of payload.list) {
                        let foundEveryFilter = true
                        let index = 0
                        for (const [row, values] of Object.entries(payload.activeFilters)) {
                            let found = false

                            if (values.length > 0) {

                                for (let val of values) {
                                    if(!object.values){
                                        console.log('0')
                                        for (let obj of object[row]) {
                                            if(!obj.value){
                                                if (obj.id === val) {
                                                    console.log(obj.id)
                                                    found = true
                                                }
                                            }else{
                                                if (obj.value === val) {
                                                    found = true
                                                }
                                            }
                                        }

                                    }
                                    else if (!object.values[row]) {
                                        console.log('1')
                                        if(row === 'country'){
                                            if(val === 'DE'){
                                                found = true
                                            }
                                        }else{
                                            found = true
                                        }

                                    }
                                    else if (object.values[row].value) {
                                        // Wenn Wert ein ID Feld ist
                                        console.log('6')
                                        if (object.values[row].value === val) {
                                            found = true
                                        }
                                    }else if( row === 'priceClass'){
                                        if (object.values[row] === val) {
                                            found = true
                                        }
                                    }
                                    else if (Array.isArray(object.values[row])) {
                                        console.log('2')
                                        // Wenn Wert ein MultiComplete ist
                                        for (let obj of object.values[row]) {
                                            if (obj.value === val) {
                                                found = true
                                            }
                                        }
                                    } else if (Number.isInteger(object.values[row])) {
                                        console.log('3')
                                        let number = Number(object.values[row])
                                        let valNumber = Number(values)
                                        if (number >= valNumber) {
                                            found = true
                                        }
                                    } else if (object.values[row].includes('m²')) {
                                        console.log('4')
                                        let number = Number(object.values[row].split(' ')[0])
                                        let valNumber = Number(values)
                                        if (number > valNumber) {
                                            found = true
                                        }
                                    } else {
                                        console.log('5')
                                        console.log(object.values[row])
                                        console.log(val)
                                        if (object.values[row] === val) {
                                            found = true
                                        }
                                    }
                                }
                            }
                            index++
                            if (!found) {
                                foundEveryFilter = false
                            }
                        }
                        if (index === 0) {
                            resolve(payload.list)
                        }
                        if (foundEveryFilter) {
                            results.push(object)
                        }
                    }
                    resolve(results)
                }
            )
        },
        /**
         * sort the objectArray from the payload
         *
         * @param dispatch
         * @param payload
         *  sortStyle: ASC oder DESC
         *  sortField: The fieldname that the values should sort to
         *  objectArray: List of Objects to sort
         */
        sort({dispatch}, payload) {
            let sortStyle = payload.sortStyle
            let sortField = payload.sortField
            let objectArray = payload.objectArray
            if (sortStyle === 'ASC') {
                dispatch('compare', {objectArray, sortField, dir1: -1, dir2: 1})
            } else {
                dispatch('compare', {objectArray, sortField, dir1: 1, dir2: -1})
            }
        }
        ,
        // eslint-disable-next-line no-empty-pattern
        compare({}, payload) {
            console.log(payload)
            if (payload.objectArray[0].values) {
                payload.objectArray.sort(function (a, b) {
                    if (payload.sortField === 'category') {
                        if (a.values[payload.sortField].name < b.values[payload.sortField].name) {
                            return payload.dir1;
                        }
                        if (a.values[payload.sortField].name > b.values[payload.sortField].name) {
                            return payload.dir2;
                        }
                    } else {
                        if (a.values[payload.sortField] < b.values[payload.sortField]) {
                            return payload.dir1;
                        }
                        if (a.values[payload.sortField] > b.values[payload.sortField]) {
                            return payload.dir2;
                        }
                    }
                    return 0;
                })
            } else {
                if (payload.sortField === 'category') {
                    payload.objectArray.sort(function (a, b) {
                        if (a[payload.sortField].name < b[payload.sortField].name) {
                            return payload.dir1;
                        }
                        if (a[payload.sortField].name > b[payload.sortField].name) {
                            return payload.dir2;
                        }
                        return 0;
                    })
                } else {
                    payload.objectArray.sort(function (a, b) {
                        if (a[payload.sortField] < b[payload.sortField]) {
                            return payload.dir1;
                        }
                        if (a[payload.sortField] > b[payload.sortField]) {
                            return payload.dir2;
                        }
                        return 0;
                    })
                }

            }
        }
    },

}

