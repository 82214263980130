<template>
  <div>
    <v-container>
      <WeddingStatus :user="user"></WeddingStatus>
    </v-container>
    <v-divider class="mt-5 mb-4"></v-divider>
    <v-container>
      <MyProducts :user="user"></MyProducts>
    </v-container>
    <v-divider class="mt-5 mb-4"></v-divider>
    <v-container>
      <WeddingLabel :user="user"></WeddingLabel>
    </v-container>

    <v-divider class="mt-5 mb-4"></v-divider>
    <v-container>
      <VendorCategory :user="user"></VendorCategory>
    </v-container>
    <v-divider class="mt-5 mb-4"></v-divider>
    <v-container>
      <VendorStatus :user="user"></VendorStatus>
    </v-container>
    <v-divider class="mt-5 mb-4"></v-divider>
    <v-container>
      <Stil :user="user"></Stil>
    </v-container>
    <v-divider class="mt-5 mb-4"></v-divider>
    <v-container>
      <VendorServices :user="user"></VendorServices>
    </v-container>
    <v-divider class="mt-5 mb-4"></v-divider>
    <v-container>
      <CustomerStatus :user="user"></CustomerStatus>
    </v-container>




  </div>
</template>
<script>

import {msgObj} from "@/helper/helper";

const WeddingStatus = () => import("@/components/entities/Settings/statusAndFieldSettings/WeddingStatus");
const VendorStatus = () => import("@/components/entities/Settings/statusAndFieldSettings/VendorStatus");
const CustomerStatus = () => import("@/components/entities/Settings/statusAndFieldSettings/CustomerStatus");
const VendorCategory = () => import("@/components/entities/Settings/statusAndFieldSettings/VendorCategory");
const MyProducts = () => import("@/components/entities/Settings/statusAndFieldSettings/MyProducts");
const Stil = () => import("@/components/entities/Settings/statusAndFieldSettings/Stil");
const VendorServices = () => import("@/components/entities/Settings/statusAndFieldSettings/VendorServices");
const WeddingLabel = () => import("@/components/entities/Settings/statusAndFieldSettings/WeddingLabel");

export default {
  name: "FieldSettings",
  components:{
    WeddingStatus,
    VendorStatus,
    CustomerStatus,
    VendorCategory,
    MyProducts,
    VendorServices,
    Stil,
    WeddingLabel
  },
  data() {
    return {
      message: msgObj(),
    }
  },
  props: {
    user: {
      type: Object,
      default() {
        return {}
      }
    },
  },
}
</script>
