import {storage, databases} from "@/config/server-api";
import {
    profilePicBucket,
    weddingFileBucket,
    weddingFilesCollection,
} from "@/config/awSettings"
import {Query} from "appwrite";
import {getError} from "@/config/errors";

const delay = ms => new Promise(res => setTimeout(res, ms));


export default {
    namespaced: true,
    state: {
        files: [],
        /**
         * Contains the current profile picture of the user
         */
        profilePic: '',
        memoryUsed: 0,
        uploadStatus: true,
    },
    getters: {
        files: (state) => state.files,
        /**
         * Get the profile picture of the logged in user
         *
         * @param state to access the profilePicture
         * @returns {null}
         */
        profilePic: (state) => state.profilePic,
        memoryUsed: (state) => state.memoryUsed,
        uploadStatus: (state) => state.uploadStatus

    },
    actions: {
        /**
         * Lädt ein File hoch und erstellt einen Eintrag in der Datenbank mit der HochzeitsId, DocId und plannerId
         *
         * @param dispatch
         * @param payload
         * @returns {Promise<unknown>}
         */
        uploadFile({dispatch, commit}, payload) {
            return new Promise((resolve, reject) => {
                commit('setUploadStatus', false)
                dispatch('getMemoryUsed', {uid: payload.uid}).then((memory) => {
                    if (memory < 21474836480) {
                        const promise = storage.createFile(weddingFileBucket, 'unique()', payload.file);
                        promise.then(function (file) {
                            let promiseCreateDoc
                            let isVendor = false
                            let isWedding = false
                            let isLocation = false
                            if (!payload.weddingId && payload.vendorId && !payload.locationId) {
                                isVendor = true
                            } else if (payload.weddingId && !payload.vendorId && !payload.locationId) {
                                isWedding = true
                            } else if (!payload.weddingId && !payload.vendorId && payload.locationId) {
                                isLocation = true
                            }
                            promiseCreateDoc = databases.createDocument(weddingFilesCollection, "unique()", {
                                docId: file.$id,
                                plannerId: payload.uid,
                                weddingId: payload.weddingId,
                                locationId: payload.locationId,
                                vendorId: payload.vendorId,
                            }).then(() => {
                                if (isVendor) {
                                    promiseCreateDoc.then(function () {
                                        commit('setUploadStatus', true)
                                        dispatch('getFilesForVendor', {uid: payload.uid, vendorId: payload.vendorId})
                                        resolve()
                                    }, function (error) {
                                        commit('setUploadStatus', true)
                                        console.log(error)
                                        reject(getError(error, "E0030"))
                                    })
                                } else if (isWedding) {
                                    promiseCreateDoc.then(function () {
                                        dispatch('getFiles', {uid: payload.uid, weddingId: payload.weddingId})
                                        commit('setUploadStatus', true)
                                        resolve()
                                    }, function (error) {
                                        commit('setUploadStatus', true)
                                        console.log(error)
                                        reject(getError(error, "E0030"))
                                    })
                                } else if (isLocation) {
                                    promiseCreateDoc.then(function () {
                                        dispatch('getFilesForLocation', {
                                            uid: payload.uid,
                                            locationId: payload.locationId
                                        })
                                        commit('setUploadStatus', true)
                                        resolve()
                                    }, function (error) {
                                        commit('setUploadStatus', true)
                                        console.log(error)
                                        reject(getError(error, "E00F5F30"))
                                    })
                                }
                            }).catch((err) => {
                                commit('setUploadStatus', true)
                                console.log(err); // Failure
                                reject(err)
                            })
                        }, function (error) {
                            commit('setUploadStatus', true)
                            console.log(error); // Failure
                            reject(getError(error, "E0031"))
                        });
                    } //20GB
                    else {
                        commit('setUploadStatus', true)
                        console.log('Speicherplatz voll.'); // Failure
                        reject('Du hast bereits deinen gesamten Speicherplatz verbraucht. Bitte aktualisiere deinen Plan oder kontaktiere uns.')
                    }
                }).catch(() => {
                    commit('setUploadStatus', true)
                    reject('Der verfügbare Speicherplatz konnte nicht ermittelt werden.')

                })

            })
        },
        /**
         * Liest alle Files zu einer bestimmten Hochzeit
         *
         * @param commit
         * @param payload
         * @returns {Promise<unknown>}
         */
        getFiles({commit}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDocs = databases.listDocuments(weddingFilesCollection, [
                    Query.equal("plannerId", payload.uid),
                    Query.equal("weddingId", payload.weddingId)
                ], 0)
                promiseGetDocs.then(async function (documentListX) {
                    if (documentListX.total === 0) {
                        commit('setFiles', [])

                        resolve([])
                    }
                    let pages = Math.ceil(documentListX.total / 25)
                    let files = []
                    for (let i = 0; i < pages; i++) {
                        databases.listDocuments(weddingFilesCollection, [
                            Query.equal("plannerId", payload.uid),
                            Query.equal("weddingId", payload.weddingId)
                        ], 25, i * 25).then((documentList) => {
                            for (let doc of documentList.documents) {
                                let docId = doc.docId
                                const promise = storage.getFile(weddingFileBucket, docId);
                                promise.then(function (response) {
                                    let map = response
                                    map['entryID'] = doc.$id
                                    map['isTitlePic'] = doc.isTitlePic

                                    const promise2 = storage.getFileView(weddingFileBucket, docId);
                                    if (promise2.href) {
                                        map['href'] = promise2.href
                                    }
                                    files.push(map)

                                }, function (error) {
                                    console.log(error)
                                    reject(getError(error, "E0032"))
                                }).finally(() => {
                                    if (i === pages - 1) {
                                        commit('setFiles', files)
                                        resolve(files)
                                    }
                                });
                            }
                        }).catch((err) => {
                            console.log(err)
                        })
                        await delay(100)
                    }
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0033"))
                });
            })
        },
        getFilesForVendor({commit}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDocs = databases.listDocuments(weddingFilesCollection, [
                    Query.equal("plannerId", payload.uid),
                    Query.equal("vendorId", payload.vendorId)
                ], 0)
                promiseGetDocs.then(async function (documentListX) {
                    if (documentListX.total === 0) {
                        commit('setFiles', [])
                        resolve([])
                    }
                    let pages = Math.ceil(documentListX.total / 25)
                    let files = []
                    for (let i = 0; i < pages; i++) {
                        databases.listDocuments(weddingFilesCollection, [
                            Query.equal("plannerId", payload.uid),
                            Query.equal("vendorId", payload.vendorId)
                        ], 25, i * 25).then((documentList) => {
                            for (let doc of documentList.documents) {
                                let docId = doc.docId
                                const promise = storage.getFile(weddingFileBucket, docId);
                                promise.then(function (response) {
                                    let map = response
                                    map['entryID'] = doc.$id
                                    map['isTitlePic'] = doc.isTitlePic

                                    const promise2 = storage.getFileView(weddingFileBucket, docId);
                                    if (promise2.href) {
                                        map['href'] = promise2.href
                                    }
                                    files.push(map)
                                }, function (error) {
                                    console.log(error)
                                }).finally(() => {
                                    if (i === pages - 1) {
                                        commit('setFiles', files)
                                        resolve(files)
                                    }
                                });
                            }
                        }).catch((err) => {
                            console.log(err)
                        })
                        await delay(100)
                    }
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0033"))
                });
            })
        },
        getFilesForLocation({commit}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDocs = databases.listDocuments(weddingFilesCollection, [
                    Query.equal("plannerId", payload.uid),
                    Query.equal("locationId", payload.locationId)
                ], 0)
                promiseGetDocs.then(async function (documentListX) {
                    if (documentListX.total === 0) {
                        commit('setFiles', [])
                        resolve([])
                    }
                    let pages = Math.ceil(documentListX.total / 25)
                    let files = []
                    for (let i = 0; i < pages; i++) {
                        databases.listDocuments(weddingFilesCollection, [
                            Query.equal("plannerId", payload.uid),
                            Query.equal("locationId", payload.locationId)
                        ], 25, i * 25).then((documentList) => {
                            for (let doc of documentList.documents) {
                                let docId = doc.docId
                                const promise = storage.getFile(weddingFileBucket, docId);
                                promise.then(function (response) {
                                    let map = response
                                    map['entryID'] = doc.$id
                                    map['isTitlePic'] = doc.isTitlePic

                                    const promise2 = storage.getFileView(weddingFileBucket, docId);
                                    if (promise2.href) {
                                        map['href'] = promise2.href
                                    }
                                    files.push(map)
                                }, function (error) {
                                    console.log(error)
                                    reject(getError(error, "E0032"))
                                }).finally(() => {
                                    if (i === pages - 1) {
                                        commit('setFiles', files)
                                        resolve()
                                    }
                                });
                            }
                        }).catch((err) => {
                            console.log(err)
                        })
                        await delay(100)
                    }
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0033"))
                });
            })
        },
        getMemoryUsed({commit}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDocs = databases.listDocuments(weddingFilesCollection, [
                    Query.equal("plannerId", payload.uid)
                ], 0)
                promiseGetDocs.then(async function (documentListX) {
                    if (documentListX.total === 0) {
                        resolve(0)
                    }
                    let pages = Math.ceil(documentListX.total / 25)
                    let memory = 0
                    for (let i = 0; i < pages; i++) {
                        databases.listDocuments(weddingFilesCollection, [
                            Query.equal("plannerId", payload.uid)
                        ], 25, i * 25).then((documentList) => {
                            for (let doc of documentList.documents) {
                                let docId = doc.docId
                                const promise = storage.getFile(weddingFileBucket, docId);
                                promise.then(function (response) {
                                    memory = memory + response.sizeOriginal
                                }, function (error) {
                                    console.log(error)
                                }).finally(() => {
                                    if (i === pages - 1) {
                                        commit('setMemoryUsed', memory)
                                        resolve(memory)
                                    }
                                });
                            }
                        }).catch((err) => {
                            console.log(err)
                            reject(err)
                        })
                        await delay(100)
                    }
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0033"))
                });
            })
        },
        /**
         * Löscht ein File und den dazugehörigen Datenbankeintrag
         *
         * @param dispatch
         * @param payload
         * @returns {Promise<unknown>}
         */
        // eslint-disable-next-line no-empty-pattern
        deleteFile({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDocs = databases.listDocuments(weddingFilesCollection, [
                    Query.equal("docId", payload.path)
                ])
                promiseGetDocs.then(function (documentList) {
                    for (let doc of documentList.documents) {
                        const promise = storage.deleteFile(weddingFileBucket, payload.path);
                        promise.then(function () {
                            const promiseDeleteDoc = databases.deleteDocument(weddingFilesCollection, doc.$id);
                            promiseDeleteDoc.then(function () {
                                dispatch('getFiles', {
                                    uid: payload.uid,
                                    weddingId: payload.weddingId,
                                })
                                resolve()
                            }, function (error) {
                                console.log(error); // Failure
                                reject(getError(error, "E0034"))
                            });
                        }, function (error) {
                            console.log(getError(error, "E0035")); // Failure
                            reject(error)
                        });
                    }
                }, function (error) {
                    console.log(error); // Failure
                    reject(getError(error, "E0036"))
                });
            })
        },

        // eslint-disable-next-line no-empty-pattern
        downloadFile({}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDocs = storage.getFileView(weddingFileBucket, payload.path)
                if (promiseGetDocs.href) {
                    window.open(promiseGetDocs.href)
                    resolve()
                } else {
                    reject(getError("Datei konnte nicht geladen werden.", "E0037"))
                }
            })
        },
        createOrUpdateProfilePic({dispatch}, payload) {
            return new Promise((resolve, reject) => {
                storage.listFiles(profilePicBucket).then((files) => {
                    if (files.total > 0) {
                        let index = 0
                        for (let file of files.files) {
                            storage.deleteFile(profilePicBucket, file.$id).finally(() => {
                                if (index >= files.total - 1) {
                                    const promise = storage.createFile(profilePicBucket, 'unique()', payload.file);
                                    promise.catch((error) => {
                                        console.log(error); // Failure
                                        reject(error)
                                    }).then(() => {
                                        dispatch('getProfilePic')
                                        resolve()
                                    });
                                }
                                index++
                            })
                        }
                    } else {
                        const promise = storage.createFile(profilePicBucket, 'unique()', payload.file);
                        promise.catch((error) => {
                            console.log(error); // Failure
                            reject(error)
                        }).then(() => {
                            resolve()
                        });
                    }
                })


            })
        },
        // eslint-disable-next-line no-empty-pattern
        deleteProfilePic({}) {
            return new Promise((resolve, reject) => {
                storage.listFiles(profilePicBucket).then((files) => {
                    if (files.total > 0) {
                        storage.deleteFile(profilePicBucket, files.files[0].$id).then(() => {
                            resolve()
                        }).catch((err) => {
                            reject(err)
                        })
                    } else {
                        resolve()
                    }
                })

            })
        },
        getViewImage({commit}, payload) {
            return new Promise((resolve, reject) => {
                const promiseGetDocs = storage.getFileView(weddingFileBucket, payload.id)
                if (promiseGetDocs.href) {
                    commit('setProfilePic', promiseGetDocs.href)
                    resolve(promiseGetDocs.href)
                } else {
                    reject("Fehler")
                }
            })
        },
        getProfilePic({commit}) {
            return new Promise((resolve, reject) => {
                storage.listFiles(profilePicBucket).then((files) => {
                    if (files.total > 0) {
                        const promiseGetDocs = storage.getFileView(profilePicBucket, files.files[0].$id)
                        if (promiseGetDocs.href) {
                            commit('setProfilePic', promiseGetDocs.href)
                            resolve(promiseGetDocs.href)
                        } else {
                            reject("Fehler")
                        }
                    } else {
                        commit('setProfilePic', null)
                        resolve()
                    }
                })

            })
        }
    }
    ,
    mutations: {
        setFiles(state, payload) {
            state.files = payload
        }
        ,
        /**
         * Set Profile picture to the state
         *
         * @param state access profile Picture
         * @param payload contains the image
         */
        setProfilePic(state, payload) {
            state.profilePic = payload
        },
        setMemoryUsed(state, payload) {
            state.memoryUsed = payload
        },
        setUploadStatus(state, payload) {
            state.uploadStatus = payload
        },
    }
}

