var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Message',{attrs:{"message":_vm.message},on:{"closeAlert":function($event){_vm.message.show = false}}}),(_vm.loaded)?_c('div',[(_vm.customFieldsForEntity.length>0)?_c('v-row',{staticClass:"pb-6 pt-3"},[_c('v-col',_vm._l((_vm.customFieldsForEntity),function(customField){return _c('KeyValueView',{key:customField.id,attrs:{"customFieldValueId":customField.valueId,"title":customField.name,"edit-btn":{
              icon:'mdi-check-circle',
              entity: 'customFieldValue',
              id: customField.id,
              row: customField.value,
              type: customField.type
            },"value":customField.value},on:{"editEntry":_vm.editEntry}})}),1)],1):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }