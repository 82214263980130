var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loaded = true)?_c('div',{staticClass:"container customers pb-16 fadeInFast"},[_c('Message',{attrs:{"message":_vm.message},on:{"closeAlert":function($event){_vm.message.show = false}}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"5"}},[_c('HeadlineDetailView',{attrs:{"icon":'mdi-account',"headline":_vm.customer.firstname +' '+  _vm.customer.lastname,"entity":'Kunde'}})],1),_c('v-col',{staticClass:"text-end",attrs:{"cols":"12","md":"6","lg":"7"}},[(_vm.customer.isArchived)?_c('a',{staticClass:"v-btn",on:{"click":function($event){return _vm.unArchiveWedding()}}},[_c('v-btn',{staticClass:"mt-2 ms-3",attrs:{"width":"170"}},[_c('v-icon',{staticClass:"pe-2"},[_vm._v(" mdi mdi-star ")]),_vm._v(" Aus Archiv ")],1)],1):_c('a',{staticClass:"v-btn",on:{"click":function($event){return _vm.archiveCustomer()}}},[_c('v-btn',{staticClass:"mt-2 ms-3",attrs:{"width":"170"}},[_c('v-icon',{staticClass:"pe-2"},[_vm._v(" mdi mdi-archive-outline ")]),_vm._v(" Archivieren ")],1)],1),(!_vm.customer.isArchived)?_c('a',{staticClass:"v-btn",attrs:{"href":'tel:'+_vm.customer.tel}},[_c('v-btn',{staticClass:"primary ms-3 mt-2",attrs:{"width":"150"}},[_c('v-icon',{staticClass:"pe-2"},[_vm._v(" mdi-phone ")]),_vm._v(" Anrufen ")],1)],1):_vm._e(),(!_vm.customer.isArchived)?_c('a',{staticClass:"v-btn",attrs:{"href":'mailto:'+_vm.customer.email}},[_c('v-btn',{staticClass:"primary ms-3 mt-2",attrs:{"width":"150"}},[_c('v-icon',{staticClass:"pe-2"},[_vm._v(" mdi-mail ")]),_vm._v(" E-Mail ")],1)],1):_vm._e()])],1),_c('v-card',{staticClass:"detailCard pa-4 rounded-xl mt-6 pb-8",attrs:{"flat":""}},[_c('Tab',{attrs:{"tabs":['Allgemein','Partner','Adresse','Rechnungen','Sonstiges'],"register":_vm.tabs},on:{"changed":function($event){_vm.tabs=$event}}}),(_vm.tabs === 0)?_c('div',{staticClass:"row pa-4"},[_c('div',{staticClass:"col-12 col-sm-7"},[_c('KeyValueBlock',{attrs:{"title":'',"key-values":[
            {title: 'Vorname', value: _vm.customer.firstname, isEdit: false, editBtn:{
              icon:'mdi-check-circle',
              entity: 'customer',
              id: _vm.customer.id,
              row: 'firstname'
            }},
             {title: 'Nachname', value: _vm.customer.lastname, isEdit: false, editBtn:{
              icon:'mdi-check-circle',
              entity: 'customer',
              id: _vm.customer.id,
              row:'lastname'
            }},
            {
              title: 'Status',
              value: _vm.customer.status,
              editBtn:{
                icon:'mdi-check-circle',
                entity: 'customer',
                id: _vm.customer.id,
                row: 'status',
                type: 'autoComplete',
                autoComplete:{
                  value: _vm.customer.status,
                  color: _vm.getCustomerStatusColor(_vm.customer.status),
                  items: _vm.customerStatusForSelection
                }
              }
            },
             {title: 'Tel', value: _vm.customer.tel, isEdit: false,
             editBtn:{
              icon:'mdi-check-circle',
              entity: 'customer',
              id: _vm.customer.id,
              row: 'tel'
            }},
            {title: 'E-Mail', value: _vm.customer.email, isEdit: false, editBtn:{
              icon:'mdi-check-circle',
              entity: 'customer',
              id: _vm.customer.id,
              row: 'email'
            }},
            {
              title: 'Notizen',
              value: _vm.customer.notes,
              isEdit: false,
              editBtn:{
                icon:'mdi-check-circle',
                entity: 'customer',
                id: _vm.customer.id,
                row: 'notes',
                type: 'textarea'
              }
            },
            ]},on:{"editEntry":_vm.editEntry}}),_c('CustomFieldBlock',{attrs:{"entity":'customer',"user":_vm.user,"entryId":_vm.customer.id}})],1),_c('div',{staticClass:"col-12 col-sm-5"},[(_vm.weddingsForCustomer.length > 0)?_c('div',{},[_c('v-row',_vm._l((_vm.weddingsForCustomer),function(wedding){return _c('v-col',{key:wedding.id,attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-xl mb-3",staticStyle:{"background-color":"#F3F4F3"},attrs:{"flat":""},on:{"click":function($event){return _vm.openWedding(wedding.id)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card-title',[_c('h5',{staticStyle:{"width":"100%"}},[_c('v-icon',{staticClass:"pe-1"},[_vm._v("mdi-ring")]),_vm._v(" "+_vm._s(_vm.customer.firstname)),(_vm.customer.partnerFirstname)?_c('span',[_vm._v(" und "+_vm._s(_vm.customer.partnerFirstname))]):_vm._e(),_vm._v(" "),_c('small',[(wedding.isArchived)?_c('span',{staticClass:"red--text"},[_c('i',[_vm._v("Archiviert")])]):_vm._e()])],1)]),_c('v-card-text',[_c('b',[_vm._v(_vm._s(wedding.values.status))]),_c('br'),_vm._v(" Am "),_c('b',[_vm._v(_vm._s(_vm.getStringDate(wedding.wedDate)))])]),_c('v-card-actions',{},[_c('v-spacer'),_c('a',[_vm._v("ANSEHEN")])],1)],1)],1)],1)],1)}),1)],1):_c('div',{staticClass:"pa-4 pt-8"},[_c('p',[_c('i',[_vm._v("Es gibt noch kein Hochzeit zu diesem Kunden")])]),_c('v-btn',{staticClass:"primary",on:{"click":function($event){_vm.dialogCreateWedding = true}}},[_vm._v(" Hochzeit erstellen ")])],1)])]):_vm._e(),(_vm.tabs === 1)?_c('div',{staticClass:"row pa-4"},[_c('div',{staticClass:"col-12 col-sm-7"},[_c('KeyValueBlock',{attrs:{"key-values":[
                {
                  title: 'Vorname', value: _vm.customer.partnerFirstname, isEdit: false,
                  editBtn:
                {
                  icon:'mdi-check-circle',
                  entity: 'customer',
                  id: _vm.customer.id,
                  row: 'partnerFirstname',
                }
                },
                {
                  title: 'Nachname', value: _vm.customer.partnerLastname, isEdit: false,
                  editBtn:
                  {
                    icon:'mdi-check-circle',
                    entity: 'customer',
                    id: _vm.customer.id,
                    row: 'partnerLastname'
                  }
                },
                 {title: 'Telefon', value: _vm.customer.partnerTel, isEdit: false, editBtn:{
              icon:'mdi-check-circle',
              entity: 'customer',
              id: _vm.customer.id,
              row: 'partnerTel'
            }},
             {title: 'E-Mail', value: _vm.customer.partnerMail, isEdit: false, editBtn:{
              icon:'mdi-check-circle',
              entity: 'customer',
              id: _vm.customer.id,
              row: 'partnerMail'
            }}
              ]},on:{"editEntry":_vm.editEntry}})],1),_c('div',{staticClass:"col-12 col-sm-5"})]):_vm._e(),(_vm.tabs === 2)?_c('div',{staticClass:"row pa-4"},[_c('div',{staticClass:"col-12 col-sm-7"},[_c('KeyValueBlock',{attrs:{"key-values":[
                {
                  title: 'Straße',
                  value: _vm.customer.street,
                  isEdit: false,
                  editBtn:{
                    icon:'mdi-check-circle',
                    entity: 'customers',
                    id: _vm.customer.id,
                    row: 'street'
                  }
                },
                {
                  title: 'Hausnummer',
                  value: _vm.customer.houseNumber,
                  isEdit: false,
                  editBtn:{
                    icon:'mdi-check-circle',
                    entity: 'customer',
                    id: _vm.customer.id,
                    row: 'houseNumber'
                  }
                },
                {
                  title: 'Postleitzahl',
                  value: _vm.customer.plz,
                  isEdit: false,
                  editBtn:{
                    icon:'mdi-check-circle',
                    entity: 'customer',
                    id: _vm.customer.id,
                    row: 'plz'
                  }
                },
                {
                  title: 'Ort',
                  value: _vm.customer.city,
                  isEdit: false,
                  editBtn:{
                    icon:'mdi-check-circle',
                    entity: 'customer',
                    id: _vm.customer.id,
                    row: 'city'
                  }
                },
            ]},on:{"editEntry":_vm.editEntry}})],1),_c('div',{staticClass:"col-12 col-sm-5"},[(_vm.customer.street !== '' && _vm.customer.street !== undefined && _vm.customer.street !== null)?_c('div',[_c('p',[_vm._v(" "+_vm._s(_vm.customer.street)+" "+_vm._s(_vm.customer.houseNumber)),_c('br'),_vm._v(" "+_vm._s(_vm.customer.plz)+" "+_vm._s(_vm.customer.city)+" ")])]):_vm._e()])]):_vm._e(),(_vm.tabs === 3)?_c('div',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"primary",on:{"click":function($event){_vm.dialogInvoice =true}}},[_vm._v(" Neue Rechnung ")]),_c('VirtualScrollInvoice',{attrs:{"items":_vm.invoices,"noResultsText":'Du hast aktuell keine offenen Rechnungen für diese Hochzeit.'}})],1)],1)],1)],1):_vm._e(),(_vm.tabs === 4)?_c('div',{staticClass:"row pa-4"},[_c('div',{staticClass:"col-12 col-sm-7"},[_c('KeyValueBlock',{attrs:{"key-values":[
            {title: 'Erstellt am', value: _vm.getStringDate(_vm.customer.created), isEdit: false},
            ]}})],1),_c('div',{staticClass:"col-12 col-sm-5"})]):_vm._e()],1)],1):_vm._e(),_c('CreateInvoice',{attrs:{"dialog":_vm.dialogInvoice,"customerSelection":_vm.customer.id},on:{"hideDialog":function($event){_vm.dialogInvoice = false}}}),_c('CreateWedding',{attrs:{"dialog":_vm.dialogCreateWedding,"openAfterCreate":true,"customerSelection":_vm.customer.id},on:{"hideDialog":_vm.hideDialog}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }