<template>
    <v-container>
        <Message
                :message="message"
                @closeAlert="message.show = false"
        ></Message>
        <v-card flat class="detailCard pa-8 rounded-xl mt-6">
            <div v-if="loginEnabled">

                <v-row class="pt-4 align-center">
                    <v-col cols="12" md="6" v-if="!send && loaded">
                        <h1 class="pb-3">Problem melden / Support</h1>
                        <p>Du kommst nicht weiter? Schildere uns dein Problem und wir leiten alle weiteren Schritte
                            ein. </p>
                        <small>Leider ist es uns aktuell <b>noch</b> nicht möglich einen telefonischen Support bereit zu
                            stellen. Wir bitten um dein Verständnis!</small>
                        <h4 class="pt-4">Support-Ticket erstellen</h4>
                        <v-row>
                            <v-col>
                                <v-text-field
                                        label="E-Mail Adresse"
                                        v-model="email"
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                        label="Betreff"
                                        v-model="subject">
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-textarea
                                        label="Beschreibung des Problems"
                                        v-model="description"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-spacer></v-spacer>
                            <v-btn
                                    class="primary"
                                    @click="saveBugReport()"
                            >Senden
                            </v-btn>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="6" v-else-if="!loaded">
                        <Spinner></Spinner>
                    </v-col>
                    <v-col cols="12" md="6" class="text-center" v-else>
                        <h3>Nachricht erfolgreich versendet.</h3>
                    </v-col>

                    <v-col cols="12" md="6" class=" pa-16 text-center">
                        <v-img
                                :src="require('@/assets/images/drawSVGs/bug.svg')"
                                max width="350px"
                                class="d-inline-flex"
                        />
                    </v-col>
                </v-row>
            </div>
            <div v-else>
                <h1 class="pb-3">Problem melden / Support</h1>
                <div class="text-center">
                    <v-img
                            :src="require('@/assets/images/drawSVGs/builder.svg')"
                            max-width="150px"
                            class="d-inline-flex mt-3"
                            style="transform: scaleX(-1);"/>
                    <br>
                    <p>Wir nehmen gerade einige Änderungen vor und sind daher vorrübergehend nicht erreichbar. Bitte
                        versuche es später noch einmal.
                    </p>
                </div>
            </div>

        </v-card>
    </v-container>
</template>

<script>

import {mapGetters} from "vuex";
import {success, msgObj} from "@/helper/helper";

const Message = () => import("@/components/generalUI/Message");
const Spinner = () => import("@/components/generalUI/Spinner");

export default {
    name: "Support",
    components: {
        Message,
        Spinner
    },
    computed: {
        ...mapGetters('auth', {
            user: 'user',
            loginEnabled: 'loginEnabled',

        }),
    },
    mounted() {
        this.$store.dispatch('auth/getIsLoginDisabled').then(() => {

        })
    },
    data() {
        return {
            email: "",
            subject: "",
            description: "",
            message: msgObj(),
            loaded: true,
            send: false
        }
    },
    methods: {
        saveBugReport() {
            this.loaded = false
            this.$store.dispatch("support/saveBugReport", {
                uid: this.user.id,
                email: this.email,
                subject: this.subject,
                description: this.description,
                bug: true,
                optimizing: false
            }).then(() => {
                this.send = true
                this.message = success('Meldung erfolgreich versendet.')
            }).finally(() => {
                this.loaded = true
            })
        }
    }
}
</script>

<style lang="sass" scoped>
</style>
<style scoped>
</style>
