<template>
    <v-container class="pb-16 ">
        <Message
                :message="message"
                @closeAlert="message.show = false"
        ></Message>
        <v-row>
            <v-col>
                <h1 class="pb-6">Sitzplan anordnung <v-btn class="primary ms-3 " @click="save"><v-icon>mdi-disk</v-icon> Anordnung speichern</v-btn></h1>

                <div>
                    <ul>
                        <li>
                            <small>
                                Verschiebe die Tische per Drag & Drop
                            </small>
                        </li>
                        <li>
                            <small>
                                Du kannst Tische größer und kleiner ziehen
                            </small>
                        </li>
                        <li>
                            <small>
                                Halte die Maus gedrückt, um Gäste für einen Tisch ein- und auszublenden
                            </small>
                        </li>
                        <li>
                            <small>
                                Erstellte Tische tauchen an der linken Seite auf (Evtl. weiter unten)
                            </small>
                        </li>
                        <li>
                            <small>
                                <b>Speichere deine Änderungen!</b>
                            </small>
                        </li>
                    </ul>

                </div>
                <v-card class="rounded-xl mt-6" flat        style="min-height: 1000px; min-width: 1400px; max-height:1200px; max-width:2000px">
                    <SeatPlanComp

                        v-for="table of tables" :key="table.id"
                        :h="table.h"
                        :w="table.w"
                        :x="table.x"
                        :y="table.y"
                        :table="table"
                        @setPosition="setPosition($event)"
                    >
                    </SeatPlanComp>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>


import {mapGetters} from "vuex";

const Message = () => import("@/components/generalUI/Message");
import { msgObj} from "@/helper/helper";
import {error, success} from "../../../../helper/helper";
const SeatPlanComp = () => import("@/components/entities/wedding/SeatPlan/SeatPlanComp.vue");

/**
 * User can plan budget for own wedding with different categories.
 * Every category has items and each item's budget can be planned
 * @displayName BudgetPlan
 *
 */
export default {
    components: {
        Message,
        SeatPlanComp
    },
    name: "GuestList",
    props: {
        guests: Array,
        tables: Array,
        tablesSelection: Array,
        SeatPlanId: String,
    },
    data() {
        return {
            message: msgObj(),
            newPositions: {}
        }
    },
    computed: {
        ...mapGetters('seatPlan', {
            guests: 'guests',
            tables: 'tables',
            seatPlan: 'seatPlan',
            tablesSelection: 'tablesSelection'
        }),
    },
    /**
     * Check if user is logged in and then get budgetplan of logged user
     *
     */
    mounted() {
        this.$store.dispatch('auth/checkLogin', {}).then((res) => {
            this.$store.dispatch('seatPlan/getSeatPlan', {
                uid: res,
                seatPlanId: this.$route.params.id
            }).then(() => {

            }).catch((err) => {
                this.message = error(err)
            })
        })
    },
    methods: {
        setPosition(coordinates)
        {
            console.log(coordinates)
            let map = this.newPositions
            map[coordinates.tableId] = coordinates
            console.log(map)
            this.newPositions = map
        },
        save(){
            for(let position in this.newPositions){
                this.$store.dispatch('seatPlan/updatePositions', this.newPositions[position]).then(() => {
                    this.message = success('Erfolgreich gespeichert')
                    this.$store.dispatch('seatPlan/getSeatPlan', {
                        seatPlanId: this.$route.params.id
                    }).then(() => {

                    }).catch((err) => {
                        this.message = error(err)
                    })
                }).catch((err) => {
                    this.message = error(err)
                })
            }
        }
    }


}
</script>

<style scoped>
.row {
    border-width: thin;
}

v-container {
    type: 150vh;
}

.sliders {
    padding: 1.5em
}

</style>
