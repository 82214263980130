<template>
  <v-container class="fadeInFast">
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>

    <HeadlineTableView
        :icon="'mdi-calendar'"
        :headline="'Termine'"
        :button-icon="'mdi-calendar'"
        :button-text="'Neuer Termin'"
        @buttonClick="openDialogCreateEvent()"
    ></HeadlineTableView>

    <v-card flat class="rounded-xl pa-2 pa-sm-4">
      <CalendarComponent
          v-if="loaded"
          :events="events"
          @deleteEvent="deleteEvent($event)"
      ></CalendarComponent>
      <Spinner v-else></Spinner>

      <CreateEvent
          :dialog="dialog"
          @hideDialog="hideDialog"
      ></CreateEvent>
    </v-card>
  </v-container>
</template>
<script>

import {mapGetters} from "vuex";
import {error, msgObj, success} from "@/helper/helper";

const CalendarComponent = () => import("@/components/entities/calendar/Calendar");
const HeadlineTableView = () => import("@/components/tableView/HeadlineTableView");
const Message = () => import("@/components/generalUI/Message");
const CreateEvent = () => import("@/components/dialog/createDialogs/CreateEvent");
const Spinner = () => import("@/components/generalUI/Spinner");

export default {
  name: "Calendar",
  components: {
    CalendarComponent,
    HeadlineTableView,
    Message,
    CreateEvent,
    Spinner
  },
  computed: {
    ...mapGetters('auth', {
      user: 'user',
    }),
    ...mapGetters('calendar', {
      events: 'events',
    }),
  },
  data() {
    return {
      dialog: false,
      message: msgObj(),
      datetime: null,
      loaded: false,
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    this.$store.dispatch('auth/checkLogin', {}).then((res) => {
      this.$store.dispatch('calendar/getEvents', {
        uid: res
      }).then(()=>{
        this.loaded = true
      }).catch((err) => {
        this.message = error(err)
      })
    })

  },
  methods: {
    openDialogCreateEvent() {
      this.dialog = true
    },
    hideDialog() {
      this.dialog = false
    },
    deleteEvent(payload) {
      console.log(payload)
      this.$store.dispatch('calendar/deleteEvent', {
        eventId: payload.eventId,
        uid: this.user.id
      }).then(() => {
            this.message = success("Termin wurde erfolgreich gelöscht.")
          }
      ).catch((err) => {
        this.message = error(err)
      })
    },
  }
}
</script>

<style lang="sass" scoped>
.v-card.on-hover.theme--dark
  background-color: rgba(#FFF, 0.8)

  > .v-card__text
    color: #000
</style>
<style scoped>
.border {
  border-right: 1px solid grey
}
</style>
