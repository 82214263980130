<template>
    <interact
            v-if="table.form === 'round'"
            draggable
            :dragOption="dragOption"
            resizable
            :resizeOption="resizeOption"
            class="circle-drag"
            :style="style"
            @dragmove="dragmove"
            @resizemove="resizemove"
            @hold="togglePopup"
    >
        <div class="">
            <p class="text-center pt-6 pb-6" style="background-color: rgba(255,255,255,0.3)">
                {{ table.name }} <br>
                <v-icon>mdi-account</v-icon>
                {{ table.takenSeats }}/{{ table.seats }}
            </p>
            <l-popup v-if="showPopup">

                <v-card class="pa-3"  style="z-index: 10000" min-width="220px">
                    <v-row v-for="guest of table.guests" :key="guest.id" class=" ">
                        <v-col class="col-8">
                            {{ guest.name }}
                        </v-col>
                        <v-col class="col-4">
                            <v-icon>mdi-account</v-icon>
                            {{ guest.count }}
                        </v-col>
                    </v-row>
                </v-card>
            </l-popup>
        </div>

    </interact>
    <interact
            v-else
            draggable
            :dragOption="dragOption"
            resizable
            :resizeOption="resizeOption"
            class="rect-drag"
            :style="style"
            @dragmove="dragmove"
            @resizemove="resizemove"
            @hold="togglePopup"

    >
        <div>
            <p class="text-center pt-2 pb-2" style="background-color: rgba(255,255,255,0.3)">
                {{ table.name }} <br>
                <v-icon>mdi-account</v-icon>
                {{ table.takenSeats }}/{{ table.seats }}
            </p>
            <l-popup v-if="showPopup">

                                <v-card class="pa-3 rounded-xl" min-width="220px" style="z-index: 10000">
                                    <v-row v-for="guest of table.guests" :key="guest.id" class=" ">
                                        <v-col class="col-8">
                                            {{ guest.name }}
                                        </v-col>
                                        <v-col class="col-4">
                                            <v-icon>mdi-account</v-icon>
                                            {{ guest.count }}
                                        </v-col>
                                    </v-row>
                                </v-card>

            </l-popup>
        </div>

    </interact>

</template>

<script>
import interact from "interactjs";

export default {
    name: "resizeDrag",
    data: () => ({
        resizeOption: {
            edges: {left: true, right: true, bottom: true, top: true}
        },
        dragOption: {
            modifiers: [
                interact.modifiers.restrictRect({
                    restriction: "parent",
                    endOnly: true
                })
            ],
        },
        showPopup: false
        // values for interact.js transformation

    }),
    props: {
        x: Number,
        y: Number,
        w: Number,
        h: Number,
        table: Object
    },
    computed: {
        style() {
            return {
                height: `${this.h}px`,
                width: `${this.w}px`,
                transform: `translate(${this.x}px, ${this.y}px)`,
                background: this.table.color
            };
        }
    },

    methods: {
        togglePopup() {
            this.showPopup = !this.showPopup
        },
        test() {
            console.log("test")
        },
        dragmove(event) {
            this.x += event.dx;
            this.y += event.dy;
            this.$emit('setPosition', {tableId: this.table.id, x: this.x, y: this.y, h: this.h, w: this.w})
        },
        resizemove(event) {
            this.w = event.rect.width;
            this.h = event.rect.height;
            this.x += event.deltaRect.left;
            this.y += event.deltaRect.top;
            this.$emit('setPosition', {tableId: this.table.id, x: this.x, y: this.y, h: this.h, w: this.w})
        }
    }
};
</script>

<style scoped>
.circle-drag {
    box-sizing: border-box;
    /* To prevent interact.js warnings */
    user-select: none;
    -ms-touch-action: none;
    touch-action: none;
    border-radius: 100%;

}

.rect-drag {
    box-sizing: border-box;
    background: #41b883;
    /* To prevent interact.js warnings */
    user-select: none;
    -ms-touch-action: none;
    touch-action: none;
}
</style>
